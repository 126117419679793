import { useContext } from 'react';
import { store } from 'components/Store';
import { useAuth0 } from '@auth0/auth0-react';
import ClientsPicker from './ClientsPicker';
import NavPages from './NavPages';
import { useNavigate, useLocation } from 'react-router-dom';

const useCloseSectionsCondition = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { clickedOnContentUrl } = state;

  const location = useLocation();

  const savedItems = location.pathname.startsWith('/saved-items');
  const search = location.pathname.startsWith('/search-results');
  const calendar = location.pathname.startsWith('/calendar');
  const specificPaths = savedItems || search || calendar;
  const clickOnSearchItem = clickedOnContentUrl?.startsWith('/search-results');

  return { specificPaths, clickOnSearchItem, savedItems, search, calendar };
};

const ClientNavigation = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { readyKeywordLists, prevUrl } = state;

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const { resetClientNavigation } = useResetClientNavigation();

  const { specificPaths, clickOnSearchItem, savedItems, search, calendar } = useCloseSectionsCondition();

  const createNameOfCloseButton = () => {
    if (savedItems) {
      return `saved items`;
    } else if (search || clickOnSearchItem) {
      return `search`;
    } else if (calendar) {
      return `calendar`;
    } else {
      return '';
    }
  };

  return (
    <>
      {readyKeywordLists && isAuthenticated && (
        <>
          {specificPaths || clickOnSearchItem ? (
            <div className='w-100 flex-centered justify-content-end rounded-pill pr-3'>
              <button
                className='rounded-pill py-1 general-button bg-yellow text-white client-navigation-close-button'
                onClick={() => {
                  navigate(prevUrl ?? '/');
                  resetClientNavigation();
                }}
              >
                Close {createNameOfCloseButton()}
                <span className='d-inline-block icon-close-bold paragraph-p6' />
              </button>
            </div>
          ) : (
            <div className='flex-centered bg-white text-main-blue rounded-pill agency-clients-navigation'>
              <ClientsPicker dropdownAdditionalClass={'main-client-picker-lists'} />
              <NavPages />
            </div>
          )}
        </>
      )}
    </>
  );
};

const useResetClientNavigation = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const resetClientNavigation = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'prevUrl',
      value: null,
    });
    dispatch({ type: 'MODIFY_SECTION', parameter: 'clickedOnContentUrl', value: null });
  };

  const resetClientState = () => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'readySetCurrentTeam', value: null });
    dispatch({ type: 'MODIFY_SECTION', parameter: 'dispatchSearchForClients', value: null });
  };

  return { resetClientNavigation, resetClientState };
};

export { useResetClientNavigation, useCloseSectionsCondition };
export default ClientNavigation;
