import React, { useState } from 'react';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import { useRef } from 'react';
import { CrmContactImage } from 'components/Dashboard/Influence/CrmContacts/CrmContactsTable/CrmContactItem/CrmContactItem';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { useCollapse } from 'react-collapsed';
import useEmailRecipientInfo from './useEmailRecipientInfo';
import AdditionalRecipientInfo from './AdditionalRecipientInfo';
import { useParams } from 'react-router-dom';
import useGetContactById from 'components/Dashboard/Influence/CrmContacts/UpdateCustomContact/useGetContactById';
import RemovedContactModal from './RemovedContactModal';
import CustomScrollbar from 'components/Common/CustomScrollbar';

function EmailRecipientsBody({ contactsByActiveTab, searchValue }) {
  const [heightContainer, containerRef] = useHeightContainer();
  const scrollBarRef = useRef(null);
  const columns = ['Recipient', 'Status'];
  const [sort, setSort] = useState(['Recipient', 'ascending']);
  const [showRemovedContactsModal, setShowRemovedContactsModal] = useState(false);
  const { getContactById } = useGetContactById();

  const showHideRemovedContactModal = () => {
    setShowRemovedContactsModal(!showRemovedContactsModal);
  };

  const handleChangeSortParam = (col) => {
    if (sort[0] === col) {
      if (sort[1] === 'ascending') {
        setSort([col, 'descending']);
      } else if (sort[1] === 'descending') {
        setSort([]);
      }
    } else {
      setSort([col, 'ascending']);
    }
  };
  const renderTableHeading = () => {
    const columnOnSort = (col) => {
      if (sort) {
        return sort[0] === col ? sort[1] : false;
      }
    };
    const renderSortArrows = (sort) => {
      return (
        <div className='crm-table-arrows-container email-recipients-table-arrows'>
          <span style={{ opacity: sort === 'ascending' ? 1 : 0.4 }} />
          <span style={{ opacity: sort === 'descending' ? 1 : 0.4 }} />
        </div>
      );
    };
    return (
      <div className='email-recipients-table-header email-recipients-table-row'>
        {columns.map((col, index) => {
          return (
            <div key={index} className='email-recipients-table-cell' onClick={() => handleChangeSortParam(col)}>
              <span>{col}</span>
              {renderSortArrows(columnOnSort(col))}
            </div>
          );
        })}
      </div>
    );
  };

  const RecipientsTableRow = ({ recipient, showHideRemovedContactModal }) => {
    const [isExpanded, setOpen] = useState(false);
    const params = useParams();
    const { crmEmailId } = params;
    const { getRecipientInfo, isLoading, recipientInfo } = useEmailRecipientInfo();
    const { getToggleProps, getCollapseProps } = useCollapse({
      isExpanded,
    });
    let { fullName, status, imageId, stakeholderContactId, clickedUrl, crmEmailSendRecordId, crmContactId } = recipient;

    if (status === 'NotSent') {
      status = 'Not sent';
    }

    const getStatusColor = () => {
      if (status === 'Bounced') {
        return { color: '#B70202' };
      }
    };

    const recipientClickHandler = async (e) => {
      e.stopPropagation();
      if (stakeholderContactId != null) {
        window.open(`/stakeholders/${stakeholderContactId}/${normalize(fullName)}`, '_blank');
      } else {
        try {
          const contact = await getContactById(crmContactId);
          if (contact) {
            window.open(`../directory/update/${crmContactId}`, '_blank');
          } else {
            showHideRemovedContactModal();
          }
        } catch (err) {}
      }
    };

    return (
      <>
        <div
          className='email-recipients-table-row email-recipients-regular-row'
          key={crmEmailSendRecordId}
          {...getToggleProps({
            onClick: () => {
              if (!isExpanded) {
                getRecipientInfo({
                  emailId: crmEmailId,
                  contactId: crmContactId,
                });
              }
              setOpen((prev) => !prev);
            },
          })}
          tabIndex={-1}
        >
          <div className='email-recipients-regular-row-first-cell'>
            <div
              className='recipients-row-arrow'
              style={isExpanded ? { transform: 'rotate(90deg)', marginTop: '8px' } : undefined}
              tabIndex={-1}
            />
            <CrmContactImage imageId={imageId} />
            <div className='blue-link-underline-recipient' onClick={recipientClickHandler}>
              {fullName}
            </div>
            <span className='icon-external-bold external-link-recipient'></span>
          </div>
          <div className='email-recipients-regular-row-second-cell'>
            <span style={getStatusColor()}>{status}</span>
            {status === 'Clicked' && (
              <a className='email-performance-link' href={clickedUrl} target='_blank' rel='noreferrer'>
                {clickedUrl}
              </a>
            )}
          </div>
        </div>
        <div {...getCollapseProps()} className='email-recipients-expanded-content'>
          <AdditionalRecipientInfo isLoading={isLoading} info={recipientInfo} status={status} />
        </div>
      </>
    );
  };

  const prepearedRecipients = () => {
    const filteredRecipientsBySearch = contactsByActiveTab.filter((item) =>
      item?.fullName?.toLowerCase().includes(searchValue.toLowerCase())
    );
    if (sort[0] === 'Recipient') {
      if (sort[1] === 'ascending') {
        return filteredRecipientsBySearch.sort(function (a, b) {
          return b.fullName.length - a.fullName.length;
        });
      } else {
        return filteredRecipientsBySearch.sort(function (a, b) {
          return a.fullName.length - b.fullName.length;
        });
      }
    }
    if (sort[0] === 'Status') {
      if (sort[1] === 'ascending') {
        return filteredRecipientsBySearch.sort(function (a, b) {
          return b.fullName.length - a.fullName.length;
        });
      } else {
        return filteredRecipientsBySearch.sort(function (a, b) {
          return a.fullName.length - b.fullName.length;
        });
      }
    }
    return filteredRecipientsBySearch;
  };

  return (
    <div className='email-performance-section-container email-recipients-body recipients-container'>
      {prepearedRecipients().length ? (
        <>
          {renderTableHeading()}
          <div ref={containerRef} />
          <CustomScrollbar
            className={`main-content-scrollbar stakeholder-list-scrollbar`}
            style={{ height: `${heightContainer - 58}px` }}
            maximalThumbYSize={100}
            ref={scrollBarRef}
          >
            {prepearedRecipients().map((recipient, index) => (
              <RecipientsTableRow
                recipient={recipient}
                key={`recipient-${index}`}
                showHideRemovedContactModal={showHideRemovedContactModal}
              />
            ))}
          </CustomScrollbar>
          <RemovedContactModal showPopUp={showRemovedContactsModal} setShowPopup={setShowRemovedContactsModal} />
        </>
      ) : (
        <>
          <div ref={containerRef} />
          <div className='empty-recipients-message' style={{ height: `${heightContainer - 58}px` }}>
            {!!searchValue.length && (
              <>
                <p className='empty-message-no-results'>Contact not found in this send</p>
                <p>The contact you are searching for was not included in this email send</p>
              </>
            )}
            {!searchValue.length && <p className='engaged-links-empty-state'>Nothing to show</p>}
          </div>
        </>
      )}
    </div>
  );
}

export default EmailRecipientsBody;
