import { commentatorsTypes } from 'components/Dashboard/UserContent/ui/PoliticalUpdates';
import currentDate from 'components/Dashboard/utilities/currentDateUTC';

const Sections = [
  {
    name: 'KeyUpdates',
    type: 'KeyUpdate',
  },
  {
    name: 'Consultations',
    type: 'Consultation',
    filters: [{ field: 'closeDate', value: currentDate, operator: 'DATE_GTE' }],
  },
  {
    name: 'Legislation',
  },
  {
    name: 'ParliamentaryRecord',
    collapseField: 'contentUrl',
  },
  {
    name: 'LibraryMaterial',
    type: 'CommonsLibraryArticle',
  },
  {
    name: 'UpcomingEvents',
    type: 'Event',
    urlName: 'calendar',
  },
  {
    name: 'Stakeholders',
    type: 'stakeholder',
  },
  {
    name: 'Tweets',
    type: 'tweet',
    filters: commentatorsTypes.map((value) => {
      return { field: 'policyMogulUserType', value, operator: 'str_eq' };
    }),
  },
  {
    name: 'LobbyingMaterial',
    type: 'UserContent',
  },
];

if (process.env.REACT_APP_ENABLE_TWITTER === 'false') {
  let twitterPosition = Sections.findIndex((item) => item.name === 'Tweets');
  Sections.splice(twitterPosition, 1);
}

export default Sections;
