import { useContext } from 'react';
import { store } from 'components/Store';
import { consultantAgenciesMembers } from 'components/Dashboard/sidebar/parliamentarianUsers';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';

const useTopicOrClientWord = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, activeSubscriptions, userProfile } = state;
  const { code, allowMultipleTeams } = activePlan;
  const { planCode } = activeSubscriptions;
  const topicOrClientBase =
    (Object.keys(activePlan).length > 0 || Object.keys(activeSubscriptions).length > 0) &&
    consultantAgenciesMembers(code ?? planCode);

  const topicOrClientWord = topicOrClientBase && !allowMultipleTeams ? 'client' : 'topic';

  const transformTopicOrClientWord = ({ plural, uppercase }) => {
    let word = topicOrClientWord;
    if (plural) {
      word = `${topicOrClientWord}s`;
    }
    if (uppercase) {
      word = uppercaseFirstLetter(word);
    }
    return word;
  };

  const agencyUserBase =
    topicOrClientBase ||
    ((userProfile?.userType === 'Lobbying or PR agency' || userProfile?.userType === 'Consultant') &&
      activePlan?.name === 'Policy Influence');

  const agencyUser = agencyUserBase && !allowMultipleTeams;
  const newAgencyPlan = agencyUserBase && allowMultipleTeams;

  return { topicOrClientWord, newAgencyPlan, transformTopicOrClientWord, agencyUser, agencyUserBase };
};

export default useTopicOrClientWord;
