import React, { useContext, useState, useEffect, useRef } from 'react';
import { store } from 'components/Store.js';
import StakeHolderNoMogulUsers from 'components/Dashboard/StakeHolders/StakeHolderNoMogulUsers.js';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import addMetaTags from 'components/Dashboard/utilities/addMetaTags.js';
import SkeletonKeyUpdates from './SkeletonKeyUpdates.js';

const NoAllowedReference = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan } = state;
  const { enableReferenceSection } = activePlan;
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const initialCall = useRef();
  initialCall.current = () => {
    let activePlanItems = Object.keys(activePlan);
    if (activePlanItems.length > 0 || !isAuthenticated) {
      setIsLoading(false);
      if (!enableReferenceSection) {
        setPageTitle();
      }
    }
  };

  useEffect(() => {
    initialCall.current();
  }, []);

  const setPageTitle = () => {
    let title = `Reference - PolicyMogul`;
    let hash = '';
    addMetaTags({ title, hash, location, dispatch });
  };

  return (
    <>
      {isLoading && <SkeletonKeyUpdates />}
      {!isLoading && (
        <>
          <StakeHolderNoMogulUsers
            title={'Search the political landscape'}
            subtext={
              'Search current and historic political updates from hundreds of official and unofficial sources across the UK'
            }
            image={'/images/20220210-reference-screenshot.png'}
          />
        </>
      )}
    </>
  );
};

export default NoAllowedReference;
