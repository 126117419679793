import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import './App.scss';
import { Route, BrowserRouter, Navigate, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'components/Login/Loading';
import Initialise from 'components/Login/Initialise';
import NeedsVerification from 'components/Login/NeedsVerification';
import { StateProvider, store } from 'components/Store';
import ReactNotification from 'react-notifications-component';
import useTrackingCodes, { useGetUserHash } from 'components/TrackingCodes';
import Register from 'components/Login/Register';
import AcceptInvite from 'components/Login/AcceptInvite';
import { EndOfTrial } from 'components/Login/EndOfTrial';
import PricingPage from 'components/Pricing/PricingPage';
import CheckPlan from 'components/Settings/CheckPlan';
import CreateProfile from 'components/Login/CreateProfile';
import LoginSuccess from 'components/Login/LoginSuccess';
import Home from 'components/home/home';
import EmbedPage from 'components/embed';
import * as Sentry from '@sentry/react';
import InfluenceAddContent from 'components/Dashboard/UserContent/InfluenceAddContent';
import mobileCheck from 'components/Dashboard/helpers/mobileCheck';
import RegistrationVerification from 'components/Login/RegistrationVerification';
import Dashboard from 'components/Dashboard';
import Settings from 'components/Settings';
import InfluenceCreate from 'components/Dashboard/UserContent/InfluenceCreateContainer.js';
import Admin from 'components/Admin';
import SavedItems from 'components/SavedItems';
import GimmickPopUp from 'components/Dashboard/components/GimmickPopup';
import SentimentComponent from 'components/Dashboard/Sentiment';
import SharePage from 'components/Dashboard/UserContent/ShareUserContentPage';
import InfluenceAIContainer from 'components/Dashboard/UserContent/InfluenceAIContainer';
import ReferralCode from 'components/Dashboard/ReferralCode/ReferralCode';
import EmbedAnalytics from 'components/EmbedAnalytics';
import OauthCallback from 'components/Settings/SlackIntegration/OauthCallback';
import parliamentarianUsers, {
  consultantAgenciesMembers,
  membershipMembers,
} from 'components/Dashboard/sidebar/parliamentarianUsers';
import SlackIntegration from 'components/Dashboard/SlackIntegration/SlackIntegration';
import Modal from 'react-modal';
import EmailVerificationSucccess from 'components/Dashboard/EmailVerificationSucccess';
import StakeHolders from 'components/Dashboard/StakeHolders/index.js';
import NoAllowedReference from 'components/Dashboard/key_updates/NoAllowedReference.js';
import Analytics from 'components/Dashboard/Analytics/index.js';
import ListRoutes from 'components/Dashboard/ListRoutes.js';
import InfluenceContainer from 'components/Dashboard/Influence/index.js';
import Redirections, { RedirectionsNoAuthenticated } from './Routes/Redirections.js';
import WritingAssitant from 'components/WritingAssistant/index.js';
import ContentRoutes from 'components/Dashboard/ContentRoutes.js';
import { ContentMainRoutesMapping } from './Routes/ContentMainRoutes.js';
import axios from 'axios';
import getActivePlan from 'components/Settings/Utilities/getActivePlan.js';
import normalize from 'components/Dashboard/utilities/normalizeString.js';
import Reports from 'components/Reports/index.js';
import CalendarList from 'components/Dashboard/Upcoming_events/CalendarList.js';
import Campaigns from 'components/Dashboard/Campaigns';
import useInfluenceRoutesMapping from './Routes/useInfluenceRoutesMapping.js';
import SearchResults from 'components/Dashboard/SearchControl/SearchResults.js';
import Election2024 from 'components/Temporary/Election2024.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import { FiltersProvider } from 'components/Dashboard/Filters/FiltersContext.js';
import removeLocalStorage from 'components/Dashboard/Navigation/RemoveLocalStorage.js';
import DataHubRedirect from './Routes/DataHubRedirect';

const App = () => {
  const { isAuthenticated, isLoading, error, user, loginWithRedirect } = useAuth0();
  Modal.setAppElement('#root');

  useEffect(() => {
    if (isAuthenticated && user !== undefined) {
      var userId = `${user.sub}`;
      var emailAddress = `${user.email}`;

      Sentry.setUser({ username: userId, email: emailAddress });

      if (window.hj !== undefined) {
        window.hj('identify', userId, {
          email: emailAddress,
        });
      }

      if (window.clarity !== undefined) {
        window.clarity('identify', userId, null, null, emailAddress);

        window.clarity('set', 'Auth0ID', userId);
        window.clarity('set', 'Email', emailAddress);
    }
    }
    if (mobileCheck()) {
      //method from Intercom to hide the button on mobile
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  }, [user, isAuthenticated]);

  // TC: Do not run this code if we are a bot as it will result in the page not rendering for the bot
  if (!/bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent)) {
    if (error) {
      // if it is a state mismatch error, run the login again
      // with the react SDK using the universal login.
      //
      // As there is an active session the user will log in seamlessly without
      // the need to present credentials.
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${process.env.REACT_APP_APPURL}/login-success`,
        },
      });
    }
    if (isLoading) {
      return <Loading />;
    }
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <StateProvider>
          <FiltersProvider>
            <ContentApp />
          </FiltersProvider>
        </StateProvider>
      </Suspense>
    </BrowserRouter>
  );
};

const ContentApp = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan } = state;
  const { enableStakeholders, code } = activePlan ?? {};
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated, user, logout } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const location = useLocation();
  const navigate = useNavigate();

  const { InfluenceRoutesMapping, createParameters } = useInfluenceRoutesMapping();
  const { getUserHash } = useGetUserHash();

  const updateViewQuery = useRef();
  updateViewQuery.current = () => {
    const searchQuery = new URLSearchParams(location.search);
    if (
      !!activePlan &&
      !searchQuery.get('view') &&
      Object.keys(activePlan).length > 0 &&
      !location.pathname.startsWith('/settings')
    ) {
      let view = '';
      if (consultantAgenciesMembers(code)) {
        view = 'c';
      }
      if (parliamentarianUsers(code)) {
        view = 'p';
      }
      if (membershipMembers(code)) {
        view = 'm';
      }
      if (view !== '') {
        searchQuery.set('view', view);
        navigate(
          {
            search: searchQuery.toString(),
            hash: window.location.hash,
          },
          {
            replace: true,
            state: location?.state,
          }
        );
      }
    }
  };

  const callActivePlan = useRef();
  callActivePlan.current = async (source) => {
    const intercomConditional = window.Intercom && `${process.env.REACT_APP_INTERCOM_APP_ID}`.trim() !== '';
    try {
      if (location.pathname !== '/logout') {
        //INTERCOM
          if (isAuthenticated) {
            let hmac = await getUserHash(source);
            if (hmac?.status === 401) {
              return logout({ logoutParams: { returnTo: window.location.origin } });
            }
            if (!!hmac) {
            if (intercomConditional) {
              window.Intercom('boot', {
                app_id: `${process.env.REACT_APP_INTERCOM_APP_ID}`,
                name: user.name,
                email: user.email,
                user_id: user.sub,
                custom_launcher_selector: '.contact-button-launch-messenger',
                user_hash: hmac,
              });
            }
              let activePlan =
                Object.keys(state.activePlan).length === 0
                  ? await getActivePlan(getAccessToken, source, logout)
                  : state.activePlan;
              if (!!activePlan) {
                dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
                setIsLoading(false);
              }
            }
          } else {
          if (intercomConditional) {
            window.Intercom('boot', {
              app_id: `${process.env.REACT_APP_INTERCOM_APP_ID}`,
              custom_launcher_selector: '.contact-button-launch-messenger',
            });
          }
            setIsLoading(false);
          }
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useTrackingCodes();
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callActivePlan.current(source);
    return () => {
      source.cancel('Main app loading cancelled by the user');
    };
  }, []);

  //AE: This replaces the scrollToTop element
  useEffect(() => {
    window.scrollTo(0, 0);
    const paths = ['/', '/pricing', '/data-hub'];
    if (!isAuthenticated && paths.some((path) => location.pathname === path)) {
      document.querySelector('body').classList.add('body-home-page');
    }
    return () => {
      document.querySelector('body').classList.remove('body-home-page');
    };
  }, [location?.pathname]);

  useEffect(() => {
    setTimeout(() => {
      updateViewQuery.current();
    }, 10);
  }, [activePlan, location.pathname]);

  return (
    <>
      <ReactNotification />
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          {Redirections(location)}
          {!isAuthenticated && RedirectionsNoAuthenticated(location)}
          <Route
            path='/'
            element={isAuthenticated ? <Navigate to={`/monitor-inbox${window.location.search}`} /> : <Home />}
          ></Route>
          <Route path='/integration-with-slack' element={SlackIntegration} />
          <Route path='/email-verification-success' element={<EmailVerificationSucccess />} />
          <Route path='/login-success' element={<LoginSuccess />} />
          <Route path='/needs-verification' element={<NeedsVerification />} />
          <Route path={'/create-your-profile/*'} element={<CreateProfile />}>
            <Route path=':step' element={<CreateProfile />} />
          </Route>
          <Route path='/initialise' element={<Initialise />} />
          <Route path='/accept-invite' element={<AcceptInvite />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Register login={true} />}></Route>
          <Route
            path='/registration-verification'
            element={isAuthenticated ? <Navigate replace to={`/`} /> : <RegistrationVerification />}
          ></Route>
          <Route
            path='/referral-registration-verification'
            element={isAuthenticated ? <Navigate replace to={`/`} /> : <RegistrationVerification />}
          ></Route>
          <Route path='/end-of-trial' element={<EndOfTrial />} />
          <Route path='/end-of-subscription' element={<EndOfTrial />} />
          <Route path='/sentiment' element={<SentimentComponent />} />
          <Route path={'/data-hub/*'} element={<DataHubRedirect />}>
            <Route path={'*'} element={<Analytics />} />
          </Route>

          <Route exact path='/general-election-2024/*' element={<Dashboard />}>
            <Route path={'*'} element={<Election2024 />} />
          </Route>

          <Route path='/logout' element={<Logout />} />
          <Route path='/slack-oauth-callback' element={<OauthCallback />} />
          <Route path={`/pricing`} element={<PricingPage />} />
          <Route path={`/admin/*`} element={<Admin />} />

          <Route path={'/saved-items/*'} element={<Dashboard />}>
            <Route path={``} element={<SavedItems />} />
            <Route path={`:id(\\d+)`} element={<SavedItems />} />
          </Route>

          <Route path={`/settings/check-plan`} element={<CheckPlan />} />
          <Route path='/settings/*' element={<Settings />}>
            <Route path='*' element={<Settings />} />
          </Route>
          <Route path='/influence/ai/:title' element={<InfluenceAIContainer />} />
          <Route path='/influence/:page/create/:step' element={<InfluenceCreate />} />
          <Route path='/influence/:page/edit/:id/:step' element={<InfluenceCreate />} />
          <Route path='/influence/add-content' element={<InfluenceAddContent />} />

          <Route path={'/policymakers/*'} element={<Dashboard />}>
            <Route path='*' element={<InfluenceContainer />}>
              <Route path='' element={<StakeHolders />} />
            </Route>
          </Route>

          <Route path={`/influence/*`} element={<Dashboard />}>
            {Object.keys(InfluenceRoutesMapping).map((section) => {
              const { element, parameters } = InfluenceRoutesMapping[section];
              return (
                <Route key={`route-${section}`} path={`${section}/*`} element={<InfluenceContainer />}>
                  <Route index path={'*'} element={element} />
                  {parameters && <>{createParameters(parameters, element)}</>}
                </Route>
              );
            })}
            <Route
              index
              element={
                <Navigate
                  replace
                  to={`/influence/${
                    enableStakeholders ? `stakeholder-mapping` : 'lobbying-material'
                  }${location.search}${location.hash}`}
                />
              }
            ></Route>
          </Route>
          <Route path='/share/:type/:id' element={<SharePage />} />
          <Route path='/r/:referralCode' element={<ReferralCode />} />

          <Route path={`/reference/upgrade`} element={<NoAllowedReference />} />
          <Route path={'/calendar/*'} element={<Dashboard />}>
            <Route path=':startdate' element={<CalendarList />} />
            <Route path={'*'} element={<CalendarList />} />
          </Route>
          <Route path={'/campaigns/*'} element={<Dashboard />}>
            <Route path={'*'} element={<Campaigns />} />
          </Route>

          <Route path={'/analytics/*'} element={<Dashboard />}>
            <Route path={'*'} element={<Analytics />} />
          </Route>
          <Route path={'/ai-assistant/*'} element={<Dashboard />}>
            <Route index path={'*'} element={<WritingAssitant />} />
          </Route>
          <Route path={'/reports/*'} element={<Dashboard />}>
            <Route index path={'*'} element={<Reports />} />
          </Route>

          {Object.keys(ContentMainRoutesMapping).map((section) => {
            const { element, parameters } = ContentMainRoutesMapping[section];
            return (
              <Route key={`${normalize(section)}`} path={`/${section}/*`} element={<Dashboard />}>
                <Route path={`*`} element={<ContentRoutes />}>
                  {parameters ? (
                    <>{createParameters(parameters, element)}</>
                  ) : (
                    <>
                      <Route path={`:id`} element={<ContentRoutes />} />
                      <Route path={`:id/:title`} element={element} />
                    </>
                  )}
                </Route>
              </Route>
            );
          })}
          <Route path='/search-results' element={<SearchResults />} />
          <Route path='/:mainSection/*' element={<Dashboard />}>
            <Route path='*' element={<ListRoutes />} />
          </Route>
          <Route element={<NoMatchPage />} />
        </Routes>
      )}
      <GimmickPopUp />
    </>
  );
};

const NoMatchPage = () => {
  return <h3 className='px-5'>404 - Not found</h3>;
};

const Logout = () => {
  const { logout } = useAuth0();

  const logoutWithRedirect = () => logout({ logoutParams: { returnTo: window.location.origin } });
  const logoutFunction = useRef();
  logoutFunction.current = () => {
    logoutWithRedirect();
    removeLocalStorage();
  };

  useEffect(() => {
    logoutFunction.current();
  }, []);
  return <Loading />;
};

const EmbedApp = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <StateProvider>
          <ReactNotification />
          <Routes>
            <Route path='/embed' element={<EmbedPage />} />
            <Route path='/embed-analytics' element={<EmbedAnalytics />} />
          </Routes>
        </StateProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export { EmbedApp };
export default App;
