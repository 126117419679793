import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatMonthFirstChar, formatShortMonth, startOfMonth } from 'components/Dashboard/helpers/formatGeneralDate';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import { Link } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, Filler, Legend);

ChartJS.defaults.font.family = 'Lato';

const PoliticalMentionsChart = (props) => {
  const { data, topic, setShowPopup, enableAnalytics } = props;
  const labels = data?.labels ?? [];
  const emptyMessage = labels.length === 0;

  let options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgb(244, 239, 237)',
        borderColor: 'rgba(0, 18, 43, 0.3)',
        borderWidth: 1,
        titleColor: 'rgb(0, 18, 43)',
        bodyColor: 'rgb(0, 18, 43)',
        padding: 12,
        titleFont: { size: 14 },
        displayColors: false,
        callbacks: {
          title: function (context) {
            let label = context?.[0]?.label || '';
            const currentMonth = startOfMonth(label).diff(startOfMonth(new Date())) >= 0;
            return currentMonth ? formatShortMonth(label) + ' (so far)' : formatShortMonth(label);
          },
          label: function (context) {
            return `${context.dataset.label}: ${thousandCommas(context.formattedValue)}`;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          maxTicksLimit: 5,
          callback: function (value) {
            let labelOfTick = this.getLabelForValue(value);
            return formatMonthFirstChar(labelOfTick);
          },
          padding: 10,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
  };

  const color = '0, 28, 53';
  const dash = (ctx) => (ctx.p0DataIndex > labels.length - 3 ? [6, 6] : [2, 0]);

  let dataContent = {
    labels,
    datasets: [
      {
        label: `Parliamentary mentions`,
        data: data?.value,
        borderWidth: 2,
        hoverBorderWidth: 2,
        pointRadius: 3,
        pointHoverRadius: 3,
        pointBackgroundColor: `rgb(${color})`,
        pointHoverBackgroundColor: `rgb(${color})`,
        pointBorderWidth: 2,
        borderColor: `rgb(${color})`,
        fill: true,
        hoverBackgroundColor: 'transparent',
        backgroundColor: 'transparent',
      },
    ],
  };
  dataContent = {
    ...dataContent,
    datasets: [
      {
        ...dataContent.datasets[0],
        segment: {
          borderDash: (ctx) => dash(ctx) || [2, 0],
        },
      },
    ],
  };

  return (
    <div className={`${emptyMessage ? 'flex-centered justify-content-center' : 'mt-2'} h-100 w-100 `}>
      {emptyMessage ? (
        <p className='no-related-message mb-0' style={{ marginTop: '8px', lineHeight: 'normal' }}>
          <span className='icon-warning-triangle'></span>
          No mentions in Parliament in the last 6 months
        </p>
      ) : (
        <div className='pointer' style={{ height: '100px', marginBottom: '-10px' }}>
          <Link
            to={`/analytics/mentions?topic-id=${topic?.id}#~dates:last-6-months~resolution:month~style:line~group-by:overall~nations:uk-wales-scotland`}
            className={'w-100 h-100 d-block'}
            target='_blank'
            rel='noopener noreferrer'
            onClick={(e) => {
              if (!enableAnalytics) {
                e.preventDefault();
                setShowPopup(true);
              }
            }}
          >
            <Line options={options} data={dataContent} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default PoliticalMentionsChart;
