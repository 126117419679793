import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { store } from 'components/Store';
import { useLocation } from 'react-router-dom';
import normalize from 'components/Dashboard/utilities/normalizeString';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';

const NoResultsTeam = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, activePlan, teamContentFilter } = state;

  const { results, influenceSection, text, engagementStatistics } = props;
  const [heightContainer, containerRef] = useHeightContainer();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const isMobile = useIsMobile(992);
  const location = useLocation();

  return (
    <>
      <div
        className={`${
          influenceSection ? 'influence-box settings-box-all-screen p-4' : ''
        } settings-box d-lg-flex justify-content-lg-center align-items-lg-center`}
        ref={containerRef}
        style={{
          height: `${heightContainer}px`,
        }}
      >
        <div className={`text-center message-content`}>
          {results?.length === 0 && !teamContentFilter && (
            <div style={{ marginTop: isMobile ? '150px' : '-50px' }} className='text-center'>
              <p
                className='light-50-text title-h5-m mb-5'
                style={{
                  maxWidth: influenceSection
                    ? engagementStatistics
                      ? '460px'
                      : `${newAgencyPlan ? '720' : '600'}px`
                    : undefined,
                  margin: influenceSection ? '0 auto' : undefined,
                }}
              >
                {text ??
                  (newAgencyPlan
                    ? `Lobbying material - including policy asks, briefing material, and news releases - is shared with relevant policymakers. Content is published as if from the client, not ${activePlan?.organisationName}. You will be asked to confirm that  ${team?.teamName} has given authority to publish content on their behalf`
                    : agencyUser
                      ? 'Lobbying material you draft or publish on behalf of clients will appear here'
                      : influenceSection
                        ? 'Policy asks, briefing material and news releases are shared with organisations and parliamentarians based on their interests'
                        : 'Your draft and published lobbying material will appear here')}
              </p>
              <Link
                to='/influence/add-content?step=1'
                className='action-button general-button rounded-button px-5 py-2'
              >
                Add lobbying material{' '}
                {newAgencyPlan ? <span className='d-none d-lg-inline-block'> for {team?.teamName}</span> : ''}
              </Link>
            </div>
          )}
          {results?.length === 0 && !!teamContentFilter && (
            <>
              <p className='message-text title-h5-m main-light-text mx-auto'>{`Your team has not created any ${TransformFromPascalCase(
                teamContentFilter?.type
              )?.toLowerCase()} yet`}</p>
              <Link
                to={`/influence/${normalize(teamContentFilter?.name)}/create/1?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`}
                className='action-button roundend-action-button text-uppercase d-inline-block'
              >
                Add{' '}
                {teamContentFilter?.name === 'Policy asks'
                  ? 'a policy ask'
                  : TransformFromPascalCase(teamContentFilter?.type)?.toLowerCase()}
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NoResultsTeam;
