import React, { useContext } from 'react';
import { store } from 'components/Store';
import { formatDate } from 'components/Dashboard/helpers/formatDate';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import Visibility from './ui/Visibility';
const InfluenceVisibilityContainer = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { sectionValues, type } = props;
  const { fields } = sectionValues;
  const influenceTypeName = type === 'policy-asks' ? 'policy ask' : 'briefing material';
  const { agencyUser } = useTopicOrClientWord();
  const options = [
    {
      title: 'Anyone',
      subtext: `Organisations, parliamentarians and others will be able to see and support your ${influenceTypeName}`,
      option: 'Anyone',
    },
    {
      title: 'Stakeholders only',
      subtext: `Only parliamentarians and their staff registered on PolicyMogul will be able to see and support your ${influenceTypeName}`,
      option: 'StakeholdersOnly',
      disabled: !activePlan?.enableUserContentStakeholderOnly,
    },
  ];

  const dateOptions = [
    {
      name: '1 month',
      value: formatDate(1, 'month'),
    },
    {
      name: '3 months',
      value: formatDate(3, 'month'),
    },
    {
      name: '6 months',
      value: formatDate(6, 'month'),
    },
    {
      name: '12 months',
      value: formatDate(12, 'month'),
    },
    {
      name: 'On a specific date',
      value: 'picker',
    },
    {
      name: 'No expiry date',
      value: null,
    },
  ];
  const compileVisibilityOptions = () => {
    let visibilityOptions = {
      visibility: {
        options: options,
      },
      organisationWebsite: {},
      websiteLink: {},
      date: {
        options: dateOptions,
      },
      location: {},
      keywords: {},
    };
    if (agencyUser) {
      delete visibilityOptions.websiteLink;
    }
    let items = Object.keys(visibilityOptions);
    let changeValues = (field) => {
      switch (field) {
        case 'location':
          return 'region';
        case 'date':
          return 'expirationDate';
        default:
          return field;
      }
    };
    let findItem = (field) => {
      return fields.find((item) => item.name === changeValues(field));
    };
    items.forEach((item) => {
      visibilityOptions[item].value = findItem(item)?.value;
      visibilityOptions[item].setValue = findItem(item)?.setValue;
    });
    return visibilityOptions;
  };
  return <Visibility visibilityOptions={compileVisibilityOptions()} {...props} />;
};

export default InfluenceVisibilityContainer;
