import React, { useContext } from 'react';
import { Navigate, Route } from 'react-router-dom';
import StakeHolders from 'components/Dashboard/StakeHolders';
import ListRoutes from 'components/Dashboard/ListRoutes';
import EngagementStatistics from 'components/Dashboard/Influence/EngagementStatistics/EngagementStatistics';
import EmailPerformance from 'components/Dashboard/Influence/EmailPerformance/EmailPerformance';
import CrmEmails from 'components/Dashboard/Influence/CrmEmails';
import CrmContacts from 'components/Dashboard/Influence/CrmContacts';
import UpdateCustomContact from 'components/Dashboard/Influence/CrmContacts/UpdateCustomContact/UpdateCustomContact';
import EmailComponent from 'components/Dashboard/Influence/CrmEmails/CrmEmailComponent';
import TeamContent from 'components/Settings/TeamContent';
import { store } from 'components/Store';
import { useAuth0 } from '@auth0/auth0-react';
import parliamentarianUsers from 'components/Dashboard/sidebar/parliamentarianUsers';
import { InfluenceWritingAssistant } from 'components/Dashboard/Influence';
import CrmGroups from 'components/Dashboard/Influence/CrmGroups/CrmGroups';
import GroupView from 'components/Dashboard/Influence/CrmGroups/GroupView';

const useInfluenceRoutesMapping = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableStakeholders, enableCrm, teamName, code } = activePlan ?? {};

  const { isAuthenticated, user } = useAuth0();

  const hideInfluenceSection =
    parliamentarianUsers(code) || (teamName === 'NCA' && user.email !== 'james.mitchell@nca.gov.uk');

  const InfluenceRoutesMapping = {
    'have-your-voice-heard': {
      element: <Navigate replace to={`/influence/lobbying-material`} />,
    },
    'stakeholder-mapping': {
      element: hideInfluenceSection ? (
        <Navigate replace to={`/policymakers`} />
      ) : !enableStakeholders && isAuthenticated ? (
        <Navigate replace to={`/influence`} />
      ) : (
        <StakeHolders />
      ),
    },
    directory: {
      element: !enableCrm ? <Navigate replace to={`/influence`} /> : <CrmContacts />,
      parameters: [
        {
          param: 'update/:id',
          static: true,
          element: !enableCrm ? <Navigate replace to={`/influence`} /> : <UpdateCustomContact />,
        },
      ],
    },
    'my-groups': {
      element: !enableCrm ? <Navigate replace to={`/influence`} /> : <CrmGroups />,
      parameters: [
        {
          param: ':groupId',
          static: true,
          element: !enableCrm ? <Navigate replace to={`/influence`} /> : <GroupView />,
        },
      ],
    },
    emails: {
      element: !enableCrm ? <Navigate replace to={`/influence`} /> : <CrmEmails />,
      parameters: [
        {
          // if it can be static word along with dynamic param pass it with coma
          param: 'create,:crmEmailId',
          element: !enableCrm ? <Navigate replace to={`/influence`} /> : <EmailComponent />,
        },
      ],
    },
    'email-performance': {
      element: !enableCrm ? <Navigate replace to={`/influence`} /> : <EmailPerformance />,
      parameters: [
        {
          param: 'crmEmailId',
          element: !enableCrm ? <Navigate replace to={`/influence`} /> : <EmailPerformance />,
        },
      ],
    },
    'lobbying-material': {
      element: (
        <div className='flex-grow-1'>
          <TeamContent />
        </div>
      ),
    },
    'engagement-statistics': {
      element: <EngagementStatistics />,
      parameters: [
        {
          param: 'type/:itemId',
          element: <EngagementStatistics />,
        },
      ],
    },
    stakeholders: {
      element: <ListRoutes />,
    },
    'ai-assistant': {
      element: <InfluenceWritingAssistant />,
    },
  };

  const createParameters = (parameters, element) => {
    let parameter = '';
    let elements = [];
    for (let i = 0; i < parameters.length; i++) {
      // if it can be static word along with dynamic param pass it with coma
      if (parameters[i]?.param?.includes(',')) {
        parameters[i]?.param.split(',').forEach((parameter) => {
          elements.push(
            <Route path={parameter} key={`route-${i}-${parameter}`} element={parameters[i]?.element ?? element} />
          );
        });
      } else {
        let newParam = parameters[i];
        parameter += `${parameter === '' ? '' : '/'}${newParam?.static ? '' : ':'}${newParam?.param ?? newParam}`;
        elements.push(<Route path={parameter} key={`route-${i}`} element={newParam?.element ?? element} />);
      }
    }
    return elements;
  };

  return { InfluenceRoutesMapping, createParameters };
};

export default useInfluenceRoutesMapping;
