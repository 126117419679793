import idsWithoutImages from 'components/Dashboard/key_updates/idsWithoutImages';
import ReactDOM from 'react-dom';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { useLocation } from 'react-router-dom';

const useExternalTooltipHandler = () => {
  const location = useLocation();
  const dashboard =
    location.pathname.includes('analytics/dashboard') || location.pathname.startsWith('/embed-analytics');

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.className = 'tooltip-trends-information-box';
      tooltipEl.style.background = 'rgba(255, 255, 255)';
      tooltipEl.style.borderRadius = '5px';
      tooltipEl.style.boxShadow = '0px 2px 10px rgba(0, 0, 0, 0.25)';
      tooltipEl.style.color = '#212529';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'fixed';
      tooltipEl.style.transform = 'translate(-50%, 15px)';
      tooltipEl.style.maxWidth = dashboard ? '310px' : '590px';
      tooltipEl.style.minWidth = dashboard ? '310px' : '590px';
      tooltipEl.style.transition = 'all .1s ease';
      tooltipEl.style.padding = '0px';
      tooltipEl.style.zIndex = '1000000';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const tableBody = document.createElement('tbody');
      const point = tooltip.dataPoints[tooltip.dataPoints.length - 1];
      const { imageId } = point.raw;

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const tdImage = document.createElement('td');
      tdImage.style.borderWidth = 0;
      tdImage.style.width = '32%';
      tdImage.style.position = 'relative';
      const image = document.createElement('div');
      image.className = 'h-100 w-100 hover-stakeholder-image';
      image.style = `position: absolute; top: 0; left: 0; background-image: ${
        imageId !== null && imageId !== 0 && !idsWithoutImages.includes(imageId)
          ? `url('https://www.parliamenttoday.com/image.php?imageid=${imageId}&ml=1')`
          : `url('${process.env.REACT_APP_CDNURL}/images/20220714-stakeholders-placeholder.png')`
      }`;
      tdImage.appendChild(image);

      const tdText = document.createElement('td');
      tdText.style.borderWidth = 0;
      tdText.style.width = dashboard ? '100&' : '68%';
      tdText.style.fontSize = '13px';
      tdText.style.padding = '1rem 0.75rem';

      const stakeholderLink = document.createElement('div');
      ReactDOM.render(<StakeholderInformation data={point.raw} />, stakeholderLink);

      tdText.appendChild(stakeholderLink);
      tr.appendChild(tdText);
      if (!dashboard) {
        tr.appendChild(tdImage);
      }
      tableBody.appendChild(tr);

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children

      tableRoot.style.width = '100%';
      tableRoot.appendChild(tableBody);
    }

    const { left: positionX, top: positionY, width } = chart.canvas.getBoundingClientRect();
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    let tooltipHeight = tooltipEl.getBoundingClientRect().height;
    let tooltipWidth = tooltipEl.getBoundingClientRect().width;
    let leftPosition = positionX + tooltip.caretX;
    let topPosition = positionY + tooltip.caretY;

    if (topPosition + tooltipHeight > window.innerHeight) {
      topPosition = positionY + tooltip.caretY - (tooltipHeight + 35);
    }

    if (leftPosition + tooltipWidth > positionX + width) {
      leftPosition = positionX + tooltip.caretX - tooltipWidth / 3;
    }

    if (leftPosition < 190) {
      leftPosition = 190;
    }

    tooltipEl.style.left = leftPosition + 'px';
    tooltipEl.style.top = topPosition + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
  };

  return { externalTooltipHandler };
};

const StakeholderInformation = (props) => {
  const dashboard =
    window.location.pathname.includes('analytics/dashboard') || window.location.pathname.startsWith('/embed-analytics');
  const { data } = props;
  const {
    name,
    textBody,
    keywordMatchedAppgs,
    keywordMatchedLordSelectCommittees,
    keywordMatchedSelectCommittees,
    positions,
  } = data;
  const renderPositions = (positions, title, finalWord) => {
    return (
      <>
        {positions?.length > 0 && (
          <>
            <ul className='policy-list px-0 mb-0'>
              {positions.map((item, index) => (
                <li
                  key={`position-${normalize(name)}-${index}`}
                  className={`mb-2 paragraph-p4 committe-subtitle ${title === 'positions' ? 'position-subtitle' : ''}`}
                >
                  {title !== 'positions' ? 'Member of' : ''} {item} {finalWord}
                </li>
              ))}
            </ul>
          </>
        )}
      </>
    );
  };

  const addNumbers = (value) => {
    return data[`${value}Count12mo`] + data[`${value}Count3mo`] + data[`${value}Count1mo`] + data[`${value}Count1w`];
  };

  const createNumbers = (values) => {
    let finalNumber = 0;
    values.forEach((value) => {
      finalNumber += addNumbers(value);
    });
    return finalNumber;
  };

  const parliamentaryContributionsNumber = createNumbers(['parliamentaryContribution', 'writtenQuestionsAsked']);
  const tweetsNumber = createNumbers(['tweet', 'retweet'], 'stake-information-tweet', 'Tweet');
  return (
    <>
      <div className={`pb-2 px-2 ${dashboard ? '' : 'border-bottom mb-3'}`}>
        <p className='font-weight-bold mb-0 paragraph-p2'>{name}</p>
        <p className='font-weight-normal paragraph-p3 main-subtle-text mb-0'>{textBody}</p>
      </div>
      {!dashboard && (
        <div className='px-2'>
          {(positions?.length > 0 ||
            keywordMatchedLordSelectCommittees?.length > 0 ||
            keywordMatchedAppgs?.length > 0 ||
            keywordMatchedSelectCommittees?.length > 0) && (
            <h3 className='text-uppercase paragraph-p5-bold blue-text mt-3 mb-2'>Influence</h3>
          )}
          <div className='pb-2'>
            {renderPositions(positions, 'positions')}
            {renderPositions(keywordMatchedSelectCommittees, 'select committees', 'select committee')}
            {renderPositions(keywordMatchedAppgs, 'APPGs', 'APPG')}
            {renderPositions(keywordMatchedLordSelectCommittees, 'lord select committees', 'lord select committee')}
          </div>
          {(parliamentaryContributionsNumber > 0 ||
            (process.env.REACT_APP_ENABLE_TWITTER === 'true' && tweetsNumber > 0)) && (
            <h3 className='text-uppercase paragraph-p5-bold blue-text mb-2'>INTEREST IN THIS TOPIC</h3>
          )}
          <ul className='policy-list px-0 mb-0'>
            {parliamentaryContributionsNumber > 0 && (
              <li className={`stake-information-parliamentary mb-2 paragraph-p4 committe-subtitle`}>
                {parliamentaryContributionsNumber} relevant parliamentary contribution
                {parliamentaryContributionsNumber > 1 ? 's' : ''} in the last 12 months
              </li>
            )}
            {tweetsNumber > 0 && process.env.REACT_APP_ENABLE_TWITTER === 'true' && (
              <li className={`stake-information-tweet mb-2 paragraph-p4 committe-subtitle`}>
                {tweetsNumber} relevant Tweet{tweetsNumber > 1 ? 's' : ''} in the last 12 months
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default useExternalTooltipHandler;
