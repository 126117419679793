import axios from 'axios';
import createNotification from 'components/Settings/Utilities/CreateNotification.js';
import { net_api_url } from 'components/Store';
const generalApiCall = async (props) => {
  const { method, pathname, requestProperties, requestHeaders, notShowErrorMessage, returnCompleteRequest } = props;
  try {
    let request = await axios[method](
      `${net_api_url}${pathname}`,
      method === 'post' ? requestProperties : requestHeaders,
      method === 'post' ? requestHeaders : ''
    );
    return returnCompleteRequest ? request : request.data;
  } catch (error) {
    let message = `An error ocurred.`;
    if (typeof error?.response?.data === 'string' && !!error?.response?.data) {
      message = error?.response?.data;
    }
    if (!!error?.response?.data?.message) {
      message = error.response.data.message;
    }
    if (!notShowErrorMessage) {
      createNotification('danger', message);
    }
  }
};

export default generalApiCall;
