import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import ModalComponent from 'components/Common/Modal';

const ContentPopup = (props) => {
  const { type } = props;
  const { agencyUser } = useTopicOrClientWord();

  const createTitle = () => {
    switch (type) {
      case 'stakeholders':
        return 'Influence starts with insight';
      case 'contacts':
        return 'Relationships are complicated';
      case 'email':
        return 'Contact policymakers';
      case 'analytics':
        return 'Analyse your impact';
      case 'analytics-page':
        return `Data that's off the charts`;
      default:
        return '';
    }
  };

  const createSubtitle = () => {
    switch (type) {
      case 'stakeholders':
        return 'Identify the ever-changing stakeholders relevant to your interests. Replace hours of research with the click of a button.';
      case 'contacts':
        return 'PolicyMogul makes them easier by enabling you to manage political stakeholders, write notes, add tags, send emails and track engagement.';
      case 'email':
        return 'PolicyMogul enables you to send personalised emails to policymakers, and track every open and click.';
      case 'analytics':
        return 'Track the performance of your email campaigns to policymakers across a range of metrics.';
      case 'analytics-page':
        return `Our reporting and analytics software gives you instant access to the insights that will transform your public affairs and campaigning work ${agencyUser ? 'on behalf of your clients' : ''} — no statistics degree required.`;
      default:
        return '';
    }
  };

  const mainTitle = () => {
    switch (type) {
      case 'stakeholders':
        return 'Stakeholder mapping';
      case 'analytics-page':
        return 'Analytics';
      default:
        return 'CRM';
    }
  };

  return (
    <div className='bg-white rounded-lg text-center relative'>
      <div className='d-lg-flex align-items-center'>
        <div className='bg-salmon rounded-left p-4 p-lg-5 text-left' style={{ minWidth: '720px' }}>
          <h3 className=' title-h2-bold mb-2'>{createTitle()}</h3>
          <p className='mb-5 title-h5 main-subtle-text' style={{ maxWidth: '500px' }}>
            {createSubtitle()}
          </p>
          <img
            style={type === 'analytics-page' ? null : { maxHeight: '298px' }}
            className='image-render-optimized img-fluid mb-3 not-include-feature-image'
            src={`${process.env.REACT_APP_CDNURL}/images/${type === 'analytics-page' ? `20230804-analytics-screenshot${agencyUser ? '-agency' : ''}` : `202305181-preview-${type}`}.png`}
            alt={`${type}`}
          />
        </div>
        <div className='grow px-4 px-lg-5 pb-5 mb-3 text-left d-none d-lg-block' style={{ minWidth: '300px' }}>
          <h3 className='title-h3-bold pt-5 mb-5'>{mainTitle()}</h3>

          <ul className='policy-list text-left title-h5-m statistics-popup-list px-0 pb-5 mb-5'>
            <li className='mb-3 nowrap-item text-main-blue statistics-list-active-item '>
              <span className='title-h3 mr-3 icon-close-circle' />
              Campaigner
            </li>
            <li className='mb-3 nowrap-item '>
              <span className={`title-h3 mr-3 icon-${type === 'stakeholders' ? 'small-check' : 'close-circle'}`} />
              Pro
            </li>
            <li className='mb-3 nowrap-item '>
              <span
                className={`title-h3 mr-3 icon-${type === 'analytics-page' && agencyUser ? 'close-circle' : 'small-check'}`}
              />
              Mogul
            </li>
            <li className='mb-3 nowrap-item '>
              <span className='title-h3 mr-3 icon-small-check' />
              Enterprise
            </li>
          </ul>

          <div className='text-center text-lg-right mt-4 mt-lg-0 w-100 pt-5'>
            <Link className='action-button rounded-button py-2 px-4 px-xl-5 title-h5 d-inline-block' to={`/pricing`}>
              Compare plans
              <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfluenceNotIncludedFeaturesPopup = (props) => {
  return (
    <Portal>
      <ModalComponent
        maxWidth={1020}
        overlayClassName={'rate-limit-popup react-modal-overlay'}
        isOpen={props.showPopup}
        setIsOpen={props.setShowPopup}
      >
        <ContentPopup {...props} />
      </ModalComponent>
    </Portal>
  );
};

export default InfluenceNotIncludedFeaturesPopup;
