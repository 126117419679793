import { Link } from 'react-router-dom';
import createUtmParameters from 'components/Dashboard/helpers/createUtmParameters';
import ModalComponent from 'components/Common/Modal';

const RateLimitPopupContent = () => {
  return (
    <div className='d-flex'>
      <div className='rounded-left bg-yellow py-5 px-2 pl-lg-4 sentiment-political-box pr-lg-0'>
        <div className='pr-4'>
          <div className='px-4'>
            <h2 className='title-h4-bold text-center'>Political data that’s off the charts</h2>
            <p className='title-h5-m text-center mb-0'>... and so much more</p>
          </div>
        </div>
        <ul className='policy-list px-2 pt-5 pr-lg-0'>
          <li className='sentiment-list-item sentiment-list-item-selected'>Political reporting and analysis</li>
          <li className='sentiment-list-item '>Real-time political monitoring</li>
          <li className='sentiment-list-item '>Lobby parliamentarians</li>
          <li className='sentiment-list-item '>Advanced stakeholder mapping</li>
        </ul>
        <Link
          to={`/register?reg_source=sentiment_page${createUtmParameters('&')}`}
          className='ml-4 general-button action-button rounded-button mt-4 mb-5 d-inline-block create-stakeholder-map py-2 sentiment-action-button'
        >
          Try it all, for free
        </Link>
      </div>
      <div className='flex-grow-1 h-100 d-none d-lg-block'>
        <img
          className='sentiment-screenshot'
          src={`${process.env.REACT_APP_CDNURL}/images/20220928-sentiment-screenshot.png`}
          alt='sentiment screenshot'
        />
      </div>
    </div>
  );
};

const RateLimitPopup = (props) => {
  return (
    <ModalComponent {...props} maxWidth={960} overlayClassName={'react-modal-overlay analytics-rate-limit-popup'}>
      <RateLimitPopupContent {...props} />
    </ModalComponent>
  );
};

export default RateLimitPopup;
