import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { useState, useRef, useContext, useEffect } from 'react';
import SkeletonKeyUpdates from 'components/Dashboard/key_updates/SkeletonKeyUpdates';
import { store } from 'components/Store';
import { WidgetEmptyMessage } from './MostInterestedStakeholder';
import useContentWidgetFunctions from './useContentWidgetFunctions';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import TopicPicker from 'components/Common/TopicPicker';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory';
import createMarkup from 'components/Dashboard/helpers/createMarkup';
import dayjs from 'dayjs';
import idsWithoutImages from 'components/Dashboard/key_updates/idsWithoutImages';
import ModalComponent from 'components/Common/Modal';
import { Portal } from 'react-portal';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import Axios from 'axios';

const LatestNews = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;

  const { item, widgetData, setWidgetData, previewMode, modifyItemLayout, report } = props;

  const [isLoading, setIsLoading] = useState(!widgetData);
  const [updatesSummaries, setUpdatesSummaries] = useState({ isOpen: false, update: null });
  const { previeMaxHeight, itemMinHeight, mainContentElement, itemsList, numberOfItemsShown } =
    useContentWidgetFunctions({ previewMode, isLoading, item, widgetData, modifyItemLayout });

  const topic = item?.data?.state?.topic ?? keywordsLists.filter((item) => item.id !== null)?.[0];

  const loadLatestNews = useRef();
  const initialWidgetData = useRef();
  const initialDeletedIds = useRef();
  const { generalApiCall } = useGeneralApiCall();

  loadLatestNews.current = async (source) => {
    try {
      const prospective = topic?.prospective;
      const deletedIds = item?.data?.state?.deletedIds ?? [];
      if (!widgetData) {
        setIsLoading(true);
      }
      const pathname = `/api/${!!prospective ? 'search/query' : 'search/query-by-keyword-list'}`;
      const method = 'post';
      let filters = [];
      if (deletedIds) {
        filters = deletedIds.map((id) => {
          return {
            field: 'id',
            value: `${id}`,
            operator: `str_not_eq`,
          };
        });
      }

      if (report?.updateTo) {
        filters = [
          ...filters,
          {
            field: 'dateTime',
            value: dayjs(report?.updateTo).add('1', 'day').format('YYYY-MM-DD'),
            operator: 'date_lt',
          },
        ];
      }

      let requestProperties = {
        type: 'KeyUpdate',
        includeHighlights: true,
        pageNumber: 1,
        pageSize: 7,
        sort: { field: 'dateTime', order: 'desc' },
        filters,
      };

      if (prospective) {
        requestProperties = {
          ...requestProperties,
          query: ChangeKeywordsCategory(topic?.keywords),
        };
      } else {
        requestProperties = {
          ...requestProperties,
          keywordListId: topic?.id,
        };
      }

      const results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
      if (results) {
        let hits = results?.hits;
        let { summaries } = item?.data?.state;
        if (summaries) {
          hits = hits.map((item) => {
            let summariItem = summaries[item.id];
            if (summariItem) {
              return { ...item, oneSentenceSummary: summariItem };
            } else {
              return item;
            }
          });
        }
        setWidgetData(hits);
        initialWidgetData.current = widgetData;
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if (!widgetData) {
      loadLatestNews.current(source);
    }
    return () => {
      source.cancel('Latest news cancelled');
    };
  }, [topic, item?.data?.state, report?.updateTo]);

  const removeNew = ({ newItem, setShowActions }) => {
    initialWidgetData.current = widgetData;
    let deletedIds = item?.data?.state?.deletedIds ?? [];
    initialDeletedIds.current = deletedIds;
    deletedIds.push(newItem.id);
    let newData = [...widgetData];
    let position = newData.findIndex((data) => data.id === newItem.id);
    setShowActions(false);
    newData.splice(position, 1);
    setWidgetData(newData);
    modifyItemLayout({ item, state: { ...item?.data?.state, deletedIds } });
    createNotification(
      'success',
      (() => {
        return (
          <span>
            Update removed.{' '}
            <span
              onClick={() => {
                setWidgetData(initialWidgetData.current);
                modifyItemLayout({ item, state: { ...item?.data?.state, deletedIds: initialDeletedIds.current } });
              }}
              className='text-underline'
            >
              Undo
            </span>
          </span>
        );
      })()
    );
  };

  const saveSummary = ({ update, newValue }) => {
    let newWidgetData = [...widgetData];
    let position = newWidgetData.findIndex((item) => item.id === update.id);
    let newItem = { ...widgetData[position] };
    newItem = { ...newItem, oneSentenceSummary: newValue };
    newWidgetData[position] = newItem;
    setWidgetData(newWidgetData);
    let summaries = item?.data?.state?.summaries ?? {};
    modifyItemLayout({ item, state: { ...item?.data?.state, summaries: { ...summaries, [newItem.id]: newValue } } });
  };

  const [commentaryHeight, setCommentaryHeight] = useState(0);
  useEffect(() => {
    let previousElement = mainContentElement?.current?.previousElementSibling;
    if (previousElement) {
      setCommentaryHeight(mainContentElement?.current?.previousElementSibling?.getBoundingClientRect()?.height + 24);
    }
  }, [mainContentElement?.current, item?.data?.state?.commentary]);

  const background = useRef();

  return (
    <>
      <div
        ref={mainContentElement}
        className={`overflow-hidden`}
        style={{
          height: `calc(100% - ${commentaryHeight}px)`,
          padding: '0 20px',
          maxHeight: previewMode ? previeMaxHeight : 'none',
        }}
      >
        {isLoading ? (
          <SkeletonKeyUpdates />
        ) : (
          <>
            {widgetData?.length === 0 ? (
              <WidgetEmptyMessage text={'latest news'} />
            ) : (
              <ul className={`policy-list px-0 items-list mb-0 pb-0`} ref={itemsList}>
                {widgetData?.map((newItem, index) => (
                  <LatestNewItem
                    removeNew={removeNew}
                    item={newItem}
                    key={`stakeholder-${index}`}
                    state={item?.data?.state}
                    previewMode={previewMode}
                    itemMinHeight={itemMinHeight}
                    numberOfItemsShown={numberOfItemsShown}
                    index={index}
                    showImages={item?.data?.state?.showImages}
                    setUpdatesSummaries={setUpdatesSummaries}
                    background={background}
                  />
                ))}
              </ul>
            )}
          </>
        )}
      </div>
      <ChangeSummaryPopup
        updatesSummaries={updatesSummaries}
        setUpdatesSummaries={setUpdatesSummaries}
        saveSummary={saveSummary}
        background={background.current}
      />
    </>
  );
};

const LatestNewItem = (props) => {
  const {
    itemMinHeight,
    previewMode,
    removeNew,
    item,
    index,
    numberOfItemsShown,
    showImages,
    setUpdatesSummaries,
    background,
  } = props;
  const { title, oneSentenceSummary, contentUrl, imageId } = item ?? {};

  const [showActions, setShowActions] = useState(false);
  const contentContainer = useRef();
  const buttonElement = useRef();

  const { top, left, height, width } = buttonElement?.current?.getBoundingClientRect() ?? {};

  const placeHolderUrl = `${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png`;
  const placeHolderMime = 'image/png';
  const urlImage =
    imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
      ? `${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(`https://www.parliamenttoday.com/image.php?imageid=${imageId}&mode=1`)}&mime=${encodeURIComponent(placeHolderMime)}&cache=true`
      : `${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(placeHolderUrl)}&mime=${encodeURIComponent(placeHolderMime)}&cache=true`;

  return (
    <div
      className={`position-relative report-stakeholder-item d-flex flex-column justify-content-center latest-new-item ${index === numberOfItemsShown - 1 ? '' : 'border-bottom'}`}
      style={{ minHeight: `${contentContainer.current?.scrollHeight + itemMinHeight}px` }}
    >
      <div className='py-3 pr-3 d-flex position-relative' ref={contentContainer}>
        <div className='flex-grow-1 pr-1'>
          <div className={`flex-centered mb-1 justify-content-between`}>
            <h4 className={`title-h5-bold mb-0`}>{title}</h4>
          </div>
          <div
            className='report-stakeholder-content hidden-lines hidden-three-lines main-light-text'
            dangerouslySetInnerHTML={createMarkup(oneSentenceSummary)}
          />
        </div>
        {showImages && (
          <div className='content-image d-none d-sm-block' style={{ backgroundImage: `url(${urlImage})` }} />
        )}

        {!previewMode && (
          <div className='z-max'>
            <button
              ref={buttonElement}
              className={`general-button circle-button item-actions-button`}
              style={showActions ? { backgroundColor: 'rgba(212,212,212,0.5)' } : null}
              onClick={(e) => {
                setShowActions(!showActions);
              }}
            >
              <span className='icon-three-dots light-50-text title-h5' />
            </button>
            {showActions && (
              <Portal>
                <div
                  className='general-close-background z-extra-max'
                  ref={background}
                  onClick={() => setShowActions(false)}
                />
                <div
                  className='box box-shadow py-2 item-actions-box reports-actions-box reports-latest-news-actions-box position-fixed'
                  style={{
                    top: `${top + height}px`,
                    left: `${left + width - 182}px`,
                    right: 'auto',
                  }}
                >
                  <a href={contentUrl} target={'_blank'} rel='noopener noreferrer' className='general-button'>
                    <span className='icon-external-bold' /> View update
                  </a>
                  <button
                    className='general-button'
                    onClick={() => {
                      setUpdatesSummaries({ isOpen: true, update: item });
                    }}
                  >
                    <span className='icon-edit-pencil-thick' /> Edit summary
                  </button>

                  <button
                    className='general-button'
                    onClick={() => {
                      removeNew({ newItem: item, setShowActions });
                    }}
                  >
                    <span className='icon-close-bold paragraph-p5 d-inline-block-centered' /> Remove update
                  </button>
                </div>
              </Portal>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const LatestPoliticalUpdatesSettings = (props) => {
  const globalState = useContext(store);
  const { state: appState } = globalState;
  const { keywordsLists } = appState;

  const { state, setState } = props;
  const { topic, showImages } = state ?? {};
  const { agencyUser } = useTopicOrClientWord();

  return (
    <>
      {!agencyUser && (
        <div className='mb-4 reports-topick-picker'>
          <label className='mt-4 mb-2'>Topic</label>
          <TopicPicker
            keywordsLists={keywordsLists.filter((item) => item.id !== null)}
            showTopicCases={true}
            searchFunction={({ topic }) => {
              setState({ ...state, topic });
            }}
            defaultTopic={topic}
            fixedPosition
            placeholder={`Select a topic`}
            showTopicSelected={true}
          />
        </div>
      )}
      <div className={`d-flex justify-content-between align-items-center pb-2  pt-4 mt-2`}>
        <p className='font-weight-bold'>Include images</p>
        <button
          className={`general-button filter-toggle filter-toogle-${showImages ? 'active' : 'inactive'}`}
          onClick={() => {
            setState({ ...state, showImages: !showImages });
          }}
        >
          <span className='toggle-item' />
        </button>
      </div>
    </>
  );
};

const ChangeSummaryPopup = (props) => {
  const { saveSummary, updatesSummaries, setUpdatesSummaries, background } = props;
  const { isOpen, update } = updatesSummaries ?? {};
  const { contentUrl, oneSentenceSummary, title } = update ?? {};

  const [value, setValue] = useState(oneSentenceSummary);
  const textareaElement = useRef();

  useEffect(() => {
    setValue(update?.oneSentenceSummary);
  }, [update]);

  useEffect(() => {
    setTimeout(() => {
      let el = textareaElement.current;
      if (isOpen && !!el) {
        let textareaLength = el.value.length;
        el.setSelectionRange(textareaLength, textareaLength);
        el.focus();
      }
    }, 300);
  }, [isOpen, textareaElement.current]);

  const closeModal = (value) => {
    setUpdatesSummaries({ ...updatesSummaries, isOpen: value });
    if (!!background) {
      background.click();
    }
  };

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={closeModal}>
      <div className='border-bottom px-5 py-4'>
        <h3 className='title-h5-m-bold mb-2 pr-2'>{title}</h3>
        <a
          href={`${process.env.REACT_APP_APPURL}${contentUrl}`}
          className='view-link d-inline-block'
          target='_blank'
          rel='noopener noreferrer'
        >
          <button className='view-link-button general-button pl-0'>View update</button>
        </a>
      </div>
      <div className='px-5 py-4'>
        <h3 className='title-h5-bold mb-0'>Summary</h3>
        <p className='mb-3 main-light-text'>
          {!!oneSentenceSummary ? `This summary was created using PolicyMogul's AI assistant` : ``}
        </p>
        <textarea
          ref={textareaElement}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className='main-input writing-textarea '
        ></textarea>

        <div className='text-right pt-4'>
          <button
            disabled={value === update?.oneSentenceSummary}
            className='general-button action-button px-5 py-2'
            onClick={() => {
              saveSummary({ update, newValue: value });
              closeModal(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export { LatestPoliticalUpdatesSettings };
export default LatestNews;
