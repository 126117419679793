import React, { useEffect, useRef, useState } from 'react';
import { formatGeneralDate } from 'components/Dashboard/helpers/formatGeneralDate';
import { ChartOptionsButton } from 'components/Dashboard/Analytics/ui/MentionsByTopic';
import {
  createDate,
  useAllowRightPanel,
  useChartDataOptionSelected,
} from 'components/Dashboard/Analytics/ui/MentionsByTopic/ChartDataTable';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';

const createColour = (value) => {
  switch (value) {
    case 'Positive':
      return 'rgba(163, 228, 86, 1)';
    case 'Negative':
      return 'rgba(227, 66, 66, 0.7)';
    case 'Mixed':
      return 'rgba(195, 147, 76, 0.7)';
    default:
      return 'rgba(212, 212, 212, 0.4)';
  }
};

const wordPerValue = (finalValue) => {
  let value;
  if (finalValue === 0) {
    value = 'Neutral';
  } else if (finalValue > 0) {
    value = 'Positive';
  } else if (finalValue < 0) {
    value = 'Negative';
  }
  return value;
};

const SentimentOverTimeDataTable = (props) => {
  const {
    analyticsState,
    rawData,
    labelDates,
    activeSection,
    highlightGraphOnHover,
    totalValues,
    chartDataContainer,
    isLoading,
    rightPanelState,
    setRightPanelState,
  } = props;
  const { currentView, restrictPanel } = analyticsState;

  const createSectionLabels = (label, e) => {
    let rawDataKeys = Object.keys(rawData);
    let lengths = rawDataKeys.map((item) => Object.keys(rawData[item]).length);
    let maxIndex = lengths.indexOf(Math.max(...lengths));
    let sentimentObj = JSON.parse(JSON.stringify(rawData[rawDataKeys[maxIndex]]));
    let pointValueMapping = {
      Negative: -1,
      Positive: 1,
      Neutral: 0,
    };
    if (label && e) {
      const element = e.target;
      const order = e.target.dataset.order;
      let newKeys = Object.keys(sentimentObj).sort((a, b) => {
        return order === 'asc'
          ? pointValueMapping[createPointValue(label, a).value] - pointValueMapping[createPointValue(label, b).value]
          : pointValueMapping[createPointValue(label, b).value] - pointValueMapping[createPointValue(label, a).value];
      });
      element.dataset.order = order === 'asc' ? 'desc' : 'asc';
      setContentKeys(newKeys);
    } else {
      return Object.keys(rawData[rawDataKeys[maxIndex]]);
    }
  };

  const [contentKeys, setContentKeys] = useState(createSectionLabels());

  useEffect(() => {
    let rawDataKeys = Object.keys(rawData);
    let lengths = rawDataKeys.map((item) => Object.keys(rawData[item]).length);
    let maxIndex = lengths.indexOf(Math.max(...lengths));
    setContentKeys(Object.keys(rawData[rawDataKeys[maxIndex]]));
  }, [rawData]);

  const createPointValue = (date, contentKey) => {
    let positiveData = rawData.positive?.[contentKey];
    let negativeData = rawData.negative?.[contentKey];

    let positiveValue = positiveData ? positiveData.find((item) => item.date === date).value : 0;
    let negativeValue = negativeData ? negativeData.find((item) => item.date === date).value : 0;

    let finalValue = positiveValue - negativeValue;
    let value = wordPerValue(finalValue);

    return { value, number: finalValue };
  };

  const { chartDataOption } = useChartDataOptionSelected();
  const topicParameter = getUrlParam('topic-id');

  useEffect(() => {
    if (!restrictPanel) {
      setRightPanelState({ open: false });
    }
  }, [chartDataOption, topicParameter, restrictPanel]);

  const moveTable = ({ el }) => {
    if (el) {
      setTimeout(() => {
        chartDataContainer.current.scrollLeft = el.offsetLeft - 20;
      }, 10);
    }
  };

  return (
    <>
      <div className='d-lg-flex align-items-center pt-5 pb-4 mb-1 sticky-item'>
        <div className='flex-centered'>
          <h3 className='title-h5-m-bold mb-0 mr-3 mr-lg-2'>Chart data</h3>
          <ChartOptionsButton analyticsState={analyticsState} isLoading={isLoading} />
        </div>
      </div>
      <div className='chart-data-container d-flex align-items-start'>
        {chartDataOption !== 'Overall' && (
          <div className='overflow-auto chart-data-labels-container'>
            <table className='table chart-data-labels chart-data-labels-sentiment-changes'>
              <thead>
                <tr>
                  <th className='font-weight-bold'>{chartDataOption}</th>
                </tr>
              </thead>
              <tbody>
                {chartDataOption !== 'Overall' && (
                  <>
                    {contentKeys.map((key, i) => {
                      return (
                        <tr key={`data-top-${i}`}>
                          <td>
                            <span className='hidden-lines hidden-one-line'>{key}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
                <tr>
                  <td className='font-weight-bold'>Total net sentiment</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div
          className={`overflow-auto chart-data-info-container flex-grow-1 ${chartDataOption !== 'Overall' ? '' : 'border-left'}`}
          ref={chartDataContainer}
        >
          <table className='table chart-data-info chart-data-info-sentiment-changes pointer'>
            <thead>
              <tr>
                {labelDates.map((label, index) => (
                  <th
                    onMouseOver={() => {
                      highlightGraphOnHover(index);
                    }}
                    onClick={(e) => {
                      createSectionLabels(label, e);
                    }}
                    data-order={'asc'}
                    className={`${chartDataOption === 'Overall' ? '' : 'sort-table-item'}  nowrap-item font-weight-bold ${
                      index === activeSection ? 'selected-label-total' : ''
                    }`}
                    key={`label-${label}`}
                  >
                    {formatGeneralDate(label, false, currentView === 'Month')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {chartDataOption !== 'Overall' && (
                <>
                  {contentKeys.map((key, i) => {
                    return (
                      <tr key={`content-table-${key}-${i}`}>
                        {labelDates.map((label, index) => {
                          let value = createPointValue(label, key).value;
                          let number = createPointValue(label, key).number;
                          return (
                            <CellItem
                              key={`date-${label}`}
                              index={index}
                              activeSection={activeSection}
                              value={value}
                              highlightGraphOnHover={highlightGraphOnHover}
                              analyticsState={analyticsState}
                              label={label}
                              dataKey={key}
                              rightPanelState={rightPanelState}
                              setRightPanelState={setRightPanelState}
                              rawData={rawData}
                              number={number}
                              moveTable={moveTable}
                            />
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}

              <tr>
                {totalValues.map((item, index) => {
                  return (
                    <CellTotal
                      key={`total-${item}-${index}`}
                      item={item}
                      index={index}
                      activeSection={activeSection}
                      highlightGraphOnHover={highlightGraphOnHover}
                      rightPanelState={rightPanelState}
                      labelDates={labelDates}
                      setRightPanelState={setRightPanelState}
                      moveTable={moveTable}
                      rawData={rawData}
                      analyticsState={analyticsState}
                    />
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const CellTotal = (props) => {
  const {
    item,
    index,
    activeSection,
    highlightGraphOnHover,
    rightPanelState,
    labelDates,
    setRightPanelState,
    moveTable,
    rawData,
    analyticsState,
  } = props;
  const { currentView } = analyticsState;
  const activeCell = rightPanelState?.date === labelDates[index] && rightPanelState?.dataKey === 'totalCell';
  const totalCell = useRef();
  const { delayShowPanelTitle } = useAllowRightPanel();

  return (
    <td
      ref={totalCell}
      key={`total-${item}-${index}`}
      className={`font-weight-bold ${index === activeSection ? 'selected-label-info' : ''}`}
      onMouseOver={() => {
        highlightGraphOnHover(index);
      }}
      style={{
        backgroundColor: activeCell ? '#0094CC' : createColour(wordPerValue(item)),
      }}
      onClick={() => {
        setRightPanelState({
          open: true,
          date: labelDates[index],
          rawData,
          text: () => (
            <span>
              Overall sentiment {createDate(currentView, labelDates[index])} by parliamentarians was{' '}
              <span className={`cell-item-${wordPerValue(item).toLowerCase()}`}>
                {wordPerValue(item).toLowerCase()}
              </span>
            </span>
          ),
          dataKey: 'totalCell',
          noResults: item === 0,
        });
        moveTable({ el: totalCell.current });
        delayShowPanelTitle();
      }}
    >
      <span className={`${activeCell ? '' : 'cell-element'} ${activeCell ? 'text-white font-weight-bold' : ''}`}>
        {wordPerValue(item)}
      </span>
    </td>
  );
};

const CellItem = (props) => {
  const {
    index,
    activeSection,
    highlightGraphOnHover,
    value,
    analyticsState,
    label,
    dataKey,
    rightPanelState,
    setRightPanelState,
    rawData,
    number,
    moveTable,
  } = props;
  const { currentView } = analyticsState;
  const labelLowerCase = value.toLowerCase();
  const { delayShowPanelTitle } = useAllowRightPanel();

  const createDataTip = () => {
    return (
      <span className='mb-0'>
        Overall sentiment {createDate(currentView, label)} by parliamentarians ({dataKey}) was{' '}
        <span className={`cell-item-${value.toLowerCase()}`}>{labelLowerCase}</span>
      </span>
    );
  };

  const activeCell = rightPanelState?.date === label && rightPanelState?.dataKey === dataKey;
  const cellItem = useRef();

  return (
    <td
      ref={cellItem}
      className={`${index === activeSection ? 'selected-label-info' : ''}`}
      onMouseOver={() => {
        highlightGraphOnHover(index);
      }}
      style={{ backgroundColor: activeCell ? '#0094CC' : createColour(value) }}
      onClick={(e) => {
        setRightPanelState({
          open: true,
          date: label,
          rawData,
          text: createDataTip,
          dataKey,
          noResults: number === 0,
        });
        moveTable({ el: cellItem.current });
        delayShowPanelTitle();
      }}
    >
      <span className={`${activeCell ? '' : 'cell-element'} ${activeCell ? 'text-white font-weight-bold' : ''}`}>
        {value}
      </span>
    </td>
  );
};

export { createColour, wordPerValue };
export default SentimentOverTimeDataTable;
