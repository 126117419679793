import React, { useContext, useMemo, useRef } from 'react';
import Share from 'components/Dashboard/components/Share';
import TweetItem from 'components/Dashboard/social_media/TweetItem';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import { Link, useNavigate } from 'react-router-dom';
import changeLabelOfContent from 'components/Dashboard/Parliamentary_record/ChangeLabelOfContent';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { store } from 'components/Store';
import useChangeClient from 'components/Dashboard/Navigation/useChangeClient';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

const SavedItemElements = (props) => {
  const { hits, rememberScrollPosition } = props;
  const transformResources = useRef();

  const { newAgencyPlan } = useTopicOrClientWord();

  transformResources.current = (item) => {
    let finalItem = { ...item, renderTitle: item.title };
    switch (item.type) {
      case 'KeyUpdate':
        finalItem = {
          ...finalItem,
          renderSubtitle: dayjs(item.dateTime).format('DD MMM[,] YYYY'),
          additionalItemsSubtitle: [dayjs(item.dateTime).format('HH:mm')],
        };
        break;
      case 'Consultation':
        finalItem = {
          ...finalItem,
          renderSubtitle: `Source: ${item.departmentName}`,
        };
        break;
      case 'Legislation':
        finalItem = {
          ...finalItem,
          renderSubtitle: `${item.primaryTypeOfBill}${item.contentType === 'Secondary' ? ' legislation' : ''}`,
          additionalItemsSubtitle: !!item.primarySession ? [`Session ${item.primarySession}`] : null,
        };
        break;
      case 'ParliamentaryRecord':
        const changeTypeOfContent = () => {
          if (!!item.hansardContentType) {
            return item.hansardContentType;
          } else if (item.contentType === 'QuestionAndAnswer') {
            return 'Written Q&As';
          } else if (item.contentType === 'Edm') {
            return 'Early Day Motion';
          } else {
            return changeLabelOfContent(item.contentType);
          }
        };

        finalItem = {
          ...finalItem,
          renderSubtitle: changeTypeOfContent(),
          additionalItemsSubtitle: [
            `${
              item.contentType.includes('QuestionAndAnswer') || item.contentType === 'Edm'
                ? item.questionHasAnswer === 'True'
                  ? `Answered on `
                  : `Tabled on `
                : ''
            } ${dayjs(item.dateTime).format('DD MMMM YYYY')}`,
          ],
        };
        break;
      case 'Stakeholder':
        finalItem = {
          ...finalItem,
          renderSubtitle: item.summary,
        };
        break;
      case 'UserContent':
        finalItem = {
          ...finalItem,
          renderSubtitle: `Lead organisation: ${item.organisation}`,
        };
        break;
      case 'Event':
        finalItem = {
          ...finalItem,
          contentUrl: `/calendar/${dayjs(item.dateTime).format('YYYY-MM-DD')}`,
        };
        break;
      case 'CrmContact':
        finalItem = {
          ...finalItem,
          renderSubtitle: newAgencyPlan ? <CrmContactSavedItemSubtitle clientId={item.teamId} /> : '',
        };
        break;
      case 'CommitteePublication':
        let getAdditionalItemsSubtitle = () => {
          let additionalItemsArr = [];
          if (item.committee) {
            additionalItemsArr = [...additionalItemsArr, item.committee];
          }
          if (item.publicationType) {
            additionalItemsArr = [...additionalItemsArr, item.publicationType];
          }
          return [...additionalItemsArr, dayjs(item.dateTime).format('DD MMMM YYYY')];
        };
        finalItem = {
          ...finalItem,
          renderSubtitle: `${item.house && item.house !== 'n/a' ? item.house.charAt(0).toUpperCase() : changeLabelOfContent(item.contentType)}${item.house && item.house !== 'n/a' ? item.house.slice(1) : ''}`,
          additionalItemsSubtitle: getAdditionalItemsSubtitle(),
        };
        break;
      default:
        finalItem = {
          ...finalItem,
        };
    }
    return finalItem;
  };

  return (
    <div className={`save-items-elements-list`}>
      {hits.map((item, index, array) => {
        const { type } = item;
        const explanations = [];
        const props = { index, item, array, explanations };
        if (type === 'Tweet') {
          return <TweetItem {...props} key={`${type}-item-${index}`} />;
        } else {
          return (
            <SavedItemElement
              {...props}
              key={`${type}-item-${index}`}
              item={transformResources.current(item)}
              rememberScrollPosition={rememberScrollPosition}
            />
          );
        }
      })}
    </div>
  );
};

const SavedItemElement = (props) => {
  const { item, index, array, rememberScrollPosition } = props;
  const { renderTitle, renderSubtitle, additionalItemsSubtitle, contentUrl } = item;
  const { newAgencyPlan } = useTopicOrClientWord();
  const { changeClientFunction } = useChangeClient();
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;
  const navigate = useNavigate();
  const objectType = () => {
    if (item.type === 'UserContent') {
      return item.contentType;
    } else if (item.type) {
      return item.type;
    } else {
      return item.objectType;
    }
  };
  const getTitle = (text) => {
    if (text === 'Country specific parliamentary record') {
      return 'Devolved parliament official report';
    } else if (text === 'Crm contact') {
      return 'CRM contact';
    } else {
      return text;
    }
  };

  return (
    <Link
      to={item.type === 'CrmContact' ? `/influence/directory/update/${item.id}` : contentUrl}
      className='content-item-link'
      style={{ zIndex: array.length - index }}
      onClick={async (e) => {
        if (newAgencyPlan && team?.teamId !== item?.teamId) {
          e.preventDefault();
          await changeClientFunction({ clientId: item?.teamId });
          navigate(`/influence/directory/update/${item.id}`);
        }
        rememberScrollPosition();
      }}
    >
      <div className='saved-item-element d-flex justify-content-between align-items-start'>
        <div>
          <p
            className={`individual-item beta-feature paragraph-p4 text-light-blue blue-icon popup-individual-item mb-2 mt-0`}
          >
            <span className={`icon-${savedItemIconsMapping[objectType().toLowerCase()]}`}></span>
            {getTitle(TransformFromPascalCase(objectType()))}
          </p>
          <h3 className={`content-item-title ${renderSubtitle ? '' : 'mb-0'}`}>{renderTitle}</h3>
          <p className='paragraph-p2 main-light-text mb-0'>
            {renderSubtitle}
            {additionalItemsSubtitle?.length > 0 && (
              <>
                {additionalItemsSubtitle.map((additionalItemSubtitle) => (
                  <>
                    <span className='dot'></span>
                    {additionalItemSubtitle}
                  </>
                ))}
              </>
            )}
          </p>
        </div>
        <div className='flex-centered'>
          <Share item={item} listItem influenceItem={item?.type === 'UserContent'} />
        </div>
      </div>
    </Link>
  );
};

const CrmContactSavedItemSubtitle = ({ clientId }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { clients } = state;
  const client = useMemo(() => {
    return clients.find((item) => item.teamId === clientId);
  }, [clients, clientId]);

  const { organisationLogo, teamName } = client ?? {};

  return client ? (
    <div className='d-flex align-items-center'>
      <img
        className='img-fluid rounded-circle'
        src={`${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(organisationLogo ?? `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`)}&mime=${encodeURIComponent('image/png')}&cache=true`}
        alt='organisation logo'
        style={{ height: '16px' }}
      />
      <span className='d-block hidden-lines hidden-one-line flex-grow-1 nowrap-item ml-2'>{teamName}</span>
    </div>
  ) : null;
};

const savedItemIconsMapping = {
  keyupdate: 'key-update',
  consultation: 'consultation',
  legislation: 'legislation',
  event: 'calendar',
  parliamentaryrecord: 'parliamentary-record',
  hansardcontent: 'parliamentary-record',
  countryspecificparliamentaryrecord: 'parliamentary-record',
  seneddcommitteetranscript: 'parliamentary-record',
  writtenquestion: 'parliamentary-record',
  tweet: 'twitter',
  stakeholder: 'team',
  crmcontact: 'team',
  policyask: 'policy-ask',
  briefingmaterial: 'briefing-material',
  research: 'briefing-material',
  newsrelease: 'briefing-material',
  commonslibraryarticle: 'library-material',
  committeepublication: 'document-bold',
};

export { savedItemIconsMapping };

export default SavedItemElements;
