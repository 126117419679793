import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { store } from 'components/Store';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { analyticsStateMapping, createAnalyticsState } from './createAnalyticsState';

function useAnalyticsHashesFunctions() {
  const globalState = useContext(store);
  const { state } = globalState;
  const navigate = useNavigate();
  const location = useLocation();

  const addAnalyticsFilterHashToUrl = ({ reset, individualHashHelper, chart, setHashes }) => {
    let analyticsState = reset ? createAnalyticsState(true) : state.analyticsState;
    let hashParts = [];
    let analyticsStateHashedFields = [
      { fieldName: 'activeDateInterval' },
      { fieldName: 'currentView' },
      { fieldName: 'typeOfChart' },
      { fieldName: 'chartDataOptionSelected' },
      { fieldName: 'selectedCountries' },
      {
        fieldName: 'allowFrontbenchers',
        allowedLocation: 'interest-by-region',
      },
    ];
    let pagesWithFiltersBar = ['mentions', 'tweets', 'sentiment-changes', 'sentiment-snapshot', 'interest-by-region'];

    const FiltersBySection = {
      InterestByRegion: ['style', 'nations', 'include-westminster-frontbenchers'],
      SentimentSnapshot: ['group-by'],
    };

    let setFilterHashesCondition = pagesWithFiltersBar.some(
      (page) => location.pathname.includes(`/analytics/${page}`) || location.pathname.includes(`/data-hub/${page}`)
    );

    const hashPhraseHelper = (field) => {
      if (field === 'activeDateInterval') {
        if (analyticsState[field].name === 'Custom') {
          return `between-${analyticsState.activeDateInterval.filter[0]}-and-${analyticsState.activeDateInterval.filter[1]}`;
        } else {
          return analyticsState[field].name;
        }
      } else if (field === 'typeOfChart' && location.pathname.includes('analytics/sentiment-changes')) {
        return analyticsState['typeOfChartSentimentChanges'];
      } else if (field === 'chartDataOptionSelected' && location.pathname.includes('analytics/tweets')) {
        return analyticsState['chartDataOptionSelectedTweets'];
      } else if (field === 'chartDataOptionSelected' && location.pathname.includes('analytics/sentiment-changes')) {
        return analyticsState['chartDataOptionSelectedSentimentChanges'];
      } else if (field === 'typeOfChart' && location.pathname.includes('analytics/interest-by-region')) {
        return analyticsState['typeOfChartInterestByRegion'];
      } else if (field === 'selectedCountries') {
        if (location.pathname.includes('analytics/interest-by-region')) {
          return analyticsState['selectedCountriesInterestByRegion'].map((item) => item.value).join('-');
        } else {
          return analyticsState[field].map((item) => item.value).join('-');
        }
      } else if (field === 'chartDataOptionSelected' && analyticsState[field] === 'Overall') {
        return 'none';
      } else if (field === 'allowFrontbenchers') {
        return `${analyticsState[field]}`;
      } else {
        return analyticsState[field];
      }
    };

    analyticsStateHashedFields
      .filter((item) => {
        // if we have specific hash only for some route
        return !item.allowedLocation || (item.allowedLocation && location.pathname.includes(item.allowedLocation));
      })
      .forEach(({ fieldName: field }) => {
        hashParts.push(
          `${analyticsStateMapping[field]}:${normalize(individualHashHelper ? individualHashHelper({ field, analyticsState, chart }) : hashPhraseHelper(field))}`
        );
      });

    let initialHashes = window.location.hash
      .replace('#', '')
      .split('~')
      .filter((item) => !Object.values(analyticsStateMapping).includes(item.split(':')[0]));
    if (location.pathname.includes('/sentiment-snapshot')) {
      hashParts = hashParts.filter((hash) => FiltersBySection.SentimentSnapshot.includes(hash.split(':')[0]));
    }
    if (location.pathname.includes('/interest-by-region')) {
      hashParts = hashParts.filter((hash) => FiltersBySection.InterestByRegion.includes(hash.split(':')[0]));
    }

    if (setFilterHashesCondition || setHashes) {
      navigate(
        {
          search: window.location.search,
          hash: initialHashes.length ? `#${initialHashes.join('~')}~${hashParts.join('~')}` : `#${hashParts.join('~')}`,
        },
        { replace: true }
      );
    }
  };

  return {
    addAnalyticsFilterHashToUrl,
    analyticsStateMapping,
  };
}

export default useAnalyticsHashesFunctions;
