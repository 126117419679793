import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import MainSearchBar from 'components/Dashboard/MainSearchBar';
import ClearFiltersButton from 'components/Dashboard/SubNav/ui/ClearFiltersButton';
import useDetectActiveFilters from 'components/Dashboard/SubNav/useDetectActiveFilters';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import InfluenceNotIncludedFeaturesPopup from 'components/Dashboard/Influence/CrmContacts/InfluenceNotIncludedFeaturesPopup';
import { store } from 'components/Store';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import TotalResults from 'components/Dashboard/components/TotalResults';
import InfluenceSectionTitle from 'components/Dashboard/Influence/InfluenceSectionTitle/InfluenceSectionTitle';
import parliamentarianUsers from 'components/Dashboard/sidebar/parliamentarianUsers';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import StakeholdersSortFilter from 'components/Dashboard/Filters/ui/DropDownFilters/StakeholdersSortFilter';
import MoreFiltersButton from 'components/Dashboard/Filters/ui/MoreFiltersButton';

function StakeholdersSubNav({ isMobile, isSmallMobile, readyKeywordLists }) {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, activeResults, activePlan } = state;
  const { list } = search;
  const { code } = activePlan ?? {};
  const { Stakeholders } = contentResults[activeResults] ?? {};
  const stakeholdersToRender = Stakeholders?.hits ?? [];

  const stakeholderHeaderRef = useRef(null);

  const { detectActiveFilters } = useDetectActiveFilters();
  const activeFilters = detectActiveFilters() || list?.id || getUrlParam('topic-id');
  const [showInfluenceCrmPopup, setShowInfluenceCrmPopup] = useState(false);

  const showSubNavFilters = useIsMobile(1280);
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <div className='w-100 flex-centered' ref={stakeholderHeaderRef}>
        <div
          className={`single-content-width-monitor flex-centered justify-content-between stakeholder-sub-nav ${newAgencyPlan ? 'single-content-agency' : ''}`}
        >
          <div style={{ visibility: parliamentarianUsers(code) ? 'hidden' : 'visible' }}>
            <InfluenceSectionTitle />
          </div>
          {readyKeywordLists && !isMobile && <MainSearchBar />}
          <div className='flex-centered d-lg-none'>
            {showSubNavFilters && (
              <>
                <MoreFiltersButton section={'Stakeholders'} />
                {!isSmallMobile && activeFilters && (
                  <div style={{ marginLeft: '10px' }}>
                    <ClearFiltersButton stakeholdersSubNav />
                  </div>
                )}
              </>
            )}
            {activeFilters && showSubNavFilters && (
              <div className='stakeholder-action-btn-container'>
                <div className='flex-centered'>
                  <div className='stakeholders-mapping-total-results'>
                    <div className='ml-auto mr-0 onlysort-filters-button'>
                      <StakeholdersSortFilter />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex justify-content-end flex-grow-1'>
          <div className='d-none d-lg-block outside-filters-container-width text-right pr-1'>
            <>
              {stakeholdersToRender.length > 0 && activeFilters && (
                <>
                  <TotalResults onlyNumber /> matching stakeholder{stakeholdersToRender?.length === 1 ? '' : 's'}
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <InfluenceNotIncludedFeaturesPopup
        showPopup={showInfluenceCrmPopup}
        setShowPopup={setShowInfluenceCrmPopup}
        type={'contacts'}
      />
    </>
  );
}

export default StakeholdersSubNav;
