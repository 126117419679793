import React from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { generateStateCell } from 'components/Dashboard/Influence/CrmEmails/CrmEmailTableItem/CrmEmailTableItem';
import { useContext } from 'react';
import { store } from 'components/Store';
import ConfirmDiscardPopup from 'components/Dashboard/Influence/CrmEmails/ConfirmDiscardPopup';
import SendPopup from 'components/Dashboard/Influence/CrmEmails/SendPopup';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import useEmailComponentFunctions from 'components/Dashboard/Influence/CrmEmails/useEmailComponentFunctions';
import useValidateContactsOversubscribed from 'components/Dashboard/utilities/useValidateContactsOversubscribed';
import SendTooltipOnTrial from './SendTooltipOnTrial';
import SendPreviewPopup from 'components/Dashboard/Influence/CrmEmails/SendPreviewPopup';
import normalize from 'components/Dashboard/utilities/normalizeString';
import createNotification from 'components/Settings/Utilities/CreateNotification';

const EmailComponentNav = () => {
  const params = useParams();

  const globalState = useContext(store);
  const { state } = globalState;
  const {
    currentEmailDetails,
    activeTabEmailSent,
    activePlan,
    emailComponentOnLoad,
    listDomainsVerified,
    emailSelectedContacts,
    remainingEmailCampaigns,
  } = state;
  const isSmallMobile = useIsMobile(769);
  const { subscriptionStatus } = activePlan;
  const trialUser = subscriptionStatus === 'InTrial';
  const { remainingCampaigns, isReady } = remainingEmailCampaigns;

  const {
    isLoading,
    saveEmail,
    showConfirmDiscardPopUp,
    setShowConfirmDiscardPopUp,
    showSendPopUp,
    setShowSendPopUp,
    setShowSendPreviewPopUp,
    showSendPreviewPopUp,
    emailIsNotValid,
  } = useEmailComponentFunctions();

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  const location = useLocation();
  const navigate = useNavigate();
  const createEmailPath = location.pathname.includes('/emails/create');

  const renderActionButtons = () => {
    const sendDisabledCondition = currentEmailDetails?.status === 'Sent';
    const actionButtons = [
      {
        text: 'Discard',
        action: () => validateContactsOversubscribed(() => setShowConfirmDiscardPopUp(true), 'discard_email'),
        disabled: sendDisabledCondition && isSmallMobile,
        processing: isLoading === 'delete',
        isLoading,
      },
      {
        text: 'Preview',
        action: () => {
          if (emailIsNotValid({ preview: true })) {
            return createNotification('danger', emailIsNotValid({ preview: true }));
          } else {
            if (createEmailPath) {
              validateContactsOversubscribed(
                () =>
                  saveEmail({
                    isForSending: false,
                    notRedirect: true,
                    actionOnSuccess: (email) => {
                      navigate(`/influence/emails/${email.crmEmailId}`, {
                        state: { openSendPreview: true },
                        replace: true,
                      });
                    },
                    loadingType: 'preview',
                    preview: true,
                  }),
                'save_email'
              );
            } else {
              validateContactsOversubscribed(() => setShowSendPreviewPopUp(true), 'save_email');
            }
          }
        },
        disabled: sendDisabledCondition,
        processing: isLoading === 'preview',
        isLoading,
        buttonProps:
          emailComponentOnLoad || listDomainsVerified.domainsProccessing
            ? { disabled: true }
            : emailSelectedContacts?.length === 0
              ? {
                  'data-tooltip-content':
                    "Add at least one recipient. You'll then be able to send yourself a preview email from a recipient's perspective, including the appropriate salutation.",
                  'data-tooltip-id': 'general-tooltip',
                  'data-tooltip-class-name': `header-tooltip pricing-tooltip-description pricing-tooltip-description`,
                  'data-tooltip-place': 'bottom',
                  disabled: true,
                }
              : undefined,
      },
      {
        text: 'Save draft',
        action: () => validateContactsOversubscribed(() => saveEmail({ isForSending: false }), 'save_email'),
        disabled: sendDisabledCondition,
        processing: isLoading === 'save',
        isLoading,
      },
      {
        text: 'Send',
        action: () => validateContactsOversubscribed(() => setShowSendPopUp(true), 'send_email'),
        disabled: sendDisabledCondition,
        processing: isLoading === 'send',
        isLoading,
        buttonProps: emailIsNotValid() ? { disabled: true } : undefined,
        isBlocked: remainingCampaigns != null && remainingCampaigns <= 0,
      },
    ];
    const trialCondition = (action) => action.text === 'Send' && trialUser;
    return (
      <div
        className={`subnav-email-button-container pl-3 ${
          activeTabEmailSent === 'Performance' && sendDisabledCondition
            ? 'subnav-email-button-container-performance'
            : activeTabEmailSent === 'Email' && sendDisabledCondition
              ? 'subnav-email-button-container-email'
              : ''
        }`}
      >
        {actionButtons
          .filter((button) => !button.disabled)
          .map((action, index) => {
            const { buttonProps = {}, isBlocked } = action;
            return (
              <div className='position-relative' key={`action-btn-${index}`}>
                <button
                  key={`button-action-${index}`}
                  onClick={action.action}
                  className={`position-relative subnav-email-button email-component-action-button-${normalize(action.text)} ${trialCondition(action) ? 'send-email-trial' : ''}`}
                  disabled={action.isLoading || trialCondition(action) || isBlocked}
                  data-tooltip-id={'general-tooltip'}
                  data-tooltip-content={
                    isBlocked && isReady
                      ? `You've reached your email campaign limit for this month. Upgrade to increase it, or wait for your next subscription month to begin.`
                      : ''
                  }
                  data-tooltip-class-name={'general-tooltip pricing-feature-tooltip report-popup-tooltip'}
                  {...buttonProps}
                >
                  {action.processing ? (
                    <i className='fas fa-spinner fa-spin' style={{ marginRight: '2px' }}></i>
                  ) : (
                    action.text
                  )}
                  {isBlocked && isReady && <span className='icon-padlock ml-2 d-inline-block-centered centered-6' />}
                </button>
                {trialCondition(action) && <SendTooltipOnTrial />}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div className='side-left-element pl-md-5 pl-3 side-left-link'>
        <Link to={'/influence/emails'} className='mb-0 back-to-emails-button'>
          Emails
        </Link>
      </div>

      <div
        className={`main-content flex-centered  filters-general-container justify-content-between pr-xl-5 px-md-4 px-3 email-component-sub-nav position-relative`}
      >
        <div
          className='email-component-sub-nav-title-container'
          style={{ gap: currentEmailDetails?.subject?.length ? '28px' : 0 }}
        >
          <p className='mb-0 email-component-sub-nav-title'>{currentEmailDetails?.subject}</p>
          {params?.id && params?.id !== 'create' && generateStateCell(currentEmailDetails.status || 'Draft', true)}
        </div>

        {renderActionButtons()}
      </div>
      <ConfirmDiscardPopup showPopup={showConfirmDiscardPopUp} setShowPopup={setShowConfirmDiscardPopUp} />
      <SendPopup showPopUp={showSendPopUp} setShowPopup={setShowSendPopUp} />
      <SendPreviewPopup showPopUp={showSendPreviewPopUp} setShowPopup={setShowSendPreviewPopUp} />
    </>
  );
};

export default EmailComponentNav;
