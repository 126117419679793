import { useContext } from 'react';
import { store } from 'components/Store';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useCompileFiltersFunction from 'components/Dashboard/Filters/useCompileFilters';
import { changeTypeOfResource } from 'components/Dashboard/helpers/createTypeOfResource';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory';
import { getUrlParamRaw } from 'components/Dashboard/utilities/getUrlParam';
import { useAuth0 } from '@auth0/auth0-react';
import parliamentarianUsers from 'components/Dashboard/sidebar/parliamentarianUsers';
import useCompileInitialFilters from 'components/Dashboard/Filters/useCompileInitialFilters';
import { useParams } from 'react-router-dom';
import addQuotesToString from 'components/Dashboard/utilities/addQuotesToString';
import { useFilters } from 'components/Dashboard/Filters/FiltersContext';

const useNumberOfUnseenItems = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { search, referenceState, activePlan, keywordsLists } = state;
  const { code } = activePlan;
  const { activeReference } = referenceState;
  const { list, query, activeSearch } = search;

  const { contentFilters } = useFilters();
  const { generalApiCall } = useGeneralApiCall();
  const params = useParams();
  const { mainSection } = params;
  const { inboxSection, referenceSection, searchResultsSection } = useMainSectionParameter(params);
  const { compileFiltersFunction } = useCompileFiltersFunction();
  const { compileInitialFilters } = useCompileInitialFilters();

  const { isAuthenticated } = useAuth0();
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  let createNumberSections = () => {
    let sections = [
      'KeyUpdates',
      'Consultations',
      'Legislation',
      'ParliamentaryRecord',
      'UpcomingEvents',
      'Stakeholders',
      'Tweets',
      'LobbyingMaterial',
      'LibraryMaterial',
      'CommitteePublication',
    ];
    if (parliamentarianUsers(code)) {
      let position = sections.indexOf('LobbyingMaterial');
      sections[position] = 'Campaigns';
    }
    if (!showTweets) {
      let indexPosition = sections.indexOf('Tweets');
      sections.splice(indexPosition, 1);
    }
    return sections;
  };
  let numberSections = createNumberSections();

  const callUnseenItemsList = async ({ listId }) => {
    let pathname = `/api/feed/count-items-since-last-seen-report${listId !== null ? `?keywordListId=${listId}` : ''}`;
    let method = 'get';
    let resultsUnseen = await generalApiCall({
      pathname,
      method,
      notShowErrorMessage: true,
      needsAuthentication: true,
    });
    let totalNumbers = {};

    if (!!resultsUnseen && resultsUnseen.length > 0) {
      resultsUnseen.forEach((item) => {
        totalNumbers[item.contentType] = item.unseen;
      });
    }
    return totalNumbers;
  };

  const callTotalItemsExplore = async (source) => {
    let totalNumbers = {};
    for (var i = 0; i < numberSections.length; i++) {
      let section = numberSections[i];
      let filtersToApply;
      //AE: compileInitialFilters gets filters from hash so it works on first load but while exploring returns incorrectly the filters.
      let referenceFilters =
        Object.keys(contentFilters).length > 0 && contentFilters[mainSection]
          ? contentFilters[mainSection]
          : compileInitialFilters(mainSection);

      let filtersToRender = referenceFilters.filter(
        (filter) =>
          filter.pages.includes(section) &&
          (filter.searchState === 'always' ||
            filter.searchState === 'activeSearch' ||
            filter.searchState === 'referenceSection' ||
            (searchResultsSection && filter.searchState === 'not-inbox'))
      );
      filtersToApply = compileFiltersFunction(filtersToRender);

      if (activeReference || activeSearch) {
        let { filters, searchWithin } = filtersToApply;
        let numbers = await individualApiCall(section, filters, searchWithin, source);
        if (numbers) {
          totalNumbers[section] = numbers.totalHits[changeTypeOfResource(section)];
        }
      }
    }
    return totalNumbers;
  };

  const individualApiCall = async (section, filters, searchWithin, source) => {
    let method = 'post';
    let pathname = `/api/search/count`;
    let requestProperties = {
      query,
      filters,
      types: [changeTypeOfResource(section)],
    };

    if (section === 'ParliamentaryRecord') {
      requestProperties = { ...requestProperties, distinctCountField: 'contentUrl' };
    }

    if (searchWithin) {
      requestProperties = { ...requestProperties, ...searchWithin };
    }

    if (activeReference) {
      const { keywords, andKeywords, keywordExclusions, topic } = referenceState;
      requestProperties = {
        ...requestProperties,
        query: ChangeKeywordsCategory(topic?.keywords ?? keywords),
        andQueries: ChangeKeywordsExclusions(topic?.andKeywords ?? andKeywords),
        mustNotQueries: ChangeKeywordsExclusions(topic?.keywordExclusions ?? keywordExclusions),
      };
    }

    if (searchResultsSection && activeSearch) {
      //AE: It's necessary due to the state is updated but the function stored inside the search-results component have the original state where the query is empty and it's not updated.
      requestProperties = {
        ...requestProperties,
        query: decodeURIComponent(getUrlParamRaw('search')),
      };
      if (section === 'Stakeholders') {
        requestProperties = { ...requestProperties, query: addQuotesToString(query) };
      }
    }

    if (section === 'Stakeholders') {
      requestProperties = { ...requestProperties, smartMatch: false };
    }

    let numbers = await generalApiCall({
      method,
      notShowErrorMessage: true,
      pathname,
      requestProperties,
      needsAuthentication: isAuthenticated,
      requestSource: source,
    });
    if (numbers) {
      return numbers;
    }
  };

  let enabledListConditional = () => {
    let listToCheckState = keywordsLists.find((topic) => list && list?.id === topic.id)?.myState;
    return listToCheckState !== 'Hidden' && listToCheckState !== 'Disabled';
  };

  const numberOfUnseenItems = async (source) => {
    try {
      if (inboxSection && list !== 'noListsCreated' && enabledListConditional()) {
        const listId = list?.id;
        let numbersCurrentList = await callUnseenItemsList({ listId, source });
        if (numbersCurrentList) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'unseenNumbersCurrentList',
            value: Object.keys(numbersCurrentList).length > 0 ? numbersCurrentList : {},
          });
        }
      }
      if (referenceSection || searchResultsSection) {
        let totalNumbers = await callTotalItemsExplore(source);
        if (totalNumbers) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'totalNumbers', value: totalNumbers });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { numberOfUnseenItems };
};

export default useNumberOfUnseenItems;
