import axios from 'axios';
import { net_api_url } from 'components/Store';
import currentDateFilter from './currentDateFilter';
const callContentRelated = async (objectId, objectType, source) => {
  try {
    let contentRelated = await axios.post(
      `${net_api_url}/api/search/more-like-this`,
      {
        objectId: objectId,
        objectType: objectType,
        maxResultsPerResultsType: 3,
        resultTypes: [
          { type: 'KeyUpdate' },
          { type: 'Legislation' },
          {
            type: 'ParliamentaryRecord',
            filters: [
              { field: 'contentType', value: 'QuestionAndAnswer', operator: 'STR_EQ' },
              { field: 'contentType', value: 'Edm', operator: 'STR_EQ' },
              { field: 'contentType', value: 'ScotlandQuestionAndAnswer', operator: 'STR_EQ' },
              { field: 'contentType', value: 'WalesQuestionAndAnswer', operator: 'STR_EQ' },
              { field: 'contentType', value: 'NorthernIrelandQuestionAndAnswer', operator: 'STR_EQ' },
            ],
            collapseField: 'contentUrl',
          },
          { type: 'HansardContent' },
          { type: 'CommonsLibraryArticle' },
          { type: 'Consultation', filters: [currentDateFilter] },
          { type: 'CountrySpecificParliamentaryRecord' },
          { type: 'SeneddCommitteeTranscript' },
        ],
      },
      {
        cancelToken: source.token,
      }
    );
    return contentRelated.data;
  } catch (error) {
    console.error(error);
  }
};

export default callContentRelated;
