import React from 'react';
import createUtmParameters from 'components/Dashboard/helpers/createUtmParameters.js';
import { useLocation } from 'react-router-dom';
import ModalComponent from 'components/Common/Modal';

const CreateAccountPopup = (props) => {
  const { setShowPopup, text, listItems, regSource, showPopup } = props;
  const location = useLocation();

  const getRegSource = () => {
    if (location.pathname === '/general-election-2024/candidates') {
      return `election-2024-saved-items-stakeholders-button`;
    } else if (location.pathname === '/general-election-2024/key-updates') {
      return `election-2024-saved-items-key-updates-button`;
    } else {
      return `${!!regSource ? regSource : 'filters-popup'}${createUtmParameters('&')}`;
    }
  };
  return (
    <ModalComponent isOpen={showPopup} setIsOpen={setShowPopup}>
      <div className='p-3 px-lg-5 pt-5 pb-lg-5'>
        <div className='modal-title px-0 px-lg-3'>
          <h2 className='text-center mt-4 mt-lg-2'>{text}</h2>
        </div>
        <main className='modal__content mt-2 mb-3'>
          <ul className='policy-list px-0 pb-0 mb-0 mx-auto gimmick-list my-5'>
            <li>{listItems?.[0] ?? 'Monitor the political landscape with unlimited keywords'}</li>
            <li>{listItems?.[1] ?? 'Identify relevant political stakeholders'}</li>
            <li>{listItems?.[2] ?? 'Present content to parliamentarians'}</li>
          </ul>
          <button
            className='general-button main-button px-5 py-2 d-block mx-auto'
            onClick={() => {
              window.location.assign(`/register?reg_source=${getRegSource()}`);
            }}
          >
            Start your free trial
          </button>
        </main>
      </div>
    </ModalComponent>
  );
};

export default CreateAccountPopup;
