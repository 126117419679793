import { useAuth0 } from '@auth0/auth0-react';
import { store } from 'components/Store.js';
import { locationTopicDescriptor } from 'components/Dashboard/helpers/locationSearchDescriptor.js';
import parliamentarianUsers from 'components/Dashboard/sidebar/parliamentarianUsers.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';
import React, { useCallback, useContext, useRef, useState } from 'react';
import SimpleSearchBar from './SimpleSearchBar';
import dayjs from 'dayjs';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { AnalyticsPopup, ReportsPopup } from './NotFeaturePopups.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { useSetListsIds } from 'components/Dashboard/MainSearchBar/setListIds.js';
import NavPagesLoggedOut from './NavPagesLoggedOut.js';
import { Portal } from 'react-portal';
import reorderArray from 'utils/reorderArray.js';
import useWindowHeight from 'components/Dashboard/utilities/useWindowHeight';

const NavPages = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activePlan, selectedCrmContacts, selectedGroups, keywordsLists } = state;
  const { code, enableAnalytics, teamName, enableReports } = activePlan;

  const closeMenu = () => {
    let hamburgerMenu = document.getElementsByClassName('hamburger')[0];
    hamburgerMenu.classList.remove('is-active');
    dispatch({ type: 'SHOW_HIDE_MENU', value: true });
  };
  const { stakeholderUrl, policymakerUrl, stakeholderTopicParam, monitorUrl } = useResearchItemsUrl();
  const { isAuthenticated, user } = useAuth0();
  const hideInfluenceSection =
    parliamentarianUsers(code) || (teamName === 'NCA' && user.email !== 'james.mitchell@nca.gov.uk');

  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const isContactsSelected =
    selectedCrmContacts.selectedIds.length > 0 ||
    selectedCrmContacts.selectedAll ||
    selectedCrmContacts.selectedAllVisible.length > 0;

  const isGroupsSelected = selectedGroups.selectedIds.length > 0 || selectedGroups.selectedAll;
  const activeSubNavCondition = isContactsSelected || isGroupsSelected;
  const { windowWidth } = useWindowHeight();

  const [showAnalyticsPopup, setShowAnalyticsPopup] = useState(false);
  const [isOpenReportsPopup, setIsOpenReportsPopup] = useState(false);
  const isMobile = useIsMobile();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const navPagesRef = useRef(null);
  const links = [
    {
      id: 'monitor_link',
      label: 'Monitor',
      listClassname: 'static-page-link-container',
      to: monitorUrl,
      className: ({ isActive }) =>
        `${isActive ? 'active-page' : ''} static-page-monitor topnav-monitor-link static-page-link ${location.pathname.includes('/monitor-inbox') ? 'active-page ' : ''}`,
      'data-intercom-target': 'Monitor link',
      onClick: (e) => {
        closeMenu();
      },
      condition: true,
    },
    {
      id: 'campaigns_link',
      label: 'Campaigns',
      listClassname: 'static-page-link-container',
      to: `/campaigns`,
      className: ({ isActive }) =>
        `${isActive ? 'active-page' : ''} topnav-monitor-link static-page-link campaigns-page`,
      'data-intercom-target': 'Campaigns link',
      onClick: (e) => {
        closeMenu();
      },
      condition: parliamentarianUsers(code),
    },
    {
      id: 'stakeholder_mapping_link',
      label: 'Influence',
      listClassname: 'static-page-link-container',
      to: `/influence/stakeholder-mapping${stakeholderTopicParam}`,
      className: `static-page-link topnav-influence-link ${
        location.pathname.includes('/influence')
          ? `${activeSubNavCondition ? 'active-page-active-subnav' : 'active-page'}`
          : ''
      }`,
      onClick: (e) => {
        closeMenu();
      },
      'data-intercom-target': 'Influence link',
      condition: !hideInfluenceSection,
    },
    {
      id: 'parliamentarians_link',
      label: 'Policymakers',
      listClassname: 'static-page-link-container',
      to: parliamentarianUsers(code) ? policymakerUrl : stakeholderUrl,
      className: ({ isActive }) => `${isActive ? 'active-page' : ''} static-page-link topnav-influence-link`,
      onClick: (e) => {
        closeMenu();
      },
      'data-intercom-target': 'Parliamentarian stakeholder mapping link',
      condition: parliamentarianUsers(code) && isMobile,
    },
    {
      id: 'analytics_link',
      label: 'Analytics',
      listClassname: 'static-page-link-container static-page-link-analytics-container',
      to: `/analytics/dashboard${
        getUrlParam('topic-id') && getUrlParam('topic-id') !== 'all'
          ? `?topic-id=${getUrlParam('topic-id')}`
          : `${getUrlParam('search') && location.pathname.startsWith('/analytics') ? `${location.search}` : ''}`
      }`,
      className: ({ isActive }) =>
        `${isActive ? 'active-page' : ''} static-page-link topnav-influence-link analytics-static-link ${
          location.pathname.match(/\/analytics\/\w+/i)?.input ? 'active-page' : ''
        }`,
      onClick: (e) => {
        if (!enableAnalytics) {
          e.preventDefault();
          setShowAnalyticsPopup(true);
        }
        closeMenu();
      },
      'data-intercom-target': 'Analytics page',
      condition: true,
    },
    {
      id: 'ai_assistant_link',
      label: 'AI assistant',
      listClassname: 'static-page-link-container',
      to: `/ai-assistant`,
      className: `static-page-link topnav-influence-link ${
        location.pathname.match(/\/ai-assistant\/content\/\w+/i)?.input ? 'ai-assistant-content' : ''
      } ${location.pathname.includes('/ai-assistant') ? 'active-page' : ''}`,
      onClick: (e) => {
        closeMenu();
      },
      'data-intercom-target': 'Writing assistant link',
      condition: !newAgencyPlan,
    },
    {
      id: 'calendar_link',
      label: 'Calendar',
      listClassname: '',
      to: `/calendar/${dayjs().format('YYYY-MM-DD')}`,
      className: ({ isActive }) => `${isActive ? 'active-page' : ''} static-page-link topnav-calendar-link`,
      'data-intercom-target': 'Calendar link',
      onClick: (e) => {
        closeMenu();
      },
      condition: isMobile,
    },
    {
      id: 'reports_link',
      label: 'Reports',
      listClassname: 'static-page-link-container static-page-link-reports-container',
      to: `/reports`,
      className: `static-page-link topnav-influence-link reports-static-link ${location.pathname.includes('/reports') ? 'active-page' : ''}`,
      onClick: (e) => {
        if (!enableReports) {
          e.preventDefault();
          setIsOpenReportsPopup(true);
        }
        closeMenu();
      },
      'data-intercom-target': 'Reports link',
      condition: !agencyUser || newAgencyPlan,
    },
  ];

  const actionsElement = useRef();
  const linksContainer = useRef();

  const { top, height, left } = actionsElement.current?.getBoundingClientRect() ?? {};

  const getLinksToRender = useCallback(() => {
    let linksToReturn = [...links];
    if (parliamentarianUsers(code)) {
      linksToReturn = reorderArray(4, 5, linksToReturn);
    }
    linksToReturn = [...linksToReturn.filter((link) => link.condition)];
    return linksToReturn;
  }, [code, location.pathname, location?.search, keywordsLists]);

  const linksToRender = getLinksToRender();

  const getMoreLinks = useCallback(() => {
    let items = [];
    const currentlyHiddenItems = linksContainer?.current
      ? [...linksContainer?.current?.querySelectorAll(`li`)]?.filter((item) => item?.offsetTop > 0)
      : [];
    linksToRender.forEach((link) => {
      const { condition, listClassname, label, className, onClick, ...linkProps } = link;
      if (linksContainer?.current?.querySelector(`#${linkProps.id}`)?.offsetTop > 0) {
        // OP: check if last item fits instead of 'more' button
        let lastItemFitsCondition =
          currentlyHiddenItems?.length === 1 &&
          !!currentlyHiddenItems?.[0]?.querySelector(`#${linkProps.id}`) &&
          actionsElement?.current &&
          linksContainer?.current?.querySelector(`#${linkProps.id}`)?.offsetWidth <= 116; //OP: 116px - width of actionsElement in case if it's not displayed

        if (lastItemFitsCondition && navPagesRef.current) {
          navPagesRef.current.style.paddingRight = 0;
        } else {
          items.push(
            <Link
              className='w-100 d-block text-left general-button list-action-button px-3 py-2 reset-link reset-link-color text-main-blue'
              onClick={() => {
                setIsOpen(false);
              }}
              {...linkProps}
            >
              {label}
            </Link>
          );
        }
      }
    });
    return items;
  }, [windowWidth, linksContainer.current, navPagesRef.current]);

  const moreLinks = getMoreLinks();

  return (
    <>
      <ul
        className={`position-relative policy-list d-flex flex-lg-row flex-column mb-0 pl-0 nav-pages ${
          parliamentarianUsers(code) ? 'nav-pages-additional-links' : ''
        } pt-lg-0 ${isAuthenticated ? 'pt-3 flex-lg-grow-1' : ''}`}
        ref={navPagesRef}
      >
        {isAuthenticated && Object.keys(activePlan).length > 0 && (
          <>
            {isMobile && (
              <li className='search-nav-button-container py-3 mb-2'>
                <div className='home-searchbar-container border-grey border rounded-button sidenav-searchbar'>
                  <SimpleSearchBar
                    homePage
                    enforceShortPlaceholder
                    closeFunction={() => {
                      closeMenu();
                    }}
                  />
                </div>
              </li>
            )}
            {newAgencyPlan ? (
              <AgencyUserNavigation linksToRender={linksToRender} />
            ) : (
              <>
                <div
                  className='d-lg-flex flex-lg-wrap overflow-hidden nav-pages-links-container row-gap-15'
                  ref={linksContainer}
                  style={{ maxHeight: '24px' }}
                >
                  <NavLinks linksToRender={linksToRender} />
                </div>
              </>
            )}

            {moreLinks?.length > 0 && (
              <div ref={actionsElement} className={`nav-pages-more-btn`}>
                <button
                  className='general-button px-3 more-navigation-element'
                  style={{ height: '24px' }}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <span className={`text-white dropdown-item-element dropdown-item-element-10 ${isOpen ? 'open' : ''}`}>
                    More
                  </span>
                </button>
                {isOpen && (
                  <>
                    <div className='general-close-background z-extra-max' onClick={() => setIsOpen(false)}></div>
                    <Portal>
                      <div
                        className='box-shadow rounded py-2 z-extra-max client-navigation-more-actions'
                        style={{ position: 'fixed', top: `${top + height + 10}px`, left: `${left}px` }}
                      >
                        {moreLinks}
                      </div>
                    </Portal>
                  </>
                )}
              </div>
            )}
          </>
        )}
        {!isAuthenticated && <NavPagesLoggedOut closeMenu={closeMenu} />}
      </ul>
      <AnalyticsPopup isOpen={showAnalyticsPopup} setIsOpen={setShowAnalyticsPopup} />
      <ReportsPopup isOpen={isOpenReportsPopup} setIsOpen={setIsOpenReportsPopup} />
    </>
  );
};

const AgencyUserNavigation = (props) => {
  const { linksToRender } = props;
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const actionsElement = useRef();
  const { top, height, left } = actionsElement.current?.getBoundingClientRect() ?? {};

  return (
    <>
      <NavLinks linksToRender={linksToRender} />
      {!isMobile && (
        <div className='position-relative more-navigation-container' ref={actionsElement}>
          <button
            className='general-button px-3 more-navigation-element'
            style={{ height: '28px' }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <span className={`dropdown-item-element dropdown-item-element-10 ${isOpen ? 'open' : ''}`}>More</span>
          </button>
          {isOpen && (
            <>
              <div className='general-close-background z-extra-max' onClick={() => setIsOpen(false)}></div>
              <Portal>
                <div
                  className='box-shadow rounded py-2 z-extra-max client-navigation-more-actions'
                  style={{ position: 'fixed', top: `${top + height + 10}px`, left: `${left}px` }}
                >
                  <Link
                    to={'/analytics/dashboard'}
                    className='w-100 d-block text-left general-button list-action-button px-3 py-2 reset-link reset-link-color text-main-blue'
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Analytics
                  </Link>
                  <Link
                    to={`/reports`}
                    className='w-100 d-block text-left general-button list-action-button px-3 py-2 reset-link reset-link-color text-main-blue'
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Reports
                  </Link>
                </div>
              </Portal>
            </>
          )}
        </div>
      )}
    </>
  );
};

const NavLinks = (props) => {
  const { linksToRender } = props;
  return (
    <>
      {linksToRender?.map((link) => {
        const { condition, listClassname, label, ...navLinkProps } = link;
        if (condition) {
          return (
            <li className={link.listClassname} key={`item-${label}`}>
              <NavLink {...navLinkProps}>{label}</NavLink>
            </li>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

const useResearchItemsUrl = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, modifiedListId, memoryMasterTopic } = state;

  const location = useLocation();
  const { getStoredListIds } = useSetListsIds();

  const storedLists = getStoredListIds({});
  let localStorageId;
  if (!!storedLists) {
    localStorageId = storedLists?.[0];
  }
  const stakeholdersfirstList = keywordsLists?.find((item) =>
    modifiedListId ? item.id === modifiedListId : localStorageId ? item.id === localStorageId : item.id !== null
  );

  const firstList = keywordsLists?.[0];
  const stakeholderTopicParam =
    getUrlParam('topic-id') && getUrlParam('topic-id') !== 'all'
      ? `?topic-id=${getUrlParam('topic-id')}`
      : stakeholdersfirstList
        ? `?topic-id=${stakeholdersfirstList.id}`
        : '';

  const createMonitorUrl = () => {
    let search;
    if (memoryMasterTopic && keywordsLists?.length > 1) {
      search = '?topic-id=all';
    } else {
      search = `${
        locationTopicDescriptor(location) !== '' && getUrlParam('topic-id') !== 'none'
          ? locationTopicDescriptor(location)
          : `${firstList ? `?topic-id=${firstList.id === null ? 'all' : firstList.id}` : ''}`
      }`;
    }
    return `/monitor-inbox/key-updates${search}`;
  };

  const monitorUrl = createMonitorUrl();
  const stakeholderUrl = `/influence/stakeholder-mapping${stakeholderTopicParam}`;
  const policymakerUrl = `/policymakers${stakeholderTopicParam}`;

  return { stakeholderUrl, policymakerUrl, stakeholderTopicParam, monitorUrl };
};

export { useResearchItemsUrl };
export default NavPages;
