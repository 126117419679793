import generalApiCall from 'components/Dashboard/apiCalls/generalApiCall';
import createNotification from 'components/Settings/Utilities/CreateNotification';

const transformUpcomingEventsAlerts = (alerts) => {
  if (alerts) {
    let upcomingAlerts = [
      {
        type: 'WeeklyAllEvents',
        name: 'The week ahead',
        description: 'Mondays, typically around midday',
        dataTip: 'Includes business at Westminster and the devolved parliaments',
      },
      {
        type: 'DailyAllEvents',
        name: 'The day ahead',
        description: 'Tuesday - Friday mornings',
        dataTip: 'Includes business at Westminster and the devolved parliaments',
      },
      {
        type: 'WeeklyWestminsterBusinessEvents',
        name: 'Westminster business',
        description: 'Thursday afternoons',
        dataTip: 'Includes business at Westminster for the upcoming fortnight',
      },
      {
        type: 'MajorAnnouncements',
        name: 'Based on major announcements',
        description: 'as soon as it happens',
        dataTip: "Includes announcements such as King's Speech, Budget, recesses and ministerial appointments",
      },
    ];
    alerts.forEach((item) => {
      let position = upcomingAlerts.findIndex((alert) => alert.type === item.type);
      if (position >= 0) {
        const { id } = item;
        upcomingAlerts[position].id = id;
      }
    });
    return upcomingAlerts;
  }
};

const controlEventAlerts = (props) => {
  const { source, getAccessToken, allUpcomingEvents, setAllUpcomingEvents, onlySendRelevant, setOnlySendRelevant } =
    props;

  const createUpcomingEvent = async (item) => {
    let pathname = '/api/newsletter-subscriptions/create';
    let method = 'post';
    let token = await getAccessToken();
    let requestHeaders = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source?.token,
    };
    let requestProperties = {
      type: item.type,
      onlySendIfContainsRelevantContent: item.type === 'MajorAnnouncements' ? null : onlySendRelevant,
    };
    let response = await generalApiCall({ pathname, method, requestHeaders, requestProperties });
    if (!!response) {
      item.id = response.id;
      let newUpcomingEvents = [...allUpcomingEvents];
      let position = newUpcomingEvents.findIndex((event) => event.type === item.type);
      newUpcomingEvents[position] = item;
      setAllUpcomingEvents(newUpcomingEvents);
      createNotification('success', `Email alert has been enabled`);
    }
  };
  const deleteUpcomingEvent = async (item) => {
    let pathname = `/api/newsletter-subscriptions/${item.id}`;
    let method = 'delete';
    let token = await getAccessToken();
    let requestHeaders = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source?.token,
    };
    let response = await generalApiCall({ pathname, method, requestHeaders });
    if (!!response) {
      let newUpcomingEvents = [...allUpcomingEvents];
      let position = newUpcomingEvents.findIndex((event) => event.id === item.id);
      delete newUpcomingEvents[position].id;
      setAllUpcomingEvents(newUpcomingEvents);
      createNotification('success', `Email alert has been paused`);
    }
  };

  const onlySendEmailEventsRelevant = async (e, value) => {
    let el = e.target;
    el.disabled = true;
    setOnlySendRelevant(value);
    for (let i = 0; i < allUpcomingEvents.length; i++) {
      let alert = allUpcomingEvents[i];
      if (alert.id && alert.type !== 'MajorAnnouncements') {
        await modifySendEmailEvents(alert.id, value);
      }
    }
    createNotification('success', `Your alert settings have been updated`);
    el.disabled = false;
  };

  const modifySendEmailEvents = async (id, value) => {
    let pathname = `/api/newsletter-subscriptions/update`;
    let method = 'post';
    let token = await getAccessToken();
    let requestHeaders = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source?.token,
    };
    let requestProperties = {
      id,
      onlySendIfContainsRelevantContent: value,
    };
    await generalApiCall({ pathname, method, requestHeaders, requestProperties });
  };

  return {
    transformUpcomingEventsAlerts,
    createUpcomingEvent,
    deleteUpcomingEvent,
    onlySendEmailEventsRelevant,
  };
};

export { transformUpcomingEventsAlerts };
export default controlEventAlerts;
