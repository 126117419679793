import dayjs from 'dayjs';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from 'components/Store';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import { compileData } from './MentionsByTopicContainer';
import PartyMetaData, { RegionMetaData } from './ui/MentionsByTopic/PartyMetadata';
import { chartDataOptions } from './createAnalyticsState';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions';
import useSelectedCountriesFilters from './useSelectedCountriesFilters';
import useAnalyticsIntervalLimit from './useAnalyticsIntervalLimit';
import { useAuth0 } from '@auth0/auth0-react';
import ChangeSearchQuery from 'components/Dashboard/utilities/changeSearchQuery';

const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const TweetsFilters = [
  { field: 'policyMogulUserType', value: 'MP - England', operator: 'str_eq' },
  { field: 'policyMogulUserType', value: 'MP - Scotland', operator: 'str_eq' },
  { field: 'policyMogulUserType', value: 'MP - Wales', operator: 'str_eq' },
  {
    field: 'policyMogulUserType',
    value: 'MP - Northern Ireland',
    operator: 'str_eq',
  },
  { field: 'policyMogulUserType', value: 'Lord', operator: 'str_eq' },
];

let graphDataSections = [
  {
    name: 'Spoken parliamentary contributions',
    type: 'ParliamentaryRecord',
    field: 'contentType',
    value: 'ParliamentaryContribution',
  },
  {
    name: 'Written questions',
    type: 'ParliamentaryRecord',
    field: 'contentType',
    value: 'QuestionAndAnswer',
  },
  {
    name: 'EDMs',
    type: 'ParliamentaryRecord',
    field: 'contentType',
    value: 'Edm',
  },
  {
    name: 'Committee contributions',
    type: 'ParliamentaryRecord',
    field: 'contentType',
    value: 'SeneddCommitteeTranscriptContribution',
  },
];

if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
  graphDataSections = [
    ...graphDataSections,
    {
      name: 'Tweets',
      type: 'Tweet',
      field: 'isRetweet',
      value: 'false',
      additionalFilters: TweetsFilters,
    },
    {
      name: 'Retweets',
      type: 'Tweet',
      field: 'isRetweet',
      value: 'true',
      additionalFilters: TweetsFilters,
    },
  ];
}

const createKey = ({ chartDataOption, item }) => {
  if (chartDataOption === 'Party') {
    let name = item
      .split(' ')
      .map((item) => uppercaseFirstLetter(item))
      .join(' ');
    let newName = PartyMetaData.changeNames[name];
    if (newName) {
      name = newName;
    }
    return name;
  } else if (chartDataOption === 'Region') {
    if (item === 'yorkshire & the humber') {
      return 'Yorkshire and the Humber';
    } else {
      return item
        .split(' ')
        .map((item) => uppercaseFirstLetter(item))
        .join(' ');
    }
  } else {
    switch (item) {
      case 'backbencher':
        return `Backbenchers`;
      case 'ms':
        return 'MSs';
      case 'msp':
        return 'MSPs';
      default:
        return uppercaseFirstLetter(item);
    }
  }
};

export const mergeResults = (targetArr, mergeArr) => {
  if (targetArr?.length > 0) {
    let result = targetArr?.map((item) => {
      let mergeItem = mergeArr?.find((datapoint) => datapoint.date === item.date);
      if (mergeItem) {
        return { date: item.date, value: item.value + mergeItem.value };
      } else {
        return item;
      }
    });
    return result;
  } else {
    return mergeArr;
  }
};

const useCallMentionsByTopic = (props) => {
  const { teamId, selectedCountriesProps, chartType } = props ?? {};
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, analyticsState } = state;
  const { isAuthenticated } = useAuth0();

  const { currentView, activeDateInterval, chartDataOptionSelected, chartDataOptionSelectedTweets } = analyticsState;
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const { generalApiCall } = useGeneralApiCall();
  const topicParameter = getUrlParam('topic-id');
  const { validateDataForLoggedOutUsers, conditionOfPublicApi } = useAnalyticsIntervalLimit();

  const location = useLocation();
  const parliamentaryPositionsFilters = chartDataOptions.find(
    (item) => item.name === 'Parliamentary position'
  )?.filters;

  const { getSelectedCountriesFilter, UKSelected, WalesSelected, getCountrySpecificFilters } =
    useSelectedCountriesFilters({ selectedCountriesProps });

  const stakeholderContributions = location.pathname.includes('/mentions') || chartType === 'Mentions';
  const defaultTopic = topicParameter
    ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
    : keywordsListsToUse[0];

  const callMentionsByTopic = async ({
    view = currentView,
    topic = defaultTopic,
    dateInterval = activeDateInterval.filter,
    chartDataOption = stakeholderContributions ? chartDataOptionSelected : chartDataOptionSelectedTweets,
    stakeholderContributionsGraph,
    source,
    freeSearch,
  }) => {
    try {
      if (topic || freeSearch) {
        let value;
        let rawData;

        if (chartDataOption === 'Contribution type' || chartDataOption === 'Overall') {
          if (stakeholderContributions || stakeholderContributionsGraph) {
            let parliamentaryRecord = await parliamentaryRecordCall({
              topic,
              view,
              dateInterval,
              source,
              freeSearch,
            });
            if (!!parliamentaryRecord) {
              value = {
                'Spoken parliamentary contributions': mergeResults(
                  parliamentaryRecord?.series?.find((item) => item.bucket === 'parliamentarycontribution')?.datapoints,

                  mergeResults(
                    parliamentaryRecord?.series?.find(
                      (item) => item.bucket === 'walescountryspecificparliamentarycontribution'
                    )?.datapoints,
                    parliamentaryRecord?.series?.find(
                      (item) => item.bucket === 'scotlandcountryspecificparliamentarycontribution'
                    )?.datapoints
                  )
                ),
                'Written questions': mergeResults(
                  parliamentaryRecord?.series?.find((item) => item.bucket === 'questionandanswer')?.datapoints,
                  mergeResults(
                    parliamentaryRecord?.series?.find((item) => item.bucket === 'walesquestionandanswer')?.datapoints,
                    parliamentaryRecord?.series?.find((item) => item.bucket === 'scotlandquestionandanswer')?.datapoints
                  )
                ),
              };
              if (WalesSelected) {
                value = {
                  ...value,
                  'Committee contributions': parliamentaryRecord?.series?.find(
                    (item) => item.bucket === 'seneddcommitteetranscriptcontribution'
                  )?.datapoints,
                };
              }
              if (UKSelected) {
                value = {
                  ...value,
                  EDMs: parliamentaryRecord?.series?.find((item) => item.bucket === 'edm')?.datapoints,
                };
              }
            }
          }
          if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
            let tweets = await tweetsCall({ topic, view, dateInterval, source, freeSearch });
            if (!!tweets) {
              value = {
                ...value,
                Tweets: tweets?.series?.find((item) => item.bucket === '0')?.datapoints,
                Retweets: tweets?.series?.find((item) => item.bucket === '1')?.datapoints,
              };
            }
          }
          rawData = value;
        } else {
          let chartData = await callGetGraphData({
            topic,
            view,
            dateInterval,
            chartDataOption,
            stakeholderContributionsGraph,
            source,
            freeSearch,
          });
          if (!!chartData) {
            value = chartData.buckets;
            rawData = chartData.rawResults;
          }
        }

        let totalData = compileData({ value }, analyticsState);
        let compiledDataRaw = totalData.raw;
        let data = {
          labels: compiledDataRaw.map((item) => item.date),
          value: chartDataOption === 'Overall' ? { [`Total contributions`]: totalData?.raw } : value,
        };

        if (!!value && Object.keys(value)?.length > 0) {
          return { data, rawData };
        }
      }
    } catch (error) {}
  };

  const parliamentaryRecordCall = async ({ topic, view, dateInterval, source, freeSearch }) => {
    try {
      const pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${freeSearch && conditionOfPublicApi ? 'public-' : ''}date-histogram${
        !!topic && !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''
      }`;
      const method = 'post';
      let requestProperties = {
        type: 'ParliamentaryRecord',
        interval: transformInterval(view),
        filters: [
          ...parliamentaryPositionsFilters,
          ...createFilters(dateInterval, view),
          ...getSelectedCountriesFilter(),
        ],
        bucketField: 'contentType',
        order: 'asc',
      };

      if (topic?.prospective) {
        const { keywords, andKeywords, keywordExclusions } = topic;
        requestProperties = {
          ...requestProperties,
          query: ChangeKeywordsCategory(keywords),
          andQueries: ChangeKeywordsExclusions(andKeywords),
          mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
        };
      } else if (freeSearch) {
        requestProperties = {
          ...requestProperties,
          query: ChangeSearchQuery(freeSearch),
        };
      } else {
        requestProperties = {
          ...requestProperties,
          keywordListId: topic.id,
        };
      }

      if (teamId) {
        requestProperties = {
          ...requestProperties,
          teamId,
        };
      }

      let results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: !teamId || (freeSearch && isAuthenticated),
        notShowErrorMessage: true,
        requestSource: source,
        returnError: true,
      });
      if (!validateDataForLoggedOutUsers(results)) {
        return [];
      }
      if (!!results) {
        return results;
      }
    } catch (error) {}
  };

  const tweetsCall = async ({ topic, view, dateInterval, source, freeSearch }) => {
    try {
      const pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${freeSearch && conditionOfPublicApi ? 'public-' : ''}date-histogram${
        !!topic && !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''
      }`;
      const method = 'post';
      let filters = createFilters(dateInterval, view);
      filters = [...filters, ...parliamentaryPositionsFilters, ...TweetsFilters];
      let requestProperties = {
        type: 'Tweet',
        interval: transformInterval(view),
        filters,
        bucketField: 'isRetweet',
        order: 'asc',
      };
      if (topic?.prospective) {
        const { keywords, andKeywords, keywordExclusions } = topic;
        requestProperties = {
          ...requestProperties,
          query: ChangeKeywordsCategory(keywords),
          andQueries: ChangeKeywordsExclusions(andKeywords),
          mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
        };
      } else if (freeSearch) {
        requestProperties = {
          ...requestProperties,
          query: ChangeSearchQuery(freeSearch),
        };
      } else {
        requestProperties = {
          ...requestProperties,
          keywordListId: topic.id,
        };
      }
      if (teamId) {
        requestProperties = {
          ...requestProperties,
          teamId,
        };
      }
      let results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: !teamId || (freeSearch && isAuthenticated),
        notShowErrorMessage: true,
        requestSource: source,
        returnError: true,
      });
      if (!!results && validateDataForLoggedOutUsers(results)) {
        return results;
      }
    } catch (error) {}
  };

  const createFilters = (dateInterval, view) => {
    if (dateInterval.length === 2) {
      return [
        {
          field: 'dateTime',
          value: dayjs(dateInterval[0]).startOf('day').format('YYYY-MM-DD'),
          operator: 'date_gte',
        },
        {
          field: 'dateTime',
          value: dayjs(dateInterval[1]).add('1', 'day').format('YYYY-MM-DD'),
          operator: 'date_lt',
        },
      ];
    } else {
      return [
        {
          field: 'dateTime',
          value: dayjs(dateInterval[0])
            .startOf(view ? view.toLowerCase() : 'day')
            .format('YYYY-MM-DD'),
          operator: 'date_gte',
        },
        {
          field: 'dateTime',
          value: dayjs().add('1', 'day').format('YYYY-MM-DD'),
          operator: 'date_lt',
        },
      ];
    }
  };

  const transformInterval = (interval) => {
    switch (interval) {
      case 'Day':
        return 'daily';
      case 'Week':
        return 'weekly';
      case 'Month':
        return 'monthly';
      default:
        return 'daily';
    }
  };

  const individualGetDataChartApiCall = async ({
    topic,
    view,
    dateInterval,
    section,
    optionData,
    source,
    freeSearch,
  }) => {
    try {
      const { field, value, type, additionalFilters } = section;
      const pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${freeSearch && conditionOfPublicApi ? 'public-' : ''}date-histogram${
        !!topic && !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''
      }`;
      const method = 'post';
      let filters = [
        ...parliamentaryPositionsFilters,
        ...createFilters(dateInterval, view),
        ...getCountrySpecificFilters({ value, field }),
      ];

      if (optionData?.filters) {
        filters = [...filters, ...optionData.filters];
      }

      if (additionalFilters) {
        filters = [...filters, ...additionalFilters];
      }
      let requestProperties = {
        type: type,
        interval: transformInterval(view),
        filters,
        bucketField: optionData?.bucketField,
        order: 'asc',
      };

      if (topic?.prospective) {
        const { keywords, andKeywords, keywordExclusions } = topic;
        requestProperties = {
          ...requestProperties,
          query: ChangeKeywordsCategory(keywords),
          andQueries: ChangeKeywordsExclusions(andKeywords),
          mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
        };
      } else if (freeSearch) {
        requestProperties = {
          ...requestProperties,
          query: ChangeSearchQuery(freeSearch),
        };
      } else {
        requestProperties = {
          ...requestProperties,
          keywordListId: topic.id,
        };
      }

      if (teamId) {
        requestProperties = {
          ...requestProperties,
          teamId,
        };
      }

      let results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: !teamId || (freeSearch && isAuthenticated),
        notShowErrorMessage: true,
        requestSource: source,
      });

      if (!!results && validateDataForLoggedOutUsers(results)) {
        return results;
      }
    } catch (error) {}
  };

  const callGetGraphData = async ({
    view = currentView,
    topic = defaultTopic,
    dateInterval = activeDateInterval.filter,
    chartDataOption,
    stakeholderContributionsGraph,
    source,
    freeSearch,
  }) => {
    try {
      let finalResults = [];
      let rawResults = {};
      let optionData = chartDataOptions.find((item) => item.name === chartDataOption);
      let graphDataSectionsFiltered = graphDataSections.filter((item) => {
        if (item.value === 'Edm') {
          return UKSelected;
        }
        if (item.value === 'SeneddCommitteeTranscriptContribution') {
          return WalesSelected;
        }
        if (stakeholderContributions || stakeholderContributionsGraph) {
          return graphDataSections;
        } else {
          return item.type === 'Tweet';
        }
      });
      for (let i = 0; i < graphDataSectionsFiltered.length; i++) {
        let section = graphDataSectionsFiltered[i];
        let results = await individualGetDataChartApiCall({
          topic,
          view,
          dateInterval,
          section,
          optionData,
          source,
          freeSearch,
        });
        if (results) {
          finalResults.push(results.series);
          let rawResultsSimplified = {};
          JSON.parse(JSON.stringify(results.series)).forEach(
            (result) =>
              (rawResultsSimplified[createKey({ chartDataOption, item: result?.bucket })] = result?.datapoints)
          );
          rawResults[section?.name] = rawResultsSimplified;
        }
      }
      let initialBuckets = finalResults.flat().reduce((acc, item) => {
        let key = createKey({ chartDataOption, item: item.bucket });
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item.datapoints);

        return acc;
      }, {});
      let bucketKeys = Object.keys(initialBuckets);
      let buckets = initialBuckets;

      if (chartDataOption === 'Region') {
        buckets = {};
        RegionMetaData.orderingRegions.forEach((item) => {
          if (bucketKeys.includes(item)) {
            buckets[item] = initialBuckets[item];
          }
        });
      }

      Object.keys(buckets).forEach((bucket) => {
        let series = buckets[bucket];
        let datapoints = [];
        datapoints = series.flat().reduce((acc, item) => {
          let dateIndex = acc.findIndex((point) => point.date === item.date);
          if (dateIndex >= 0) {
            let { value } = acc[dateIndex];
            let newValue = item.value + value;
            acc[dateIndex].value = newValue;
          } else {
            acc.push(item);
          }
          return acc;
        }, []);
        let condition = optionData.showRowsFor ? !optionData.showRowsFor.includes(bucket) : false;
        if (condition) {
          delete buckets[bucket];
        } else {
          buckets[bucket] = datapoints;
        }
      });
      return { buckets, rawResults };
    } catch (error) {}
  };

  return {
    callMentionsByTopic,
    transformInterval,
    createFilters,
    callGetGraphData,
  };
};

export { graphDataSections, TweetsFilters, createKey };
export default useCallMentionsByTopic;
