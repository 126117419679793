import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import TagsInput from 'react-tagsinput';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useWindowHeight from 'components/Dashboard/utilities/useWindowHeight';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import KeywordsSuggestions from 'components/Settings/Topics/KeywordsSuggestions';

const CreateATopicPage = (props) => {
  const {
    stateProperties,
    errorProperties,
    topicProperties,
    clientProperties,
    remainingKeywords,
    handleChange,
    createFirstList,
    isLoading,
    createTeam,
    disabledClientName,
  } = props;

  const { error, setError } = errorProperties;
  const { topicName, setTopicName } = topicProperties;
  const { clientName, setClientName } = clientProperties;

  const { parliamentarianOnboarding, keywords, activePlan } = stateProperties;
  const { agencyUserBase, agencyUser } = useTopicOrClientWord();
  const agencyPlan = agencyUserBase || agencyUser;
  const newAgencyPlan = agencyUserBase && !agencyUser;
  const [activeItem, setActiveItem] = useState(newAgencyPlan ? 'clientName' : 'topicName');
  const [showSuggestion, setShowSuggestion] = useState(true);
  const inputRef = useRef(null);
  const clientRef = useRef(null);
  const tagsContainer = useRef(null);
  const keywordsContainer = useRef(null);
  const clientContainer = useRef(null);
  const inputContainer = useRef(null);

  const defaultRenderTag = (props) => {
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    return (
      <span
        key={key}
        {...other}
        style={{
          paddingRight: '25px',
        }}
      >
        {getTagDisplayValue(tag)}
        <button className={`${classNameRemove} general-button`} onClick={(e) => onRemove(key)} />
      </span>
    );
  };

  const disabledConditional = () => {
    let condition = remainingKeywords > 0 || remainingKeywords === 'unlimited';
    return !condition;
  };

  const defaultRenderLayout = (tagComponents, inputComponent) => {
    return (
      <>
        <div className='d-flex flex-wrap align-items-center'>
          {tagComponents}
          <span className='flex-grow-1'>{inputComponent}</span>
        </div>
      </>
    );
  };

  const addClass = () => {
    keywordsContainer.current.classList.add('keywords-container-focused');
    setActiveItem('topicKeywords');
  };
  const removeClass = () => {
    keywordsContainer.current.classList.remove('keywords-container-focused');
  };

  const nameTopic = useRef();

  const createStyles = () => {
    let element;
    if (activeItem === 'topicName') {
      element = inputContainer;
    } else if (activeItem === 'clientName') {
      element = clientContainer;
    } else {
      element = keywordsContainer;
    }
    return { top: element.current?.getBoundingClientRect().top ?? 0 + 8 };
  };

  const [styles, setStyles] = useState(createStyles());

  useEffect(() => {
    setStyles(createStyles());
    if (inputRef.current !== null) {
      inputRef.current.setAttribute('data-hj-allow', '');
    }
  }, [inputRef, activeItem]);

  const [heightContainer, containerRef] = useHeightContainer();
  const blueSectionElement = useRef();
  const { windowHeight, windowWidth } = useWindowHeight();

  const salmonSection = useCallback(
    (node) => {
      if (!!node) {
        setTimeout(() => {
          const sectionHeight = blueSectionElement?.current?.getBoundingClientRect()?.height;
          node.style.minHeight = `${sectionHeight}px`;
        }, 10);
      }
    },
    [windowWidth, windowHeight]
  );

  useEffect(() => {
    let el = newAgencyPlan && !disabledClientName ? clientRef : nameTopic;
    if (!!el?.current) {
      el.current.focus();
      el.current.select();
    }
  }, []);

  return (
    <div className='dashboard-root-container'>
      <div className='flex-grow-1 position-relative' ref={containerRef}>
        <CustomScrollbar className={'simple-scrollbar top-content-scrollbar'} maximalThumbYSize={100}>
          <div className='d-flex h-100'>
            <div
              className='bg-salmon p-5 d-none main-subtle-text d-lg-flex flex-column create-topic-tips-box position-relative h-100'
              ref={salmonSection}
            >
              <div className='arrow-pointer d-none d-lg-block' style={styles} />
              <div className='flex-grow-1'>
                <h2 className='company-name mt-lg-5 company-name-create-a-topic'>PolicyMogul</h2>
                {(activeItem === 'topicName' || activeItem === 'clientName') && (
                  <>
                    {agencyPlan ? (
                      <>
                        <p>
                          You’re about to start a trial of the Mogul plan. Consultants and agencies typically use
                          PolicyMogul’s Enterprise plan, which includes:
                        </p>
                        <ul className='my-4 strong-keywords include-items'>
                          <li className='mb-1'>News under embargo</li>
                          <li className='mb-1'>Human- and AI-curated updates</li>
                          <li className='mb-1'>Live select committee coverage</li>
                          <li className='mb-1'>Research team </li>
                          <li className='mb-1'>Advanced analytics</li>
                        </ul>
                        <p>
                          If you would like to switch to a trial of our Enterprise plan for agencies, please{' '}
                          <a
                            className='simple-link-button'
                            href='https://meet.intercom.com/elliot1/30min'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            book a set-up call.
                          </a>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className='font-weight-bold'>Tip: Choose one area of interest</p>
                        <p>Each topic you or your colleagues create should represent one area of policy interest.</p>
                        <p className='font-weight-bold'>Example topic names:</p>
                        <ul className='signup-topic-list'>
                          <li className='mb-1'>“Animal welfare”</li>
                          <li className='mb-1'>“Financial issues”</li>
                          <li className='mb-1'>“Carbon neutrality”</li>
                          <li className='mb-1'>“Higher education”</li>
                          <li className='mb-1'>“Competitors”</li>
                        </ul>
                      </>
                    )}
                  </>
                )}
                {activeItem === 'topicKeywords' && (
                  <>
                    <p className='font-weight-bold'>Tip: Be specific</p>
                    <p>
                      Use a larger number of specific keywords {agencyPlan ? 'relevant to your client' : ''} rather than
                      fewer broad keywords.
                    </p>
                    <p className='font-weight-bold'>Examples of weak keywords:</p>
                    <ul className={`weak-keywords ${agencyPlan ? 'agency-keywords' : ''}`}>
                      <li className='mb-1'>“health”</li>
                      <li className='mb-1'>{agencyPlan ? '“cars”' : '“economy”'}</li>
                      <li className='mb-1'>“education”</li>
                    </ul>

                    <p className='font-weight-bold'>Examples of strong keywords:</p>
                    <ul className={`strong-keywords ${agencyPlan ? 'agency-keywords' : ''}`}>
                      <li className='mb-1'>“health inequalities”</li>
                      <li className='mb-1'>{agencyPlan ? '“electic vehicles”' : '“economic reform”'}</li>
                      <li className='mb-1'>“further education”</li>
                      {agencyPlan && <li className='mb-1'>Name of client & competitors </li>}
                    </ul>
                  </>
                )}
              </div>
              <div className='pb-5'>
                <a
                  href='https://meet.intercom.com/elliot1/30min'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='action-state-hover general-button rounded-button px-5 py-2 outline-badge d-inline-block'
                >
                  Get setup help
                </a>
              </div>
            </div>

            {/*TOPIC SECTION*/}
            <div
              className='flex-grow-1 bg-main-blue text-white p-5 create-topic-content-box'
              style={{ minHeight: `${heightContainer}px` }}
              ref={blueSectionElement}
            >
              <div className='px-lg-5 pt-lg-5 sign-up-create-container pb-lg-0'>
                <h2 className='mb-2 title-h2-bold'>
                  {parliamentarianOnboarding
                    ? 'Finally, create a topic to monitor'
                    : `${agencyPlan ? `Set up your first client${newAgencyPlan ? ' and a topic of interest' : ''} ` : `Create your first topic to monitor`}`}
                </h2>
                <p className='border-bottom pb-4 mb-4 create-topic-subtext'>
                  {newAgencyPlan
                    ? 'Please choose a client and a topic of interest to them, along with some relevant keywords. You’ll be able to make edits, set up advanced rules and add more clients at any time.'
                    : `${agencyUser ? 'Please add the keywords and phrases relevant to one of your clients or prospective clients. You’ll be able to make edits, set up advanced rules and add more clients at any time.' : 'A topic categorises the keywords and phrases related to one of your areas of interest. You’ll be able to make edits, set up advanced rules and add more topics at any time.'}`}{' '}
                </p>

                {newAgencyPlan && (
                  <div className='form-group my-4'>
                    <label className='title-h5-m-bold mb-3'>Client name</label>
                    <div ref={clientContainer}>
                      <input
                        data-hj-allow
                        value={clientName}
                        type='text'
                        disabled={disabledClientName}
                        className='form-control create-name-topic-input bg-white main-input signup-topic-input'
                        onChange={(e) => {
                          setClientName(e.target.value);
                          setError({ type: '', message: '' });
                        }}
                        onFocus={() => {
                          setActiveItem('clientName');
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (clientName !== '') {
                              e.preventDefault();
                              nameTopic.current.focus();
                              nameTopic.current.select();
                            }
                          }
                        }}
                        ref={clientRef}
                        placeholder={'Start typing...'}
                      />
                      {error.type === 'clientName' && (
                        <p className='mb-0 profile-error-message py-2 px-3 mt-2'>{error.message}</p>
                      )}
                    </div>
                  </div>
                )}

                <div className='form-group my-4'>
                  <label className='title-h5-m-bold mb-3'>
                    {' '}
                    {newAgencyPlan ? 'First topic name' : `${agencyUser ? 'Client name' : 'Give your topic a name'}`}
                  </label>
                  <div ref={inputContainer}>
                    <input
                      data-hj-allow
                      value={topicName}
                      type='text'
                      className='form-control create-name-topic-input bg-white main-input signup-topic-input'
                      onChange={(e) => {
                        setTopicName(e.target.value);
                        setError({ type: '', message: '' });
                      }}
                      onFocus={() => {
                        setActiveItem('topicName');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (topicName !== '') {
                            e.preventDefault();
                            inputRef.current.focus();
                            inputRef.current.select();
                          }
                        }
                      }}
                      ref={nameTopic}
                      placeholder={'Start typing...'}
                    />
                    {error.type === 'topicName' && (
                      <p className='mb-0 profile-error-message py-2 px-3 mt-2'>{error.message}</p>
                    )}
                  </div>
                </div>
                <div className='d-flex justify-content-between flex-wrap align-items-center mt-3'>
                  <label className='mb-0 title-h5-m-bold'>Keywords and phrases</label>
                  <button
                    className='general-button paragraph-p1-bold create-subtext text-white mt-2 mt-sm-0'
                    onClick={() => {
                      setShowSuggestion(!showSuggestion);
                    }}
                  >
                    {showSuggestion ? 'Hide' : 'Show'} suggestions
                  </button>
                </div>
                <p className='create-subtext'>
                  Enter the keywords and phrases related to this {agencyUser ? 'client' : 'topic'}
                </p>
                {error.type === 'keywords' && (
                  <p className='mb-2 profile-error-message py-2 px-3 mt-2'>{error.message}</p>
                )}
                {showSuggestion && (
                  <KeywordsSuggestions
                    topicName={topicName}
                    onSelect={(keyword) => {
                      handleChange([...keywords, keyword], [keyword], [keywords.length]);
                      tagsContainer.current?.div?.click();
                    }}
                    setShowSuggestion={setShowSuggestion}
                    createPage
                  />
                )}
                <div
                  className={`keywords-category-words p-2 keywords-category-words-onboarding ${showSuggestion ? 'keywords-category-words-onboarding-suggestions' : ''}`}
                  ref={keywordsContainer}
                  onClick={() => {
                    inputRef.current.focus();
                  }}
                >
                  <TagsInput
                    ref={tagsContainer}
                    onlyUnique={true}
                    value={keywords}
                    onChange={handleChange}
                    renderTag={defaultRenderTag}
                    renderLayout={defaultRenderLayout}
                    inputProps={{
                      placeholder:
                        disabledConditional() || (keywords.length === 0 && activeItem !== 'topicKeywords')
                          ? ''
                          : keywords.length > 0
                            ? '+ Add more'
                            : 'Start typing...',
                      ref: inputRef,
                      style: { width: '100%' },
                      disabled: disabledConditional(),
                      onFocus: () => {
                        addClass();
                      },
                      onBlur: (e) => {
                        removeClass();
                        if (e.target.value !== '') {
                          tagsContainer.current.accept();
                        }
                      },
                    }}
                  />
                </div>
                {remainingKeywords < 0 && (
                  <p className='mb-0 profile-error-message py-2 px-3 mt-2'>
                    Please <span>remove {`${-remainingKeywords} keyword${-remainingKeywords !== 1 ? 's' : ''}`}</span>{' '}
                    to comply with your plan’s limitations.
                  </p>
                )}

                <div className='text-right mt-2'>
                  <button
                    className='btn btn-general action-button px-5 py-2 my-4 rounded-button'
                    disabled={remainingKeywords < 0}
                    onClick={async () => {
                      if (!isLoading) {
                        if (newAgencyPlan && clientName === '') {
                          setError({ type: 'clientName', message: 'Please complete this field' });
                        } else if (topicName === '') {
                          setError({ type: 'topicName', message: 'Please complete this field' });
                        } else if (keywords.length === 0) {
                          setError({ type: 'keywords', message: 'Please add at least one keyword or phrase' });
                        } else {
                          if (newAgencyPlan) {
                            let team = await createTeam();
                            if (!!team) {
                              createFirstList();
                            }
                          } else {
                            createFirstList();
                          }
                        }
                      }
                    }}
                  >
                    {isLoading ? (
                      <i className='fas fa-spinner fa-spin'></i>
                    ) : activePlan?.name === 'Policy Influence' ? (
                      'Start PolicyMogul trial'
                    ) : (
                      `Complete signup`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default CreateATopicPage;
