import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { store } from 'components/Store';
import StakeHolderListSkeleton from 'components/Dashboard/StakeHolders/StakeHolderListSkeleton';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory';
import TopicPicker from 'components/Common/TopicPicker';
import useContentWidgetFunctions from './useContentWidgetFunctions';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions';
import { Portal } from 'react-portal';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { DateFilterButton } from 'components/Dashboard/Analytics/ui/MentionsByTopic/FiltersBar';
import dayjs from 'dayjs';
import { createLabelText } from 'components/Dashboard/Analytics/ui/OverallSentiment/PieOverallSentimentChart';
import { formatSimpleGeneralDate } from 'components/Dashboard/helpers/formatGeneralDate';
import { createDataOptionsMapping } from 'components/Dashboard/Analytics/createAnalyticsState';
import Axios from 'axios';
import { stakeholderUrlImage } from 'components/Dashboard/Analytics/useCallMostRelevantStakeholders';

const MostInterestedStakeholder = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;

  const { item, widgetData, setWidgetData, previewMode, modifyItemLayout, report } = props;
  const [isLoading, setIsLoading] = useState(!widgetData);
  const { previeMaxHeight, itemMinHeight, mainContentElement, itemsList, numberOfItemsShown } =
    useContentWidgetFunctions({ previewMode, isLoading, item, widgetData, modifyItemLayout });
  const { dates } = item?.data?.state ?? {};
  const topic = item?.data?.state?.topic ?? keywordsLists.filter((item) => item.id !== null)?.[0];
  const loadMostInterestedStakeholders = useRef();
  const initialWidgetData = useRef();
  const initialDeletedIds = useRef();

  const { generalApiCall } = useGeneralApiCall();
  loadMostInterestedStakeholders.current = async (source) => {
    try {
      const prospective = topic?.prospective;
      if (!widgetData) {
        setIsLoading(true);
      }
      const pathname = `/api/graph-data/aggregation${!prospective ? '-by-keyword-list' : ''}-v2`;

      const method = 'post';
      const types = [
        'ParliamentaryContribution',
        'QuestionAndAnswer',
        'Edm',
        'ScotlandCountrySpecificParliamentaryContribution',
        'ScotlandQuestionAndAnswer',
        'WalesCountrySpecificParliamentaryContribution',
        'WalesQuestionAndAnswer',
      ];
      const contentTypesFilters = types.map((type) => {
        return {
          field: 'contentType',
          value: type,
          operator: 'str_eq',
        };
      });
      const filters = [
        {
          field: 'dateTime',
          value: dates?.filter?.[0],
          operator: 'date_gte',
        },
        {
          field: 'dateTime',
          value: dayjs(dates?.filter?.[1] ?? dayjs())
            .add('1', 'day')
            .format('YYYY-MM-DD'),
          operator: 'date_lt',
        },
        ...contentTypesFilters,
      ];

      let requestProperties = {
        type: 'ParliamentaryRecord',
        filters: filters,
        bucketField: 'contactIds',
        order: 'desc',
        bucketMaxCount: 20,
      };

      if (prospective) {
        requestProperties = {
          ...requestProperties,
          query: ChangeKeywordsCategory(topic?.keywords),
        };
      } else {
        requestProperties = {
          ...requestProperties,
          keywordListId: topic?.id,
        };
      }

      const results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
      if (results) {
        let contacts = await getStakeholders({ results: results?.values, topic, source });
        if (contacts) {
          setWidgetData(contacts);
          initialWidgetData.current = contacts;
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getStakeholders = async ({ results, topic, source }) => {
    try {
      const pathname = '/api/search/query';
      const method = 'post';
      const valueOfContributions = results.map((item) => item.key);
      const deletedIds = item?.data?.state?.deletedIds ?? [];
      const filters = valueOfContributions.map((item) => {
        return {
          field: 'id',
          value: `${item}`,
          operator: `str_${deletedIds.includes(parseInt(item)) ? 'not_' : ''}eq`,
        };
      });
      const requestProperties = {
        type: 'stakeholder',
        filters,
        pageNumber: 1,
        pageSize: 20,
        query: '',
      };
      const contacts = await generalApiCall({
        pathname,
        method,
        requestProperties,
        notShowErrorMessage: true,
        requestSource: source,
      });
      if (contacts) {
        let resultsWithoutDeleted = results?.filter((result) => !deletedIds.includes(parseInt(result.key)));
        let baseResults = [];

        resultsWithoutDeleted?.forEach((result) => {
          let item = contacts?.hits?.find((item) => `${item.id}` === result.key);
          if (!!item) {
            baseResults.push({
              ...item,
              numberOfContributions: result?.value,
            });
          }
        });
        let state = item?.data?.state;
        let finalResults =
          state?.showRelevantPositions || state?.showRelevantMemberships
            ? await getMatchInformation({ results: baseResults, topic })
            : baseResults;
        return finalResults;
      }
    } catch (error) {}
  };

  const getMatchInformation = async ({ results, topic }) => {
    let newResults = [...results];
    try {
      for (let i = 0; i < newResults?.length; i++) {
        let item = newResults[i];
        const { id } = item;
        if (!!id) {
          const pathname = `/api/stakeholder/get-match-information`;
          const method = 'post';
          const requestProperties = {
            contactId: id,
            query: ChangeKeywordsCategory(topic?.keywords),
            mustNotQueries: ChangeKeywordsExclusions(topic?.keywordExclusions),
            andQueries: ChangeKeywordsExclusions(topic?.andKeywords),
          };
          const result = await generalApiCall({
            pathname,
            method,
            requestProperties,
            needsAuthentication: true,
            notShowErrorMessage: true,
          });
          if (result) {
            newResults[i] = { ...item, ...result };
          }
        }
      }
      return newResults;
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if (!widgetData) {
      loadMostInterestedStakeholders.current(source);
    }
    return () => {
      source.cancel('Stakeholders cancelled');
    };
  }, [topic, item?.data?.state, report?.updateTo]);

  const removeStakeholder = ({ stakeholder, setShowActions }) => {
    initialWidgetData.current = widgetData;
    let deletedIds = item?.data?.state?.deletedIds ?? [];
    initialDeletedIds.current = deletedIds;
    deletedIds.push(stakeholder.id);
    let newData = [...widgetData];
    let position = newData.findIndex((item) => item.id === stakeholder.id);
    setShowActions(false);
    newData.splice(position, 1);
    setWidgetData(newData);
    modifyItemLayout({ item, state: { ...item?.data?.state, deletedIds } });
    createNotification(
      'success',
      (() => {
        return (
          <span>
            Stakeholder removed.{' '}
            <span
              onClick={() => {
                setWidgetData(initialWidgetData.current);
                modifyItemLayout({ item, state: { ...item?.data?.state, deletedIds: initialDeletedIds.current } });
              }}
              className='text-underline'
            >
              Undo
            </span>
          </span>
        );
      })()
    );
  };

  const [commentaryHeight, setCommentaryHeight] = useState(0);
  useEffect(() => {
    let previousElement = mainContentElement?.current?.previousElementSibling;
    let heightElement = previousElement?.getBoundingClientRect()?.height;
    if (previousElement && heightElement && heightElement > 0) {
      setCommentaryHeight(heightElement + 24);
    }
  }, [mainContentElement?.current, item?.data?.state?.commentary]);

  return (
    <div
      ref={mainContentElement}
      className={`overflow-hidden`}
      style={{
        height: `calc(100% - ${item?.data?.state?.commentary ? commentaryHeight : 0}px)`,
        padding: '0 20px',
        maxHeight: previewMode ? previeMaxHeight : 'none',
      }}
    >
      {isLoading ? (
        <StakeHolderListSkeleton />
      ) : (
        <>
          {widgetData?.length === 0 ? (
            <WidgetEmptyMessage text={'stakeholders'} />
          ) : (
            <ul className={`policy-list px-0 items-list mb-0 pb-0`} ref={itemsList}>
              {widgetData?.map((stakeholder, index) => (
                <StakeholderReportItem
                  removeStakeholder={removeStakeholder}
                  item={stakeholder}
                  key={`stakeholder-${index}`}
                  state={item?.data?.state}
                  previewMode={previewMode}
                  itemMinHeight={itemMinHeight}
                  numberOfItemsShown={numberOfItemsShown}
                  reportDateInterval={dates?.filter}
                  index={index}
                />
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

const StakeholderReportItem = (props) => {
  const { item, index, state, previewMode, removeStakeholder, itemMinHeight, numberOfItemsShown, reportDateInterval } =
    props;
  const { imageId, title, positions, groupMemberships, numberOfContributions } = item;
  const { showRelevantContributions, showRelevantPositions, showRelevantMemberships } = state ?? {};
  const [showActions, setShowActions] = useState(false);

  const urlImage = stakeholderUrlImage({ imageId });

  const contentElement = useRef();
  const actionsElement = useRef();

  const changeMemberType = (type) => {
    switch (type) {
      case 'Select Committees':
        return 'Select Committee';
      case 'Lords Select Committees':
        return 'Lords Select Committee';
      case 'All Party Groups':
        return 'APPG';
      default:
        return type;
    }
  };

  const emptyState = !showRelevantContributions && !showRelevantPositions && !showRelevantMemberships;
  const values = [showRelevantContributions, showRelevantPositions, showRelevantMemberships];
  const showIcon = values.filter((item) => !!item)?.length > 1;

  const createNumbers = () => {
    let label = `${createLabelText(reportDateInterval)} to ${formatSimpleGeneralDate(reportDateInterval[1] ?? dayjs())}`;
    return (
      <>
        {showIcon && <span className='icon-parliamentary-record paragraph-p5 mr-2 ' />}
        {numberOfContributions > 0 ? (
          <>
            <span>
              {numberOfContributions} relevant parliamentary contribution{numberOfContributions > 1 ? 's' : ''} in the{' '}
              {label}
            </span>
          </>
        ) : (
          <>
            <span>No relevant parliamentary contributions in the {label}</span>
          </>
        )}
      </>
    );
  };

  const buttonElement = useRef();
  const { top, left, height, width } = buttonElement?.current?.getBoundingClientRect() ?? {};
  const [localItemHeight, setLocalItemHeight] = useState(0);

  const contentContainer = useCallback(
    (node) => {
      if (node !== null) {
        setLocalItemHeight(node?.scrollHeight + itemMinHeight);
      }
    },
    [itemMinHeight]
  );

  return (
    <div
      className={`position-relative report-stakeholder-item d-flex flex-column justify-content-center ${index === numberOfItemsShown - 1 ? '' : 'border-bottom'} ${emptyState ? 'report-stakeholder-item-empty' : ''} ${item?.data?.state?.dates?.name}`}
      style={{ minHeight: `${localItemHeight}px` }}
    >
      <div className={`d-flex py-3 align-items-${emptyState ? 'center' : 'start'}`} ref={contentContainer}>
        <img src={urlImage} alt={`${title}`} />
        <div className='flex-grow-1 report-stakeholder-item-content '>
          <div className={`d-flex mb-1 justify-content-between`}>
            <h4 className={`title-h5${emptyState ? '' : '-bold'} mb-0 nowrap-item`}>{title}</h4>
            {!previewMode && (
              <div className='position-relative  z-max' ref={actionsElement}>
                <button
                  ref={buttonElement}
                  className={`general-button circle-button item-actions-button ${showActions ? 'active' : ''}`}
                  onClick={(e) => {
                    setShowActions(!showActions);
                  }}
                >
                  <span className='icon-three-dots light-50-text title-h5' />
                </button>
                {showActions && (
                  <Portal>
                    <div className='general-close-background z-extra-max' onClick={() => setShowActions(false)} />
                    <div
                      className='box box-shadow py-2 item-actions-box reports-actions-box reports-latest-news-actions-box reports-stakeholders-widget position-fixed'
                      style={{
                        top: `${top + height}px`,
                        left: `${left + width - 195}px`,
                        right: 'auto',
                      }}
                    >
                      <button
                        className='general-button'
                        onClick={() => {
                          removeStakeholder({ stakeholder: item, setShowActions });
                        }}
                      >
                        <span className='icon-close-bold paragraph-p5 d-inline-block-centered' /> Remove stakeholder
                      </button>
                    </div>
                  </Portal>
                )}
              </div>
            )}
          </div>
          <div className='report-stakeholder-content' ref={contentElement}>
            {showRelevantPositions && (
              <>
                {positions?.length > 0 ? (
                  <>
                    {positions.map((item, index) => (
                      <p
                        className='mb-1 paragraph-p2 main-light-text d-flex reports-contribution-text'
                        key={`item-position${index}`}
                      >
                        {showIcon && <span className={`icon-star d-inline-block mr-2 `} style={{ fontSize: '11px' }} />}
                        <span>{item}</span>
                      </p>
                    ))}
                  </>
                ) : (
                  <p className='mb-1 paragraph-p2 main-light-text d-flex'>
                    {showIcon && <span className={`icon-star d-inline-block mr-2 `} style={{ fontSize: '11px' }} />}
                    <span>No relevant positions</span>
                  </p>
                )}
              </>
            )}

            {showRelevantMemberships && (
              <>
                {groupMemberships?.length > 0 ? (
                  <>
                    {groupMemberships?.map((item, index) => (
                      <p
                        className='mb-1 paragraph-p2 main-light-text d-flex reports-contribution-text'
                        key={`item-position${index}`}
                      >
                        {showIcon && <span className={`icon-group d-inline-block mr-2 `} style={{ fontSize: '8px' }} />}
                        <span>
                          Member of {item?.name} {changeMemberType(item?.type)}
                        </span>
                      </p>
                    ))}
                  </>
                ) : (
                  <p className='mb-1 paragraph-p2 main-light-text d-flex'>
                    {showIcon && <span className={`icon-group d-inline-block mr-2 `} style={{ fontSize: '8px' }} />}
                    <span>No relevant memberships</span>
                  </p>
                )}
              </>
            )}

            {showRelevantContributions && (
              <p className='mb-0 paragraph-p2 main-light-text d-flex align-items-start reports-contribution-text'>
                {createNumbers()}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MostInterestedStakeholderSettings = (props) => {
  const globalState = useContext(store);
  const { state: appState } = globalState;
  const { keywordsLists } = appState;

  const { state, setState, report } = props;
  const { topic, dates } = state ?? {};

  const toggles = ['contributions', 'positions', 'memberships'];
  const { agencyUser } = useTopicOrClientWord();

  return (
    <>
      {!agencyUser && (
        <div className='mb-4 reports-topick-picker'>
          <label className='mt-4 mb-2'>Topic</label>
          <TopicPicker
            keywordsLists={keywordsLists.filter((item) => item.id !== null)}
            showTopicCases={true}
            searchFunction={({ topic }) => {
              setState({ ...state, topic });
            }}
            defaultTopic={topic}
            fixedPosition
            placeholder={`Select a topic`}
            showTopicSelected={true}
          />
        </div>
      )}

      <div className={`reports-graph-settings`}>
        <label className='mb-2'>Timeframe</label>
        <DateFilterButton
          analyticsState={{ activeDateInterval: dates }}
          modifyFiltersBar={({ property, newValue }) => {
            if (property === 'CompleteState') {
              setState({ ...state, dates: newValue?.activeDateInterval });
            } else {
              let finalValue = { ...newValue };
              let dateToReference = report?.updateTo;
              let firstDateParent = createDataOptionsMapping({ date: dateToReference })?.find(
                (item) => item.name === finalValue?.name
              );
              let dates = [firstDateParent?.filter?.[0], dayjs(dateToReference).startOf('day').format('YYYY-MM-DD')];
              finalValue = {
                ...finalValue,
                filter: dates,
              };
              setState({ ...state, dates: finalValue });
            }
          }}
          customTopValue={35}
          customLeftValue={0}
        />
      </div>

      {toggles.map((item, index) => {
        const value = state[`showRelevant${uppercaseFirstLetter(item)}`];
        return (
          <div
            className={`d-flex justify-content-between align-items-center pb-2  pt-4 mt-2 ${index === 0 ? '' : 'border-top '}`}
            key={`toggle-${item}`}
          >
            <p className='font-weight-bold'>
              Include {item === 'contributions' ? 'number of' : ''} relevant {item}
            </p>
            <button
              className={`general-button filter-toggle filter-toogle-${value ? 'active' : 'inactive'}`}
              onClick={() => {
                setState({ ...state, [`showRelevant${uppercaseFirstLetter(item)}`]: !value });
              }}
            >
              <span className='toggle-item' />
            </button>
          </div>
        );
      })}
    </>
  );
};

const WidgetEmptyMessage = (props) => {
  const { text } = props;
  return (
    <div className='text-center pt-2 rounded h-100 flex-centered justify-content-center'>
      <div className='px-3 px-lg-5' style={{ maxWidth: '400px' }}>
        <p className='title-h4 text-center main-light-text my-0'>No {text} found</p>
      </div>
    </div>
  );
};

export { MostInterestedStakeholderSettings, WidgetEmptyMessage };
export default MostInterestedStakeholder;
