import React, { useContext, useState } from 'react';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { store } from 'components/Store';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import { useRef } from 'react';
import normalize from 'components/Dashboard/utilities/normalizeString';
import useContactsApiCalls from 'components/Dashboard/Influence/CrmContacts/useContactsApiCalls';
import useGetContactsTags from 'components/Dashboard/Filters/useGetContactsTags';
import ModalComponent from 'components/Common/Modal';

const createTagFilterOption = (array) => {
  return array.map((tag) => {
    return { label: tag, value: tag };
  });
};

function RemoveContactTagsModal({ totalHits = 0, showRemoveContactTagsModal }) {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedCrmContacts, contentResults, activeResults, selectedContactTags } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const { removeTagsFromContacts } = useContactsApiCalls();
  const options = useRef([...createTagFilterOption(selectedContactTags)]);
  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;

  const { upadateContactTags } = useGetContactsTags({ notCallInitially: true });

  const hideRemoveContactTagsModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'deleteStakeholderId',
      value: null,
    });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showRemoveContactTagsModal',
      value: false,
    });
  };

  const closeModal = () => {
    hideRemoveContactTagsModal();
  };

  const contactsCount =
    selectedAllVisible.length > 0 ? selectedAllVisible.length : selectedIds.length > 0 ? selectedIds.length : totalHits;

  const handleChange = (e) => {
    if (e.target.checked) {
      setTags([...tags, e.target.value]);
    } else {
      setTags([...tags.filter((tag) => tag !== e.target.value)]);
    }
  };

  const handleRemoveTags = async () => {
    try {
      const contactIds = selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds;
      setIsLoading(true);
      let result = await removeTagsFromContacts({ tags, contactIds });
      if (result || result?.status === 200) {
        createNotification(
          'success',
          `Tag${tags.length > 1 ? 's' : ''} removed from ${contactsCount > 1 ? `${thousandCommas(contactsCount)} contacts` : 'contact'} successfully`
        );
        //update contactsTags value in store in case if there is no more such tag
        upadateContactTags();
        let newCrmContacts;
        if (selectedAll) {
          newCrmContacts = contentResults[activeResults].CrmContacts?.hits.map((stakeholder) => {
            return {
              ...stakeholder,
              tags: [...stakeholder.tags.filter((item) => !tags.includes(item))],
            };
          });
        } else {
          newCrmContacts = contentResults[activeResults].CrmContacts?.hits.map((stakeholder) => {
            if (contactIds.includes(stakeholder.id)) {
              return {
                ...stakeholder,
                tags: [...stakeholder.tags.filter((item) => !tags.includes(item))],
              };
            } else {
              return stakeholder;
            }
          });
        }

        // ADD TAG TO GLOBAL STORE TOO, TO AVOID RELOAD CONTACTS
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: {
            ...contentResults,
            [activeResults]: {
              CrmContacts: {
                ...contentResults[activeResults].CrmContacts,
                hits: newCrmContacts,
              },
            },
          },
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedCrmContacts',
          value: {
            selectedAll: false,
            selectedIds: [],
            selectedAllVisible: [],
          },
        });
        setTags([]);
        closeModal();
      }
      setIsLoading(false);
    } catch (error) {}
  };
  return (
    <ModalComponent maxWidth={558} isOpen={showRemoveContactTagsModal} setIsOpen={hideRemoveContactTagsModal}>
      <div className='pt-4'>
        <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 pl-3 pr-5'>
          Select tags to remove them from{' '}
          {contactsCount === 1 ? `this contact` : `these ${thousandCommas(contactsCount)} contacts`}
        </h3>
        <div className='remove-contacts-wrapper pt-2 px-3 px-lg-5'>
          {options.current.map((tag) => (
            <div
              className={`checkbox-item filter-item-${normalize(tag.value)}`}
              key={`filter-item-${normalize(tag.value)}`}
            >
              <input
                id={normalize(tag.value)}
                onChange={handleChange}
                type={`checkbox`}
                checked={tags.includes(tag.value)}
                value={tag.value}
              />
              <label htmlFor={normalize(tag.value)}>{tag.value}</label>
            </div>
          ))}
        </div>
        <p className='remove-contacts-helper-text px-lg-5 px-3 mt-2'>
          <span className='remove-contacts-helper-text-icon mr-2' />
          These changes impact all members of your team
        </p>
        <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white rounded-bottom pt-4'>
          <button onClick={closeModal} className='danger-button-outlined px-3 py-2 mr-3' disabled={isLoading}>
            Cancel
          </button>
          <button
            onClick={handleRemoveTags}
            className='danger-button px-3 py-2'
            disabled={isLoading || tags.length === 0}
          >
            {isLoading ? (
              <i
                className='fas fa-spinner fa-spin'
                style={{
                  margin: '0 41.2px',
                  color: '#fcfcfc',
                  marginTop: '2px',
                  width: '33.7px',
                }}
              ></i>
            ) : tags.length === 1 ? (
              `Remove tag`
            ) : (
              `Remove ${tags.length > 0 ? thousandCommas(tags.length) : ''} tags`
            )}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default RemoveContactTagsModal;
