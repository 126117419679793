import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { initialCrmEmailState, store } from 'components/Store';
import CrmEmailTableItem from './CrmEmailTableItem/CrmEmailTableItem';
import { useCallback } from 'react';
import { preventHistoryBack } from 'components/Dashboard/helpers/preventHistoryBack';
import CrmEmailsTableSkeleton, { CrmEmailItemSkeleton } from './CrmEmailsTableSkeleton';
import EmailsOnBoarding from './EmailsOnBoarding';
import isDummyTableItem from 'components/Dashboard/helpers/isDummyTableItem';
import { loadMoreTableRowsDependOnScreen } from 'components/Dashboard/helpers/loadMoreTableRowsDependOnScreen';
import addMetaTags from 'components/Dashboard/utilities/addMetaTags';
import { useLocation, useNavigate } from 'react-router-dom';
import TableComponent from 'components/Dashboard/Influence/tableComponents/TableComponent';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';
import useCallRemainingEmailCampaigns from './useCallRemainingEmailCampaigns';
import useEmailDomainFunctions from 'components/Dashboard/hooks/useEmailDomainFunctions';

function CrmEmails() {
  const columns = [
    {
      accessorKey: 'subject',
      header: 'Title',
      ref: useRef(),
    },
    {
      accessorKey: 'status',
      header: 'State',
      ref: useRef(),
      notShowResizer: true,
    },
    {
      accessorKey: 'lastEditedBy',
      header: 'Last edited by',
      ref: useRef(),
    },
    {
      accessorKey: 'from',
      header: 'Sender',
      ref: useRef(),
    },
    {
      accessorKey: 'sendRecordCount',
      header: 'Recipients',
      ref: useRef(),
    },
    {
      accessorKey: 'openedCount',
      header: 'Opens',
      ref: useRef(),
    },
    {
      accessorKey: 'lastUpdated',
      header: 'Updated at',
      ref: useRef(),
    },
    {
      accessorKey: 'finishedSendingAt',
      header: 'Sent at',
      ref: useRef(),
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { callRemainingEmailCampaigns } = useCallRemainingEmailCampaigns();

  const initialCall = useRef();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { net_api_url, crmEmailsTableOnSort, listDomainsVerified, searchEmailsString, team } = state;
  const { contentResults, activeResults } = state;
  const { CrmEmails } = contentResults[activeResults];
  const [scrollTopContainer, setScrollTopContainer] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });

  const [pageSize, setPageSize] = useState(20);
  const [updateBySort, setUpdateBySort] = useState('');
  const [scrollPositionX, setScrollPositionX] = useState(crmEmailsTableOnSort?.scrollPositionX);
  const { needsDomainVerification: needsDomainVerificationCondition } = useEmailDomainFunctions();

  const needsDomainVerification =
    //no verified domains
    needsDomainVerificationCondition || location?.state?.setUpDomain;

  const [afterInitialSort, setAfterInitialSort] = useState(false);
  const scrollBarRef = useRef(null);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setScrollTopContainer({ scrollTop, scrollHeight, clientHeight });
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      setScrollPositionX(0);
      load_more_results();
    }
  };

  const isDummyItem = useCallback((props) => isDummyTableItem(props), [scrollTopContainer]);

  //LOAD ONSCROLL
  const load_more_results = async () => {
    if (CrmEmails !== undefined) {
      let { thereIsMoreContent, pageNumber } = CrmEmails;
      if (thereIsMoreContent) {
        try {
          await createResults(pageNumber + 1);
        } finally {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      }
    }
  };

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const query = new URLSearchParams(location.search);
  const sort = query.get('sort') ? query.get('sort').split('_') : null;
  const searchQuery = query.get('search') ? decodeURI(query.get('search')) : '';

  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  initialCall.current = async (source) => {
    try {
      await createResults(null, null, source);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setScrollTopContainer({
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
    });
    initialCall.current(source);
    return () => {
      source.cancel('CrmEmails canceled by the user.');
    };
  }, [updateBySort, searchEmailsString]);

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailStateAfterSave',
      value: initialCrmEmailState(),
    });
  }, []);

  useEffect(() => {
    if (!isLoading && scrollPositionX && scrollBarRef.current) {
      scrollBarRef.current.scrollLeft = scrollPositionX;
    }
  }, [isLoading, scrollBarRef.current]);

  //PREVENT HISTORY BACK ON TRACKPAD SCROLL
  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.addEventListener('mousewheel', (e) => preventHistoryBack(e, scrollBarRef.current));
      loadMoreTableRowsDependOnScreen({
        containerHeight: scrollBarRef.current.clientHeight,
        cellHeight: 57.8,
        headerHeight: 50,
        setPageSize,
        pageSize,
      });
    }
  }, [scrollBarRef.current]);

  useEffect(() => {
    if (pageSize > 20) {
      load_more_results();
    }
  }, [pageSize]);

  async function createResults(pageNumber, processing, sourceProp) {
    try {
      if (!pageNumber && !processing) {
        setIsLoading(true);
      }
      const params = {};
      if (sort) {
        params.sortField = sort[0];
        params.sortOrder = sort[1];
      } else if (crmEmailsTableOnSort?.sort?.length) {
        params.sortField = crmEmailsTableOnSort?.sort.split('_')[0];
        params.sortOrder = crmEmailsTableOnSort?.sort.split('_')[1];
      } else {
        params.sortField = 'lastUpdated';
        params.sortOrder = 'descending';
      }
      params.query = searchQuery;
      params.pageNumber = pageNumber ? pageNumber : 1;

      const urlParams = new URLSearchParams(params);

      let token = isAuthenticated ? await getAccessToken() : '';
      let url = `${net_api_url}/api/crm-email/search?${urlParams.toString()}&pageSize=${pageSize}`;
      let requestOptions = {
        cancelToken: sourceProp ? sourceProp.token : source.token,
      };
      if (isAuthenticated) {
        requestOptions.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      let callResults = await Axios.get(url, requestOptions);
      if (!!callResults) {
        await callRemainingEmailCampaigns(source);
        let result = callResults.data;
        let emailResults = pageNumber ? CrmEmails.results : [];
        if (result.results !== null) {
          emailResults = emailResults.concat(result.results);
        }
        let emailsQntty = callResults.data.totalCount;
        let currentId = result.hits !== null && result.results.length > 0 ? result.results[0].crmEmailId : 0;
        let ResultsToState = {
          ...result,
          results: emailResults,
          pageNumber: pageNumber ? pageNumber : 1,
          currentId: currentId,
          thereIsMoreContent: result.results !== null ? result.results.length >= pageSize : false,
        };
        let CrmEmailsResults = {
          ...contentResults,
          [activeResults]: {
            CrmEmails: ResultsToState,
          },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: CrmEmailsResults,
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'totalEmailsCount',
          value: emailsQntty,
        });
        setAfterInitialSort(true);
        setIsLoading(false);
      }
    } catch (error) {}
  }

  //SET FILTERS TO URL
  useEffect(() => {
    if (crmEmailsTableOnSort?.sort?.length) {
      query.set('sort', crmEmailsTableOnSort?.sort);
    }
    let urlToHistory = `${location.pathname}?${query.toString()}`;
    let title = `Email campaigns - Influence - PolicyMogul`;
    addMetaTags({ title, hash: null, location, dispatch });
    navigate(urlToHistory, { replace: true });
  }, [crmEmailsTableOnSort]);

  const emailsToRender = CrmEmails?.results ?? [];

  return (
    <>
      <div className={`main-content pl-md-4 pl-3 h-100`}>
        <div className='d-flex flex-column h-100'>
          <div
            className={`row mx-0 h-100 ${
              (!isLoading && afterInitialSort && !CrmEmails?.results?.length > 0) || needsDomainVerification
                ? 'pr-md-4 pr-3'
                : ''
            }`}
          >
            <div className='flex-grow-1' style={isLoading ? { overflow: 'hidden' } : null}>
              {(isLoading || !afterInitialSort || listDomainsVerified.domainsProccessing) && (
                <div
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <CrmEmailsTableSkeleton />
                </div>
              )}
              {!isLoading &&
                afterInitialSort &&
                (!CrmEmails?.results?.length > 0 || needsDomainVerification ? (
                  <>
                    {!query.get('search') && !needsDomainVerification ? (
                      <div className='contact-list-scrollbar-table-no-data my-lg-2'>
                        <div className='crm-table-no-contacts'>
                          <p className='crm-table-no-contacts-title'>You haven't sent any emails yet</p>
                          <p className='crm-table-no-contacts-description'>
                            Once you or your teammates add contacts, you’ll be able to add them to email campaigns and
                            take other actions
                          </p>
                        </div>
                      </div>
                    ) : needsDomainVerification ? (
                      <EmailsOnBoarding />
                    ) : (
                      <div className='contact-list-scrollbar-table-no-data my-lg-2'>
                        <div className='crm-table-no-contacts'>
                          <p className='crm-table-no-contacts-description'>No data available</p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!isLoading && (
                      <>
                        <div className='email-list-scrollbar-table-wrapper'>
                          <div className='stakeholder-list-content-scrollbar' />
                          <div className='email-list-scrollbar-table' onScroll={handleScroll} ref={scrollBarRef}>
                            {emailsToRender.length > 0 && (
                              <TableComponent
                                afterInitialSort={afterInitialSort}
                                setScrollPositionX={setScrollPositionX}
                                setUpdateBySort={setUpdateBySort}
                                scrollBarRef={scrollBarRef}
                                columns={columns}
                                additionalClass={'crm-table-emails'}
                                activeIndexState={{ activeIndex, setActiveIndex }}
                              >
                                {emailsToRender.map((item, index, array) => (
                                  <CrmEmailTableItem
                                    item={item}
                                    team={team}
                                    key={`email-item-${index}`}
                                    dummyItem={isDummyItem({
                                      index,
                                      itemHeight: 57.8,
                                      cachedInvisibleItems: 1,
                                      scrollTopContainer,
                                      pageSize,
                                    })}
                                  />
                                ))}
                                {CrmEmails?.thereIsMoreContent && <CrmEmailItemSkeleton />}
                              </TableComponent>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CrmEmails;
