import { useContext, useState } from 'react';
import ModalComponent from 'components/Common/Modal';
import KeywordsAndAdvancedSearchComponent from 'components/Settings/Topics/KeywordsAndAdvancedSearchComponent';
import { useNavigate } from 'react-router-dom';
import { store } from 'components/Store';

const ProspectiveButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        data-tooltip-content={'Prospecting'}
        data-tooltip-id={'general-tooltip'}
        className='border bg-main-white mr-2 mr-lg-3 general-button prospective-top-nav-icon'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <img src={`${process.env.REACT_APP_CDNURL}/images/20231216-prospecting-icon.png`} alt='Prospecting icon' />
      </button>
      <ProspectiveModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

const ProspectiveModal = (props) => {
  const { isOpen, setIsOpen } = props;

  const [step, setStep] = useState('1');
  const [organisationData, setOrganisationData] = useState({ name: '', websiteUrl: '' });
  const closeModal = (value) => {
    setIsOpen(value);
    setTimeout(() => {
      setStep('1');
      setOrganisationData({ name: '', websiteUrl: '' });
    }, 300);
  };

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={closeModal} maxWidth={1020}>
      <div className='bg-grey rounded-lg text-center relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue text-white p-4 p-lg-5 text-left rounded-left' style={{ minWidth: '380px' }}>
            <h3 className='title-h3 mb-5 report-prospecting-title'>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/20231216-prospecting-icon.png`}
                style={{ width: '27px' }}
                alt='Prospecting icon'
                className='d-inline-block-centered prospecting-icon'
              />
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                alt='Logo'
                className='policy-logo mx-2'
              />
              Prospecting
            </h3>
            <ul className='policy-list text-left title-h5-m statistics-popup-list px-0 py-5 mb-5'>
              <li className='mb-4 nowrap-item '>
                <span className='title-h3 mr-3 icon-small-check' />
                Tailored for any prospect
              </li>
              <li className='mb-4 nowrap-item '>
                <span className={`title-h3 mr-3 icon-small-check`} />
                Includes unique insights
              </li>
              <li className='mb-4 nowrap-item '>
                <span className={`title-h3 mr-3 icon-small-check`} />
                Personalise with your logo
              </li>
              <li className='nowrap-item '>
                <span className='title-h3 mr-3 icon-small-check' />
                Fully customisable
              </li>
            </ul>
          </div>
          <div className='flex-grow-1 text-left d-none d-lg-block pt-5'>
            <div className='prospecting-content-padding'>
              <p className='text-uppercase text-light-blue paragraph-p3 font-weight-bold mb-2'>Step {step} of 2</p>
            </div>
            {step === '1' && (
              <div className='prospecting-content-padding'>
                <h3 className='title-h2-bold mb-5' style={{ maxWidth: '500px' }}>
                  Enter some details about your prospect in order to create a tailored report
                </h3>
                <div className='form-group'>
                  <label className='font-weight-bold' for='organisationName'>
                    Their organisation name
                  </label>
                  <input
                    value={organisationData?.name}
                    onChange={(e) => setOrganisationData({ ...organisationData, name: e.target.value })}
                    className='main-input form-control'
                    type='text'
                    placeholder='Organisation name'
                    autoFocus={true}
                  />
                </div>

                <div className={`form-group mt-5`}>
                  <label className='font-weight-bold' htmlFor='organisationWebsite'>
                    Their website URL{' '}
                    <span
                      className='icon-info ml-2'
                      data-tooltip-html="For most websites, this will enable us<br/> to automatically include the prospect's<br/> logo on the report. You'll be able to remove<br/> or change it later."
                      data-tooltip-id='modal-tooltip'
                    />
                  </label>
                  <input
                    id='organisationWebsite'
                    value={organisationData?.websiteUrl}
                    onChange={(e) => setOrganisationData({ ...organisationData, websiteUrl: e.target.value })}
                    className='main-input form-control'
                    type='text'
                    placeholder='Website URL'
                  />
                </div>
                <div className='text-center text-lg-right mt-4 mt-lg-0 w-100 py-5'>
                  <button
                    className='action-button rounded-button py-2 px-4 px-xl-5 title-h5 d-inline-block'
                    onClick={() => setStep('2')}
                    disabled={organisationData?.name === '' || organisationData.websiteUrl === ''}
                  >
                    Choose keywords
                    <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2' />
                  </button>
                </div>
              </div>
            )}

            {step === '2' && (
              <ProspectiveSecondStep organisationData={organisationData} setIsOpen={setIsOpen} setStep={setStep} />
            )}
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const ProspectiveSecondStep = (props) => {
  const { organisationData, setIsOpen, setStep } = props;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [customTopic, setCustomTopic] = useState({
    isMyList: true,
    id: '',
    keywords: [],
    andKeywords: [],
    keywordExclusions: [],
  });
  const [openStates, setOpenStates] = useState({
    additional: false,
    exclusions: false,
  });

  const navigate = useNavigate();

  return (
    <>
      <div className='prospecting-content-padding mb-3'>
        <h3 className='title-h2-bold mb-0'>Customise your report for {organisationData.name}</h3>
      </div>

      <div className='prospecting-content-padding prospecting-keywords prospective-keywords-container overflow-auto pt-3'>
        <p className='title-h5-bold mb-1'>Keywords and phrases</p>
        <p className='mb-3'>Enter the keywords and phrases relevant to this client or prospect</p>
        <KeywordsAndAdvancedSearchComponent
          customTopic={customTopic}
          customHandleChange={({ topic }) => setCustomTopic(topic)}
          openStates={openStates}
          setOpenStates={setOpenStates}
          tooltipTarget={'modal-tooltip'}
          enforceInitialFocus={true}
        />
      </div>

      <div className='prospecting-content-padding flex-centered justify-content-between mt-4 mt-lg-0 w-100 py-5'>
        <button
          className='text-light-blue general-button rounded-button px-0 py-2'
          onClick={() => {
            setStep('1');
          }}
        >
          <span className='icon-long-arrow-left mr-2 paragraph-p5' />
          Back
        </button>
        <button
          className='action-button rounded-button py-2 px-4 px-xl-5 title-h5 d-inline-block'
          disabled={customTopic?.keywords?.length === 0}
          onClick={() => {
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'topicForReport',
              value: { ...organisationData, ...customTopic, prospective: true },
            });
            navigate({ pathname: '/reports/create' }, { state: { topicId: null } });
            setIsOpen(false);
          }}
        >
          View and share report
          <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2' />
        </button>
      </div>
    </>
  );
};

export default ProspectiveButton;
