import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import React, { useCallback, useMemo, useState } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import { store } from 'components/Store';
import { CrmContactImage } from 'components/Dashboard/Influence/CrmContacts/CrmContactsTable/CrmContactItem/CrmContactItem';
import AddContactModal from 'components/Dashboard/Influence/CrmContacts/AddContactModal';
import InfluenceNotIncludedFeaturesPopup from 'components/Dashboard/Influence/CrmContacts/InfluenceNotIncludedFeaturesPopup';
import useValidateContactsOversubscribed from 'components/Dashboard/utilities/useValidateContactsOversubscribed';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import useOnScreen from 'components/Dashboard/hooks/useOnScreen';
import { useLocation, useNavigate } from 'react-router-dom/';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import CrmContactsSelectOption, { ActionsBar, SelectAllBtn } from './CrmContactsSelectOption';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { useGroupFunctions } from 'components/Dashboard/Influence/CrmGroups/GroupFunctions';

const { ValueContainer, Placeholder } = components;
const CustomMenuList = (props) => {
  const { children, selectProps } = props ?? {};
  let selectAllCondition = () => {
    return (
      children?.[1]?.props?.data?.label === 'contacts' &&
      !children?.[1]?.props?.data?.options?.every((opt) => opt.disabled)
    );
  };

  const autoHeightProps = selectProps?.customMenuAutoHeight ? { autoHeight: true, autoHeightMin: '100%' } : {};

  return (
    <components.MenuList {...props}>
      <div className={`d-flex flex-column h-100`}>
        <div className='overflow-auto flex-grow-1'>
          <CustomScrollbar {...autoHeightProps} className='simple-scrollbar'>
            {selectAllCondition() ? [children[0], props?.selectProps?.RenderSelectAllBtn(), children[1]] : children}
          </CustomScrollbar>
        </div>
        {children?.length > 0 && selectProps?.showBottomBar && selectProps?.RenderActionsBar()}
      </div>
    </components.MenuList>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const location = useLocation();
  const sentEmail = location.pathname.includes('email-performance');
  const [selectedListPage, setSelectedListPage] = useState(1);
  const valueContainerRef = useRef(null);
  const containerRef = useRef(null);

  const containerHeight = () => {
    let height = window.innerHeight - containerRef.current?.getBoundingClientRect()?.top - 29 - 24;
    if (!Number.isNaN(height)) {
      return height;
    } else {
      return 0;
    }
  };

  return (
    <>
      <ValueContainer {...props}>
        <div ref={containerRef} className='RefferenceHeightComponent'></div>
        <div
          ref={valueContainerRef}
          className='ValueContainerScrollbar'
          style={{
            height: containerHeight(),
          }}
        >
          {!sentEmail && (
            <Placeholder {...props} isFocused={props.isFocused}>
              {props.selectProps.placeholder}
            </Placeholder>
          )}
          {props?.selectProps?.inputValue !== '' && <span className='icon-close'></span>}
          {!sentEmail &&
            React.Children.toArray(children, (child) => (child && child.type !== Placeholder ? child : null)).find(
              (item) => item.type === Input
            )}
          <CustomScrollbar
            className={'simple-scrollbar crm-contacts-scrollbar'}
            onScroll={(e) => {
              if (
                React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null)).length >
                selectedListPage * 20
              ) {
                if (Math.abs(e.scrollHeight - (e.scrollTop + e.clientHeight)) <= 1) {
                  setSelectedListPage(selectedListPage + 1);
                }
              }
            }}
            maximalThumbYSize={100}
          >
            {[
              ...React.Children.map(children, (child) =>
                child && child.type !== Placeholder && child.type !== Input ? child : null
              ).slice(0, selectedListPage * 20),
            ]}
          </CustomScrollbar>
        </div>
      </ValueContainer>
    </>
  );
};

const CustomMultiValueLabel = (props) => {
  const { imageId, label, email } = props.data;
  const [showToolTip, setShowTooltip] = useState(false);
  const labelTextRef = useRef();
  const isMobile = useIsMobile(768);
  const multivalueRef = useRef(null);
  const isVisibleOnScreen = useOnScreen(multivalueRef, props?.selectProps?.inputValue);
  const isVisible = isMobile || isVisibleOnScreen;

  useEffect(() => {
    if (labelTextRef?.current.clientWidth >= 202 && !isMobile) {
      setShowTooltip(true);
    }
  }, [labelTextRef.current, isVisible]);

  return (
    <div className='contacts-select-multivalue-item' ref={multivalueRef}>
      {isVisible ? <CrmContactImage imageId={imageId} size={20} /> : <div style={{ width: '20px' }} />}
      <div
        ref={labelTextRef}
        data-tooltip-content={showToolTip && isVisible ? `${label}\n${email}` : undefined}
        data-tooltip-id={showToolTip && isVisible ? 'general-tooltip' : undefined}
        data-tooltip-class-name={
          showToolTip && isVisible ? 'header-tooltip pricing-tooltip-description table-item-cell-tooltip' : undefined
        }
        style={{ height: '30px' }}
      >
        {isVisible && <components.MultiValueLabel {...props} />}
      </div>
    </div>
  );
};

const CustomSingleLabel = (props) => {
  const { imageId } = props.data;
  const multivalueRef = useRef(null);
  return (
    <>
      <div className='contacts-select-multivalue-item' ref={multivalueRef}>
        <CrmContactImage imageId={imageId} size={20} />
        <div className='ml-1'>
          <components.SingleValue {...props} />
        </div>
      </div>
      <div
        className='close-icon-container'
        style={{
          position: 'absolute',
          right: '20px',
        }}
      >
        <button
          className='general-button close-icon'
          onClick={() => {
            props.clearValue();
          }}
        />
      </div>
    </>
  );
};

const Input = (props) => {
  const isMobile = useIsMobile(768);
  const location = useLocation();
  const sentEmail = location.pathname.includes('email-performance');
  if (sentEmail) return null;
  return !isMobile ? (
    <div className='w-100'>
      <span className={`icon-search`} style={{ zIndex: 1 }}></span>
      <components.Input {...props}></components.Input>
    </div>
  ) : (
    <components.Input {...props}></components.Input>
  );
};

const CustomAsyncSelect = ({
  selectRef,
  sideBar,
  disabled,
  showHideAddContactModal,
  initialValues,
  autoFocus,
  Input,
  onKeyDown,
  CustomValueContainer,
  setValue,
  multi = true,
  includeTags = true,
  placeholder,
  loadOptions,
  emptyMessage,
  optionState,
  showBottomBar = true,
  customMenuAutoHeight,
}) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { net_api_url } = state;

  const { inputValue, setInputValue } = optionState ?? {};

  const { getAccessToken } = useGetAccessToken();
  const { isAuthenticated } = useAuth0();
  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();
  const { previewGroup } = useGroupFunctions();
  const navigate = useNavigate();
  const [tagContacts, setTagContacts] = useState({});
  const [groupContacts, setGroupContacts] = useState({});

  let axiosCancelToken = useRef(null);

  useEffect(() => {
    if (!disabled && autoFocus && selectRef.current) {
      selectRef.current?.focus();
    }
  }, [disabled]);

  const getAsyncOptions = useCallback(
    async (inputValue, tagContacts, groupContacts) => {
      //OP: trigger api call only if input value present, to skip extra call on clear up input
      if (!!selectRef?.current?.inputRef?.value) {
        try {
          const token = isAuthenticated ? await getAccessToken() : '';
          const contactUrl = `${net_api_url}/api/autocomplete/crm-contact?term=${inputValue}`;
          const tagsUrl = `${net_api_url}/api/autocomplete/crm-contact-tag?term=${inputValue}`;
          const groupsUrl = `${net_api_url}/api/autocomplete/crm-contact-group?term=${inputValue}`;
          const tagsContactsUrl = `${net_api_url}/api/crm-contact/query`;
          let contactTagsRes = {};
          let contactGroupsRes = {};

          if (axiosCancelToken.current) {
            axiosCancelToken.current.cancel();
          }

          axiosCancelToken.current = Axios.CancelToken.source();

          let requestOptions = {
            cancelToken: axiosCancelToken.current.token,
          };

          if (isAuthenticated) {
            requestOptions.headers = {
              Authorization: `Bearer ${token}`,
            };
          }

          const callContactResults = await Axios.get(contactUrl, requestOptions);

          const getContactsTagsQntty = async (tag) => {
            const contactsByTagsRes = await Axios.post(
              tagsContactsUrl,
              {
                filters: [
                  {
                    field: 'tags',
                    value: tag,
                    operator: 'str_eq',
                  },
                ],
                query: '',
                pageSize: 10000,
                pageNumber: 1,
              },
              requestOptions
            );
            let contactsByTags = contactsByTagsRes?.data?.hits ?? [];
            if (contactsByTags.length) {
              contactsByTags = contactsByTags.map((contact) => {
                return {
                  value: contact.id,
                  imageId: contact.imageId,
                  label: contact.title,
                  id: contact.id,
                  email: contact.email,
                };
              });
            }
            contactTagsRes[tag] = contactsByTags;
            return contactsByTagsRes.data.totalHits;
          };
          const getContactsGroupsQntty = async (value) => {
            const contactsByGroupsRes = await previewGroup({
              source: axiosCancelToken.current,
              id: value,
              pageSize: 10000,
            });
            let contactsByGroups = contactsByGroupsRes?.hits ?? [];
            if (contactsByGroups.length) {
              contactsByGroups = contactsByGroups.map((contact) => {
                return {
                  value: contact.id,
                  imageId: contact.imageId,
                  label: contact.title,
                  id: contact.id,
                  email: contact.email,
                };
              });
            }
            contactGroupsRes[value] = contactsByGroups;
            return contactsByGroupsRes.totalHits;
          };

          let tagResults = [];
          let groupResults = [];

          if (includeTags) {
            let callTagsResults = await Axios.get(tagsUrl, requestOptions);
            tagResults = await Promise.all(
              callTagsResults.data.map(async (tag) => {
                return {
                  value: tag.value,
                  label: tag.label,
                  tagOption: true,
                  contacts: tagContacts[tag.value]?.length ?? (await getContactsTagsQntty(tag.value)),
                };
              })
            );

            let callGroupsResults = await Axios.get(groupsUrl, requestOptions);
            groupResults = await Promise.all(
              callGroupsResults.data
                ?.filter((item) => item?.label?.toLowerCase().includes(inputValue?.toLowerCase().trim()))
                ?.map(async (item) => {
                  const { value, label } = item;
                  return {
                    value,
                    label,
                    isGroup: true,
                    contacts: groupContacts[value]?.length ?? (await getContactsGroupsQntty(value)),
                  };
                })
            );
          }

          const contactResults = callContactResults.data.map((contact) => {
            return {
              value: contact.crmContactId,
              imageId: contact.imageId,
              label: contact.fullName,
              id: contact.crmContactId,
              email: contact.emailAddress,
            };
          });

          const finalOptions = includeTags
            ? [
                {
                  label: 'tags',
                  options: [
                    ...tagResults?.filter((item) => item.contacts !== 0).sort((a, b) => a.label.localeCompare(b.label)),
                  ],
                },
                {
                  label: 'groups',
                  options: [
                    ...groupResults
                      ?.filter((item) => item.contacts !== 0)
                      .sort((a, b) => a.label.localeCompare(b.label)),
                  ],
                },
                {
                  label: 'contacts',
                  options: !!contactResults.length
                    ? contactResults.sort((a, b) => a.label.localeCompare(b.label))
                    : [{ value: 'No contacts', disabled: true }],
                },
              ]
            : !!contactResults.length
              ? contactResults
              : [{ value: 'No contacts', disabled: true }];

          if (Object.keys(contactTagsRes).length > 0) {
            setTagContacts({ ...tagContacts, ...contactTagsRes });
          }
          if (Object.keys(contactGroupsRes).length > 0) {
            setGroupContacts({ ...groupContacts, ...contactGroupsRes });
          }
          return finalOptions;
        } catch (e) {}
      }
    },
    [selectRef?.current?.inputRef?.value]
  );

  //AE: Source https://github.com/JedWatson/react-select/issues/614
  const getContactOptions = useCallback(
    _.debounce((inputText, callback) => {
      if (inputText.length > 1) {
        getAsyncOptions(inputText, tagContacts, groupContacts).then((options) => callback(options));
      }
    }, 500),
    [tagContacts, groupContacts]
  );

  const colourStyles = useMemo(
    () => ({
      control: (styles) => ({
        ...styles,
        borderColor: 'transparent',
        outline: 'none',
        boxShadow: 'none',
        ':hover': {
          borderColor: 'transparent',
        },
        backgroundColor: 'transparent',
        cursor: 'text',
        marginLeft: '-9px',
      }),
      option: (styles) => {
        return {
          ...styles,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          backgroundColor: 'transparent',
          cursor: 'pointer',
          color: '#00122b',
          fontSize: '16px',
          ':active': {
            ...styles[':active'],
            backgroundColor: 'transparent',
          },
        };
      },
      placeholder: (styles, state) => ({
        ...styles,
        position: sideBar ? 'absolute' : undefined,
        top: sideBar ? '8px' : undefined,
        left: sideBar ? '38px' : undefined,
        display: sideBar ? (state.selectProps.inputValue ? 'none' : 'block') : undefined,
        zIndex: sideBar ? 0 : undefined,
      }),
      indicatorsContainer: (styles) => ({
        ...styles,
        backgroundColor: 'transparent',
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: 'transparent',
        pointerEvents: disabled ? 'none' : 'all',
        ':hover': {
          backgroundColor: 'transparent',
        },
      }),
      valueContainer: (styles) => ({
        ...styles,
        maxHeight: '120px',
        overflow: 'auto',
      }),
      loadingIndicator: (styles) => ({
        position: !sideBar ? undefined : 'absolute',
        top: !sideBar ? undefined : '12px',
        right: !sideBar ? undefined : '45px',
        ...styles,
      }),
    }),
    [sideBar]
  );

  const handleChange = useCallback(
    async (selected, action) => {
      if (multi) {
        if (
          action.action === 'select-option' &&
          (action.option?.selectAllBtn || action.option?.tagOption || action.option?.actionBar)
        ) {
          return;
        }
        let contactsByTags = [];
        let newSelectedValue = selected;
        const valueToState = [...newSelectedValue, ...contactsByTags].map((val) => ({
          id: val.id,
          email: val.email,
        }));
        if (setValue) {
          setValue(valueToState);
        } else {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'emailSelectedContacts',
            value: valueToState,
          });
          selectRef?.current?.blur();
        }
      } else {
        setValue(selected);
      }
    },
    [multi, selectRef]
  );

  const onInputChange = (query, { action }) => {
    if (action !== 'set-value') {
      setInputValue(query.replace(/\\/g, ''));
    } else {
      return inputValue;
    }
  };

  const NoOptionsMessage = ({ inputValue }) => {
    if (emptyMessage) {
      return emptyMessage;
    }
    return (
      <div
        style={{
          textAlign: `${!!inputValue.length ? 'left' : 'center'}`,
          padding: `15px 0 15px ${!!inputValue.length ? '15px' : 0}`,
        }}
      >
        <p
          style={{
            color: '#999',
            marginBottom: !!inputValue.length ? '-4px' : '5px',
          }}
        >
          {!!inputValue.length ? 'No matching contacts in your ' : 'Search contacts'}
        </p>
        <p
          className='blue-link-underline-select'
          onClick={() => {
            if (!!inputValue.length) {
              navigate('/influence/directory');
            } else {
              validateContactsOversubscribed(showHideAddContactModal, 'add_custom_contact', 1);
            }
          }}
        >
          {!!inputValue.length ? 'contact list' : 'Add a new contact'}
        </p>
      </div>
    );
  };

  const FindMoreContactsMessage = ({ text }) => {
    return (
      <div style={{ textAlign: 'center', padding: '15px 0' }}>
        <p style={{ color: '#999', marginBottom: '5px', whiteSpace: 'pre-wrap' }}>{text}</p>
      </div>
    );
  };

  const RenderActionsBar = useCallback(() => {
    return <ActionsBar selectRef={selectRef} optionState={optionState} />;
  }, [selectRef, inputValue, optionState]);

  const RenderSelectAllBtn = useCallback(() => {
    return <SelectAllBtn selectRef={selectRef} optionState={optionState} key={'react-select-all-btn'} />;
  }, [selectRef, inputValue, optionState]);
  return (
    <>
      <AsyncSelect
        RenderActionsBar={RenderActionsBar}
        RenderSelectAllBtn={RenderSelectAllBtn}
        captureMenuScroll={false}
        ref={selectRef}
        inputValue={inputValue}
        onInputChange={onInputChange}
        defaultValue={initialValues}
        loadOptions={loadOptions ?? getContactOptions}
        cacheOptions={false}
        showBottomBar={showBottomBar}
        customMenuAutoHeight={customMenuAutoHeight}
        components={{
          MenuList: CustomMenuList,
          Option: (props) => (
            <CrmContactsSelectOption
              {...props}
              selectRef={selectRef}
              sideBar={sideBar}
              tagContacts={tagContacts}
              groupContacts={groupContacts}
              FindMoreContactsMessage={FindMoreContactsMessage}
              NoOptionsMessage={NoOptionsMessage}
              optionState={optionState}
            />
          ),
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          ClearIndicator: () => null,
          ValueContainer: CustomValueContainer ?? ValueContainer,
          SingleValue: CustomSingleLabel,
          MultiValueLabel: CustomMultiValueLabel,
          Input,
        }}
        isMulti={multi}
        noOptionsMessage={(e) => (e.inputValue.length > 0 ? <NoOptionsMessage inputValue={e.inputValue} /> : null)}
        styles={colourStyles}
        placeholder={placeholder ?? (sideBar ? 'Search by group, tag or name' : '')}
        onChange={handleChange}
        openMenuOnClick={!multi}
        hideSelectedOptions={false}
        classNamePrefix={'react-custom-select-contacts'}
        autoFocus={autoFocus}
        blurInputOnSelect={!multi}
        closeMenuOnSelect={false}
        onSelectResetsInput={false}
        onFocus={(e) => {
          if (disabled) {
            e.target.blur();
          }
        }}
        onKeyDown={onKeyDown}
      />
    </>
  );
};

const SelectedContactsQntty = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { emailSelectedContacts } = state;

  const { sentEmail, setActiveTab, setActiveRecipientsOption, selectRef } = props;
  const selectedContacts = emailSelectedContacts.length;
  const [showActions, setShowActions] = useState(false);

  const actionsContainer = useRef();

  const hideItem = () => {
    setShowActions(false);
  };

  useOutsideElement(actionsContainer, hideItem);

  if (!selectedContacts) {
    return null;
  }
  return (
    <div
      className={`selected-contacts-qntty ${sentEmail ? 'selected-contacts-qntty-sent' : ''} ${selectedContacts >= 1 ? 'd-flex' : ''}`}
      onClick={() => {
        if (sentEmail) {
          setActiveTab('Recipients');
          setActiveRecipientsOption('All');
        }
      }}
    >
      {thousandCommas(selectedContacts)} recipient{selectedContacts > 1 ? 's' : ''}
      {selectedContacts >= 1 && !sentEmail && (
        <div
          className='position-relative d-inline-block ml-1 remove-all-recipients-action'
          ref={actionsContainer}
          style={{ marginRight: '3px', marginTop: '-3px' }}
        >
          <button
            className={`general-button circle-button hover-background-gray-05 p-1`}
            onClick={(e) => {
              e.stopPropagation();
              setShowActions(!showActions);
            }}
          >
            <span className='icon-three-dots' style={{ color: 'rgba(0, 18, 43, 0.4)' }} />
          </button>
          {showActions && (
            <div className='box box-shadow py-2 item-actions-box w-auto'>
              <div className='share-container position-relative'>
                <div className='share-container-items flex-column'>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch({
                        type: 'MODIFY_SECTION',
                        parameter: 'emailSelectedContacts',
                        value: [],
                      });
                      selectRef.current.setValue([], 'set-value');
                      setShowActions(false);
                    }}
                    className='general-button'
                  >
                    <span className='text-dark-red'>Remove all</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const CrmContactsSelect = ({
  initialValues,
  disabled,
  autoFocus,
  sideBar,
  sentEmail,
  setActiveTab,
  setActiveRecipientsOption,
  setValue,
  multi = true,
  includeTags,
  placeholder,
  loadOptions,
  emptyMessage,
  showBottomBar,
}) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { showAddContactModal, newContactFromSelect, activePlan, emailSelectedContacts } = state;
  const { enableCrm } = activePlan;

  const selectRef = useRef(null);

  const [heightContainer, containerRef] = useHeightContainer();
  const [inputValue, setInputValue] = useState('');
  const [localRecipients, setLocalRecipients] = useState([]);
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const [selectedGroupOptions, setSelectedGroupOptions] = useState([]);

  useEffect(() => {
    const valueToState = multi
      ? initialValues
        ? initialValues.map((val) => ({ id: val.id, email: val.email }))
        : []
      : '';
    if (setValue) {
      setValue(valueToState);
    } else {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'emailSelectedContacts',
        value: valueToState,
      });
    }
  }, []);

  useEffect(() => {
    if (newContactFromSelect) {
      selectRef.current.setValue([...selectRef.current.state.selectValue, newContactFromSelect], 'set-value');
      setTimeout(() => {
        selectRef.current.focus();
      }, 100);
    }
  }, [newContactFromSelect]);

  useEffect(() => {
    if (inputValue.length === 0) {
      setLocalRecipients([]);
      setSelectedTagOptions([]);
      setSelectedGroupOptions([]);
    }
  }, [inputValue]);

  const showHideAddContactModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddContactModal',
      value: !showAddContactModal,
    });
  };

  const EmptyStateMessage = () => {
    const selectedContacts = emailSelectedContacts.length;
    if (!selectedContacts) {
      return <div className='selected-contacts-qntty-emty-state'>No recipients</div>;
    }
    return null;
  };

  const optionState = {
    inputValue,
    setInputValue,
    localRecipients,
    setLocalRecipients,
    selectedTagOptions,
    setSelectedTagOptions,
    selectedGroupOptions,
    setSelectedGroupOptions,
  };

  return (
    initialValues && (
      <>
        <InfluenceNotIncludedFeaturesPopup
          showPopup={showAddContactModal && !enableCrm}
          type={'contacts'}
          setShowPopup={showHideAddContactModal}
        />
        {enableCrm && (
          <AddContactModal
            showHideAddContactModal={showHideAddContactModal}
            showAddContactModal={showAddContactModal}
            fromContactsSelect
          />
        )}
        {sideBar ? (
          <div className={`select-recipient-sidebar mb-4 ${sentEmail ? 'select-recipient-sidebar-sent' : ''}`}>
            <SelectedContactsQntty
              sentEmail={sentEmail}
              setActiveTab={setActiveTab}
              setActiveRecipientsOption={setActiveRecipientsOption}
              selectRef={selectRef}
            />
            <div
              ref={containerRef}
              className={`d-flex flex-centered sidebar-recipients-input-container ${disabled ? 'opacity-6' : ''}`}
            >
              <div className='flex-grow-1' style={{ height: heightContainer - 25 - 24, overflowY: 'auto' }}>
                <CustomAsyncSelect
                  sideBar
                  selectRef={selectRef}
                  disabled={disabled}
                  showHideAddContactModal={showHideAddContactModal}
                  initialValues={initialValues}
                  autoFocus={autoFocus}
                  Input={Input}
                  CustomValueContainer={CustomValueContainer}
                  onKeyDown={(e) => {
                    if (e.keyCode === 8 && e.target.value === '') {
                      e.preventDefault();
                    }
                  }}
                  emailSelectedContacts={emailSelectedContacts}
                  optionState={optionState}
                />
              </div>
            </div>
            <EmptyStateMessage />
          </div>
        ) : (
          <div className='email-component-select-container' data-value={disabled ? 'disabled' : ''}>
            <CustomAsyncSelect
              disabled={disabled}
              selectRef={selectRef}
              showHideAddContactModal={showHideAddContactModal}
              initialValues={initialValues}
              autoFocus={autoFocus}
              Input={multi ? Input : components.Input}
              setValue={setValue}
              multi={multi}
              includeTags={includeTags}
              placeholder={placeholder}
              loadOptions={loadOptions}
              emptyMessage={emptyMessage}
              optionState={optionState}
              showBottomBar={showBottomBar}
              customMenuAutoHeight={true}
            />
          </div>
        )}
      </>
    )
  );
};

export default CrmContactsSelect;
