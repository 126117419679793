import React, { useContext, useEffect, useRef } from 'react';
import SkeletonKeyUpdates from 'components/Dashboard/key_updates/SkeletonKeyUpdates';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import SavedItemElements from './SavedItemElements';
import { store } from 'components/Store';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const FolderItems = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { scrollPositionSavedItems } = state;
  const {
    currentFolder,
    finalHits,
    isLoadingFolder,
    savedItems,
    filtersResources,
    setFiltersResources,
    loadFolderItems,
  } = props;
  const [heightContainer, containerRef] = useHeightContainer();

  const scrollBarRef = useRef(null);

  const rememberScrollPosition = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'scrollPositionSavedItems',
      value: scrollBarRef.current.viewScrollTop,
    });
  };

  const scrollRestoration = useRef();
  scrollRestoration.current = () => {
    if (scrollPositionSavedItems !== null && !isLoadingFolder) {
      scrollBarRef.current.scrollTop(scrollPositionSavedItems);
      dispatch({ type: 'MODIFY_SECTION', parameter: 'scrollPositionSavedItems', value: null });
    }
  };

  useEffect(() => {
    scrollRestoration.current();
  }, [isLoadingFolder]);

  return (
    <div className='pt-5'>
      {isLoadingFolder && <SkeletonKeyUpdates />}
      {!isLoadingFolder && (
        <>
          {savedItems.length === 0 && (
            <div className='pt-2'>
              <div className='box-top-rounded pt-5' ref={containerRef} style={{ height: `${heightContainer}px` }}>
                <div className='pt-5 text-center'>
                  <p className='text-center mt-5 pt-5 title-h4 main-light-text px-5'>
                    {filtersResources.length > 0
                      ? 'No saved items in this folder matching your filters'
                      : 'Content you save to this folder will appear here'}
                  </p>
                  {filtersResources.length > 0 && (
                    <button
                      className={`general-button action-button empty-button third-button`}
                      onClick={() => {
                        setFiltersResources([]);
                        loadFolderItems(currentFolder.id);
                      }}
                    >
                      Clear filters
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          {savedItems.length > 0 && (
            <div className='saved-items-main-list'>
              <div ref={containerRef} />
              <CustomScrollbar
                className={'main-content-scrollbar'}
                style={{ height: `${heightContainer}px` }}
                maximalThumbYSize={100}
                ref={scrollBarRef}
              >
                <div className='py-2'>
                  <SavedItemElements hits={finalHits()} rememberScrollPosition={rememberScrollPosition} />
                </div>
              </CustomScrollbar>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FolderItems;
