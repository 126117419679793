import React, { useState, useEffect, useRef, useContext } from 'react';
import NavigationKeywordsLists from './NavigationsKeywordLists.js';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSetListsIds } from './setListIds.js';
import { store } from 'components/Store.js';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';
import masterTopicObject from './masterTopicObject.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';

const SearchBarWithTopic = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { search, team, activePlan, keywordsLists, readyKeywordLists } = state;
  const { searchResults, isMainHome, isInHome, setIsOpen, isOpen } = props;
  const [maxHeightContainer, setMaxHeightContainer] = useState(293);
  const { query } = search;
  const topicIdQuery = getUrlParam('topic-id');
  //AE: I left the state property of the list that is mainly controlled by the useDashboardInitialController function. This is to avoid possible conflicts with other parts of the app but in case of any issues we need to verify if they're created for that reason
  const list =
    search?.list === 'noListsCreated'
      ? search?.list
      : keywordsLists.find((item) => item.id === (topicIdQuery === 'all' ? null : parseInt(topicIdQuery)));

  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const [filteringKeywordLists, setFilteringKeywordLists] = useState(keywordsLists);

  const showOptionsList = () => {
    if (!!setIsOpen) {
      return isOpen;
    } else {
      return showDropdownContent;
    }
  };

  const searchBarElement = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { setListsIds } = useSetListsIds();

  const { isAuthenticated } = useAuth0();

  const hideSearchBar = () => {
    if (!!setIsOpen) {
      setIsOpen(false);
    } else {
      setShowDropdownContent(false);
    }
  };
  useOutsideElement(searchBarElement, hideSearchBar);

  useEffect(() => {
    setFilteringKeywordLists(
      keywordsLists.map((item) => {
        return { ...item, show: true };
      })
    );
  }, [keywordsLists]);

  const searchList = (item) => {
    if (item.keywords.join(', ') !== query) {
      let queryToSearch = ChangeKeywordsCategory(item.keywords);
      if (queryToSearch !== query) {
        searchResults(item);
      } else {
        navigate({
          pathname: location.pathname,
          search: '',
          hash: location.hash,
        });
        let locationToStorage = {
          title: document.title,
          storageLocation: { ...location, search: '' },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'locationToStorage',
          value: locationToStorage,
        });
      }
    } else {
      if (item.name !== list.name) {
        navigate(0);
      }
    }
    // TC: Moved out of the main if statement to make sure the local storage gets reset should it somehow not exist
    if (newAgencyPlan || item.id !== null) {
      setListsIds(item.id);
    }
    hideSearchBar();
  };

  const stakeholderMapping = location.pathname.includes('/stakeholder-mapping');
  const policymakersSection = location.pathname.includes('/policymakers');
  let cursorConditional = list !== null && !showOptionsList() ? 'pointer' : 'auto';
  let influenceCondition =
    (stakeholderMapping || policymakersSection) && ((!!list && list.id === null) || list === null);

  let enabledListConditional =
    keywordsLists.find((topic) => list && list?.id === topic.id)?.myState === 'Enabled' || (list && list.id === null);
  const showListConditional = () => {
    return list !== null && list !== 'noListsCreated' && !influenceCondition && enabledListConditional;
  };

  const { topicOrClientWord, agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const inputPlaceholder = () => {
    if (stakeholderMapping || policymakersSection) {
      return `${
        agencyUser ? 'Select a client to see relevant stakeholders' : 'Select a topic to see relevant stakeholders'
      }`;
    } else {
      return `Select a ${topicOrClientWord} ${isMobile ? '' : `to see relevant updates`}`;
    }
  };

  const iconClass = () => {
    if (showListConditional()) {
      return list?.id === null ? 'icon-topics-master' : 'icon-topics-thin';
    } else if (influenceCondition) {
      return list?.id === null ? '' : 'icon-topics-thin';
    }
    return '';
  };

  /* const clearTopics = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'search',
      value: {
        ...search,
        list: masterTopicObject(activePlan, keywordsLists),
        prevList: search.list,
      },
    });
    navigate(`${location.pathname}?topic-id=all${location.hash}`);
  }; */

  const calculateMaxHeightContainer = useRef();
  calculateMaxHeightContainer.current = () => {
    let elements = document.querySelectorAll('.searchbar-with-topics .dropdown-keywordlist');
    let newHeight = 40;
    for (let i = 0; i < elements.length; i++) {
      let el = elements[i];
      let currentHeight = newHeight + el.getBoundingClientRect().height;
      if (currentHeight > 293) {
        newHeight = currentHeight - el.getBoundingClientRect().height / 3;
        break;
      } else {
        newHeight = currentHeight;
      }
    }
    if (elements.length > 3) {
      setMaxHeightContainer(newHeight);
    }
  };
  const getListName = (list) => {
    let masterTopic = masterTopicObject(activePlan, keywordsLists);
    if (list.id === null && (list.myState === 'Hidden' || list.myState === 'Disabled')) {
      return masterTopic.name;
    } else {
      return list.name;
    }
  };

  return (
    <>
      {showOptionsList() && <div className='general-close-background z-extra-max'></div>}
      <div
        className={`searchbar-with-topics dropdown-keywords-lists z-extra-max ${
          list === null || list === 'noListsCreated' ? 'dropdown-keyword-lists-empty' : ''
        } ${showOptionsList() ? 'dropdown-keywords-lists-open' : ''} ${
          !isAuthenticated ? 'dropdown-kewyord-lists-logged-out' : ''
        } ${
          !isAuthenticated && isMainHome ? 'dropdown-kewyord-lists-home' : ''
        } ${influenceCondition ? 'dropdown-keywords-influence' : ''} ${newAgencyPlan ? 'dropdown-keywords-new-agency' : ''}`}
        ref={searchBarElement}
        data-intercom-target='Topics dropdown'
      >
        <div className='position-relative'>
          <div
            className='dropdown-keywords-activeitem flex-centered'
            style={{
              cursor: cursorConditional,
            }}
            onClick={() => {
              if (!isMainHome && !isInHome) {
                if (!!setIsOpen) {
                  setIsOpen(true);
                } else {
                  setShowDropdownContent(true);
                }
                setTimeout(() => {
                  calculateMaxHeightContainer.current();
                }, 20);
              }
            }}
          >
            {/* TYPE ICON */}
            <span className={`main-icon-topic-picker ${iconClass()}`} />
            {/*INPUT SEARCHBAR */}
            <p
              className={`search-main-text-value ${
                list === 'noListsCreated' ? 'search-main-text-value-disabled' : ''
              } ${!showListConditional() || (stakeholderMapping && list?.id === null) ? 'placeholder' : ''}`}
              style={{
                cursor: cursorConditional,
              }}
            >
              <span className={`d-block hidden-lines hidden-one-line`}>
                {showListConditional() ? getListName(list) : inputPlaceholder()}
              </span>
            </p>

            {/* CLOSE BUTTON  
            {(location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers')) && (
              <div
                className='close-icon-container position-absolute'
                style={{
                  top: '4px',
                  right: '45px',
                  visibility: list?.id !== null && list !== 'noListsCreated' ? 'visible' : 'hidden',
                }}
              >
                <button
                  className='general-button close-icon'
                  data-tooltip-content='Clear this filter'
                  data-tooltip-id='general-tooltip'
                  data-tooltip-place='right'
                  onClick={(e) => {
                    e.stopPropagation();
                    clearTopics();
                  }}
                />
              </div>
            )}*/}

            {/* ARROW BUTTON */}
            {isAuthenticated && (list !== null || query === '') && (
              <span
                className={`search-dropdown ${showOptionsList() ? 'search-dropdown-open' : ''}`}
                data-tooltip-content={`${isAuthenticated && !showOptionsList() ? `Select a ${topicOrClientWord}` : ''}`}
                data-tooltip-id='general-tooltip'
                data-tooltip-place='right'
                onClick={(e) => {
                  if (showOptionsList()) {
                    e.stopPropagation();
                    hideSearchBar();
                  }
                }}
              ></span>
            )}
          </div>
        </div>

        <div className={`open-dropdown d-${showOptionsList() ? 'block' : 'none'}`}>
          <div className='open-dropdown-container'>
            {((Object.keys(activePlan).length > 0 && Object.keys(team).length > 0) || !isAuthenticated) && (
              <NavigationKeywordsLists
                keywordsLists={filteringKeywordLists}
                searchFunction={searchList}
                searchResults={searchResults}
                setFilteringKeywordLists={setFilteringKeywordLists}
                hideSearchBar={hideSearchBar}
                showDropdownContent={showOptionsList()}
                readyKeywordLists={readyKeywordLists}
                maxHeightContainer={maxHeightContainer}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBarWithTopic;
