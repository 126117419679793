import React, { useEffect, useRef, useState } from 'react';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useOrganizationInfoDescriptionFunctions from 'components/Dashboard/hooks/useOrganizationInfoDescriptionFunctions';
import InputError from 'components/Dashboard/components/InputError';
import dayjs from 'dayjs';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import AdminTopicName from './AdminTopicPage/AdminTopicName';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { useParams } from 'react-router-dom';

const Organization = (props) => {
  const { team, setTeam, getCurrentTopic, clientPage, modifyTeamTopics } = props;
  const params = useParams();
  const { topicId } = params;
  const topicInformation = useRef();
  topicInformation.current = getCurrentTopic ? getCurrentTopic(params.topicId) : {};
  const [heightContainer, containerRef] = useHeightContainer();
  const [initialDescription, setInitialDescription] = useState(null);
  const [currentDescription, setCurrentDescription] = useState(null);
  const [inputError, setInputError] = useState(null);
  const [action, setAction] = useState(null);

  const [loadingUpdateDescription, setLoadingUpdateDescription] = useState(false);
  const [loadingInitialDescription, setLoadingInitialDescription] = useState(false);

  const textAreaRef = useRef();
  const {
    removeTeamAdminOrganizationDescription,
    removeAndUpdateTeamAdminOrganizationDescription,
    updateTeamAdminDescription,
  } = useOrganizationInfoDescriptionFunctions();

  const getInitialDescription = useRef();
  getInitialDescription.current = async () => {
    const setTextAreaValue = (val) => {
      if (textAreaRef?.current) {
        setCurrentDescription(val);
        textAreaRef?.current.focus();
      }
    };
    if (clientPage) {
      setLoadingInitialDescription(true);
      const {
        clientOrganisationDescription,
        clientOrganisationDescriptionAuthorFullName,
        clientOrganisationDescriptionLastModified,
      } = topicInformation.current ?? {};
      if (clientOrganisationDescription) {
        setTextAreaValue(clientOrganisationDescription);
        setInitialDescription({
          authorFullName: clientOrganisationDescriptionAuthorFullName,
          lastModified: clientOrganisationDescriptionLastModified,
          organisationDescription: clientOrganisationDescription,
        });
      } else {
        setTextAreaValue('');
        setInitialDescription(null);
      }
      setLoadingInitialDescription(false);
    } else {
      setTextAreaValue(team?.organisationDescription ?? '');
      setInitialDescription({
        authorFullName: team.organisationDescriptionAuthorFullName,
        lastModified: team.organisationDescriptionLastModified,
        organisationDescription: team?.organisationDescription,
      });
    }
  };

  useEffect(() => {
    getInitialDescription.current();
    setInputError(null);
  }, [team]);

  const descriptionIsChanged =
    currentDescription !== initialDescription?.organisationDescription &&
    !(currentDescription === '' && initialDescription === null);

  let alreadyHasDescription = initialDescription?.lastModified;

  const handleClick = async (action) => {
    let text = currentDescription;
    let removeAction = async () => {
      setLoadingUpdateDescription(true);
      const response = await removeTeamAdminOrganizationDescription({
        teamId: team.id,
        keywordListId: clientPage ? topicId : undefined,
      });
      setLoadingUpdateDescription(false);
      if (response) {
        setTeam({ ...response, keywordLists: team?.keywordLists });
        createNotification('success', 'Description has been removed');
        setAction(null);
      }
      return;
    };
    setAction(action);
    if (text?.length === 0) {
      removeAction();
      return;
    }
    setLoadingUpdateDescription(true);
    let response;
    if (alreadyHasDescription) {
      response = await removeAndUpdateTeamAdminOrganizationDescription({
        teamId: team?.id,
        value: text,
        keywordListId: clientPage ? topicId : undefined,
      });
    } else {
      response = await updateTeamAdminDescription({
        teamId: team?.id,
        value: text,
        keywordListId: clientPage ? topicId : undefined,
      });
    }

    setLoadingUpdateDescription(false);
    if (response) {
      createNotification('success', `Description ${initialDescription?.lastModified ? 'updated' : 'saved'}`);
      if (clientPage) {
        const descriptionValues = {
          authorFullName: response.authorFullName,
          lastModified: response.lastModified,
          organisationDescription: response.organisationDescription,
        };
        setInitialDescription(descriptionValues);
        if (modifyTeamTopics) {
          modifyTeamTopics({
            topicId,
            descriptionValues: {
              clientOrganisationDescriptionAuthorFullName: response.authorFullName,
              clientOrganisationDescription: response.organisationDescription,
              clientOrganisationDescriptionLastModified: response.lastModified,
            },
          });
        }
      } else {
        setTeam({ ...response, keywordLists: team?.keywordLists });
      }

      setAction(null);
    }
  };

  return (
    <div className={`${clientPage ? 'pt-4 pt-lg-5' : ''}`}>
      {!clientPage && (
        <div className='row mx-0 justify-content-between py-4 align-items-center' style={{ height: '84px' }}>
          <h3 className='main-title mb-0'>Organisation description and priorities</h3>
        </div>
      )}
      <div className='widget-padding'>
        <div
          className='box-top-rounded bg-white admin-general-content p-1 p-lg-4'
          ref={containerRef}
          style={{ height: `${heightContainer}px` }}
        >
          {clientPage && (
            <div className='flex-grow-1'>
              <AdminTopicName topicInformation={topicInformation.current} disabled />
            </div>
          )}
          <CustomScrollbar className={'simple-scrollbar mt-3'} maximalThumbYSize={100}>
            <div className='container widget-padding'>
              <div className='position-relative p-0'>
                <textarea
                  ref={textAreaRef}
                  value={currentDescription ?? ''}
                  rows={8}
                  className='input-empty w-100 border border-grey rounded-sm p-3'
                  style={{ resize: 'none' }}
                  onChange={(e) => {
                    setCurrentDescription(e.target.value);
                    setInputError(null);
                  }}
                  disabled={loadingInitialDescription}
                ></textarea>
              </div>
              <div
                className={`tailor-summary-action-container flex-centered justify-content-${
                  inputError || initialDescription?.lastModified ? 'between' : 'end'
                }  mt-2 p-0`}
              >
                {inputError ? (
                  <InputError errText={inputError} />
                ) : (
                  <>
                    {initialDescription?.lastModified && (
                      <p className='mb-0 last-edited-by-subtext'>
                        <i>
                          Last edited by {initialDescription?.authorFullName || 'PolicyMogul'} on{' '}
                          {dayjs(initialDescription?.lastModified).tz('Europe/London', true).format('DD MMMM, YYYY')}
                        </i>
                      </p>
                    )}
                  </>
                )}

                <div className='flex-centered'>
                  <button
                    className={`action-button general-button px-3 filter-action-button update-button`}
                    onClick={() => {
                      handleClick('show');
                    }}
                    disabled={loadingUpdateDescription || !descriptionIsChanged}
                  >
                    {loadingUpdateDescription && action === 'show' && <i className='fas fa-spinner fa-spin mx-2' />}
                    <span>
                      {initialDescription?.lastModified ? 'Update' : 'Save'} description{' '}
                      {clientPage ? ` for ${topicInformation.current?.name}` : ''}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};

export default Organization;
