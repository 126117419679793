import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from 'components/home/Footer';
import useScrollBody from 'components/home/useScrollBody';
import getActivePlan from 'components/Settings/Utilities/getActivePlan';
import { store } from 'components/Store';
import useCallMentionsByTopic from 'components/Dashboard/Analytics/useCallMentionsByTopic';
import { compileGeneralResults } from 'components/Dashboard/Analytics/useCallSentimentOverTime';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import getUtmParameters from 'components/Dashboard/helpers/getUtmParameters';
import Navigation from 'components/Dashboard/Navigation';
import addMetaTags from 'components/Dashboard/utilities/addMetaTags';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import BigBen from './BigBen';
import changeSearchQuery from './ChangeSearchQuery';
import SentimentPage from './SentimentPage';
import axios from 'axios';
import { createAnalyticsState } from 'components/Dashboard/Analytics/createAnalyticsState';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';

const SentimentComponent = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { directLoadSentimentPage, activePlan } = state;

  const [sentimentState, setSentimentState] = useState({
    ...createAnalyticsState(),
    chartDataOptionSelected: 'Contribution type',
  });
  const { currentView, activeDateInterval } = sentimentState;
  const { transformInterval } = useCallMentionsByTopic();
  const [isLoading, setIsLoading] = useState(true);
  const [showLimitPopup, setShowLimitPopup] = useState(false);
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  const [data, setData] = useState({ labels: [], values: [], rawData: {}, totalValuesPerSection: {} });
  const { generalApiCall } = useGeneralApiCall();
  const location = useLocation();
  const searchParam = getUrlParam('search');
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const modifyFiltersBar = ({ property, newValue }) => {
    let newState = { ...sentimentState, [property]: newValue };
    setSentimentState(newState);
  };

  const showPopup = () => {
    setShowLimitPopup(true);
  };

  const initialCall = useRef();
  initialCall.current = async ({ interval = activeDateInterval, view = currentView }) => {
    if (searchParam) {
      let plan = activePlan;
      if (Object.keys(plan).length === 0 && isAuthenticated) {
        plan = await getActivePlan(getAccessToken, source);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: plan });
      }

      const { subscriptionStatus, enableAnalytics } = plan;
      setIsLoading(true);
      const authenticatedUser = isAuthenticated && subscriptionStatus !== 'TrialEnded' && enableAnalytics;
      let pathname = `/api/graph-data/${authenticatedUser ? '' : 'public-'}sentiment-changes`;
      let method = 'post';
      let intervalFilter = interval.filter;
      let requestProperties = {
        query: changeSearchQuery(),
        from: Array.isArray(intervalFilter) ? intervalFilter[0] : intervalFilter,
        to: dayjs().add('1', 'day').startOf('day').format('YYYY-MM-DD'),
        interval: transformInterval(view),
      };
      if (!showTweets) {
        requestProperties = {
          ...requestProperties,
          excludeTweets: true,
        };
      }
      const results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        returnCompleteRequest: true,
        notShowErrorMessage: true,
        returnError: true,
        needsAuthentication: authenticatedUser,
      });
      if (results) {
        if (results.status === 200) {
          let finalResults = [];
          let rawData = {};
          Object.keys(results.data).forEach((key) => {
            let result = results.data[key];
            finalResults.push(result.series);
            rawData[key] = result.series.reduce((acc, item) => {
              acc[item.bucket] = item.datapoints;
              return acc;
            }, []);
          });
          const compiledItems = compileGeneralResults(finalResults, rawData);
          setData(compiledItems);
        } else if (results.status === 429) {
          setShowLimitPopup(true);
        }
      }
      setIsLoading(false);
    }
    let title = `Sentiment - PolicyMogul`;
    addMetaTags({ title, hash: location.hash, location, dispatch });
  };

  const addUtmCodeForSentiment = useRef(null);
  addUtmCodeForSentiment.current = () => {
    if (!isAuthenticated) {
      getUtmParameters(location);
    }
    //AE:This property could be useful in different cases when we need the direct load condition to do something, probably in the future we can add this as a general property called 'directAppLoad'
    if (!isAuthenticated && directLoadSentimentPage && !location.search.includes('utm_')) {
      let code = 'utm_source=sentiment-direct-share';
      let cookieItem = document.cookie.split('; ').find((row) => row.startsWith('utm_parameters='));
      let parameters = [];

      if (cookieItem) {
        parameters.push(code);

        let cookieExpiresDate = addYears(new Date(), 10);
        document.cookie = `utm_parameters=${JSON.stringify(parameters)}; expires=${cookieExpiresDate.toUTCString()}; domain=policymogul.com; path=/; Secure`;
      }
    }
    dispatch({ type: 'MODIFY_SECTION', parameter: 'directLoadSentimentPage', value: false });
  };

  const addYears = (date, years) => {
    date.setFullYear(date.getFullYear() + years);

    return date;
  };

  useScrollBody();
  useEffect(() => {
    addUtmCodeForSentiment.current();
    initialCall.current({});
    return () => {
      source.cancel('Sentiment page canceled by the user.');
    };
  }, [location.search, currentView, activeDateInterval]);

  return (
    <>
      <Navigation />
      <div
        className={`bg-main-blue sentiment-main-section ${!getUrlParam('search') ? 'sentiment-searchbar-section' : ''}`}
      >
        <div className={`sentiment-page-container px-lg-5 `}>
          <SentimentPage
            isLoading={isLoading}
            sentimentState={sentimentState}
            data={data}
            modifyFiltersBar={modifyFiltersBar}
            popupModifiers={{ showLimitPopup, setShowLimitPopup }}
            searchParam={searchParam}
            showPopup={showPopup}
          />
          <BigBen />
        </div>
      </div>
      <Footer sentiment />
    </>
  );
};

export default SentimentComponent;
