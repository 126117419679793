import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from 'components/Store.js';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import DropdownComponent from 'components/Settings/WebsiteFeeds/DropdownComponent';
import NoTopicsView from 'components/Settings/Topics/NoTopicsView';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useDetectActiveFilters from 'components/Dashboard/SubNav/useDetectActiveFilters';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import CustomScrollbar from 'components/Common/CustomScrollbar.js';
import { useFilters } from 'components/Dashboard/Filters/FiltersContext.js';
import ModalComponent from 'components/Common/Modal';

const EmptyMessage = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search } = state;

  const { list } = search;
  const { resourceName, emptyMessageResourceName, contactsTable, commonMessageProp, notShowSubtext } = props;

  const location = useLocation();
  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);
  const { pathname } = location;
  const [heightContainer, containerRef] = useHeightContainer();
  const [showPopUp, setShowPopup] = useState(false);

  const topicParameter = getUrlParam('topic-id');
  const { detectActiveFilters } = useDetectActiveFilters();
  const { transformTopicOrClientWord } = useTopicOrClientWord();
  //AE: We are excluding here the calendar and consultations due to that filters can't be cleared but are necesarry on that pages. If we add more filters to that page, we need to review it.
  const hasActiveFilters = detectActiveFilters();

  const inboxMessageConditional =
    ((inboxSection ||
      location.pathname.includes('/stakeholder-mapping') ||
      location?.pathname?.includes('/policymakers')) &&
      !hasActiveFilters &&
      !!list) ||
    (location.pathname.startsWith('/campaigns') && getUrlParam('topic-id')) ||
    (location.pathname.includes('/analytics') && topicParameter === 'none');

  const notShowRecentMessageSections = ['upcoming events', 'stakeholders', 'policymakers'];
  const { noTopicsCondition } = useNoTopicsCondition();

  return (
    <>
      <div className={contactsTable ? 'w-100' : 'empty-main-container'}>
        <div
          className={contactsTable ? '' : 'empty-container'}
          ref={containerRef}
          style={{ height: `${heightContainer}px` }}
        >
          <CustomScrollbar className={'main-content-scrollbar top-content-scrollbar'} maximalThumbYSize={100}>
            {inboxMessageConditional && (
              <>
                {noTopicsCondition ? (
                  <div className='text-left p-4 p-lg-0'>
                    <NoTopicsView />
                  </div>
                ) : (
                  <div
                    className={`d-flex emptymessage-flex-container pt-lg-0 flex-column justify-content-lg-center align-items-center text-center h-100 inbox-empty-message`}
                  >
                    <div className='text-center empty-message-information'>
                      <div className='empty-inbox-message px-4 py-lg-4'>
                        <h2 className={`inbox-empty-title ${notShowSubtext ? 'mb-4' : ''}`}>
                          {commonMessageProp ? (
                            commonMessageProp
                          ) : (
                            <>
                              No{' '}
                              {!notShowRecentMessageSections.includes(resourceName) &&
                              !notShowRecentMessageSections.includes(emptyMessageResourceName)
                                ? 'recent'
                                : ''}{' '}
                              {emptyMessageResourceName ?? resourceName} found
                            </>
                          )}
                        </h2>
                        {!notShowSubtext && (
                          <p className='main-light-text title-h5-m mb-4 mb-lg-5'>
                            Try broadening your search by changing your keywords or adding more
                          </p>
                        )}
                        <div className='d-flex flex-wrap justify-content-center'>
                          <Link
                            to={`/settings/${transformTopicOrClientWord({
                              plural: true,
                            })}${
                              !!getUrlParam('topic-id')
                                ? `/${getUrlParam('topic-id')}`
                                : !!list?.id
                                  ? `/${list?.id}`
                                  : ''
                            }?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`}
                            className='order-0 order-md-1 action-button rounded-button px-4 py-2 '
                          >
                            Edit your keywords
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {(!inboxMessageConditional || contactsTable) && (
              <div
                className={`d-flex emptymessage-flex-container flex-column justify-content-lg-center align-items-center text-center`}
              >
                <div className='text-center empty-message-information'>
                  <img
                    className='empty-image'
                    src={`${process.env.REACT_APP_CDNURL}/images/${
                      pathname.includes('/calendar') ? 'calendar' : 'mogul'
                    }.gif`}
                    alt='Policy Mogul animation'
                  />
                  <Message {...props} />
                </div>
              </div>
            )}
          </CustomScrollbar>
        </div>
      </div>
      {/* OP: looks like popup below never shown */}
      <ChooseTopicMasterTopic setShowPopup={setShowPopup} showPopUp={showPopUp} />
    </>
  );
};

const Message = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { search, referenceState } = state;
  const { activeReference } = referenceState;

  const { list } = search;
  const {
    notIncludeButton,
    handPickedCondition,
    resourceName,
    emptyMessageResourceName,
    additionalText,
    contactsTable,
    groupsTable,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { isAuthenticated } = useAuth0();
  const isMobile = useIsMobile();
  const { detectActiveFilters } = useDetectActiveFilters();
  const { clearFilters } = useFilters();

  const { referenceSection, inboxSection, searchResultsSection } = useMainSectionParameter(params);
  const { pathname } = location;
  const query = new URLSearchParams(location.search);
  //AE: We are excluding here the calendar and consultations due to that filters can't be cleared but are necesarry on that pages. If we add more filters to that page, we need to review it.
  const hasActiveFilters = detectActiveFilters();
  const stakeholdersCondition = referenceSection && location.pathname.includes('stakeholders');
  const { topicOrClientWord, transformTopicOrClientWord } = useTopicOrClientWord();

  const commonMessage = `No ${emptyMessageResourceName ?? resourceName} found`;
  const createMessage = () => {
    if (handPickedCondition) {
      return `Key updates which have been hand-picked for this ${topicOrClientWord} will appear here`;
    } else if (stakeholdersCondition) {
      return 'There are no names or positions matching your search';
    } else if (list !== null) {
      return commonMessage;
    } else {
      return commonMessage;
    }
  };

  const searchMessage = () => {
    if (stakeholdersCondition || (hasActiveFilters && inboxSection) || contactsTable || groupsTable) {
      return '';
    } else if (hasActiveFilters) {
      return 'Try clearing your filters or changing your keywords';
    } else if (location.pathname.startsWith('/campaigns')) {
      return `Try a different search, or create a topic with multiple keywords`;
    } else if (list !== null) {
      return 'Try broadening your search by changing your keywords or adding more';
    } else {
      return `Explore other resources ${
        isMobile ? 'by clicking the blue header at the top of the page' : 'listed to the left'
      }, or ${
        referenceSection || searchResultsSection
          ? 'change your search'
          : `create a ${topicOrClientWord} with multiple keywords`
      } `;
    }
  };

  const regSource = () => {
    //AE: It will not work if the pathname is different in the future
    let path = pathname.split('/')[2];
    switch (path) {
      case 'key-updates':
      case 'consultations':
      case 'tweets':
        return path.substring(0, path.length - 1);
      case 'calendar':
        return 'upcoming-event';
      default:
        return path;
    }
  };

  const candidatesPage = location.pathname.includes('/general-election-2024/candidates');
  const keyUpdatesElectionPage = location.pathname.includes('/general-election-2024/key-updates');

  const searchMessageCondition = !candidatesPage;

  const buttonText = () => {
    if (candidatesPage) {
      return 'See all candidates';
    } else if (hasActiveFilters) {
      return 'Clear filters';
    } else if (list !== null && !location.pathname.startsWith('/campaigns') && !contactsTable && !groupsTable) {
      return 'Edit your keywords';
    } else if (activeReference) {
      return 'Edit your search';
    } else if (searchResultsSection || contactsTable || groupsTable) {
      return null;
    } else {
      return `Create a ${topicOrClientWord}`;
    }
  };

  return (
    <>
      <p className='empty-message-no-results px-4 px-lg-0'>{createMessage()}</p>
      {additionalText && <p className='px-3'>{additionalText}</p>}
      {!notIncludeButton && !handPickedCondition && (
        <>
          {searchMessageCondition && <p className='px-3'>{searchMessage()}</p>}
          {!!buttonText() && (
            <button
              className={`general-button action-button empty-button mb-5 ${hasActiveFilters ? 'action-delete-button rounded-pill' : ''}`}
              onClick={() => {
                if (candidatesPage || keyUpdatesElectionPage) {
                  if (hasActiveFilters) {
                    //CLEAR THE FILTERS
                    clearFilters();
                  }
                  dispatch({
                    type: 'MODIFY_SECTION',
                    parameter: 'search',
                    value: {
                      ...search,
                      activeSearch: false,
                      query: '',
                    },
                  });
                  query.delete('search');
                  let urlToHistory = `${location.pathname}?${query.toString()}`;
                  navigate(urlToHistory, { replace: true });
                  return;
                }
                if (isAuthenticated) {
                  if (hasActiveFilters) {
                    //CLEAR THE FILTERS
                    clearFilters();
                  } else if (activeReference) {
                    dispatch({
                      type: 'MODIFY_SECTION',
                      parameter: 'openResearchPopup',
                      value: true,
                    });
                  } else if (contactsTable || groupsTable) {
                    query.delete('search');
                    let urlToHistory = `${location.pathname}?${query.toString()}`;
                    navigate(urlToHistory, { replace: true });
                  } else {
                    navigate(
                      `/settings/${transformTopicOrClientWord({
                        plural: true,
                      })}${
                        location.pathname.includes('/stakeholder-mapping') && !!list?.id ? `/${list.id}` : '/new-topic'
                      }?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`
                    );
                  }
                } else {
                  window.location.assign(`/register?reg_source=no-${regSource()}-found-create-topic-button`);
                }
              }}
            >
              {buttonText()}
            </button>
          )}
        </>
      )}
    </>
  );
};

const useNoTopicsCondition = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, keywordsLists } = state;
  const { list } = search;

  const location = useLocation();
  const topicParameter = getUrlParam('topic-id');
  const { newAgencyPlan } = useTopicOrClientWord();

  const noTopics = keywordsLists.filter((topic) => topic.id && topic.myState === 'Enabled').length === 0;
  const noTopicsMonitorConditional = location.pathname.includes('/monitor-inbox') && noTopics;

  const noTopicsAnalyticsConditional = location.pathname.includes('/analytics') && topicParameter === 'none';

  const noTopicsCondition = list === 'noListsCreated' || noTopicsMonitorConditional || noTopicsAnalyticsConditional;
  const newAgencyNoTopicsCondition = newAgencyPlan && (noTopicsCondition || noTopics);
  return { noTopicsCondition, newAgencyNoTopicsCondition };
};

const createReferenceUrl = (enableReferenceSection, list, location) => {
  const pageName = location.pathname.replace('/monitor-inbox/', '');
  const pageUrl = pageName === '' ? 'key-updates' : pageName;
  return enableReferenceSection
    ? `/reference/${pageUrl}?search=${encodeURIComponent(list.keywords.join(','))}${
        list.andKeywords.length > 0 ? `&and=${encodeURIComponent(list.andKeywords.join(','))}` : ''
      }${list.keywordExclusions.length > 0 ? `&not=${encodeURIComponent(list.keywordExclusions.join(','))}` : ''}`
    : '/reference/upgrade';
};

const ChooseTopicMasterTopic = (props) => {
  const { showPopUp, setShowPopup } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, activePlan } = state;
  const { enableReferenceSection } = activePlan;
  const topicsWithoutMasterTopic = keywordsLists.filter((item) => item.id !== null);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTopic, setSelectedTopic] = useState(topicsWithoutMasterTopic[0]);
  const { topicOrClientWord } = useTopicOrClientWord();

  const TopicsList = () => {
    const maxHeight = 160;
    const [containerHeight, setContainerHeight] = useState(maxHeight);

    const modifyContainerHeight = useRef();
    const listsContainers = useRef();

    modifyContainerHeight.current = () => {
      let elements = listsContainers.current.children;
      let elementsHeight = 0;
      for (let i = 0; i < elements.length; i++) {
        let item = elements[i];
        let currentHeight = elementsHeight + item.offsetHeight;
        if (currentHeight > maxHeight) {
          setContainerHeight(currentHeight - item.offsetHeight / 3);
          elementsHeight = null;
          break;
        }
        elementsHeight = currentHeight;
      }
      if (elementsHeight && elementsHeight < maxHeight) {
        setContainerHeight(elementsHeight);
      }
    };
    useEffect(() => {
      if (showPopUp) {
        modifyContainerHeight.current();
      }
    }, [showPopUp]);

    return (
      <div className='keyword-lists-website-feeds research-keyword-lists research-keyword-lists-master-topic'>
        <CustomScrollbar
          className={'simple-scrollbar indented-scrollbar-small top-spaced-scrollbar-medium'}
          style={{ height: containerHeight }}
          maximalThumbYSize={100}
        >
          <div ref={listsContainers}>
            {topicsWithoutMasterTopic.map((item) => {
              const { name, id, description } = item;
              return (
                <button
                  className={`topic-name-icon general-button ${id === selectedTopic.id ? 'selected' : ''}`}
                  key={`${name}-${id}`}
                  onClick={() => {
                    setSelectedTopic(item);
                  }}
                >
                  <span className='hidden-lines hidden-one-line'>{name}</span>
                  {description && <span className='d-block topic-description-field'>{description}</span>}
                </button>
              );
            })}
          </div>
        </CustomScrollbar>
      </div>
    );
  };

  return (
    <ModalComponent isOpen={showPopUp} setIsOpen={setShowPopup}>
      <div className='px-4 py-5 p-lg-5'>
        <h3 className='title-h4-bold mb-4'>Select a {topicOrClientWord} to view historic content</h3>
        <div>
          <DropdownComponent
            items={() => <TopicsList />}
            mainItem={selectedTopic?.name}
            mainItemClass={'topic-name-icon topic-name-icon-empty-inbox'}
            fixedDropdown
          />
        </div>
      </div>
      <div className={`py-3 text-right padding-topic-component border-top flex-centered justify-content-end`}>
        <button
          className='general-button action-button px-5 py-2'
          onClick={() => {
            navigate(createReferenceUrl(enableReferenceSection, selectedTopic, location));
          }}
        >
          Explore
        </button>
      </div>
    </ModalComponent>
  );
};

export { useNoTopicsCondition };
export default EmptyMessage;
