import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { store } from 'components/Store.js';
import { normalizedDateUK } from 'components/Dashboard/utilities/normalizedDateUK.js';

const NavigationSentEmails = ({ sentEmails, maxHeightContainer, selectedItem, setIsOpen }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated && (
        <div>
          <div
            className='scroll-container hover-container search-bar-lists'
            style={{ maxHeight: `${maxHeightContainer}px` }}
          >
            <ul className='policy-list px-0 mb-0 hover-content'>
              {sentEmails.map((item, index) => (
                <NavigationTeamContentItem
                  key={`keywordlist-${item.crmEmailId}-${index}`}
                  item={item}
                  selectedItem={selectedItem}
                  setIsOpen={setIsOpen}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

const NavigationTeamContentItem = ({ item, selectedItem, setIsOpen }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { emailAddresses } = state;
  const navigate = useNavigate();
  const applySearch = () => {
    navigate(`/influence/email-performance/${item.crmEmailId}`);
    setIsOpen(false);
  };

  return (
    <div
      style={{ display: 'block' }}
      onClick={() => {
        applySearch();
      }}
    >
      <li
        className={`d-flex dropdown-emailperformance dropdown-keywordlist ${
          !!item.senderEmailAddress?.length ? 'dropdown-keywordlist-description' : ''
        }`}
      >
        <span className={`icon-email`} />
        <div className='flex-grow-1'>
          <p className={'hidden-lines hidden-one-line dropdown-name'}>{item.title}</p>
          {!!item.senderEmailAddress?.length && (
            <div className='navigation-sent-emails-description-container'>
              {emailAddresses?.length > 1 && (
                <p className='description-field heading-date navigation-sent-emails-description'>
                  {item.senderEmailAddress}
                  <span className='dot'></span>
                </p>
              )}
              <p className='nowrap-item heading-date navigation-sent-emails-description'>
                {normalizedDateUK(item.lastUpdated).fromNow()}
              </p>
            </div>
          )}
          {selectedItem === item.crmEmailId && <span className='icon-tick topic-selected-mark' />}
        </div>
      </li>
    </div>
  );
};

export default NavigationSentEmails;
