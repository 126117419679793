import { DateOptions } from './ui/MentionsByTopic/FiltersBar';
import Preloader from './ui/AnalyticsDashboard/Preloader';
import DashboardMentionsChart from './ui/AnalyticsDashboard/DashboardMentionsChart';
import PieOverallSentimentChart from './ui/OverallSentiment/PieOverallSentimentChart';
import SentimentOverTimeChart from './ui/SentimentOverTime/SentimentOverTimeChart';
import { StakeholdersGraph } from './ui/RelevantStakeholders';
import InterestByRegionChart from './ui/InterestByRegion/InterestByRegionChart';
import { compileInitialInterestByRegionData, regionsMapping } from './ui/InterestByRegion';
import { compileData } from './MentionsByTopicContainer';
import useCallMentionsByTopic from './useCallMentionsByTopic';
import useCallMostRelevantStakeholders from './useCallMostRelevantStakeholders';
import useCallSentimentOverTime from './useCallSentimentOverTime';
import useCallInterestByRegion from './useCallInterestByRegion';
import useCallOverallSentiment from './useCallOverallSentiment';
import { useContext, useEffect, useRef, useState } from 'react';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import axios from 'axios';
import { store } from 'components/Store';
import MentionsChart from './ui/MentionsByTopic/MentionsChart';
import { compileMentionsDataFunctions } from './ui/MentionsByTopic';
import useUpdateTitleAnalytics from './useUpdateTitleAnalytics';
import OverallSentiment, { transformContributionsToInclude } from './ui/OverallSentiment';
import useModifyAnalyticsState from './useModifyAnalyticsState';
import dayjs from 'dayjs';
import useCallMostVocal from './useCallMostVocal';
import MostVocalWidget from './ui/AnalyticsDashboard/MostVocalWidget';

const GraphItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, analyticsState } = state;
  const { currentView, chartDataOptionSelected, partySelectedCategories, allowFrontbenchers } = analyticsState;
  const { selectedCountries: selectedCountriesVal, selectedCountriesInterestByRegion } =
    props?.analyticsState ?? analyticsState;

  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');
  const noTopicParameter = topicParameter === 'none';

  const {
    chartType,
    topic: topicProps,
    search: searchProps,
    teamId,
    customGraph,
    isFromPopup,
    notCompileContributions,
    reportsProps,
    selectedCountriesProps,
    embedItem,
    scrollBarPosition,
    chartTypesSelected,
  } = props;

  const {
    maxTicksLimit,
    totalHeight,
    boardPosition,
    regionHeight,
    paddingXLabel,
    widgetData,
    setWidgetData,
    sentimentChangesLeftPadding,
    displayGrid,
    setIsMapResizing,
    heightGraph,
    reportUpdateTo,
  } = reportsProps ?? {};

  const [localAnalyticsState, setLocalAnalyticsState] = useState(JSON.parse(JSON.stringify(analyticsState)));
  const [isLoading, setIsLoading] = useState((!noTopicParameter || customGraph) && !widgetData);
  const [data, setData] = useState(widgetData ?? {});
  const [initialData, setInitialData] = useState(widgetData ?? {});
  const [map, setMap] = useState(null);
  const [geoData, setGeoData] = useState([]);
  const [chartKey, setChartKey] = useState('');
  const [openOtherDropdown, setOpenOtherDropdown] = useState(false);
  const [dropdownStyles, setDropdownStyles] = useState({});

  const { modifyState } = useModifyAnalyticsState();

  let setAllowFrontbenchers = (val) => {
    modifyState({ property: 'allowFrontbenchers', newValue: val });
  };

  const setSelectedCategories = (val) => {
    if (isFromPopup) {
      let newState = { ...analyticsState, partySelectedCategories: val };
      setLocalAnalyticsState(newState);
    } else {
      modifyState({ property: 'partySelectedCategories', newValue: val });
    }
  };

  let selectedCountries = chartType === 'Interest by region' ? selectedCountriesInterestByRegion : selectedCountriesVal;
  let sixMonthsFilter = DateOptions.find((item) => item.name === 'Last 6 months');
  let defaults = {
    dateInterval:
      props?.analyticsState?.activeDateInterval?.filter ??
      (chartType === 'Interest by region'
        ? [dayjs().subtract(365, 'day').add(-1, 'day').startOf('day').format('YYYY-MM-DD')]
        : sixMonthsFilter.filter),
    view: props?.analyticsState?.currentView ?? 'Month',
  };
  let topic =
    topicProps ??
    (topicParameter ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter)) : keywordsListsToUse[0]);
  let dateInterval = customGraph ? analyticsState?.activeDateInterval?.filter : defaults?.dateInterval;
  let chartDataOption = customGraph ? chartDataOptionSelected : 'Contribution type';
  let view = customGraph ? currentView : defaults?.view;
  let searchTerm = searchProps ?? searchParameter;

  const callResults = useRef();
  const chartItem = useRef();
  const isLoadedFirstTime = useRef(false);
  const { compileTotalsWithFilters } = compileMentionsDataFunctions({ chartRef: chartItem, data, analyticsState });
  const { updateTitleAnalytics } = useUpdateTitleAnalytics();
  const emptyMessage = data.labels?.length === 0;
  const { callMentionsByTopic } = useCallMentionsByTopic({ teamId, selectedCountriesProps });
  const { callRelevantStakeholders } = useCallMostRelevantStakeholders({ teamId });
  const { callSentimentOverTime } = useCallSentimentOverTime({ teamId, selectedCountriesProps });
  const { callOverallSentiment } = useCallOverallSentiment({
    teamId,
    reportDateInterval: props?.analyticsState?.activeDateInterval?.filter,
    selectedCountriesProps,
  });
  const { callInterestByRegion } = useCallInterestByRegion({
    allowFrontbenchers: reportsProps?.allowFrontbenchers ?? allowFrontbenchers,
    teamId,
    reportDateInterval: dateInterval,
    analyticsState: props?.analyticsState ?? analyticsState,
    selectedCountriesProps,
  });
  const { callMostVocal } = useCallMostVocal({ teamId, selectedCountriesProps });
  const apiCallsMap = {
    Mentions: callMentionsByTopic,
    Tweets: callMentionsByTopic,
    'Sentiment snapshot': callOverallSentiment,
    'Sentiment changes': callSentimentOverTime,
    'Top MPs and peers': callRelevantStakeholders,
    'Interest by region': callInterestByRegion,
    'Most vocal policymakers': callMostVocal,
  };

  callResults.current = async (source) => {
    try {
      if ((topic && topicParameter !== 'none') || searchTerm) {
        if (!widgetData) {
          setIsLoading(true);
        }
        let options = {
          topic,
          dateInterval,
          view,
          excludeMixedAndNeutral: !customGraph && !reportsProps,
          stakeholderContributionsGraph: chartType === 'Mentions',
          tweetsGraph: chartType === 'Tweets',
          reportWidget: !!reportsProps,
          freeSearch: !!searchTerm ? decodeURIComponent(searchTerm) : null,
          source,
        };

        if (chartType === 'Mentions' || chartType === 'Tweets') {
          options = { ...options, chartDataOption };
        }
        let results = await apiCallsMap[chartType](options);
        if (!!results) {
          let data;
          switch (chartType) {
            case 'Mentions':
              if (chartDataOption === 'Contribution type' && !notCompileContributions) {
                let value = compileData(results.data, props?.analyticsState ?? analyticsState).value;
                data = { ...results.data, value };
              } else {
                data = results?.data;
              }
              break;
            case 'Tweets':
              data = results?.data;
              break;
            case 'Top MPs and peers':
              if (results.currentlyProcessing) {
                data = {
                  data: [],
                  currentlyProcessing: { attempt: data.currentlyProcessing?.attempt ?? 0 + 1, state: true },
                };
              } else {
                data = { data: results, currentlyProcessing: { attempt: 0, state: false } };
              }
              break;
            case 'Sentiment snapshot':
              data = results;
              setInitialData(results);
              break;
            case 'Interest by region':
              data = compileInitialInterestByRegionData(
                ['Parliamentary contribution', 'Question and answer', 'EDM'],
                results
              );
              break;
            default:
              data = results;
          }
          setData(data);
          if (setWidgetData) {
            setWidgetData(data);
          }
          isLoadedFirstTime.current = true;
          setIsLoading(false);
          //AE:This created a re-render of the graph usign the key after loading, necessary for update correctly labels displayed eg. Mentions
          setChartKey(chartType);
          if (!isFromPopup && !reportsProps) {
            updateTitleAnalytics();
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (reportsProps?.allowFrontbenchers !== undefined) {
      setAllowFrontbenchers(reportsProps?.allowFrontbenchers);
    }
  }, [reportsProps?.allowFrontbenchers]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (!widgetData) {
      callResults.current(source);
    }
    return () => {
      source.cancel('Analytics cancelled by the user');
    };
  }, [
    topic,
    allowFrontbenchers,
    searchTerm,
    props.analyticsState,
    reportsProps?.allowFrontbenchers,
    selectedCountriesProps,
    selectedCountries,
    chartTypesSelected,
  ]);

  useEffect(() => {
    if (Object.keys(initialData)?.length > 0 && chartType === 'Sentiment snapshot') {
      const activeSections = reportsProps?.activeSections ?? ['Positive', 'Negative', 'Mixed'];
      let newData = JSON.parse(JSON.stringify(initialData));
      let finalValue = transformContributionsToInclude({ newData, sections: activeSections });
      setData(finalValue);
    }
  }, [reportsProps?.activeSections, initialData]);

  const checkReadyState = useRef();
  checkReadyState.current = (source) => {
    const callAgainResults = () => {
      setTimeout(async () => {
        try {
          await callResults.current(source);
        } catch (error) {}
      }, 5000);
    };
    if (data.currentlyProcessing?.state && !searchTerm) {
      callAgainResults();
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    checkReadyState.current(source);
    return () => {
      if (data.currentlyProcessing?.state) {
        source.cancel('Stakeholders canceled by the user.');
      }
    };
  }, [data.currentlyProcessing]);

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>
          {
            //AE: CUSTOM GRAPH IS WHEN WE USE NOT THE DASHBOARD VERSION BUT THE ENTIRE COMPONENT (E.G. POPUP FOR EMBED ON INDIVIDUAL PAGES)
            customGraph ? (
              <>
                {(() => {
                  switch (chartType) {
                    case 'Mentions':
                      return (
                        <MentionsChart
                          ref={chartItem}
                          data={data}
                          compiledData={compileTotalsWithFilters()}
                          analyticsState={isFromPopup ? localAnalyticsState : analyticsState}
                          emptyMessage={emptyMessage}
                          customGraph={customGraph}
                          isLoading={isLoading}
                          key={`chart-ref-${embedItem || customGraph ? '' : partySelectedCategories?.length}-${customGraph ? '' : chartKey}`}
                          selectedCategories={partySelectedCategories}
                          setSelectedCategories={setSelectedCategories}
                          openDropdownState={{
                            openOtherDropdown,
                            setOpenOtherDropdown,
                            dropdownStyles,
                            setDropdownStyles,
                          }}
                          scrollBarPosition={scrollBarPosition}
                          totalHeight={props?.totalHeight}
                        />
                      );
                    case 'Sentiment snapshot':
                      return (
                        <OverallSentiment
                          isLoading={isLoading}
                          data={data}
                          analyticsState={analyticsState}
                          isLoadedFirstTime={isLoadedFirstTime}
                          initialData={initialData}
                          setData={setData}
                          widget={true}
                        />
                      );
                    default:
                      return '';
                  }
                })()}
              </>
            ) : (
              <>
                {(() => {
                  switch (chartType) {
                    case 'Mentions':
                      return (
                        <DashboardMentionsChart
                          currentView={props?.analyticsState?.currentView ?? currentView}
                          data={data}
                          stakeholdersContributions={true}
                          maxTicksLimit={maxTicksLimit}
                          totalHeight={totalHeight}
                          typeOfChart={props?.analyticsState?.typeOfChart}
                          reportWidget={!!reportsProps}
                          displayGrid={displayGrid}
                          heightGraph={heightGraph}
                        />
                      );
                    case 'Tweets':
                      return <DashboardMentionsChart currentView={currentView} data={data} />;
                    case 'Sentiment snapshot':
                      return (
                        <PieOverallSentimentChart
                          data={data}
                          ref={chartItem}
                          totalHeight={props?.totalHeight ?? totalHeight}
                          reportWidget={!!reportsProps}
                          activeSections={reportsProps?.activeSections}
                          reportDateInterval={dateInterval}
                          heightGraph={heightGraph}
                          reportUpdateTo={reportUpdateTo}
                        />
                      );
                    default:
                      return '';
                  }
                })()}
              </>
            )
          }

          {(() => {
            switch (chartType) {
              case 'Sentiment changes':
                return (
                  <SentimentOverTimeChart
                    data={data}
                    dashboard
                    analyticsState={props?.analyticsState ?? { ...analyticsState, typeOfChartSentimentChanges: 'Line' }}
                    maxTicksLimit={maxTicksLimit}
                    totalHeight={props?.totalHeight ?? totalHeight}
                    ref={chartItem}
                    reportWidget={!!reportsProps}
                    sentimentChangesLeftPadding={sentimentChangesLeftPadding}
                    heightGraph={heightGraph}
                  />
                );
              case 'Top MPs and peers':
                return (
                  <>
                    {data.currentlyProcessing?.state ? (
                      <div className='flex-centered justify-content-center' style={{ height: '280px' }}>
                        <div className='text-center px-4'>
                          <p className='subtle-text'>
                            <strong>{'This will take a moment but will ultimately be worth it'}</strong>
                          </p>
                          <p className='mb-5 main-light-text'>
                            PolicyMogul is identifying the latest MPs and peers for{' '}
                            <em>
                              {getUrlParam('topic-id')
                                ? keywordsLists.find((item) => item.id === parseInt(getUrlParam('topic-id')))?.name
                                : ''}
                            </em>
                          </p>
                          <div className='waiting-bar mx-auto'>
                            <div className='waiting-bar-indicator' />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <StakeholdersGraph
                        dashboard={true}
                        stakeholdersData={data?.data}
                        isFromPopup={isFromPopup}
                        totalHeight={totalHeight}
                        reportWidget={!!reportsProps}
                        paddingXLabel={paddingXLabel}
                        heightGraph={heightGraph}
                      />
                    )}
                  </>
                );
              case 'Interest by region':
                return (
                  <InterestByRegionChart
                    key={'item-graph-popup'}
                    chartData={data?.rawData}
                    chartDataKeys={Object.keys(data.rawData)}
                    regionsMapping={regionsMapping}
                    map={reportsProps?.map ?? map}
                    setMap={reportsProps?.setMap ?? setMap}
                    height={regionHeight}
                    boardPosition={boardPosition}
                    data={geoData}
                    setData={setGeoData}
                    analyticsState={props?.analyticsState ?? analyticsState}
                    dashboard={!customGraph}
                    allowFrontbenchers={allowFrontbenchers}
                    setAllowFrontbenchers={() => {
                      setAllowFrontbenchers(!allowFrontbenchers);
                    }}
                    isFromPopup={isFromPopup}
                    reportWidget={!!reportsProps}
                    selectedCountries={selectedCountries}
                    setIsMapResizing={setIsMapResizing}
                  />
                );
              case 'Most vocal policymakers':
                return <MostVocalWidget key={'item-graph'} data={data} />;
              default:
                return '';
            }
          })()}
        </>
      )}
    </>
  );
};
export default GraphItem;
