import React, { useEffect, useContext, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from 'components/Store';
import { net_api_url } from 'components/Store.js';
import axios from 'axios';
import useTrackingCodes from 'components/TrackingCodes';
import removeLocalStorage from 'components/Dashboard/Navigation/RemoveLocalStorage';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './Loading';
import ChangeEmail from './ChangeEmail';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';

const NeedsVerification = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { userProfile } = state;

  const { user, logout } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const [userEmail, setUserEmail] = useState(user.email);
  const logoutWithRedirect = () => logout({ logoutParams: { returnTo: window.location.origin } });
  useTrackingCodes();
  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      if (Object.keys(userProfile).length === 0) {
        let token = await getAccessToken();
        let url = `${net_api_url}/api/user/getcurrent`;
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        });
        let result = response.data;
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userProfile', value: result });
      }
      getAccessToken({
        cacheMode: 'off',
      }).then((token) => {
        let url = `${net_api_url}/api/user/getcurrent`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          })
          .then((response) => {
            let result = response.data;
            dispatch({ type: 'MODIFY_SECTION', parameter: 'userProfile', value: result });
            if (result?.emailVerified) {
              window.location.assign(`/monitor-inbox/key-updates${window.location.search}`);
            } else {
              setIsLoading(false);
              document.title = 'Verification - PolicyMogul';
              let locationToStorage = {
                storageLocation: { ...window.location },
              };
              dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
            }
          })
          .catch((error) => {
            if (
              error.error === 'invalid_grant' ||
              error.error === 'login_required' ||
              error.error === 'consent_required'
            ) {
              logout({ logoutParams: { returnTo: window.location.origin } });
              return;
            }
          });
      });
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Update profile canceled by the user.');
    };
  }, []);

  const [resendVerificationState, setResendVerificationState] = useState({ isSending: false, resendEmail: false });
  const { isSending, resendEmail } = resendVerificationState;
  const resendVerificationEmail = async () => {
    try {
      setResendVerificationState({ ...resendVerificationState, isSending: true });
      var token = await getAccessToken();
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ emailAddress: user.email }),
      };

      await fetch(`${net_api_url}/api/user/sendverificationemail`, requestOptions);
      setResendVerificationState({ ...resendVerificationEmail, isSending: false, resendEmail: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='create-profile-container px-0 needs-verification-container'>
        <div className='scroll-container h-100 create-profile-general-content'>
          <h2 className='company-name'>Policy Mogul</h2>
          <div className='d-flex justify-content-center align-items-center w-100 py-lg-2 py-lg-5 pt-5'>
            <div className='mt-5 mt-lg-0'>
              <div className=' px-5 py-4 create-profile-content'>
                {!isLoading && (
                  <>
                    <button
                      className='general-button d-block text-right w-100'
                      onClick={() => {
                        removeLocalStorage();
                        logoutWithRedirect();
                      }}
                    >
                      <strong>Log out</strong>
                    </button>
                    <div className='pt-5 mt-5 create-profile-awaits'>
                      <h2>Please check your email</h2>

                      <div className='pr-lg-5'>
                        <p>
                          We’ve {resendEmail ? <strong>resent</strong> : <>sent</>} an email to{' '}
                          <strong>{userEmail}</strong>.
                        </p>

                        <p>
                          Please verify your email to access your PolicyMogul account (it might be in your spam folder)
                        </p>
                      </div>

                      <button
                        className='general-button resend-button mb-2'
                        onClick={() => {
                          if (!isSending) {
                            resendVerificationEmail();
                          }
                        }}
                      >
                        {isSending ? (
                          <>
                            <i className='fas fa-spinner fa-spin'></i> resending...
                          </>
                        ) : (
                          `Resend the verification email`
                        )}
                      </button>

                      <ChangeEmail setUserEmail={setUserEmail} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withAuthenticationRequired(NeedsVerification, {
  onRedirecting: () => <Loading />,
});
