import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { ShareButtons } from './ui/PublishedContentPage';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SharePage = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { type, id } = params;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { generalApiCall } = useGeneralApiCall();

  const callInformation = useRef();
  callInformation.current = async () => {
    setIsLoading(true);
    const pathname = `/api/user-generated-content/${type === 'policy-asks' ? 'policy-ask' : type}/${id}`;
    const method = 'get';
    const results = await generalApiCall({ pathname, method, needsAuthentication: true });
    if (results) {
      setData(results);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    callInformation.current();
  }, []);

  const shareClick = () => {
    navigate({
      pathname: data?.canonicalUrl,
    });
    //window.open(`${process.env.REACT_APP_POLICYINFLUENCE_APP_URL}${data?.canonicalUrl}`, '_blank')
  };

  return (
    <>
      <div className='top-0 h-100 bg-main-blue text-white d-flex flex-column w-100 z-50 share-user-content-container'>
        {isLoading ? (
          <div className='flex-grow-1 flex-centered justify-content-center'>
            <div className='text-center'>
              <SkeletonTheme baseColor='#fff'>
                <div className='mb-2'>
                  <Skeleton height={30} width={`300px`} />
                </div>
                <Skeleton height={19} width={`500px`} />
                <div className='d-flex flex-column mx-auto position-relative py-5' style={{ width: '250px' }}>
                  {Array(4)
                    .fill()
                    .map((item, index) => (
                      <div className='mb-3' key={index}>
                        <Skeleton height={40} width={'100%'} />
                      </div>
                    ))}
                </div>
              </SkeletonTheme>
            </div>
          </div>
        ) : (
          <>
            {type && (
              <div className='bg-light-blue text-white py-3 z-10 position-relative text-center '>
                <p className='mb-0'>
                  <span className='icon-circle-check text-white mr-2 text-h4' />
                  You have successfully published your {type === 'policy-asks' ? 'policy ask' : type?.replace('-', ' ')}
                </p>
              </div>
            )}

            <div className='flex-grow-1 flex-centered justify-content-center'>
              <div className='text-center'>
                <h3 className='title-h2 mb-2'>Increase your impact</h3>
                <p className='title-h4 mb-0 opacity-7'>
                  Increase impact amongst parliamentarians by gathering support for this {type.replace('-', ' ')}
                </p>
                <div className='flex flex-col max-w-[250px] mx-auto relative'>
                  <ShareButtons canonicalUrl={data?.canonicalUrl} onClick={shareClick} title={data?.title} />
                  <Link
                    className='text-white text-underline-hover'
                    to={{
                      pathname: data?.canonicalUrl,
                    }}
                  >
                    Skip for now
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SharePage;
