import { Route, Routes } from 'react-router-dom';
import ContentRoutes from 'components/Dashboard/ContentRoutes';
import KeyUpdatesContent from 'components/Dashboard/key_updates/content';
import ConsultationContent from 'components/Dashboard/consultations/ConsultationContent';
import LegislationContent from 'components/Dashboard/Legislation/LegislationContent';
import ParliamentaryContent from 'components/Dashboard/Parliamentary_record/ParlamentaryContent';
import LibraryMaterialContentControl from 'components/Dashboard/LibraryMaterial/LibraryMaterialContentControl';
import StakeholderPage from 'components/Dashboard/StakeHolders/StakeholderPage';
import Dashboard from 'components/Dashboard';
import React from 'react';
import InfluenceContentContainer from 'components/Dashboard/UserContent/InfluenceContentContainer';
import CommitteePublicationContent from 'components/Dashboard/CommitteePublication/CommitteePublicationContent';
const ContentMainRoutesMapping = {
  'key-updates': {
    element: <KeyUpdatesContent />,
  },
  consultations: {
    element: <ConsultationContent />,
  },
  legislation: {
    element: <LegislationContent />,
    parameters: ['type', 'id', 'title'],
  },
  'parliamentary-record': {
    element: <ParliamentaryContent />,
    parameters: ['type', 'id', 'title'],
  },
  'committee-publication': {
    element: <CommitteePublicationContent />,
    parameters: ['id', 'type', 'title'],
  },
  'library-material': {
    element: <LibraryMaterialContentControl />,
  },
  stakeholders: {
    element: <StakeholderPage />,
    parameters: ['id', 'name', 'contentType'],
  },
  'policy-asks': {
    element: <InfluenceContentContainer page={'policy-asks'} />,
  },
  'briefing-material': {
    element: <InfluenceContentContainer page={'briefing-material'} />,
  },
  'news-release': {
    element: <InfluenceContentContainer page={'news-release'} />,
  },
  research: {
    element: <InfluenceContentContainer page={'research'} />,
  },
};

const ContentMainRoutes = () => {
  return (
    <Routes>
      <Route path={`/key-updates/*`} element={<Dashboard />}>
        <Route path={`*`} element={<ContentRoutes />}>
          <Route path={`:id`} element={<KeyUpdatesContent />} />
          <Route path={`:id/:title`} element={<KeyUpdatesContent />} />
        </Route>
      </Route>
      <Route path={`/consultations/*`} element={<Dashboard />}>
        <Route path={`*`} element={<ContentRoutes />}>
          <Route path={`:id`} element={<ConsultationContent />} />
          <Route path={`:id/:title`} element={<ConsultationContent />} />
        </Route>
      </Route>
      <Route path={`/legislation/*`} element={<Dashboard />}>
        <Route path={`*`} element={<ContentRoutes />}>
          <Route path={`:type/:id`} element={<LegislationContent />} />
          <Route path={`:type/:id/:title`} element={<LegislationContent />} />
        </Route>
      </Route>
      <Route path={`/parliamentary-record/*`} element={<Dashboard />}>
        <Route path={`*`} element={<ContentRoutes />}>
          <Route path={`:type/:id`} element={<ParliamentaryContent />} />
          <Route path={`:type/:id/:title`} element={<ParliamentaryContent />} />
        </Route>
      </Route>
      <Route path={`/committee-publication/*`} element={<Dashboard />}>
        <Route path={`*`} element={<ContentRoutes />}>
          <Route path={`:id/:type`} element={<CommitteePublicationContent />} />
          <Route path={`:id/:type/:title`} element={<CommitteePublicationContent />} />
        </Route>
      </Route>
      <Route path={`/library-material/*`} element={<Dashboard />}>
        <Route path={`*`} element={<ContentRoutes />}>
          <Route path={`:id`} element={<LibraryMaterialContentControl />} />
          <Route path={`:id/:title`} element={<LibraryMaterialContentControl />} />
        </Route>
      </Route>

      <Route path={`/stakeholders/*`} element={<Dashboard />}>
        <Route path={`*`} element={<ContentRoutes />}>
          <Route path={`:id/:name`} element={<StakeholderPage />} />
          <Route path={`:id/:name/:contentType`} element={<StakeholderPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export { ContentMainRoutesMapping };
export default ContentMainRoutes;
