import React, { useState, useRef, useEffect, useMemo } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import useHiddenItemsFunctions from 'components/Dashboard/hooks/useHiddenItemsFunctions';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import Axios from 'axios';
import SelectClientItems from './SelectClientItems';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const SelectOfHiddenItems = (props) => {
  const { newAgencyPlan } = useTopicOrClientWord();
  return <>{newAgencyPlan ? <SelectClientItems {...props} /> : <SelectOfHiddenTopicItems {...props} />}</>;
};

const SelectOfHiddenTopicItems = (props) => {
  const {
    userItem,
    disabled,
    agencyUser,
    adminPage,
    localKeywordList,
    setLocalKeywordList,
    newMember,
    newAdminMember,
  } = props;
  const [itemsList, setItemsList] = useState([]);
  const [changingState, setChangingState] = useState(null);
  const { getHiddenItemsList } = useHiddenItemsFunctions();

  const asyncFn = async (source) => {
    let items;
    if (newMember || newAdminMember) {
      items = localKeywordList;
      setItemsList(items);
    } else {
      items = await getHiddenItemsList({
        userId: userItem.id,
        adminPage,
        source,
      });
      if (items?.length) {
        setItemsList(items);
      }
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    asyncFn(source);
    return () => {
      source.cancel('Team user cancelled by the user');
    };
  }, []);

  useEffect(() => {
    if (userItem?.newRole === 'Admin') {
      let newitemsList = itemsList.map((item) => {
        if (item.myState === 'Disabled') {
          return { ...item, myState: 'Enabled' };
        } else {
          return item;
        }
      });
      setItemsList(newitemsList);
    }
  }, [userItem?.newRole]);

  const hiddenItemsElement = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const hideDropdown = () => {
    if (!newMember && !newAdminMember) {
      setShowDropdown(false);
    }
  };
  useOutsideElement(hiddenItemsElement, hideDropdown);

  const checkedItem = (item) => {
    return item?.myState === 'Enabled' || item?.myState === 'Hidden';
  };

  const disabledConditional = changingState || disabled;

  const allItemsCheckedAdminOrOwner = useMemo(
    () => itemsList?.every((item) => item?.myState === 'Enabled'),
    [itemsList, userItem?.myState]
  );

  const allItemsChecked = useMemo(
    () => itemsList?.every((item) => item?.myState === 'Enabled' || item?.myState === 'Hidden'),
    [itemsList, userItem?.myState]
  );
  const allItemsEnabled = useMemo(
    () => itemsList?.every((item) => item?.myState === 'Enabled'),
    [itemsList, userItem?.myState]
  );

  const getSelectTitle = () => {
    if (adminPage) {
      if (allItemsEnabled) return agencyUser ? 'All clients' : 'All topics';
      return `${
        itemsList.filter((item) => item?.myState === 'Enabled').length
      } out of ${itemsList.length} ${agencyUser ? 'client' : 'topic'}${itemsList.length > 1 ? 's' : ''}`;
    } else {
      if (allItemsChecked) return agencyUser ? 'All clients' : 'All topics';
      return `${itemsList.filter(checkedItem).length} out of ${
        itemsList.length
      } ${agencyUser ? 'client' : 'topic'}${itemsList.length > 1 ? 's' : ''}`;
    }
  };
  return (
    <div
      className='position-relative role-select-container'
      style={{
        maxWidth: newMember || newAdminMember ? 'unset' : '250px',
        backgroundColor: disabled ? 'rgba(196, 196, 196, 0.2)' : undefined,
      }}
      ref={hiddenItemsElement}
    >
      <p
        className={`mb-0 hour-select px-3  ${
          showDropdown ? 'hour-select-opendropdown' : ''
        } ${disabled ? 'disabled-role-select' : ''} ${changingState ? 'disabled-hour-select' : ''}`}
        onClick={() => {
          if (!disabledConditional) {
            if ((newMember || newAdminMember) && showDropdown) {
              setLocalKeywordList(itemsList);
            }
            setShowDropdown(!showDropdown);
          }
        }}
      >
        <span className={disabled ? 'px-3' : undefined}>{getSelectTitle()}</span>
      </p>

      {showDropdown && (
        <>
          <div className='frequency-dropdown text-left team-role-dropdown scroll-container'>
            <ItemCheckbox
              allOption
              changingState={changingState}
              adminPage={adminPage}
              allItemsCheckedAdminOrOwner={allItemsCheckedAdminOrOwner}
              allItemsChecked={allItemsChecked}
              agencyUser={agencyUser}
              userItem={userItem}
              checkedItem={checkedItem}
              itemsList={itemsList}
              newAdminMember={newAdminMember}
              setChangingState={setChangingState}
              setItemsList={setItemsList}
              newMember={newMember || newAdminMember}
              allItemsEnabled={allItemsEnabled}
            />
            {itemsList.map((item) => (
              <ItemCheckbox
                item={item}
                key={`item-checkbox-${item.id}`}
                newMember={newMember || newAdminMember}
                changingState={changingState}
                adminPage={adminPage}
                allItemsCheckedAdminOrOwner={allItemsCheckedAdminOrOwner}
                allItemsChecked={allItemsChecked}
                agencyUser={agencyUser}
                userItem={userItem}
                checkedItem={checkedItem}
                itemsList={itemsList}
                newAdminMember={newAdminMember}
                setChangingState={setChangingState}
                setItemsList={setItemsList}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const ItemCheckbox = ({
  item,
  allOption,
  changingState,
  adminPage,
  allItemsCheckedAdminOrOwner,
  allItemsChecked,
  agencyUser,
  userItem,
  checkedItem,
  itemsList,
  newMember,
  newAdminMember,
  setChangingState,
  setItemsList,
  allItemsEnabled,
}) => {
  const { updateHiddenItemState } = useHiddenItemsFunctions();

  const switchVisability = async ({ item, returnRes, stateArg, showSuccsessMessage }) => {
    let updated;
    if (!changingState) {
      if (!returnRes && !newMember && !newAdminMember) setChangingState(item.id);
      let newState = () => {
        if (stateArg) {
          return stateArg;
        } else {
          if (adminPage) {
            if (checkedItem(item)) {
              if (userItem?.teamRole === 'Admin' || userItem?.teamRole === 'Owner' || newAdminMember) {
                if (item?.myState === 'Enabled') {
                  return 'Hidden';
                } else {
                  return 'Enabled';
                }
              } else {
                if (item?.myState === 'Enabled') {
                  return 'Hidden';
                } else {
                  return 'Disabled';
                }
              }
            } else {
              return 'Enabled';
            }
          } else {
            if (checkedItem(item)) {
              return 'Disabled';
            } else {
              return 'Enabled';
            }
          }
        }
      };

      if (!newMember && !newAdminMember) {
        updated = await updateHiddenItemState({
          state: newState(),
          userId: parseInt(userItem.id),
          listId: item.id,
          adminPage,
          showSuccsessMessage,
        });
      }

      if (updated || newMember || newAdminMember) {
        if (returnRes) {
          return updated;
        } else {
          let updatedItemsList = itemsList.map((listItem) => {
            if (listItem.id === item.id) {
              return { ...listItem, myState: newState() };
            }
            return listItem;
          });
          setItemsList(updatedItemsList);
        }
      }
      if (!returnRes && !newMember && !newAdminMember) setChangingState(null);
    }
  };

  const selectAllHandler = async () => {
    if (!changingState) {
      setChangingState('all');
      let updatedAll;

      if (adminPage) {
        if (userItem?.teamRole === 'Admin' || userItem?.teamRole === 'Owner' || newAdminMember) {
          if (allItemsCheckedAdminOrOwner) {
            updatedAll = await Promise.all(
              itemsList
                .filter((item) => item.myState !== 'Hidden')
                .map(async (item) =>
                  switchVisability({
                    item,
                    returnRes: true,
                    stateArg: 'Hidden',
                  })
                )
            );
          } else {
            updatedAll = await Promise.all(
              itemsList
                .filter((item) => item.myState !== 'Enabled')
                .map(async (item) =>
                  switchVisability({
                    item,
                    returnRes: true,
                  })
                )
            );
          }
        } else {
          if (allItemsChecked) {
            updatedAll = await Promise.all(
              itemsList.map(async (item) =>
                switchVisability({
                  item,
                  returnRes: true,
                  stateArg: itemsList.every((item) => item?.myState === 'Enabled') ? 'Hidden' : 'Disabled',
                })
              )
            );
          } else {
            updatedAll = await Promise.all(
              itemsList
                .filter((item) => item.myState !== 'Enabled')
                .map(async (item) =>
                  switchVisability({
                    item,
                    returnRes: true,
                    stateArg: 'Enabled',
                  })
                )
            );
          }
        }
      } else {
        if (allItemsChecked) {
          updatedAll = await Promise.all(
            itemsList.map(async (item) =>
              switchVisability({
                item,
                returnRes: true,
                stateArg: 'Disabled',
              })
            )
          );
        } else {
          updatedAll = await Promise.all(
            itemsList
              .filter((item) => item.myState !== 'Enabled')
              .map(async (item) =>
                switchVisability({
                  item,
                  returnRes: true,
                  stateArg: 'Enabled',
                })
              )
          );
        }
      }
      if (updatedAll) {
        let newState = () => {
          if (adminPage) {
            if (userItem?.teamRole === 'Admin' || userItem?.teamRole === 'Owner' || newAdminMember) {
              if (allItemsCheckedAdminOrOwner) {
                return 'Hidden';
              } else {
                return 'Enabled';
              }
            } else {
              if (allItemsChecked) {
                return itemsList.every((item) => item?.myState === 'Enabled') ? 'Hidden' : 'Disabled';
              } else {
                return 'Enabled';
              }
            }
          } else {
            if (allItemsChecked) {
              return 'Disabled';
            } else {
              return 'Enabled';
            }
          }
        };
        let updatedItemsList = itemsList.map((listItem) => {
          return {
            ...listItem,
            myState: newState(),
          };
        });
        let succsessMessage = `All ${agencyUser ? 'clients' : 'topics'} successfully ${newState().toLowerCase()}`;
        if (!newAdminMember && !newMember) {
          createNotification('success', succsessMessage);
        }
        setItemsList(updatedItemsList);
      }
      setChangingState(null);
    }
  };

  return (
    <label htmlFor={`hideTopic-${item?.id ?? 'all'}-id`} className='mb-0 w-100 font-weight-normal'>
      <div className={`mb-0 frequency-item px-3 py-2 position-relative`}>
        {(allOption ? changingState === 'all' : changingState === item?.id) ? (
          <i
            className='fas fa-spinner fa-spin'
            style={{
              position: 'absolute',
              color: '#212529',
              left: '9px',
              top: '12px',
            }}
          ></i>
        ) : (
          <div
            className={`stakeholder-checkbox-input topics-hide-checkbox-dropdown`}
            onClick={(e) => e.stopPropagation()}
            style={{
              backgroundColor: adminPage && item?.myState === 'Hidden' ? '#D1D4D7' : '',
            }}
          >
            <input
              type={'checkbox'}
              onChange={(e) => {
                allOption
                  ? selectAllHandler()
                  : switchVisability({
                      item,
                      showSuccsessMessage: true,
                    });
              }}
              name={`hideTopic-${item?.id ?? 'all'}`}
              id={`hideTopic-${item?.id ?? 'all'}-id`}
              checked={
                allOption
                  ? userItem?.teamRole === 'Admin' || userItem?.teamRole === 'Owner'
                    ? allItemsCheckedAdminOrOwner
                    : allItemsEnabled
                  : checkedItem(item)
              }
            />
            <label htmlFor={`hideTopic-${item?.id ?? 'all'}-id`} />
          </div>
        )}
        {allOption ? `All${agencyUser ? ' client' : ' topic'}${itemsList.length > 1 ? 's' : ''}` : item?.name}
      </div>
    </label>
  );
};

export default SelectOfHiddenItems;
