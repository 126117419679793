import { useContext } from 'react';
import { useEffect, useRef, useState } from 'react';
import { store } from 'components/Store';
import { useNavigate } from 'react-router-dom';

const SentimentSearchBarPage = (props) => {
  const { analyticsPage } = props;

  const inputSearch = useRef();
  const focusInput = useRef();
  focusInput.current = () => {
    inputSearch?.current?.focus();
  };

  useEffect(() => {
    focusInput.current();
  }, []);

  return (
    <section className='text-white pt-lg-5 h-100'>
      <div className='container-lg px-0'>
        <div className='col-lg-13 px-0 col-xl-11 px-3 px-sm-0 mx-auto text-center pb-5 pt-5'>
          <h1 className='mb-4 font-weight-bold sentiment-searchbar-title px-4 px-lg-0'>
            {analyticsPage ? `Explore political data on any issue` : 'Track political sentiment on any issue'}
          </h1>
          <p className='mb-lg-5 sentiment-page-subtitle mx-auto'>
            {analyticsPage
              ? 'Enter a keyword in the search bar below to see interest and sentiment amongst UK policymakers'
              : 'Enter a keyword or phrase in the search bar below to see how sentiment amongst parliamentarians has changed over time'}
          </p>
          <DataHubSimpleSearch analyticsPage={analyticsPage} />
        </div>
      </div>
    </section>
  );
};

const DataHubSimpleSearch = ({ analyticsPage, openInNewTab, placeholder }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const inputSearch = useRef();
  const [valueSearch, setValueSearch] = useState('');
  const navigate = useNavigate();
  const focusInput = useRef();
  focusInput.current = () => {
    if (!!inputSearch.current) {
      inputSearch?.current?.focus();
    }
  };

  useEffect(() => {
    focusInput.current();
  }, []);

  return (
    <div className='position-relative sentiment-page-searchbar'>
      <div className={`simple-searchbar flex-centered simple-searchbar-open mx-auto`}>
        <span className={`main-search-icon`} />
        <div className='flex-grow-1'>
          <input
            className={`search-main-input w-100`}
            data-hj-allow
            type='text'
            value={valueSearch}
            onChange={(e) => {
              let value = e.target.value;
              setValueSearch(value);
            }}
            onKeyDown={(e) => {
              let value = e.target.value;
              if (e.keyCode === 13 && value !== '') {
                if (openInNewTab) {
                  window.open(`/data-hub?search=${encodeURIComponent(value)}`, '_blank');
                } else {
                  navigate({ search: `search=${encodeURIComponent(value)}` });
                }
                if (analyticsPage) {
                  dispatch({ type: 'MODIFY_SECTION', parameter: 'analyticsHubSearchQuery', value: null }); //change for value when enabling stakeholder search
                }
              }
            }}
            ref={inputSearch}
            placeholder={placeholder ?? `Search for any keyword ${!analyticsPage ? 'or phrase' : ''}`}
          />
        </div>
        {/* CLOSE BUTTON */}
        <div className='close-icon-container' style={{ visibility: 'hidden' }}>
          <button className='general-button close-icon' data-place='right' />
        </div>
      </div>
    </div>
  );
};

export { DataHubSimpleSearch };
export default SentimentSearchBarPage;
