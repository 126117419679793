import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import useCompileFiltersFunction from './useCompileFilters';
import useCompileInitialFilters from './useCompileInitialFilters';
import { useCreateSectionStored } from './useGetSectionFilters';
import changeItemInsideArray from 'components/Dashboard/helpers/changeItemInsideArray';
import { store } from 'components/Store';
import { sectionName } from 'components/Dashboard/helpers/controlActiveFilters';
import FiltersData from './FiltersData';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { useAuth0 } from '@auth0/auth0-react';
import LocalStorage from 'utils/LocalStorage';
const FiltersContext = createContext();

const filtersReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.value };
    case 'SET_FILTERS':
      return { ...state, filtersState: action.filters, isLoading: false };
    case 'APPLY_FILTERS':
      return {
        ...state,
        filtersState: action.filters,
        contentFilters: { ...state?.contentFilters, [action.section]: action.filters },
      };
    case 'CLEAR_FILTERS':
      return { ...state, filtersState: {}, contentFilters: [] };
    case 'MODIFY_FILTERS':
      return { ...state, filtersState: action.filtersState, contentFilters: action.contentFilters };
    default:
      return state;
  }
};

const initialState = { filtersState: [], isLoading: true, contentFilters: [], additionalFilters: {} };

export const FiltersProvider = ({ children }) => {
  const globalState = useContext(store);
  const { state: appState } = globalState;
  const { search, referenceState, readyKeywordLists } = appState;
  const { list, searchState, activeSearch, query } = search ?? {};
  const { activeReference } = referenceState;

  const [state, dispatch] = useReducer(filtersReducer, initialState);
  const { filtersState, contentFilters } = state;

  const section = window.location.pathname.includes('tweets') ? 'SocialMedia' : sectionName();
  const { compileInitialFilters } = useCompileInitialFilters();
  const { activeResultsConditional } = useCompileFiltersFunction(section);
  const { sectionStored } = useCreateSectionStored();
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const isMobile = useIsMobile();
  //SET FILTERS ON SPECIFIC CHANGES
  //When we switch a list, clear search, change location, we need to get the initialFilters stored and update the state based on this change

  useEffect(() => {
    if (isAuthenticated && !!sectionStored && readyKeywordLists) {
      const initialFilters = compileInitialFilters(sectionStored);
      applyFilters(initialFilters);
    }
  }, [searchState, activeSearch, list, query, referenceState, location.pathname, readyKeywordLists]);

  const applyFilters = (filters) => {
    const filtersState = filtersWithSort(filters);
    setFiltersLocalStorage(filtersState);
    dispatch({ type: 'SET_FILTERS', filters: filtersState });
  };

  const filtersWithSort = (filters) => {
    let newFiltersData = filters.slice();
    //SET SELECTED OPTION TO FALSE (NECCESSARY TO CREATE A CONTROLLED INPUT)
    newFiltersData.forEach((item) => {
      let options = item.options;
      if (options && item.type !== 'sort') {
        options.forEach((option) => {
          if (option.selected === undefined) {
            option.selected = false;
          }
        });
      }
    });
    return newFiltersData;
  };

  const setFiltersLocalStorage = (filters) => {
    let storedFilters = LocalStorage.getItem('contentFilters');
    let finalObject = {};
    if (storedFilters) {
      let filtersParsed = JSON.parse(storedFilters);
      if (Array.isArray(filtersParsed)) {
        finalObject = {
          ...finalObject,
          initialFilters: filtersParsed,
        };
      } else {
        finalObject = filtersParsed;
      }
    }
    let sectionToStore = sectionStored;
    finalObject[sectionToStore] = filters;
    let unreadFilter = filters?.find((item) => item.name === 'See unread only');
    if (unreadFilter) {
      LocalStorage.setItem('seeUnreadFilter', unreadFilter.value.toString());
    }
    LocalStorage.setItem('contentFilters', JSON.stringify(finalObject));
  };

  //SET FILTER FUNCTIONALITY
  const applyFiltersFunction = useCallback(
    (filters) => {
      dispatch({ type: 'APPLY_FILTERS', filters: filters, section: sectionStored });
      setFiltersLocalStorage(filters);
    },
    [sectionStored]
  );

  const filterTypeModifiers = useCallback(
    ({ basefilters, filter, newValue }) => {
      /*AE: This part is neccesary to the external items on Tweets */
      const { type, name, pages, searchState } = filter;
      let referenceState = basefilters ?? filtersState;
      let newFilters = JSON.parse(JSON.stringify(referenceState));
      let filterPosition = newFilters.findIndex(
        (item) =>
          item.name === name &&
          !item.hidden &&
          JSON.stringify(item.pages) === JSON.stringify(pages) &&
          item.searchState === searchState
      );
      let currentFilter;
      switch (type) {
        case 'toggle':
        case 'search':
          currentFilter = { ...filter, value: newValue };
          break;
        case 'sort':
        case 'sortMode':
          currentFilter = { ...filter, optionSelected: newValue };
          //Clean sentiment options because is a dependent filter ot the sort mode, very specific case.
          let sentimentFilterPosition = newFilters.findIndex(
            (item) => item.searchState === 'sentimentSortModeCondition'
          );
          if (
            sentimentFilterPosition >= 0 &&
            newFilters[sentimentFilterPosition].options.filter((item) => item.selected)?.length > 0
          ) {
            let newValue = { ...newFilters[sentimentFilterPosition] };
            newValue.optionSelected = '';
            newValue?.options?.forEach((item) => (item.selected = false));
            newFilters[sentimentFilterPosition] = newValue;
          }
          break;
        case 'date':
        case 'addedOn':
          currentFilter = {
            ...filter,
            optionSelected: newValue.optionSelected,
            options: newValue.options,
          };
          break;
        case 'topicPickerWithSentiment':
          currentFilter = {
            ...filter,
            options: newValue.options,
            sentiment: newValue.sentiment,
          };
          break;
        default:
          if (filter.type === 'parliamentaryRecordMultipleHierarchy') {
            if (newValue.filter((option) => option.selected).length > 0) {
              let thereIsQuestionItem = newValue.find((item) => item.selectedOptions.includes('Written Q&As'));
              if (!thereIsQuestionItem) {
                let questionFilter = newFilters.find((item) => item.name === 'Written Q&As');
                questionFilter?.options.forEach((item) => (item.selected = false));
              }
            }
          }
          currentFilter = { ...filter, options: newValue };
      }

      // Nation filter case
      if (filter.name === 'Nation' && newValue !== 'Westminster') {
        currentFilter = { ...filter, optionSelected: newValue };
        let sortFilterPosition = newFilters.findIndex(
          (item) => item.name === 'Sort' && item.type === 'sortMode' && item.searchState === 'list'
        );
        if (sortFilterPosition >= 0) {
          let newValue = { ...newFilters[sortFilterPosition] };
          newValue.optionSelected = 'Most vocal';
          newValue?.options?.filter((item) => item.name !== 'Most vocal')?.forEach((item) => (item.selected = false));
          newFilters[sortFilterPosition] = newValue;
        }
      }
      if (filter.name === 'Nation' && newValue === 'Westminster') {
        currentFilter = { ...filter, optionSelected: newValue };
        let sortFilterPosition = newFilters.findIndex(
          (item) => item.name === 'Sort' && item.type === 'sortMode' && item.searchState === 'list'
        );
        if (sortFilterPosition >= 0) {
          let newValue = { ...newFilters[sortFilterPosition] };
          newValue.optionSelected = 'Most relevant';
          newValue?.options
            ?.filter((item) => item.name !== 'Most relevant')
            ?.forEach((item) => (item.selected = false));
          newFilters[sortFilterPosition] = newValue;
        }
      }

      newFilters[filterPosition] = currentFilter;
      return newFilters;
    },
    [filtersState, contentFilters, sectionStored, location.pathname]
  );

  const clearFilters = useCallback(() => {
    const defaultValues = JSON.parse(JSON.stringify(FiltersData()));
    //Clear consultations reference exclude filter
    let consultationFilterPosition = defaultValues.findIndex(
      (item) => item.name === 'Exclude closed consultations' && item.searchState === 'referenceSection'
    );
    defaultValues[consultationFilterPosition].value = false;
    //Clear cesclude retweets inbox
    const excludeRetweetsFilterPosition = defaultValues.findIndex(
      (item) => item.filterName === 'isRetweet' && item.searchState === 'inbox'
    );
    defaultValues[excludeRetweetsFilterPosition].value = false;

    const filters = filtersWithSort(defaultValues);
    dispatch({ type: 'APPLY_FILTERS', filters: filters, section: sectionStored });
  }, [filtersState, contentFilters, sectionStored, location.pathname]);

  const getDefaultSectionFilters = useCallback(
    (filters) => {
      let filtersObject = filters ?? filtersState;
      let filtersToRender = filtersObject.filter(
        (filter) => filter.pages.includes(section) && activeResultsConditional(filter, filtersObject)
      );
      //CHANGE FILTERS ORDER
      filtersToRender = modifyFiltersOrder(filtersToRender);

      if (activeSearch && list !== null) {
        let unreadFilter = filtersToRender.find((item) => item.name === 'See unread only');
        if (unreadFilter) {
          unreadFilter.excludeFromPopup = true;
        }
      }

      return filtersToRender;
    },
    [filtersState, contentFilters, sectionStored, location.pathname]
  );

  //INVESTIGATE RECURSIVE FUNCTIONS
  const modifyFiltersOrder = (filters) => {
    let modifiedFilters = [...filters];
    //AE: This will only work when we need to modify one filter on each section, for multiple filters we need to review function that call itself again
    let customOrderIndex = modifiedFilters.findIndex((item) => item.customOrder !== undefined);
    if (customOrderIndex >= 0) {
      let filterElement = modifiedFilters[customOrderIndex];
      let newPosition =
        (activeSearch || activeReference) && filterElement.activeSearchCustomOrder
          ? filterElement.activeSearchCustomOrder
          : filterElement.customOrder;
      modifiedFilters = changeItemInsideArray(modifiedFilters, customOrderIndex, newPosition);
    }
    return modifiedFilters;
  };

  const compileFiltersNumber = useCallback(() => {
    const filters = getDefaultSectionFilters();
    const searchFilter = filters.find((option) => option.type === 'search');
    const toggleFilter = filters.find((option) => option.type === 'toggle' && option.name !== 'See unread only');
    const dateFilter = filters.find((option) => option.type === 'date');
    const addedOnFilter = filters.find((option) => option.type === 'addedOn');
    const unreadFilter = filters.find((item) => item.name === 'See unread only');
    const additionalFilter = (item) => !isMobile && item.hiddenDesktop;
    let number = 0;

    if (unreadFilter && unreadFilter.value && isMobile) {
      number += 1;
    }

    if (toggleFilter && toggleFilter.value && !toggleFilter.hidden && !additionalFilter(toggleFilter)) {
      number += 1;
    }

    if (dateFilter && dateFilter.optionSelected !== '') {
      number += 1;
    }
    if (addedOnFilter && addedOnFilter.optionSelected !== '') {
      number += 1;
    }

    if (searchFilter && searchFilter.value !== '' && (activeSearch || activeReference)) {
      number += 1;
    }
    //normalFilters
    let filtersToCompile = filters.filter(
      (option) =>
        !option.type ||
        option.type === 'parliamentaryRecordMultipleHierarchy' ||
        option.filterName === 'tags' ||
        option.type === 'membership'
    );
    filtersToCompile.forEach((item) => {
      const { options, hidden } = item;
      if (!additionalFilter(item)) {
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          if (option.selected && !hidden) {
            number += 1;
            break;
          }
        }
      }
    });

    return number;
  }, [filtersState, location?.pathname]);

  return (
    <FiltersContext.Provider
      value={{
        filtersState,
        contentFilters,
        applyFiltersFunction,
        filterTypeModifiers,
        clearFilters,
        getDefaultSectionFilters,
        compileFiltersNumber,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters should be used inside a FiltersProvider');
  }
  return context;
};
