import { useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { copyTextToClipboard } from 'components/Dashboard/helpers/copyFunctionality';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import { createPrompt } from './Create';
import isJsonString from 'components/Dashboard/helpers/isJsonString';
import { useTypeOfUsers } from 'components/Dashboard/sidebar/parliamentarianUsers';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import normalize from 'components/Dashboard/utilities/normalizeString';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import ModalComponent from 'components/Common/Modal';

const Content = (props) => {
  const { results, isLoading, updateFunction, getSuggestion, deleteItem, createEmailFunction } = props;
  const { body, contentType, title } = results;

  const [heightContainer, containerRef] = useHeightContainer();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLoadingRegenerate, setIsLoadingRegenerate] = useState(false);

  const location = useLocation();

  const changeContentType = () => {
    switch (contentType) {
      case 'ParliamentarianDebateContribution':
        return 'Debate contribution';
      case 'ParliamentarianEDM':
        return 'Early Day Motion';
      case 'ParliamentarianConsituentResponse':
        return 'Response to a constituent';
      case 'ParliamentarianQuestion':
        return 'Question';
      default:
        return TransformFromPascalCase(contentType);
    }
  };

  const adjustContentTypeForPrompt = () => {
    switch (contentType) {
      case 'ParliamentarianDebateContribution':
        return 'debate-contribution';
      case 'ParliamentarianEDM':
        return 'edm';
      case 'ParliamentarianConsituentResponse':
        return 'response-to-a-constituent';
      case 'ParliamentarianQuestion':
        return 'question';
      default:
        return normalize(TransformFromPascalCase(contentType));
    }
  };

  const textContainer = useRef();

  const { isParliamentarian } = useTypeOfUsers();
  const userContentTypes = ['PolicyAsk', 'BriefingMaterial', 'Research', 'NewsRelease'];
  const isMobile = useIsMobile();
  const DeleteButton = () => {
    return (
      <button
        className='general-button outline-button px-2 py-1 px-lg-4 py-lg-2 mr-lg-3 text-red'
        onClick={() => {
          setShowDeletePopup(true);
        }}
      >
        Delete
      </button>
    );
  };

  return (
    <>
      <div className='flex-grow-1'>
        <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
          <div className='bg-light-red disclaimer-shadow writing-assistant-bar'>
            <div className='mx-auto main-content-wrapper px-lg-5 px-3 d-flex py-4 '>
              <Link
                to={{ pathname: `${location?.state?.influencePage ? '/influence/ai-assistant' : '/ai-assistant'}` }}
                className='main-dark-text mr-4 reset-link writing-back-link'
              >
                <span className='icon-long-arrow-left' />
              </Link>
              <div>
                <h4 className='title-h5-bold'>Disclaimer</h4>
                <p className='d-flex align-items-center mb-0'>
                  The text below was written by PolicyMogul’s AI assistant. This feature is in Beta and output may
                  contain factual errors. You can use it verbatim, but we suggest you read it carefully and consider
                  making edits to ensure it is accurate and reflects your views.
                </p>
              </div>
            </div>
          </div>
          <div className='mx-auto main-content-wrapper flex-grow-1 px-lg-5 px-3 pt-3 pt-lg-4 d-flex flex-column'>
            <div
              ref={containerRef}
              className='box-top-rounded p-lg-5 p-4'
              style={{ minHeight: `${heightContainer}px` }}
            >
              {isLoading ? (
                <ContentSkeleton />
              ) : (
                <>
                  <div className='d-lg-flex align-items-center justify-content-between mb-5'>
                    <div className='flex-centered justify-content-between mb-4 mb-lg-0'>
                      <h3 className='title-h5-m-bold flex-centered mb-0'>
                        <span className='icon-bot title-h2 inline-block mr-2' />
                        {changeContentType()}
                      </h3>
                      {isMobile && <DeleteButton />}
                    </div>
                    <div className='flex-centered my-content-buttons'>
                      {!isMobile && <DeleteButton />}
                      <button
                        className='outline-active-button general-button px-2 py-1 px-lg-4 py-lg-2 mr-2 mr-lg-3'
                        disabled={isLoadingRegenerate}
                        onClick={async () => {
                          setIsLoadingRegenerate(true);
                          let prompt = createPrompt({
                            contentType: adjustContentTypeForPrompt(),
                            title: results?.promptData?.title,
                            phrases: !!results?.promptData?.phrases ? JSON.parse(results?.promptData?.phrases) : [],
                          });
                          let suggestion = await getSuggestion({ prompt });
                          await updateFunction({ prompt, body: JSON.stringify(suggestion) });
                          setIsLoadingRegenerate(false);
                        }}
                      >
                        {isLoadingRegenerate && <i className='fas fa-spinner fa-spin mr-2' />} Regenerate
                      </button>
                      <button
                        className={`general-button ${isParliamentarian ? 'action-button' : 'outline-active-button'}  px-2 py-1 px-lg-4 py-lg-2`}
                        onClick={() => {
                          copyTextToClipboard(textContainer?.current?.innerText);
                        }}
                      >
                        Copy {isMobile ? '' : 'text'}
                      </button>
                      {userContentTypes.includes(contentType) && (
                        <Link
                          to={{
                            pathname: `/influence/${contentType === 'PolicyAsk' ? 'policy-asks' : normalize(TransformFromPascalCase(contentType))}/create/1`,
                            search: `?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`,
                          }}
                          state={{ headline: title, writingAssistantId: results?.id }}
                          className='action-button px-2 py-1 px-lg-4 py-lg-2 ml-2 ml-lg-3'
                        >
                          Add to PolicyMogul
                        </Link>
                      )}
                      {contentType === 'EmailToParliamentarians' && (
                        <CreateEmailButton createEmailFunction={createEmailFunction} />
                      )}
                    </div>
                  </div>
                  <div className='pl-lg-2'>
                    {(userContentTypes.includes(contentType) || contentType === 'EmailToParliamentarians') && (
                      <p className='rounded px-3 py-2 d-none d-lg-block mb-4 add-to-policymogul-button'>
                        {userContentTypes.includes(contentType)
                          ? `Click ‘Add to PolicyMogul’ to share your ${TransformFromPascalCase(contentType).toLowerCase()} with relevant policymakers. You'll be able to make edits before it is shared.`
                          : 'Add this text to an email campaign to send it to policymakers and track their open and click rates.'}
                      </p>
                    )}
                    <div className='assitant-text news-content-in' ref={textContainer}>
                      {isJsonString(body) ? (
                        <>
                          {JSON.parse(body)?.choices?.map((item, index) => {
                            return <p key={`content-${index}`}>{item?.message?.content ?? item?.text}</p>;
                          })}
                        </>
                      ) : (
                        <>{body}</>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </CustomScrollbar>
      </div>
      <DeleteItemPopup setShowPopup={setShowDeletePopup} showPopUp={showDeletePopup} deleteItem={deleteItem} />
    </>
  );
};

const CreateEmailButton = (props) => {
  const { createEmailFunction } = props;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <button
      className='action-button px-2 py-1 px-lg-4 py-lg-2 ml-2 ml-lg-3'
      onClick={() => {
        if (!isLoading) {
          setIsLoading(true);
          createEmailFunction();
        }
      }}
    >
      {isLoading && <i className='fas fa-spinner fa-spin mr-2' />} Create email campaign
    </button>
  );
};

const ContentSkeleton = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='flex-centered justify-content-between mb-5'>
        <Skeleton height={24} width={`400px`} />
        <div className='flex-centered'>
          <div className='mr-3'>
            <Skeleton height={24} width={`120px`} />
          </div>
          <Skeleton height={24} width={`120px`} />
        </div>
      </div>
      <div className='pl-5'>
        {Array(4)
          .fill()
          .map((item, index) => (
            <div key={index} className='mb-3'>
              <Skeleton count={3} height={19} width={`100%`} />
              <Skeleton height={19} width={`45%`} />
            </div>
          ))}
      </div>
    </SkeletonTheme>
  );
};

const DeleteItemContent = (props) => {
  const navigate = useNavigate();
  const { deleteItem, setShowPopup } = props;
  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 delete-keywordcategory-modal'>
      <div className='text-center'>
        <p>Are you sure you want to delete this item?</p>

        <div className='my-4'>
          <button
            className='general-button danger-button px-4 py-2'
            onClick={async (e) => {
              let el = e.target;
              el.disabled = true;
              let deleteContent = await deleteItem();
              if (deleteContent) {
                setShowPopup(false);
                setTimeout(() => {
                  navigate('/ai-assistant');
                }, 300);
              }
            }}
          >
            {`Delete item`}
          </button>
        </div>
      </div>
    </div>
  );
};

const DeleteItemPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent isOpen={showPopUp} setIsOpen={setShowPopup} maxWidth={500}>
      <DeleteItemContent {...props} />
    </ModalComponent>
  );
};

export { ContentSkeleton };
export default Content;
