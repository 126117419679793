import { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useTypeOfUsers } from 'components/Dashboard/sidebar/parliamentarianUsers';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { SkeletonContent, SubNavText } from './MyContent';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const promptsPhrases = {
  'debate-contribution': {
    subject: 'Write a UK parliamentary debate contribution about the following subject:',
    phrase: 'It should include the following information:',
    final:
      'The contribution should be between one and three paragraphs, written in British English, and should include UK references based on UK government and parliament.',
    subjectLabel: 'Debate name or subject',
    phraseLabel: 'Write a contribution for this debate which factors in the following information:',
    placeHolderSubject: 'E.g. The Energy Bill Relief Scheme Pass-through Requirement (Heat Suppliers)',
  },
  question: {
    subject: 'Write a question for the UK Parliament on the following subject:',
    phrase: `It should include the following information:`,
    final: `The question should be written in British English, and should include UK references based on UK government and parliament. It should start with the words 'To ask'.`,
    subjectLabel: 'I would like to table a question about the following subject:',
    phraseLabel: 'When writing this question, factor in the following information:',
    placeHolderSubject: 'E.g. Anti-social behaviour in Islington',
  },
  edm: {
    subject: 'Write an Early Day Motion for the UK Parliament which',
    phrase: 'It should include the following information:',
    final:
      'The motion should be between one and three paragraphs, written in British English, and should include UK references based on UK government and parliament.',
    subjectLabel: 'Write an Early Day Motion which...',
    phraseLabel: 'When writing this EDM, factor in the following information:',
    button: 'EDM',
    placeHolderSubject: 'E.g. Congratulates Ms Smith for her work on...',
  },
  'response-to-a-constituent': {
    subject: 'I would like to write a response to a constituent who contacted me with the following message:',
    phrase: 'It should include the following information:',
    final:
      'The response should written in British English, and should include UK references based on UK government and parliament.',
    subjectLabel: 'Write a response to a constituent who has sent the following message:',
    phraseLabel: 'When writing this response, factor in the following information:',
    button: 'response',
    placeHolderSubject: 'Message from constituent',
    placeHolderPhrases: [
      'E.g. Address the constituent as Ms Smith',
      'E.g. Thank the constituent for...',
      'E.g. Make reference to the fact that...',
    ],
  },

  'policy-ask': {
    subject:
      'Write a policy ask for me to present to relevant parliamentarians in the UK. The ask is in relation to the following subject:',
    phrase: 'It should include the following information:',
    final: `The ask should be between three and four paragraphs, written in British English, and should include UK references based on UK government and parliament. It should not be in the format of a letter, rather a document. Don't repeat the subject title and don't use headers such as 'introduction'.`,
    subjectLabel: 'What do you want to achieve with your policy ask?',
    phraseLabel: 'Key information',
    placeHolderSubject:
      'E.g. address systemic failures which are known to contribute to chronic welfare issues for many of Britain’s horses',
    placeHolderPhrases: [
      'E.g. There is a need for owner accountability, wider regulation and better enforcement in England and Wales',
      'E.g. In 2019 the RSPCA secured 182 convictions for horse cruelty, about a fifth of the number secured for dogs',
      'E.g. According to animal welfare charities FOUR PAWS UK and Redwings..',
    ],
  },

  'briefing-material': {
    subject:
      'Write a piece of briefing material for me to present to relevant parliamentarians in the UK. My organisation wants to brief parliamentarians on the following subject:',
    phrase: 'It should include the following information:',
    final: `The contribution should be between three and four paragraphs, written in British English, and should include UK references based on UK government and parliament. It should not be in the format of a letter, rather a document. Don't repeat the subject title and don't use headers such as 'introduction'.`,
    subjectLabel: 'What do you want to brief policymakers on?',
    phraseLabel: 'Key information',
    placeHolderSubject: 'E.g. Connectivity and Climate Change: How 5G will help lay the path to net zero',
    placeHolderPhrases: [
      'E.g. Mobile technologies could help cut food wastage by as much as before the end of the decade',
      'E.g. Dynamically controlled intersections powered by 5G could streamline traffic and reduce energy used by 13-44%',
      'E.g. According to the World Economic Forum, digital technologies could reduce global carbon emissions by 15%',
    ],
  },
  research: {
    subject:
      'Write a research document for me to present to relevant parliamentarians in the UK. The research is in relation to the following subject:',
    phrase: 'It should include our key findings, which are as follows:',
    final: `The research document should be between five and six paragraphs, written in British English, and should include UK references based on UK government and parliament. It should not be in the format of a letter, rather a document. Don't repeat the subject title and don't use headers such as 'introduction'.`,
    subjectLabel: 'What is the nature of your research?',
    phraseLabel: 'Key findings',
    placeHolderSubject: 'E.g. Connectivity and Climate Change: How 5G will help lay the path to net zero',
    placeHolderPhrases: [
      'E.g. Mobile technologies could help cut food wastage by as much as before the end of the decade',
      'E.g. Dynamically controlled intersections powered by 5G could streamline traffic and reduce energy used by 13-44%',
      'E.g. According to the World Economic Forum, digital technologies could reduce global carbon emissions by 15%',
    ],
    button: 'research document',
  },
  'news-release': {
    subject:
      'Write a news release for me to present to relevant parliamentarians in the UK. The news release is in relation to the following subject:',
    phrase: 'It should include the following information:',
    final: `The news release should be between five and six paragraphs, written in British English, and should include UK references based on UK government and parliament. It should not be in the format of a letter, rather a document. Don't repeat the subject title and don't use headers such as 'introduction'.`,
    subjectLabel: 'What is the nature of your news release?',
    phraseLabel: 'What is the key information you want to get across?',
    placeHolderSubject: 'E.g. Connectivity and Climate Change: How 5G will help lay the path to net zero',
    placeHolderPhrases: [
      'E.g. Mobile technologies could help cut food wastage by as much as before the end of the decade',
      'E.g. Dynamically controlled intersections powered by 5G could streamline traffic and reduce energy used by 13-44%',
      'E.g. According to the World Economic Forum, digital technologies could reduce global carbon emissions by 15%',
    ],
  },
  'email-to-parliamentarians': {
    subject:
      'Write an email for me to send to relevant parliamentarians in the UK. The email is in relation to the following subject:',
    phrase: 'It should include the following information:',
    final: `The email should be between three and four paragraphs, written in British English, and should include UK references based on UK government and parliament. It should include a subject line, followed by the body of the email.`,
    subjectLabel: 'What is the subject of the email you want to write?',
    phraseLabel: 'What is the key information you want to get across?',
    placeHolderSubject: 'E.g. How 5G will help lay the path to net zero',
    placeHolderPhrases: [
      'E.g. Mobile technologies could help cut food wastage by as much as before the end of the decade',
      'E.g. Dynamically controlled intersections powered by 5G could streamline traffic and reduce energy used by 13-44%',
      'E.g. According to the World Economic Forum, digital technologies could reduce global carbon emissions by 15%',
    ],
    button: 'email',
  },
  'media-interview': {
    subject:
      'Help me prepare for a media interview in relation to my policy work in the UK. Provide some tips for preparing for the interview which are tailored to the topic, and then give me some soundbites that might be helpful. The interview is in relation to the following subject:',
    phrase: 'I want to get across the following information:',
    final: ``,
    subjectLabel: 'What is the nature of the media interview?',
    phraseLabel: 'What is the key information you want to get across?',
    placeHolderSubject: 'E.g. Connectivity and Climate Change: How 5G will help lay the path to net zero',
    placeHolderPhrases: [
      'E.g. Mobile technologies could help cut food wastage by as much as before the end of the decade',
      'E.g. Dynamically controlled intersections powered by 5G could streamline traffic and reduce energy used by 13-44%',
      'E.g. According to the World Economic Forum, digital technologies could reduce global carbon emissions by 15%',
    ],
    button: 'interview notes',
  },
};

const createPrompt = ({ contentType, title, phrases }) => {
  const section = promptsPhrases[contentType];
  return `${section?.subject} ${title}. ${section?.phrase} ${phrases?.filter((item) => item.trim() !== '').join('; ')}. ${section?.final}`;
};

const Create = (props) => {
  const {
    titleValues,
    phrasesValues,
    getSuggestion,
    createFunction,
    individualPage,
    isLoading: mainIsLoading,
    sentPromptFromWritingAssistant,
  } = props;
  const { isParliamentarian } = useTypeOfUsers();
  const { setPhrases, phrases } = phrasesValues;
  const [isLoading, setIsLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const params = useParams();
  const location = useLocation();
  const { title: contentType } = params;

  const section = promptsPhrases[contentType];
  const basePhrases = ['E.g. I believe that...', `E.g. My Party's stance is...`, 'E.g. It should be noted that...'];
  const [heightContainer, containerRef] = useHeightContainer();

  const reviewSuggestionFunction = async (e) => {
    const influencePage = location?.state?.influencePage;
    if (titleValues?.title === '') {
      createNotification('danger', 'Please enter a subject');
    } else if (phrasesValues?.phrases?.filter((item) => item !== '')?.length === 0) {
      createNotification('danger', 'Please enter at least one piece of key information');
    } else {
      let prompt = createPrompt({ contentType, title: titleValues?.title, phrases: phrasesValues?.phrases });
      if (individualPage && !influencePage) {
        sentPromptFromWritingAssistant({ prompt });
      } else {
        setLoadingState(true);
        setIsLoading(true);
        let suggestion = await getSuggestion({ prompt });
        if (suggestion) {
          await createFunction({ prompt, body: suggestion?.choices?.[0]?.message?.content });
        }
      }
    }
  };
  const isMobile = useIsMobile();

  return (
    <div className='flex-grow-1'>
      <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
        {!individualPage && (
          <div
            className={`bg-white monitor-items-bar inbox-items-bar trends-subnav-bar overflow-auto writing-assistant-bar`}
          >
            <div className='mx-auto main-content-wrapper px-lg-5 px-3 flex-centered'>
              <Link to='/ai-assistant' className='main-dark-text mr-4 reset-link writing-back-link'>
                <span className='icon-long-arrow-left' />
              </Link>
              <div className='flex-centered'>
                <span className='icon-bot title-h2 inline-block mr-3 d-none d-lg-inline-block' />
                <SubNavText />
              </div>
            </div>
          </div>
        )}
        <div className='mx-auto main-content-wrapper px-lg-5 px-3 pt-3 pt-lg-4'>
          <div
            className='box-top-rounded p-lg-5 p-3 writing-phrases'
            ref={containerRef}
            style={{ minHeight: `${heightContainer}px` }}
          >
            {loadingState ? (
              <div
                className='d-flex flex-column justify-content-center'
                style={{ minHeight: `${heightContainer - 100}px` }}
              >
                <div>
                  <div className='text-center pb-5 mx-auto waiting-screen'>
                    <p className='mb-2'>
                      <strong>
                        Your AI assistant is creating your {section?.button ?? contentType?.replace('-', ' ')}
                      </strong>
                    </p>
                    <p>
                      <em>This could take up to about 30 seconds</em>
                    </p>
                  </div>
                  <div className='waiting-bar mx-auto'>
                    <div className='waiting-bar-indicator' />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {mainIsLoading ? (
                  <SkeletonContent />
                ) : (
                  <>
                    <div className='pb-4'>
                      <label className='title-h5-m-bold mb-3'>{section?.subjectLabel}</label>
                      {contentType === 'response-to-a-constituent' ? (
                        <textarea
                          className='writing-textarea'
                          rows='4'
                          value={titleValues?.title}
                          onChange={(e) => {
                            titleValues?.setTitle(e.target.value);
                          }}
                          autoFocus={!isMobile}
                          placeholder={section?.placeHolderSubject}
                        />
                      ) : (
                        <input
                          value={titleValues?.title}
                          onChange={(e) => {
                            titleValues?.setTitle(e.target.value);
                          }}
                          className=''
                          type='text'
                          autoFocus={!isMobile}
                          placeholder={section?.placeHolderSubject}
                        />
                      )}
                    </div>

                    <h3 className='title-h5-m-bold mt-4 mb-0'>{section?.phraseLabel}</h3>
                    <p className='main-light-text mb-4'>
                      {isParliamentarian
                        ? 'If you are a parliamentary staffer, write the information below from the perspective of the parliamentarian you work for'
                        : 'Be as detailed as possible. Consider including data, opinions and quotations.'}{' '}
                    </p>

                    <ul className='policy-list writing-list'>
                      {phrases.map((item, index) => {
                        return (
                          <li key={`base-phrase-${index}`}>
                            <input
                              className=''
                              type='text'
                              placeholder={section?.placeHolderPhrases?.[index] ?? basePhrases[index] ?? ''}
                              value={phrases[index] ?? ''}
                              onChange={(e) => {
                                let newPhrases = [...phrases];
                                newPhrases[index] = e.target.value;
                                setPhrases(newPhrases);
                              }}
                              onKeyDown={(e) => {
                                if (e.target.value === '') {
                                  if (!basePhrases[index] && e.keyCode === 8) {
                                    let newPhrases = [...phrases];
                                    newPhrases.splice(index, 1);
                                    setPhrases(newPhrases);
                                    let el = e.target?.parentElement?.previousElementSibling?.children?.[0];
                                    setTimeout(() => {
                                      if (el) {
                                        el.focus();
                                      }
                                    }, 100);
                                  }
                                } else {
                                  if (index === phrases.length - 1 && e.keyCode !== 8) {
                                    let newPhrases = [...phrases];
                                    newPhrases.push(' ');
                                    setPhrases(newPhrases);
                                  }
                                }
                              }}
                            />
                          </li>
                        );
                      })}
                    </ul>

                    <button
                      className='block rounded py-2 action-button w-100 mt-5'
                      onClick={(e) => {
                        reviewSuggestionFunction(e);
                      }}
                      disabled={isLoading}
                    >
                      {isLoading && <i className='fas fa-spinner fa-spin mr-2' />} Review{' '}
                      {isParliamentarian ? 'suggested' : 'draft'} {section?.button ?? contentType?.replace('-', ' ')}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export { createPrompt };
export default Create;
