import React, { useState } from 'react';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import CountriesPopupContainer, { CountriesPopup } from 'components/Settings/Topics/CountriesPopup';
import AdminConfirmDeleteItem from './AdminConfirmDeleteItem';
import AdminTopicKeywords from './AdminTopicKeywords';
import AdminTopicName from './AdminTopicName';
import AdminTopicOwner from './AdminTopicOwner';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const AdminTopicPage = (props) => {
  const { getCurrentTopic } = props;
  let initialTopicInformation = getCurrentTopic();
  const [topicInformation, setTopicInformation] = useState(getCurrentTopic());
  const setValue = ({ property, newValue }) => {
    let newIformation = { ...topicInformation, [property]: newValue };
    setTopicInformation(newIformation);
  };
  const [heightContainer, containerRef] = useHeightContainer();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showRegionsPopup, setShowRegionsPopup] = useState(false);

  const setCountries = (value) => {
    setValue({ property: 'countries', newValue: value });
  };

  return (
    <>
      <div className={`pt-4 pt-lg-5`}>
        <div className='settings-box'>
          <AdminTopicName
            {...props}
            topicInformation={topicInformation}
            setValue={setValue}
            setShowDeletePopup={setShowDeletePopup}
            initialTopicInformation={initialTopicInformation}
          />
          <div ref={containerRef} />
          <CustomScrollbar
            className={'topics-scrollbar'}
            style={{ height: `${heightContainer}px` }}
            maximalThumbYSize={100}
          >
            <AdminTopicOwner
              {...props}
              setValue={setValue}
              topicInformation={topicInformation}
              setTopicInformation={setTopicInformation}
            />
            <div className='topic-items-container padding-topic-component pb-5'>
              <h3 className='topic-title mb-2'>Description</h3>
              <input
                type={'text'}
                className={'form-control main-input'}
                value={topicInformation?.description || ''}
                onChange={(e) => {
                  setValue({ property: 'description', newValue: e.target.value });
                }}
              />
              <CountriesPopupContainer
                countries={topicInformation?.countries}
                setCountries={setCountries}
                setShowPopup={setShowRegionsPopup}
              />
            </div>
            <AdminTopicKeywords
              {...props}
              setValue={setValue}
              currentKeywordCategory={topicInformation}
              topicInformation={topicInformation}
            />
          </CustomScrollbar>
        </div>
      </div>
      <CountriesPopup
        showPopUp={showRegionsPopup}
        countries={topicInformation?.countries}
        setCountries={setCountries}
        setShowPopup={setShowRegionsPopup}
      />
      <AdminConfirmDeleteItem
        setShowPopUp={setShowDeletePopup}
        showPopup={showDeletePopup}
        topicId={topicInformation?.id}
        {...props}
      />
    </>
  );
};

export default AdminTopicPage;
