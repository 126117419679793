import MentionsChart from './MentionsChart';
import Preloader from 'components/Dashboard/Analytics/ui/AnalyticsDashboard/Preloader';
import React, { useEffect, useRef, useState } from 'react';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { compileData } from 'components/Dashboard/Analytics/MentionsByTopicContainer';
import { useLocation } from 'react-router-dom';
import ChartDataTable, { useChartDataOptionSelected } from './ChartDataTable';
import HoverText from 'components/Dashboard/Analytics/HoverText';
import useModifyAnalyticsState from 'components/Dashboard/Analytics/useModifyAnalyticsState';
import SortArray from 'components/Dashboard/utilities/SortArray';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import MentionsRightPanel from 'components/Dashboard/Analytics/MentionsRightPanel';
import { chartDataOptions } from 'components/Dashboard/Analytics/createAnalyticsState';
import PartyMetaData from './PartyMetadata';
import CountryPicker from 'components/Dashboard/Analytics/MentionsCountryPicker';
import { sortPositionLabels } from 'components/Dashboard/Analytics/sortPositionLabels';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import FiltersBar from './FiltersBar';
import { useContext } from 'react';
import { store } from 'components/Store';
import { Portal } from 'react-portal';
import AnalyticsTitle from 'components/Dashboard/Analytics/ui/AnalyticsTitle';
import { useTitleOnSubnavCondition } from 'components/Dashboard/Analytics/ui/AnalyticsDashboard/AnalyticsSubNav';

const autoScrollFunction = ({ activeSection, chartDataContainer }) => {
  if (activeSection !== null) {
    let elements = document.querySelectorAll('.chart-data-info thead th');
    let element = elements[activeSection];
    if (element) {
      let dataContainer = chartDataContainer.current;
      let { left: tableLeft, right: tableRight } = dataContainer.getBoundingClientRect();
      let { left, right, width } = element.getBoundingClientRect();
      if (tableLeft >= right - width || tableRight <= left + width) {
        let value = element.offsetLeft - 20;
        dataContainer.scrollLeft = value;
      }
    }
  }
};

const compileMentionsDataFunctions = ({ chartRef, data, analyticsState }) => {
  const createChartDataKeys = () => {
    if (chartRef.current && !!data?.value) {
      let sections = [];
      Object.keys(data.value).forEach((item, index) => {
        if (chartRef.current?.isDatasetVisible(index)) {
          let info = chartRef.current.getDatasetMeta(index);
          sections.push(info.label);
        }
      });
      return sortPositionLabels(sections);
    } else {
      return [];
    }
  };
  const chartDataKeys = createChartDataKeys();
  const compileTotalsWithFilters = () => {
    let activeItems = Object.keys(data.value).filter((item) => chartDataKeys.includes(item));
    let newValue = {};
    activeItems.forEach((item) => (newValue[item] = data.value[item]));
    let totalValues = compileData({ value: newValue }, analyticsState).value;
    return totalValues;
  };
  return { chartDataKeys, compileTotalsWithFilters };
};

const MentionsByTopic = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const {
    isLoading,
    data,
    analyticsState,
    isLoadedFirstTime,
    setData,
    rawData,
    selectedCountries,
    setSelectedCountries,
    embedWidget,
  } = props;
  const { chartDataOptionSelectedTweets, chartDataOptionSelected, partySelectedCategories } = analyticsState;
  const location = useLocation();
  const { chartOptionName } = useChartDataOptionSelected();

  const [activeSection, setActiveSection] = useState(null);
  const [disableSelectSection, setDisableSelectSection] = useState(false);
  const [rightPanelState, setRightPanelState] = useState({ open: false });
  const [scrollBarPosition, setScrollBarPosition] = useState(0);
  const [openOtherDropdown, setOpenOtherDropdown] = useState(false);
  const [dropdownStyles, setDropdownStyles] = useState({});

  const { modifyState } = useModifyAnalyticsState();

  const setSelectedCategories = (val) => {
    modifyState({ property: 'partySelectedCategories', newValue: val });
  };
  const compiledDataRaw = compileData(data, analyticsState).raw;
  const createDatesLabels = () => {
    return compiledDataRaw.length > 0 ? compiledDataRaw?.map((item) => item.date) : [];
  };
  const labelDates = createDatesLabels();
  const chartRef = useRef(null);
  const { chartDataKeys, compileTotalsWithFilters } = compileMentionsDataFunctions({
    chartRef,
    data,
    analyticsState,
  });

  const chartSection = useRef(null);
  const chartDataContainer = useRef(null);
  const scrollBarContainer = useRef(null);
  const chartDataSection = useRef(null);

  const emptyMessage = data.labels.length === 0;
  const isMobile = useIsMobile(768);
  const { titleOnSubnavCondition } = useTitleOnSubnavCondition();

  const autoScrollTable = useRef();
  autoScrollTable.current = () => {
    autoScrollFunction({ activeSection, chartDataContainer });
  };

  useEffect(() => {
    if (!rightPanelState.open) {
      autoScrollTable.current();
    }
  }, [activeSection, rightPanelState]);

  useEffect(() => {
    setSelectedCategories(chartOptionName === 'Party' ? PartyMetaData.showParties : null);
  }, [chartOptionName]);

  //AE: In this way we passed properties to the top nav where it's necessary for the embed and download buttons. Probably the safest way to doesn't affect the ref that other parts of the component needs.
  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'chartState',
      value: { isLoading, chartRef, chartSection, data, emptyMessage },
    });
    return () => {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'chartState',
        value: {},
      });
    };
  }, [isLoading, data]);

  const highlightGraphOnHover = (index, datasetIndex) => {
    if (!disableSelectSection && !isMobile) {
      let chart = chartRef.current;
      let lengths = chart?.config?.data?.datasets?.map((item) => item?.data?.length);
      let maxIndex = lengths.indexOf(Math.max(...lengths));
      let meta = chart.getDatasetMeta(datasetIndex ?? maxIndex);
      let rect = chart.canvas.getBoundingClientRect();
      let metaIndex = meta.data[index];
      if (metaIndex) {
        let point = metaIndex.getCenterPoint();
        let evt = new MouseEvent('mousemove', {
            clientX: rect.left + point.x,
            clientY: rect.top + point.y,
          }),
          node = chart.canvas;
        node.dispatchEvent(evt);
      }
    }
  };

  const changeOrderTableLabel = (label, e) => {
    const element = e.target;
    let value = JSON.parse(JSON.stringify(data.value));
    let arrayOrder = [];
    Object.keys(value).forEach((key) => {
      let info = value[key];
      let item = info.find((item) => item.date === label);
      if (item) {
        arrayOrder.push({
          name: key,
          value: item.value,
        });
      }
    });
    let orderArray = arrayOrder.sort(SortArray('value', element.dataset.order)).map((item) => item.name);
    document
      .querySelectorAll('.sort-table-item')
      .forEach((element) => (element.dataset.order = element.dataset.order === 'asc' ? 'desc' : 'asc'));
    let newValue = {};
    orderArray.forEach((item) => {
      newValue[item] = data.value[item];
    });
    setData({ ...data, value: newValue });
    setActiveSection(null);
  };

  const createSectionValues = (rightPanelState) => {
    const { dataKey, date, noResults } = rightPanelState;
    if (dataKey && date) {
      const TweetsSection = location.pathname.includes('/analytics/tweets');
      const chartOptionName = TweetsSection ? chartDataOptionSelectedTweets : chartDataOptionSelected;
      const totalCell = dataKey === 'totalCell';
      const contributionGraph = chartOptionName === 'Contribution type' || chartOptionName === 'Overall';
      let sections = TweetsSection
        ? []
        : ['Spoken parliamentary contributions', 'Written questions', 'EDMs', 'Committee contributions'];
      if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
        sections = [...sections, 'Tweets', 'Retweets'];
      }

      const values = [];
      if (!noResults) {
        if (totalCell) {
          sections.forEach((section) => {
            let number = 0;
            if (Array.isArray(rawData[section])) {
              let point = rawData[section]?.find((point) => point?.date === date)?.value;
              if (point && contributionGraph) {
                number += point;
              }
            } else if (rawData[section]) {
              Object.keys(rawData[section]).forEach((key) => {
                let point = rawData[section]?.[key]?.find((point) => point.date === date)?.value;
                if (point && (chartDataKeys?.includes(key) || contributionGraph)) {
                  number += point;
                }
              });
            }
            values.push({ name: section, value: number });
          });
        } else {
          sections.forEach((section) => {
            let number = 0;
            let point = rawData[section]?.[dataKey]?.find((point) => point?.date === date)?.value;
            if (point) {
              number += point;
            }
            values.push({ name: section, value: number });
          });
        }
      }
      return values.filter((item) => item.value > 0);
    }
  };

  return (
    <div className='d-flex w-100 h-100'>
      <div className={`h-100 flex-grow-1 rounded-top ${embedWidget ? '' : 'bg-main-white border'}`}>
        <CustomScrollbar
          ref={scrollBarContainer}
          className={'simple-scrollbar top-content-scrollbar smooth-scrolling'}
          maximalThumbYSize={100}
          onScroll={(scrollValues) => {
            setScrollBarPosition(scrollValues?.scrollTop);
          }}
        >
          <div className='pb-4' id='mentions-chart-main-container'>
            <div className='px-lg-5 px-3 position-relative inner-mentions-container'>
              {isLoading && !isLoadedFirstTime.current && <Preloader />}
              {isLoadedFirstTime.current && (
                <>
                  {isLoading && <div className='loading-background' />}
                  <div className='my-5'>
                    <div className='d-flex align-items-center justify-content-between'>
                      {!titleOnSubnavCondition && (
                        <div className='title-spacing-adjustment pr-2'>
                          <AnalyticsTitle title={'Mentions'} />
                        </div>
                      )}
                      <CustomScrollbar className='chart-buttons-scrollbar'>
                        <div className='d-lg-flex justify-content-between flex-grow-1'>
                          <div className='d-md-flex align-items-center'>
                            <ChartOptionsButton
                              analyticsState={analyticsState}
                              isLoading={isLoading}
                              scrollBarPosition={scrollBarPosition}
                            />
                            {!isMobile && (
                              <div className='ml-md-3'>
                                <CountryPicker
                                  selectedCountries={selectedCountries}
                                  setSelectedCountries={setSelectedCountries}
                                  scrollBarPosition={scrollBarPosition}
                                />
                              </div>
                            )}
                          </div>
                          <div className='d-none d-lg-flex align-items-center pl-3'>
                            <FiltersBar typeOfChartSections={true} scrollBarPosition={scrollBarPosition} />
                            {!embedWidget && <HoverText />}
                          </div>
                        </div>
                      </CustomScrollbar>
                    </div>
                    {isMobile && (
                      <CountryPicker
                        selectedCountries={selectedCountries}
                        setSelectedCountries={setSelectedCountries}
                      />
                    )}
                  </div>
                  <div ref={chartSection}>
                    <MentionsChart
                      key={`chart-ref-${partySelectedCategories?.length}`}
                      ref={chartRef}
                      data={data}
                      compiledData={compileTotalsWithFilters()}
                      chartOptionName={data?.chartOptionName}
                      analyticsState={analyticsState}
                      setActiveSection={setActiveSection}
                      scrollRefs={{ chartDataSection, scrollBarContainer }}
                      setDisableSelectSection={setDisableSelectSection}
                      emptyMessage={emptyMessage}
                      activeSection={activeSection}
                      isLoading={isLoading}
                      selectedCategories={partySelectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      setRightPanelState={setRightPanelState}
                      rawData={rawData}
                      chartDataKeys={chartDataKeys}
                      labelDates={labelDates}
                      openDropdownState={{ openOtherDropdown, setOpenOtherDropdown, dropdownStyles, setDropdownStyles }}
                      scrollBarPosition={scrollBarPosition}
                      embedWidget={embedWidget}
                    />
                  </div>
                  {!emptyMessage && !embedWidget && (
                    <div ref={chartDataSection}>
                      <ChartDataTable
                        analyticsState={analyticsState}
                        chartDataKeys={chartDataKeys}
                        chartDataContainer={chartDataContainer}
                        activeSection={activeSection}
                        highlightGraphOnHover={highlightGraphOnHover}
                        labelDates={labelDates}
                        compiledData={compileTotalsWithFilters()}
                        data={data}
                        changeOrderTableLabel={changeOrderTableLabel}
                        rawData={rawData}
                        rightPanelState={rightPanelState}
                        setRightPanelState={setRightPanelState}
                        createSectionValues={createSectionValues}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </CustomScrollbar>
      </div>
      {rightPanelState.open && (
        <MentionsRightPanel
          contentState={rightPanelState}
          setRightPanelState={setRightPanelState}
          analyticsState={analyticsState}
          createSectionValues={createSectionValues}
        />
      )}
    </div>
  );
};

const ChartOptionsButton = (props) => {
  const { isLoading, scrollBarPosition } = props;
  const [openOptions, setOpenOptions] = useState(false);

  const { changeChartData } = useModifyAnalyticsState();
  const filtersElement = useRef(null);
  const hideFilters = () => {
    setOpenOptions(false);
  };

  const location = useLocation();

  const orderChartDataOptions = () => {
    if (location.pathname.includes('/analytics/tweets')) {
      let newDataOptions = [...chartDataOptions];
      newDataOptions.unshift(
        newDataOptions.splice(
          newDataOptions.findIndex((elt) => elt.name === 'Contribution type'),
          1
        )[0]
      );
      return newDataOptions;
    } else {
      return chartDataOptions;
    }
  };

  const options = orderChartDataOptions();
  const { chartOptionName } = useChartDataOptionSelected();
  const sentimentChanges = location.pathname.includes('/analytics/sentiment-changes');

  const createOptionsName = () => {
    if (chartOptionName === 'Contribution type') {
      return 'type';
    } else if (chartOptionName === 'Parliamentary position') {
      return 'position';
    } else {
      return chartOptionName.toLowerCase();
    }
  };

  const { top, height, left } = filtersElement.current?.getBoundingClientRect() ?? {};

  useEffect(() => {
    if (!!scrollBarPosition) {
      setOpenOptions(false);
    }
  }, [scrollBarPosition]);

  return (
    <div className='position-relative' ref={filtersElement}>
      <button
        className={`general-button filter-item-button section-filter-button data-option-filter-button ${
          sentimentChanges ? '' : 'action'
        }`}
        onClick={() => {
          setOpenOptions(!openOptions);
        }}
      >
        <span
          className={`dropdown-item-element ${openOptions ? 'open' : ''} nowrap-item ${
            sentimentChanges ? 'title-h5-m' : ''
          }`}
        >
          {chartOptionName !== 'Overall' ? 'by' : ''} {createOptionsName()}
        </span>
      </button>
      {openOptions && (
        <Portal>
          <div className='general-close-background z-extra-max' onClick={() => setOpenOptions(false)} />
          <div
            className={`login-navbar py-2 trends-chart-data-options position-fixed z-extra-max`}
            style={
              sentimentChanges
                ? { top: `${top - 193}px`, width: '258px', left: `${left}px` }
                : { top: `${top + height + 10}px`, width: '258px', left: `${left + 8}px` }
            }
          >
            {options.map((item, index) => {
              const { name } = item;
              return (
                <button
                  className='settings-button setting-item general-button d-block text-left w-100 px-4 position-relative'
                  key={`date-${normalize(name)}${index}`}
                  onClick={() => {
                    if (!isLoading) {
                      changeChartData({ chartDataOption: name });
                      hideFilters();
                    }
                  }}
                >
                  {name !== 'Overall' ? 'by' : ''} {name.toLowerCase()}
                  {name === chartOptionName && <span className='icon-tick topic-selected-mark paragraph-p4' />}
                </button>
              );
            })}
          </div>
        </Portal>
      )}
    </div>
  );
};

export { autoScrollFunction, ChartOptionsButton, compileMentionsDataFunctions };
export default MentionsByTopic;
