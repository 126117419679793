import Preloader from 'components/Dashboard/Analytics/ui/AnalyticsDashboard/Preloader';
import SentimentOverTimeChart from './SentimentOverTimeChart';
import { useEffect, useRef, useState } from 'react';
import { autoScrollFunction } from 'components/Dashboard/Analytics/ui/MentionsByTopic';
import SentimentOverTimeDataTable, { wordPerValue } from './SentimentOverTimeDataTable';
import HoverText from 'components/Dashboard/Analytics/HoverText';
import SentimentRightPanel from 'components/Dashboard/Analytics/SentimentRightPanel';
import useModifyAnalyticsState from 'components/Dashboard/Analytics/useModifyAnalyticsState';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { createEmptyMessageCondition } from 'components/Dashboard/Analytics/useCallSentimentOverTime';
import { useContext } from 'react';
import { store } from 'components/Store';
import FiltersBar from 'components/Dashboard/Analytics/ui/MentionsByTopic/FiltersBar';
import CountryPicker from 'components/Dashboard/Analytics/MentionsCountryPicker';
import {
  createDate,
  useAllowRightPanel,
  useChartDataOptionSelected,
} from 'components/Dashboard/Analytics/ui/MentionsByTopic/ChartDataTable';
import AnalyticsTitle from 'components/Dashboard/Analytics/ui/AnalyticsTitle';
import { useTitleOnSubnavCondition } from 'components/Dashboard/Analytics/ui/AnalyticsDashboard/AnalyticsSubNav';

const SentimentOverTime = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const { isLoading, data, analyticsState, isLoadedFirstTime, embedWidget } = props;
  const { labels, values, rawData, initialResults } = data;
  const [activeSection, setActiveSection] = useState(null);
  const scrollBarContainer = useRef(null);
  const chartDataSection = useRef(null);
  const [rightPanelState, setRightPanelState] = useState({ open: false });
  const [scrollBarPosition, setScrollBarPosition] = useState(0);
  const { selectedCountries } = analyticsState;

  const emptyMessage = createEmptyMessageCondition(rawData);
  const chartRef = useRef(null);
  const chartSection = useRef(null);
  const chartDataContainer = useRef(null);
  const { delayShowPanelTitle } = useAllowRightPanel();
  const { chartDataOption } = useChartDataOptionSelected();
  const { titleOnSubnavCondition } = useTitleOnSubnavCondition();

  const scrollFromChart = (e) => {
    let points = chartRef.current?.getElementsAtEventForMode(e, 'nearest', { intersect: false }, true);
    const { currentView } = analyticsState;
    if (points?.length && !embedWidget) {
      const firstPoint = points[0];
      const value = firstPoint?.element?.$context?.raw;
      const position = firstPoint?.element?.$context?.dataIndex;
      if (value !== 0 && chartDataOption === 'Overall') {
        setRightPanelState({
          open: true,
          date: labels[position],
          rawData,
          text: () => (
            <span>
              Overall sentiment {createDate(currentView, labels[position])} by parliamentarians was{' '}
              <span className={`cell-item-${wordPerValue(values[position]).toLowerCase()}`}>
                {wordPerValue(values[position]).toLowerCase()}
              </span>
            </span>
          ),
          dataKey: 'totalCell',
          noResults: value === 0,
        });
        delayShowPanelTitle();
      } else {
        scrollBarContainer.current.scrollTop(chartDataSection.current.offsetTop);
      }
    }
  };

  const highlightGraphOnHover = (index) => {
    let chart = chartRef.current;
    let meta = chart?.getDatasetMeta(0);
    let rect = chart?.canvas.getBoundingClientRect();
    let metaIndex = meta.data[index];
    if (metaIndex) {
      let point = meta.data[index].getCenterPoint();
      let evt = new MouseEvent('mousemove', {
          clientX: rect.left + point.x,
          clientY: rect.top + point.y,
        }),
        node = chart.canvas;
      node.dispatchEvent(evt);
    }
  };

  const autoScrollTable = useRef();
  autoScrollTable.current = () => {
    autoScrollFunction({ activeSection, chartDataContainer });
  };

  useEffect(() => {
    if (!rightPanelState.open) {
      autoScrollTable.current();
    }
  }, [activeSection, rightPanelState]);

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'chartState',
      value: { isLoading, chartRef, chartSection, data, emptyMessage },
    });
    return () => {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'chartState',
        value: {},
      });
    };
  }, [isLoading, data]);

  const { modifyState } = useModifyAnalyticsState();
  let setSelectedCountries = (val) => {
    modifyState({ property: 'selectedCountries', newValue: val });
  };

  return (
    <div className='d-flex w-100 h-100'>
      <div className={`h-100  flex-grow-1 rounded-top ${embedWidget ? '' : 'bg-white border'}`}>
        <CustomScrollbar
          className={'simple-scrollbar smooth-scrolling'}
          maximalThumbYSize={100}
          ref={scrollBarContainer}
          onScroll={(scrollValues) => {
            setScrollBarPosition(scrollValues?.scrollTop);
          }}
        >
          <div className='pb-4'>
            <div className='px-lg-5 px-3 position-relative inner-sentiment-changes-container'>
              {isLoading && !isLoadedFirstTime.current && <Preloader />}
              {isLoadedFirstTime.current && (
                <>
                  {isLoading && <div className='loading-background' />}
                  <div className='my-5'>
                    <div className='d-flex align-items-md-center justify-content-between flex-column flex-md-row'>
                      {!titleOnSubnavCondition && (
                        <div className='title-spacing-adjustment pr-lg-2'>
                          <AnalyticsTitle title={'Sentiment changes'} />
                        </div>
                      )}
                      <CustomScrollbar className='chart-buttons-scrollbar'>
                        <div className='d-lg-flex justify-content-between flex-grow-1 pl-3 pl-lg-0'>
                          <CountryPicker
                            selectedCountries={selectedCountries}
                            setSelectedCountries={setSelectedCountries}
                          />
                          <div className='d-none d-lg-flex align-items-center pl-3'>
                            <FiltersBar
                              typeOfChartSections={true}
                              scrollBarPosition={scrollBarPosition}
                              chartName={'Sentiment changes'}
                            />
                            {!embedWidget && <HoverText />}
                          </div>
                        </div>
                      </CustomScrollbar>
                    </div>
                  </div>

                  <div>
                    <div ref={chartSection}>
                      <SentimentOverTimeChart
                        data={data}
                        analyticsState={analyticsState}
                        setActiveSection={setActiveSection}
                        ref={chartRef}
                        clickFunction={scrollFromChart}
                      />
                    </div>
                    {!emptyMessage && !embedWidget && (
                      <div ref={chartDataSection}>
                        <SentimentOverTimeDataTable
                          analyticsState={analyticsState}
                          chartDataContainer={chartDataContainer}
                          activeSection={activeSection}
                          highlightGraphOnHover={highlightGraphOnHover}
                          labelDates={labels}
                          totalValues={values}
                          rawData={rawData}
                          isLoading={isLoading}
                          initialResults={initialResults}
                          rightPanelState={rightPanelState}
                          setRightPanelState={setRightPanelState}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </CustomScrollbar>
      </div>
      {rightPanelState.open && (
        <SentimentRightPanel
          contentState={rightPanelState}
          setRightPanelState={setRightPanelState}
          analyticsState={analyticsState}
        />
      )}
    </div>
  );
};

export default SentimentOverTime;
