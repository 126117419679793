import React, { useEffect, useState, useRef } from 'react';
import deleteInvitation from './deteleInvitation.js';
import RoleSelect from './roleSelect';
import axios from 'axios';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import resendInvitation from './resendInvitation.js';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';

const OpenInvitationItem = (props) => {
  const { item, index, team, setTeam, allowMultiUser } = props;
  const [loaders, setLoaders] = useState({ delete: false, resed: false });
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  useEffect(() => {
    return () => {
      source.cancel('Open invitation operation canceled by the user.');
    };
  }, []);

  const { getAccessToken } = useGetAccessToken();
  const callDeleteInvitation = async (code) => {
    setLoaders({ ...loaders, delete: true });
    let deleteCall = await deleteInvitation(getAccessToken, source, code);
    if (deleteCall === 'Success') {
      createNotification('success', `Successfully deleted`);
      let newTeam = team.openInvitations.slice();
      newTeam.splice(index, 1);
      setShowActions(false);
      setLoaders({ ...loaders, delete: false });
      setTeam({ ...team, openInvitations: newTeam });
    } else {
      setLoaders({ ...loaders, delete: false });
      createNotification('danger', `An error ocurred.`);
    }
  };
  const callResendInvitation = async (code) => {
    setLoaders({ ...loaders, resend: true });
    let resendCall = await resendInvitation(getAccessToken, source, code);
    if (resendCall !== undefined && resendCall.success) {
      createNotification('success', `Invitation resent successfully`);
      let newTeam = team.openInvitations.slice();
      newTeam[index] = resendCall.invite;
      setTeam({ ...team, openInvitations: newTeam });
      setLoaders({ ...loaders, resend: false });
    } else {
      createNotification('danger', `An error ocurred.`);
      setLoaders({ ...loaders, resend: false });
    }
  };
  const [showActions, setShowActions] = useState(false);
  const hideItem = () => {
    setShowActions(false);
  };
  const itemElement = useRef(null);
  useOutsideElement(itemElement, hideItem);
  return (
    <div className='row mx-lg-0 team-item py-4 align-items-center align-items-lg-start'>
      <div className='col-lg-5 mb-4 mb-lg-0'>
        <p className='team-list-email'>{item.email}</p>
        <p className='pending-invitation'>{item.hasExpired ? 'Invitation expired' : 'Pending signup'}</p>
      </div>
      <div className='col-14 col-lg-4 align-self-lg-center pr-lg-3 pr-0'>
        <RoleSelect item={item} disabled={false} team={team} setTeam={setTeam} index={index} />
      </div>
      <div className='text-right position-relative pr-lg-2 px-0 flex-grow-1'>
        <span
          className={`team-action-selector ${showActions ? 'team-action-selector-selected' : ''}`}
          ref={itemElement}
          data-tooltip-content={'Manage user'}
          data-tooltip-id={'team-tooltip'}
          onClick={() => {
            setShowActions(!showActions);
          }}
        />
        {showActions && (
          <div className='team-actions py-2 px-3 filters-container' ref={itemElement}>
            <button
              className='general-button team-action-item'
              onClick={() => {
                callDeleteInvitation(item.code);
              }}
            >
              {loaders.delete ? <i className='fas fa-spinner fa-spin'></i> : 'Cancel invitation'}
            </button>
            {allowMultiUser && (
              <button
                className='general-button resend-btn team-action-item'
                style={{ color: item.hasExpired ? '#0094CC' : '#848484' }}
                onClick={() => {
                  callResendInvitation(item.code);
                }}
              >
                {loaders.resend ? <i className='fas fa-spinner fa-spin'></i> : 'Resend invitation'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenInvitationItem;
