import React, { useContext, useRef, useState } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import normalize from 'components/Dashboard/utilities/normalizeString';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { SideNavItem } from './SaveItemsSideNav';
import parliamentarianUsers from 'components/Dashboard/sidebar/parliamentarianUsers';
import { store } from 'components/Store';
import ModalComponent from 'components/Common/Modal';

const SubNavSavedItems = (props) => {
  const { folders, changeFolder, currentFolder, setShowPopup } = props;
  const isMobile = useIsMobile();
  const [showBox, setShowBox] = useState(false);

  const actionsElement = useRef(null);
  const hideActions = () => {
    setShowBox(false);
  };
  useOutsideElement(actionsElement, hideActions);

  return (
    <div className={`bg-white monitor-items-bar`}>
      <div className='mx-auto main-content-wrapper flex-centered justify-content-between resize-elements-container'>
        <div className='side-left-element pl-md-5 pl-3 d-none d-lg-inline-block'>
          <p className={`mb-0 title-h4 nowrap-item monitor-dropdown-item`}>Saved items</p>
        </div>
        <div className='main-content flex-centered position-relative filters-general-container justify-content-between pr-xl-5 px-md-4 px-3'>
          <div className='flex-centered position-relative' ref={actionsElement}>
            <h3
              className={`main-title mb-0 hidden-lines hidden-one-line current-folder-name ${isMobile ? `dropdown-item-element pointer dropdown-item-element-main-title` : ''} ${showBox ? 'open' : ''}`}
              onClick={() => {
                if (isMobile) {
                  setShowBox(!showBox);
                }
              }}
            >
              {currentFolder.name}
            </h3>
            {!isMobile && (
              <span
                className='circle-button folder-property folder-private'
                data-tooltip-content={'Private to me'}
                data-tooltip-id={'tooltip-mobile'}
                data-tooltip-place={'bottom'}
              ></span>
            )}
            {showBox && (
              <div className='login-navbar py-2 main-title-items mobile-list-sidenav-folders'>
                <div className='scroll-container hover-container search-bar-lists'>
                  <ul className='policy-list px-0 mb-0 hover-content'>
                    {folders.map((item) => (
                      <SideNavItem
                        key={`item-${item.id}`}
                        item={item}
                        currentFolder={currentFolder}
                        changeFolder={changeFolder}
                        hideActions={hideActions}
                      />
                    ))}
                  </ul>
                </div>
                <button
                  className='general-button add-folder-button text-light-blue saved-item-folder-button'
                  onClick={() => {
                    setShowPopup(true);
                  }}
                >
                  Create a new folder
                </button>
              </div>
            )}
          </div>
          <div className='d-flex'>
            {isMobile && (
              <span
                className='circle-button folder-property folder-private'
                data-tooltip-content={'Private to me'}
                data-tooltip-id={'tooltip-mobile'}
                data-tooltip-place={'bottom'}
              ></span>
            )}
            <FolderFilters {...props} />
            <FolderActions {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const FolderActions = (props) => {
  const { setFolderToEdit, setShowPopup, currentFolder, setShowConfirmDeletePopup, setEditParameter } = props;
  const [showActions, setShowActions] = useState(false);
  const editElement = useRef(null);
  const hideActions = () => {
    setShowActions(false);
  };
  useOutsideElement(editElement, hideActions);

  const showEditPopup = () => {
    setShowActions(false);
    setFolderToEdit(currentFolder);
    setShowPopup(true);
  };

  return (
    <div className='position-relative filter-main-container' ref={editElement}>
      <button
        className='ml-2 general-button circle-button indented-button saved-more-button action-state-hover'
        onClick={() => {
          setShowActions(!showActions);
        }}
      >
        Edit
      </button>
      {showActions && (
        <div className={`box filters-container folder-actions`}>
          {/* <button className='general-button folder-action-button folder-action-change' onClick={()=>{
            showEditPopup()
            setEditParameter('Access')
          }}>Change access</button>  */}

          <button
            className='general-button folder-action-button folder-action-rename'
            onClick={() => {
              showEditPopup();
              setEditParameter('Rename');
            }}
          >
            Rename folder
          </button>

          <button
            className='general-button folder-action-button folder-action-delete'
            onClick={(e) => {
              setShowActions(false);
              setShowConfirmDeletePopup(true);
            }}
          >
            Delete folder
          </button>
        </div>
      )}
    </div>
  );
};

const FolderFilters = (props) => {
  const { filtersResources } = props;
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className='position-relative filter-main-container'>
      <button
        className={`sort-button more-filters-button ${filtersResources.length > 0 ? 'more-filters-button-active' : ''}`}
        onClick={() => {
          setShowPopup(!showPopup);
        }}
      >
        <span className='icon-filters'></span>
        <span className='d-none d-md-inline-block'>Filters</span> {filtersResources.length > 0 && <span>(1)</span>}
      </button>
      <ResourcesPopup {...props} setShowPopup={setShowPopup} showPopUp={showPopup} />
    </div>
  );
};

const ResourcesPopupContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;

  const { activePlan } = state;
  const { code } = activePlan;
  const { setShowPopup, currentFolder, loadFolderItems, filtersResources, setFiltersResources } = props;
  const [resources, setResources] = useState(filtersResources);
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  const applyResources = (resource) => {
    let newResources = [...resources];
    if (newResources.includes(resource)) {
      let position = newResources.indexOf(resource);
      newResources.splice(position, 1);
      setResources(newResources);
    } else {
      newResources.push(resource);
    }
    setResources(newResources);
  };

  const sendFunction = () => {
    setFiltersResources(resources);
    loadFolderItems(currentFolder.id, resources);
  };

  const options = [
    {
      name: 'Key updates',
      type: 'KeyUpdate',
    },
    {
      name: 'Consultations',
      type: 'Consultation',
    },
    {
      name: 'Legislation',
    },
    {
      name: 'Parliamentary record',
      type: 'ParliamentaryRecord,HansardContent',
    },
    {
      name: 'Upcoming events',
      type: 'Event',
    },
    {
      name: 'Tweets',
      type: 'Tweet',
    },
    {
      name: parliamentarianUsers(code) ? 'Campaigns' : 'Lobbying material',
      type: 'UserContent',
    },
    {
      name: 'Stakeholders',
      type: 'Stakeholder',
    },
  ];
  if (!showTweets) {
    let tweetsPosition = options.findIndex((item) => item.name === 'Tweets');
    options.splice(tweetsPosition, 1);
  }

  return (
    <div className='pt-4 d-flex flex-column'>
      <h3 className='topic-title section-title pl-lg-5 pl-4 pb-3 mb-0'>Filters</h3>
      <div className='px-4 px-lg-5 pt-4 pb-3 filters-popup-container flex-grow-1'>
        <h3>Resources</h3>
        <div>
          {options.map((option, indexOption) => {
            const { name, type } = option;
            const resourceType = type ? type : name;
            return (
              <div className='checkbox-item mb-2' key={`filter${indexOption}`}>
                <input
                  id={normalize(name)}
                  onChange={() => {
                    applyResources(resourceType);
                  }}
                  type='checkbox'
                  checked={resources.includes(resourceType)}
                />
                <label htmlFor={normalize(name)}>{name}</label>
              </div>
            );
          })}
        </div>
      </div>
      <div className='d-flex justify-content-between align-content-center border-top px-4 px-lg-5 py-4'>
        <button
          className='simple-link-button general-button'
          onClick={() => {
            if (filtersResources.length > 0) {
              setFiltersResources([]);
              loadFolderItems(currentFolder.id);
              setShowPopup(false);
            } else {
              setResources([]);
            }
          }}
          style={{ visibility: resources.length > 0 ? 'visible' : 'hidden' }}
        >
          Clear filters
        </button>

        <button
          className='general-button action-button px-4'
          onClick={() => {
            sendFunction();
            setShowPopup(false);
          }}
        >
          Apply filters
        </button>
      </div>
    </div>
  );
};

const ResourcesPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ResourcesPopupContent {...props} />
    </ModalComponent>
  );
};

export default SubNavSavedItems;
