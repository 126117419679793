import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ContentItemsPanel from 'components/Dashboard/Analytics/ui/ContentItemsPanel';
import useRightPanelIndividualCall from 'components/Dashboard/Analytics/';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';

const InterestByRegionRightPanel = (props) => {
  const { contentState, analyticsState, setRightPanelState, allowFrontbenchers } = props;
  const { chartDataOptionSelected } = analyticsState;
  const { text, rawData, dataKey, region, contentType, noResults, totalRow } = contentState;

  const chartOptionName = chartDataOptionSelected;
  const totalCell = dataKey === 'totalCell';
  const contributionGraph = chartOptionName === 'Contribution type';
  const searchParameter = getUrlParam('search');

  const contentTypeMapping = {
    'Parliamentary contribution': 'Spoken parliamentary contributions',
    'Question and answer': 'Written questions',
    EDM: 'EDMs',
    Tweet: 'Tweet',
    Retweet: 'Retweet',
  };

  const createSectionValues = () => {
    const values = [];
    if (!noResults) {
      if (totalCell) {
        if (totalRow) {
          Object.keys(rawData[region]).forEach((contentType) => {
            values.push({
              name: contentTypeMapping[contentType],
              value: rawData[region][contentType],
            });
          });
        } else {
          const value = Object.keys(rawData)
            .map((item) => rawData[item][contentType])
            .reduce((prev, cur) => prev + (cur ?? 0), 0);
          values.push({
            name: contentTypeMapping[contentType],
            value: value,
          });
        }
      } else {
        values.push({
          name: contentTypeMapping[dataKey],
          value: rawData[region][dataKey],
        });
      }
    }

    return values.filter((item) => item.value > 0);
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const sectionsValues = createSectionValues();
  const [activeSection, setActiveSection] = useState(
    contributionGraph && !totalCell ? dataKey : sectionsValues[0]?.name
  );
  const [isLoading, setIsLoading] = useState(!noResults);
  const [results, setResults] = useState([]);

  const callContentItems = useRef();
  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const isLoadedComponent = useRef(false);

  const { individualApiCall } = useRightPanelIndividualCall({
    analyticsState,
    requestSource: source,
    contentState,
    allowFrontbenchers,
  });

  callContentItems.current = async ({ type = activeSection, pageNumber, source }) => {
    try {
      if (noResults) {
        setResults({ hits: [] });
      } else {
        if (!pageNumber) {
          setIsLoading(true);
        }
        let snapshot = !!contentState?.sentiment;
        let callresults = await individualApiCall({
          type,
          pageNumber,
          snapshot,
          freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
          source,
        });
        if (callresults) {
          let hits = pageNumber ? results.hits : [];
          let highlights = pageNumber ? results.highlights : [];

          hits = hits.concat(callresults.hits);
          highlights = highlights.concat(callresults.highlights);

          setResults({
            ...callresults,
            hits,
            highlights,
            thereIsMoreContent: callresults.hits?.length === pageSize,
          });
          setIsLoading(false);
        }
      }
      isLoadedComponent.current = true;
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callContentItems.current({ source });
    return () => {
      source.cancel('Content items panel cancelled by the user');
    };
  }, [activeSection, allowFrontbenchers]);

  useEffect(() => {
    if (isLoadedComponent.current) {
      let valueToCompare = contributionGraph && !totalCell ? dataKey : sectionsValues[0]?.name;
      if (valueToCompare !== activeSection) {
        setActiveSection(valueToCompare);
      } else {
        callContentItems.current({});
      }
    }
  }, [contentState]);

  const load_more_results = async () => {
    let { thereIsMoreContent, pageNumber } = results;
    if (thereIsMoreContent) {
      await callContentItems.current({ pageNumber: pageNumber + 1 });
    }
  };
  const handleScroll = (scrollValues) => {
    const { scrollTop, clientHeight, scrollHeight } = scrollValues;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      load_more_results();
    }
  };

  return (
    <ContentItemsPanel
      handleScroll={handleScroll}
      setRightPanelState={setRightPanelState}
      text={text}
      sectionsValues={sectionsValues}
      isLoading={isLoading}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      results={results}
      interestByRegionPage
    />
  );
};

export default InterestByRegionRightPanel;
