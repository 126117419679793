import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions.js';
import axios from 'axios';
import { store } from 'components/Store.js';
import SkeletonRelatedContent from './SkeletonRelatedContent';
import locationSearchDescriptor from 'components/Dashboard/helpers/locationSearchDescriptor';
import TotalContributionsWidget from './TotalContributionsWidget';
import ReferenceTopicWidget from 'components/Dashboard/SubNav/ReferenceTopicsWidget';
import useEditReferenceState from 'components/Dashboard/SubNav/useEditReferenceState';
import CustomScrollbar from 'components/Common/CustomScrollbar.js';
import CreateReportFromUpdate from './CreateReportFromUpdate.js';
import PoliticalMentionsWidget from './PoliticalMentionsWidget/PoliticalMentionsWidget.js';
import { createSectionName } from 'components/Dashboard/helpers/controlActiveFilters.js';
import idsWithoutImages from 'components/Dashboard/key_updates/idsWithoutImages.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import AIassistantWidget from './AIassistantWidget.js';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

const ContentRelated = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contentResults, activeResults, net_api_url, search, keywordsLists, activePlan } = state;
  const { list } = search;
  const { enableAdvancedSearch } = activePlan;
  const keywordExclusions = list !== null ? list.keywordExclusions : [];
  const [isLoading, setIsLoading] = useState(false);
  const [openAIassistantChat, setOpenAIassistantChat] = useState(false);
  const {
    content,
    relatedStakeholders,
    relatedStakeholdersRendered,
    mainSection,
    isLoadingRelatedContent,
    totalContributions,
    reachedTheEnd,
    referencePopupProps,
    keywordsListsIds,
    data,
  } = props;

  const { setShowPopup, keywordsSection, setKeywordsSection } = referencePopupProps ?? {};
  const {
    KeyUpdate,
    Legislation,
    Consultation,
    ParliamentaryRecord,
    StakeHolders,
    HansardContent,
    CommonsLibraryArticle,
    CountrySpecificParliamentaryRecord,
    SeneddCommitteeTranscript,
  } = !!content && Object.keys(content)?.length > 0 ? content.hits : {};

  const [heightContainer, containerRef] = useHeightContainer(false);
  const StakeholderConditional = StakeHolders && StakeHolders.length > 0;
  const numberOfStakeHoldersToShow = parseInt(process.env.REACT_APP_RELATED_STAKEHOLDERS_TO_SHOW);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const sectionName = createSectionName();
  const createReportFromUpdateContainer = useRef();

  useEffect(() => {
    return () => {
      source.cancel('Content related canceled by the user.');
    };
  }, []);
  const moreNumber = () => {
    let numberOfResults = relatedStakeholders.length - relatedStakeholdersRendered;
    if (numberOfResults > numberOfStakeHoldersToShow) {
      return numberOfStakeHoldersToShow;
    } else {
      return numberOfResults;
    }
  };

  const loadMoreResults = async () => {
    setIsLoading(true);
    let stakeholderFilters = [];
    let contactsIdsCalled = [];
    for (let i = 0; i < numberOfStakeHoldersToShow; i++) {
      let contactId = relatedStakeholders[i];
      contactsIdsCalled.push(contactId);
      stakeholderFilters.push({ field: 'id', value: `${contactId}`, operator: 'str_eq' });
    }

    let requestProperties = {
      includeExplanations: list !== null,
      mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
      pageNumber: 1,
      pageSize: numberOfStakeHoldersToShow,
      query: '',
      type: 'stakeholder',
      filters: stakeholderFilters,
    };

    let stakeHolderResults = await axios.post(`${net_api_url}/api/search/query`, requestProperties, {
      cancelToken: source.token,
    });

    let newResults = content['hits'].StakeHolders.concat(stakeHolderResults.data.hits);

    let newHits = {
      ...content['hits'],
      StakeHolders: newResults,
    };

    let contentMainSection = contentResults[activeResults][mainSection];
    let newMainSection = { ...contentMainSection };
    newMainSection = {
      ...newMainSection,
      contentRelated: {
        ...newMainSection.contentRelated,
        hits: newHits,
      },
      relatedStakeholders: newMainSection?.relatedStakeholders?.filter((id) => !contactsIdsCalled.includes(id)),
      relatedStakeholdersRendered: newMainSection.relatedStakeholdersRendered + newResults.length,
    };

    let resultsForState = {
      ...contentResults,
      [activeResults]: {
        [mainSection]: newMainSection,
      },
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
    setIsLoading(false);
  };

  const parliamentaryRecordSections = () => {
    const sections = {
      QuestionAndAnswer: [],
      ScotlandParliamentaryRecord: [],
      WalesCountrySpecificParliamentaryContribution: [],
      ScotlandQuestionAndAnswer: [],
      WalesQuestionAndAnswer: [],
      NorthernIrelandQuestionAndAnswer: [],
      Edm: [],
    };
    if (ParliamentaryRecord) {
      for (let i in sections) {
        sections[i] = ParliamentaryRecord.filter((item) => item.contentType === i);
      }
    }
    return sections;
  };

  const {
    QuestionAndAnswer,
    ScotlandParliamentaryRecord,
    WalesCountrySpecificParliamentaryContribution,
    ScotlandQuestionAndAnswer,
    WalesQuestionAndAnswer,
    NorthernIrelandQuestionAndAnswer,
    Edm,
  } = parliamentaryRecordSections();

  const totalContributionsCondition = totalContributions?.hits;
  const { parliamentaryRecordWidgetSection } = useEditReferenceState();

  const topicIdUrlIsNumber =
    !!getUrlParam('topic-id') && getUrlParam('topic-id') !== 'all' && getUrlParam('topic-id') !== 'none';

  return (
    <>
      {isLoadingRelatedContent && <SkeletonRelatedContent />}
      {!isLoadingRelatedContent && (
        <div
          className={`related-content ${openAIassistantChat ? 'related-content-open-assistant' : ''}`}
          id='related-container'
        >
          <div ref={containerRef} />
          <CustomScrollbar
            className={`simple-scrollbar related-content-scroll`}
            style={{ height: `${heightContainer}px` }}
            maximalThumbYSize={100}
          >
            <div className={`h-100 ${openAIassistantChat ? 'overflow-hidden' : ''}`}>
              <div ref={createReportFromUpdateContainer}>
                <CreateReportFromUpdate show={true} currentNew={data} />
              </div>
              <AIassistantWidget
                openAIassistantChatState={{ openAIassistantChat, setOpenAIassistantChat }}
                heightContainer={heightContainer}
                createReportFromUpdateContainer={createReportFromUpdateContainer}
                data={data}
              />
              {topicIdUrlIsNumber && <PoliticalMentionsWidget currentNew={data} />}
              {parliamentaryRecordWidgetSection &&
                (keywordsListsIds?.length > 0 || getUrlParam('search') || getUrlParam('or')) && (
                  <div className='box-outstanding bg-white box-rounded mb-4 parliamentary-widget'>
                    {enableAdvancedSearch && (
                      <div className='p-3 border-bottom'>
                        <ReferenceTopicWidget
                          keywordsLists={
                            keywordsListsIds?.length > 0
                              ? keywordsLists.filter((item) => keywordsListsIds?.includes(item.id))
                              : []
                          }
                          setShowPopup={setShowPopup}
                          keywordsSection={keywordsSection}
                          setKeywordsSection={setKeywordsSection}
                        />
                      </div>
                    )}
                    {totalContributionsCondition && (
                      <TotalContributionsWidget reachedTheEnd={reachedTheEnd} hits={totalContributions.hits} />
                    )}
                  </div>
                )}

              <div className={`px-3 pt-3 box-rounded box-outstanding bg-white related-content-main-content`}>
                {Object.keys(content)?.length > 0 && (
                  <>
                    {relatedStakeholders !== null && StakeholderConditional && (
                      <>
                        <h3>Related stakeholders </h3>
                        <ul className='policy-list px-0'>
                          {StakeHolders.map((item) => {
                            const { objectID, title, contentUrl, imageId, party } = item;
                            return (
                              <Link
                                key={objectID}
                                to={`${contentUrl}${locationSearchDescriptor({ topicId: sectionName === 'KeyUpdates' ? (getUrlParam('stakeholder-topic') === 'false' ? 'all' : getUrlParam('topic-id')) : null })}`}
                                className={`related-item`}
                              >
                                <li className='d-flex align-items-center px-0'>
                                  <div
                                    className={`stakeholder-profile-image stakeholder-profile-related member-picture member-${party !== null ? party.toLowerCase() : ''}-picture`}
                                    style={{
                                      backgroundImage:
                                        imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
                                          ? `url('https://www.parliamenttoday.com/image.php?imageid=${imageId}&ml=1')`
                                          : `url('${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png')`,
                                    }}
                                  />
                                  <p className='related-title related-stakeholder-item'>{title}</p>
                                </li>
                              </Link>
                            );
                          })}
                        </ul>
                        {relatedStakeholders?.length > relatedStakeholdersRendered && (
                          <button
                            className='general-button see-more-link see-more-category see-more-related mb-3'
                            onClick={() => {
                              if (!isLoading) {
                                loadMoreResults();
                              }
                            }}
                          >
                            <span className='px-2'>See {moreNumber()} more</span>
                          </button>
                        )}
                      </>
                    )}
                    <RelatedItems content={KeyUpdate} title={'key updates'} />
                    <RelatedItems content={Consultation} title={'consultations'} />
                    <RelatedItems content={Legislation} title={'legislation'} />
                    <RelatedItems content={HansardContent} title={'Hansard updates'} />
                    <RelatedItems
                      content={CountrySpecificParliamentaryRecord}
                      title={'Devolved parliament official reports'}
                    />
                    <RelatedItems content={SeneddCommitteeTranscript} title={'Senedd Committee Transcripts'} />
                    <RelatedItems content={QuestionAndAnswer} title={'Q and As (Westminster)'} />
                    <RelatedItems content={ScotlandParliamentaryRecord} title={'official report (Scotland)'} />
                    <RelatedItems
                      content={WalesCountrySpecificParliamentaryContribution}
                      title={'official report (Wales)'}
                    />
                    <RelatedItems content={ScotlandQuestionAndAnswer} title={'Q and As (Scotland)'} />
                    <RelatedItems content={WalesQuestionAndAnswer} title={'Q and As (Wales)'} />
                    <RelatedItems content={NorthernIrelandQuestionAndAnswer} title={'Q and As (NI)'} />
                    <RelatedItems content={Edm} title={'EDMs'} />
                    <RelatedItems content={CommonsLibraryArticle} title={'library material'} />
                  </>
                )}
                {KeyUpdate?.length === 0 &&
                  Legislation?.length === 0 &&
                  ParliamentaryRecord?.length === 0 &&
                  Consultation?.length === 0 &&
                  HansardContent?.length === 0 &&
                  (!StakeHolders || (StakeHolders && StakeHolders?.length === 0)) && (
                    <>
                      <h3>Related to this</h3>
                      <p className='no-related-message'>
                        <span className='icon-warning-triangle'></span>No related content found
                      </p>
                    </>
                  )}
              </div>
            </div>
          </CustomScrollbar>
        </div>
      )}
    </>
  );
};

export const relatedItemsIconMapping = {
  'key-updates': 'key-update',
  'key-update': 'key-update',
  consultations: 'consultation',
  consultation: 'consultation',
  legislation: 'legislation',
  'briefing-material': 'briefing-material',
  'policy-ask': 'policy-ask',
  'policy-asks': 'policy-ask',
  'q-and-as-': 'parliamentary-record',
  'q-and-as-scotland-': 'parliamentary-record',
  'q-and-as-wales-': 'parliamentary-record',
  'q-and-as-ni-': 'parliamentary-record',
  'official-report-scotland-': 'parliamentary-record',
  'official-report-wales-': 'parliamentary-record',
  'q-and-as-westminster-': 'parliamentary-record',
  'hansard-updates': 'parliamentary-record',
  'parliamentary-record': 'parliamentary-record',
  'devolved-parliament-official-reports': 'parliamentary-record',
  'senedd-committee-transcripts': 'parliamentary-record',
  edms: 'parliamentary-record',
  'library-material': 'library-material',
};

const RelatedItems = (props) => {
  const { content, title } = props;
  return (
    <>
      {content.length > 0 && (
        <>
          <h3>Related {title}</h3>
          <ul className='policy-list px-0'>
            {content.map((item) => {
              const { objectID, title, contentUrl, dateTime } = item;
              return (
                <Link
                  key={objectID}
                  to={`${contentUrl}${locationSearchDescriptor()}`}
                  className={`related-item related-${normalize(props.title)}`}
                >
                  <span className={`icon-${relatedItemsIconMapping[normalize(props.title)]}`}></span>
                  <li>
                    <p className='related-title'>{title}</p>
                    <p className='related-date'>{dayjs(dateTime).format('DD MMM[,] YYYY')}</p>
                  </li>
                </Link>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

export default ContentRelated;
