import { useContext } from 'react';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import transformToPascalCase from 'components/Settings/Utilities/TransformToPascalCase';
import { store } from 'components/Store';
import addMetaTags from 'components/Dashboard/utilities/addMetaTags';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const useUpdateTitleAnalytics = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const sectionName = isAuthenticated
    ? location.pathname.replace('/analytics/', '')
    : location.pathname.replace('/data-hub/', '');

  const sectionTitle = () => {
    if (sectionName === 'top-mps-and-peers') {
      return 'Top MPs and peers';
    } else {
      return TransformFromPascalCase(transformToPascalCase(sectionName));
    }
  };

  const updateTitleAnalytics = () => {
    let title = `Analytics - ${sectionTitle()} - PolicyMogul`;
    addMetaTags({ title, hash: location.hash, location, dispatch });
  };
  return { updateTitleAnalytics, sectionTitle };
};

export default useUpdateTitleAnalytics;
