import React, { useContext, useEffect } from 'react';
import { ElectionContentBox } from './Election2024';
import SimpleSearchBar from 'components/Dashboard/Navigation/SimpleSearchBar';
import { DataHubSimpleSearch } from 'components/Dashboard/Sentiment/SentimentSearchBarPage';
import { useLocation } from 'react-router-dom';
import { store } from 'components/Store';
import addMetaTags from 'components/Dashboard/utilities/addMetaTags';

function ElectionSearch() {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const location = useLocation();
  const dataHubPage = location.pathname.includes('/data-hub');

  useEffect(() => {
    addMetaTags({
      title: `${dataHubPage ? 'Data hub' : 'Monitor issues'} - Election hub - PolicyMogul`,
      hash: location.hash,
      location,
      dispatch,
    });
  }, [dataHubPage]);

  return (
    <ElectionContentBox>
      <div className='election-search-page lobbying-election-content py-3 px-3 px-lg-5'>
        <h1 className='title-h2-bold mt-5'>
          {dataHubPage ? "Political data that's off the charts" : 'Search the political landscape'}
        </h1>
        <p className='title-h4'>
          {dataHubPage
            ? 'Search for any keyword or issue to see relevant data from across the political landscape'
            : 'Search for any keyword or issue to see relevant updates from across the political landscape'}
        </p>
        <div className='py-2 home-searchbar-container'>
          {dataHubPage ? (
            <DataHubSimpleSearch openInNewTab placeholder={'Search for any keyword or issue'} />
          ) : (
            <SimpleSearchBar homePage openInNewTab placeholder={'Search for any keyword or issue'} />
          )}
        </div>
      </div>
    </ElectionContentBox>
  );
}

export default ElectionSearch;
