import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import transformToPascalCase from 'components/Settings/Utilities/TransformToPascalCase';
import ItemNew from 'components/Dashboard/key_updates/ItemNew';
import ParliamentaryItem from 'components/Dashboard/Parliamentary_record/ParlamentaryItem';
import TweetItem from 'components/Dashboard/social_media/TweetItem';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import {
  searchIncludesTitleConditional,
  relevantActivityFalseCondition,
} from 'components/Dashboard/StakeHolders/searchIncludesTitle';
import transformGroup from 'components/Dashboard/StakeHolders/TransformGroup';
import { ActivitySkeleton } from './StakeholderPageSkeleton';
import StakeholderTopicPicker from './StakeholderTopicPicker';
import StakeholderSentimentBar from './StakeholderSentimentBar';
import { store } from 'components/Store';
import { trimLastCharacter } from 'components/Dashboard/helpers/trimLastCharacter';

const ActivityElements = React.forwardRef((props, ref) => {
  const {
    stakeholderSearchCondition,
    StakeholderInformation,
    contentType,
    contentRelated,
    isLoadingActivity,
    contentScrolled,
    searchPageQuery,
    localTopic,
    setClickOnSeeMore,
    scrollBarRef,
    filterForSentimentFunction,
    filterSentiment,
  } = props;
  const [heightContainer, containerRef] = useHeightContainer();

  const { matchInformation, officalName, name, ranking, categories } = StakeholderInformation;
  const searchCondition = (localTopic !== null && localTopic.id !== null) || searchPageQuery !== '';
  const positionsCondition =
    searchCondition &&
    stakeholderSearchCondition &&
    contentType === undefined &&
    !searchIncludesTitleConditional(officalName) &&
    !relevantActivityFalseCondition();
  const positionsConditional =
    matchInformation?.groupMemberships?.length > 0 || matchInformation?.positions?.length > 0;

  const activeSearchResultsConditional =
    ((localTopic !== null && localTopic.id !== null) || searchPageQuery !== '') && stakeholderSearchCondition;
  const location = useLocation();
  const params = useParams();
  const { id } = params;

  const noResultsConditional = () => {
    let results = [];
    for (let i in contentRelated) {
      let section = contentRelated[i];
      results.push(section?.hits);
    }
    return results.filter((item) => !!item).flat().length === 0;
  };

  const isMobile = useIsMobile();
  const { isAuthenticated } = useAuth0();
  const individualItemProperties = {
    setClickOnSeeMore,
    contentRelated,
    contentType,
    stakeholderUrl: `/stakeholders/${id}/${normalize(name)}${location.search}`,
    scrollBarRef,
  };

  const getEmptyMessage = () => {
    const ppcCpndition =
      categories?.includes('PPC 2024/25') &&
      !categories?.includes('MP') &&
      !categories?.includes('Lord') &&
      !categories?.includes('Special Adviser') &&
      !categories?.includes('MSP') &&
      !categories?.includes('MS');
    if (ppcCpndition) {
      return 'Parliamentary activity is not available for candidates';
    } else if (categories?.includes('Special Adviser')) {
      return 'Parliamentary activity is not available for Special Advisers';
    } else {
      return 'No activity to show';
    }
  };

  return (
    <>
      <div ref={containerRef} />
      <div className='d-flex flex-column' ref={ref} style={{ minHeight: `${heightContainer}px` }}>
        {(isAuthenticated || !noResultsConditional()) && (
          <div
            className={`${isMobile ? '' : 'sticky-item'} activity-title-container ${contentScrolled ? 'activity-title-scrolled' : ''}`}
          >
            <div className='activity-title-element'>
              <div className='justify-content-between row mx-0 align-items-center py-3 px-4 py-lg-4 px-lg-5'>
                {!isMobile && (
                  <Link
                    to={`${contentType === undefined ? location.pathname : `/stakeholders/${id}/${normalize(name)}${location.search}`}`}
                    className={`stake-main-title ${contentType === undefined ? '' : 'stake-main-title-content-related'}`}
                  >
                    <h3 className='mb-0 title-h4-bold'>
                      {(localTopic !== null && localTopic.id !== null) || searchPageQuery
                        ? 'Relevant activity'
                        : 'Political activity'}
                    </h3>
                  </Link>
                )}

                <div className='col-lg-8 px-0'>
                  <StakeholderTopicPicker {...props} />
                </div>
              </div>
              <SentimentBar
                ranking={ranking}
                filterForSentimentFunction={filterForSentimentFunction}
                contentType={contentType}
                localTopic={localTopic}
                filterSentiment={filterSentiment}
              />
            </div>
          </div>
        )}
        <div
          className={`stakeholder-activity-content-container flex-grow-1 ${!isAuthenticated && noResultsConditional() ? '' : 'border-right border-left bg-white'}`}
        >
          {isLoadingActivity && (
            <div className='p-3 px-lg-5 py-lg-4'>
              <ActivitySkeleton />
            </div>
          )}
          {!isLoadingActivity && (
            <>
              {noResultsConditional() && (!positionsConditional || (positionsConditional && !!filterSentiment)) ? (
                <>
                  {contentType && (
                    <h4 className='category-subtitle mt-4 px-4 px-lg-5'>
                      {TransformFromPascalCase(transformToPascalCase(contentType))}
                    </h4>
                  )}

                  {isAuthenticated ? (
                    <div className='no-activity-message text-center'>
                      <p className='mb-2'>{getEmptyMessage()}</p>
                      {filterSentiment !== null && (
                        <button
                          className='third-button general-button px-3 py-1 text-white rounded'
                          onClick={() => {
                            filterForSentimentFunction({ sentiment: null });
                          }}
                        >
                          Clear filters
                        </button>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className='box-rounded box-outstanding'>
                        <div className='bg-light-blue-hovered p-5 logged-out-activity-message'>
                          <h3 className='title-h3-bold mt-4'>
                            Try the UK’s most comprehensive political stakeholder mapping
                          </h3>
                          <p className='title-h5-m pr-lg-4'>
                            See why hundreds of organisations rely on PolicyMogul to identify the most relevant MPs,
                            peers and special advisers for their areas of interest.
                          </p>
                          <Link
                            to='/register?reg_source=stakeholder_page_free_stakeholder_map'
                            className='general-button action-button rounded-button mt-4 mb-5 d-inline-block create-stakeholder-map py-2'
                          >
                            Create a free stakeholder map
                          </Link>
                        </div>
                        <img
                          className='w-100'
                          src={`${process.env.REACT_APP_CDNURL}/images/20220826-stakeholders-mapping.png`}
                          alt='stakeholder mapping'
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className='px-4 px-lg-5 pt-4'>
                    {positionsCondition && positionsConditional && !filterSentiment && (
                      <>
                        <h4 className='category-subtitle'>Positions and committee memberships</h4>
                        <div className='parliamentarian-positions-container px-3 py-2 mb-4'>
                          <ul className='px-0 policy-list mb-0 stake-list-information'>
                            {matchInformation.positions.length > 0 && (
                              <>
                                {matchInformation.positions.map((item, index) => (
                                  <li key={`item${index}`}>
                                    <span className={`icon-star`} style={{ fontSize: '11px' }} /> {item}
                                  </li>
                                ))}
                              </>
                            )}
                            {matchInformation.groupMemberships.length > 0 && (
                              <>
                                {matchInformation.groupMemberships.map((item, index) => (
                                  <li key={`item-group${index}`}>
                                    <span className={`icon-group`} style={{ fontSize: '8px' }} /> Member of{' '}
                                    {`${item.name} ${transformGroup(item.type)}`}
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='px-4 px-lg-5 pb-1'>
                    {!activeSearchResultsConditional && (
                      <IndividualActivityItems {...individualItemProperties} type='KeyUpdate' title={'Key updates'} />
                    )}

                    <IndividualActivityItems
                      {...individualItemProperties}
                      type='ParliamentaryRecord'
                      title={'Spoken parliamentary contributions'}
                    />
                    <IndividualActivityItems
                      {...individualItemProperties}
                      type='WrittenAnswer'
                      title={'Answers to written parliamentary questions'}
                    />
                    <IndividualActivityItems
                      {...individualItemProperties}
                      type='HolyroodCountrySpecificAnswer'
                      customUrl={'country-specific-answer-holyrood'}
                      title={'Answers to written parliamentary questions (Holyrood)'}
                    />
                    <IndividualActivityItems
                      {...individualItemProperties}
                      type='SeneddCountrySpecificAnswer'
                      customUrl={'country-specific-answer-senedd'}
                      title={'Answers to written parliamentary questions (Senedd)'}
                    />
                    <IndividualActivityItems
                      {...individualItemProperties}
                      type='WrittenQuestion'
                      title={'Written parliamentary contributions'}
                    />
                    <IndividualActivityItems
                      {...individualItemProperties}
                      type='EdmPrimary'
                      title={'Early Day Motions as primary sponsor'}
                      customUrl={'early-day-motions'}
                    />
                    <IndividualActivityItems
                      {...individualItemProperties}
                      type='EdmSupported'
                      title={'Early Day Motions supported'}
                    />
                    <IndividualActivityItems {...individualItemProperties} type='tweet' title={'Tweets'} />

                    {activeSearchResultsConditional && (
                      <IndividualActivityItems {...individualItemProperties} type='KeyUpdate' title={'Key updates'} />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
});

const SentimentBar = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableSentiment } = activePlan;
  const { localTopic, contentType, ranking, filterForSentimentFunction, filterSentiment } = props;
  const { isAuthenticated } = useAuth0();
  return (
    <>
      {(enableSentiment || !isAuthenticated) &&
        (!contentType ||
          contentType === 'spoken-parliamentary-contributions' ||
          contentType === 'tweets' ||
          contentType === 'written-parliamentary-contributions') && (
          <>
            <StakeholderSentimentBar
              ranking={ranking}
              filterForSentimentFunction={filterForSentimentFunction}
              filterSentiment={filterSentiment}
              localTopic={localTopic}
            />
          </>
        )}
    </>
  );
};

const IndividualActivityItems = (props) => {
  const { contentRelated, type, title, contentType, setClickOnSeeMore, scrollBarRef, customUrl } = props;
  const section = contentRelated[type];
  const items = section?.hits ?? [];
  const location = useLocation();
  const isMobile = useIsMobile();

  return (
    <>
      {items.length > 0 && (
        <div className='mb-4'>
          <h3
            className={`category-subtitle d-block mb-2 stake-main-title line-height-1-5 ${contentType && isMobile ? 'stake-main-title-content-related' : ''}`}
          >
            {title}
          </h3>
          <ul className='policy-list items-list px-0'>
            {items.map((item, index) => {
              const props = {
                index,
                item,
                explanations: section?.explanations ?? [],
                scrollBarRef,
                useContentRelatedForFeedback: true,
                nameSection: 'Stakeholders',
              };
              switch (type) {
                case 'ParliamentaryRecord':
                case 'WrittenQuestion':
                case 'WrittenAnswer':
                case 'EdmPrimary':
                case 'EdmSupported':
                case 'HolyroodCountrySpecificAnswer':
                case 'SeneddCountrySpecificAnswer':
                  return (
                    <ParliamentaryItem
                      {...props}
                      key={`${normalize(type)}-${item.objectID}`}
                      collapsedHits={item?.collapsedHits?.hits}
                      numberOfContributions={item?.collapsedHits?.totalHits}
                    />
                  );
                case 'KeyUpdate':
                  return <ItemNew {...props} key={`${normalize(type)}-${item.objectID}`} />;
                case 'tweet':
                  return <TweetItem {...props} key={`${normalize(type)}-${item.objectID}`} />;
                default:
                  return null;
              }
            })}
          </ul>
          {section.thereIsMoreContent && contentType === undefined && (
            <Link
              className='see-more-link see-more-category'
              to={`${trimLastCharacter(location.pathname, '/')}/${customUrl ?? normalize(title)}${location.search}`}
              onClick={() => setClickOnSeeMore(true)}
            >
              <span className='px-2'>See more</span>
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default ActivityElements;
