import { consultantAgenciesMembers } from 'components/Dashboard/sidebar/parliamentarianUsers';

let masterTopicObject = (activePlan, keywordLists) => {
  let agencyUser = consultantAgenciesMembers(activePlan?.code) && !activePlan?.allowMultipleTeams;
  const createName = () => {
    if (!!activePlan && !!activePlan.teamName) {
      return `All ${agencyUser ? 'clients' : 'updates'}${!activePlan.teamName.includes('@') && !agencyUser ? ` for ${activePlan.teamName}` : ''}`;
    } else {
      return `All updates for your ${agencyUser ? 'clients' : 'topics'}`;
    }
  };
  let masterTopicName = createName();
  return {
    id: null,
    name: masterTopicName,
    masterTopic: true,
    keywords: keywordLists.flatMap((list) => list.keywords),
    andKeywords: keywordLists.flatMap((list) => list.andKeywords),
    keywordExclusions: keywordLists.flatMap((list) => list.keywordExclusions),
  };
};

export default masterTopicObject;
