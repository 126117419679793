import InitialValidations from 'components/InitialValidations';
import WritingCreate from 'components/WritingAssistant/WritingCreate';
import InfluenceHeader from './ui/InfluenceHeader';

const InfluenceAIContainer = () => {
  return (
    <div className='dashboard-root-container'>
      <InitialValidations />
      <InfluenceHeader />
      <WritingCreate individualPage={true} />
    </div>
  );
};

export default InfluenceAIContainer;
