import { useEffect, useRef } from 'react';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import normalize from 'components/Dashboard/utilities/normalizeString';
import {
  useAllowRightPanel,
  useChartDataOptionSelected,
} from 'components/Dashboard/Analytics/ui/MentionsByTopic/ChartDataTable';

const getRightPanelSubTitle = ({ contentType = null, value, region = null, rawDataItem = {} }) => {
  const contentTypeMapping = {
    'Parliamentary contribution': 'spoken parliamentary contribution',
    'Question and answer': 'written question',
    EDM: 'EDM',
    Tweet: 'Tweet',
    Retweet: 'Retweet',
  };

  const contentTypeText = () => {
    return `${region ? '' : 'total '}${contentTypeMapping[contentType]}${value !== 1 ? 's' : ''}`;
  };
  return `${thousandCommas(value)} ${
    contentType
      ? contentTypeText(contentType, value)
      : `${Object.keys(rawDataItem).length === 1 ? contentTypeMapping[Object.keys(rawDataItem)?.[0]] : 'total contribution'}${value > 1 ? 's' : ''}`
  } by${!region ? '' : ` ${region}`} MPs`;
};

const InterestByRegionChartData = (props) => {
  const {
    chartDataKeys,
    chartDataContainer,
    activeSection,
    highlightGraphOnHover,
    data,
    changeOrderTableLabel,
    rightPanelState,
    setRightPanelState,
    clearHoverOnMarker,
    analyticsState,
  } = props;

  const { restrictPanel } = analyticsState;
  const { chartDataOption } = useChartDataOptionSelected();
  const topicParameter = getUrlParam('topic-id');
  const { delayShowPanelTitle } = useAllowRightPanel();

  const { labels, rawData } = data;

  useEffect(() => {
    if (!restrictPanel) {
      setRightPanelState({ open: false });
    }
  }, [chartDataOption, topicParameter, restrictPanel]);

  const moveTable = ({ el }) => {
    if (el) {
      setTimeout(() => {
        chartDataContainer.current.scrollLeft = el.offsetLeft - 20;
      }, 10);
    }
  };
  const labelsToRender = labels;

  return (
    <>
      <div className='flex-centered justify-content-between pt-5 pb-4 mb-1 sticky-item'>
        <h3 className='title-h5-m-bold mb-0'>Chart data</h3>
      </div>
      <div
        className='chart-data-container d-flex align-items-start'
        onMouseLeave={() => {
          clearHoverOnMarker();
        }}
      >
        <div className='overflow-auto chart-data-labels-container'>
          <table className='table chart-data-labels'>
            <thead onMouseEnter={clearHoverOnMarker}>
              <tr>
                <th className='font-weight-bold'>Region</th>
              </tr>
            </thead>
            <tbody>
              {chartDataKeys.map((item, i) => {
                return (
                  <tr
                    key={`data-top-${i}`}
                    onMouseOver={() => {
                      highlightGraphOnHover({ item });
                    }}
                  >
                    <td
                      className={`${item === activeSection ? 'selected-label-total' : ''}`}
                      id={`${normalize(item)}-row`}
                    >
                      <span className='nowrap-item'>{item}</span>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className='font-weight-bold'>Total</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='overflow-auto chart-data-info-container flex-grow-1' ref={chartDataContainer}>
          <table className='table chart-data-info chart-sort-table'>
            <thead onMouseEnter={clearHoverOnMarker}>
              <tr>
                {data.labels.map((label, index) => (
                  <th
                    className={`sort-table-item nowrap-item font-weight-bold pointer`}
                    key={`label-${label}`}
                    onClick={(e) => {
                      changeOrderTableLabel(label, e);
                    }}
                    data-order={'asc'}
                  >
                    {label === 'Question and answer' ? 'Questions and answer' : label}s
                  </th>
                ))}
                <th
                  className={`sort-table-item nowrap-item font-weight-bold pointer`}
                  key={`label-total`}
                  onClick={(e) => {
                    changeOrderTableLabel('total', e);
                  }}
                  data-order={'asc'}
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {chartDataKeys.map((item, i) => {
                let points = rawData[item];
                const totalRegionVal = Object.values(rawData[item]).reduce((prev, curr) => prev + curr, 0);
                const activeCell = rightPanelState?.region === item && rightPanelState?.totalRow;
                return (
                  <tr
                    key={`data-${i}`}
                    onMouseOver={() => {
                      highlightGraphOnHover({ item });
                    }}
                  >
                    {labelsToRender.map((label, index) => {
                      let value = points[label];
                      return (
                        <CellItem
                          key={`date-${normalize(item)}-${index}`}
                          dateItem={{ value, item }}
                          activeSection={activeSection}
                          index={index}
                          i={i}
                          dataKey={label}
                          region={item}
                          rawData={rawData}
                          setRightPanelState={setRightPanelState}
                          moveTable={moveTable}
                          rightPanelState={rightPanelState}
                        />
                      );
                    })}
                    <td
                      key={`total-${item}`}
                      className={`${item === activeSection ? 'selected-label-info' : ''} label-total text-light-blue`}
                      style={activeCell ? { backgroundColor: '#0094CC' } : null}
                      onClick={(e) => {
                        setRightPanelState({
                          open: true,
                          rawData,
                          text: getRightPanelSubTitle({
                            region: item,
                            value: totalRegionVal,
                            rawDataItem: rawData[item],
                          }),
                          dataKey: 'totalCell',
                          contentType: labelsToRender,
                          chartDataKeys,
                          region: item,
                          totalRow: true,
                          noResults: thousandCommas(totalRegionVal) === 0,
                        });
                        moveTable({ el: e.target });
                        delayShowPanelTitle();
                      }}
                    >
                      <span
                        className={`cell-element text-light-blue ${activeCell ? 'text-white font-weight-bold' : ''}`}
                      >
                        {thousandCommas(totalRegionVal)}
                      </span>
                    </td>
                  </tr>
                );
              })}

              <tr>
                {labelsToRender.map((item, index) => {
                  const activeCell =
                    rightPanelState?.contentType?.[0] === item &&
                    rightPanelState?.dataKey === 'totalCell' &&
                    !rightPanelState?.totalRow;
                  return (
                    <td
                      key={`total-${item}-${index}`}
                      className={`label-total text-light-blue`}
                      style={activeCell ? { backgroundColor: '#0094CC' } : null}
                      onClick={(e) => {
                        setRightPanelState({
                          open: true,
                          rawData,
                          text: getRightPanelSubTitle({
                            contentType: item,
                            value: data.value[index],
                          }),
                          dataKey: 'totalCell',
                          contentType: [item],
                          chartDataKeys,
                          noResults: thousandCommas(data.value[index]) === 0,
                        });
                        moveTable({ el: e.target });
                        delayShowPanelTitle();
                      }}
                    >
                      <span
                        className={`cell-element text-light-blue ${activeCell ? 'text-white font-weight-bold' : ''}`}
                      >
                        {thousandCommas(data.value[index])}
                      </span>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const CellItem = (props) => {
  const { dateItem, activeSection, dataKey, region, rawData, rightPanelState, moveTable, setRightPanelState } = props;
  const value = dateItem.value ?? 0;
  const { delayShowPanelTitle } = useAllowRightPanel();

  const cellItem = useRef();
  const activeCell = rightPanelState?.region === region && rightPanelState?.dataKey === dataKey;

  return (
    <td
      ref={cellItem}
      className={`${region === activeSection ? 'selected-label-info' : ''}`}
      style={activeCell ? { backgroundColor: '#0094CC' } : null}
      onClick={(e) => {
        setRightPanelState({
          open: true,
          rawData,
          text: getRightPanelSubTitle({
            contentType: dataKey,
            value,
            region,
          }),
          dataKey,
          noResults: value === 0,
          region,
        });
        moveTable({ el: cellItem.current });
        delayShowPanelTitle();
      }}
    >
      <span className={`cell-element text-light-blue ${activeCell ? 'text-white font-weight-bold' : ''}`}>
        {thousandCommas(value)}
      </span>
    </td>
  );
};
export default InterestByRegionChartData;
