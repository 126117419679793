import React from 'react';
import renderElements from 'components/Dashboard/Upcoming_events/CalendarSearchRenderFunctions';
import EventsCategoriesInsideDates from 'components/Dashboard/Upcoming_events/EventsGroupedByDate';
const CalendarEmbed = (props) => {
  const { elements, keywordListId, embedValue, customKeywords } = props;
  const eventsToShow = EventsCategoriesInsideDates(elements);
  const widgetEmbed = embedValue ? embedValue.value : true;
  return <div className='pt-2'>{renderElements(eventsToShow, widgetEmbed, keywordListId, customKeywords)}</div>;
};

export default CalendarEmbed;
