import React, { useContext } from 'react';
import { store } from 'components/Store.js';
import { Link, useLocation } from 'react-router-dom';
import TagsLists from 'components/Dashboard/TagsList';
import getSearchWithinHash from 'components/Dashboard/utilities/getSearchWithinHash.js';
import { contentItemSearchQuery } from 'components/Dashboard/helpers/locationSearchDescriptor.js';
import Share from 'components/Dashboard/components/Share.js';
import useRememberPosition from 'components/Dashboard/hooks/useRememberPosition.js';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter.js';
import { useParams } from 'react-router-dom';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import ThumbsDown from 'components/Common/ThumbsDown.js';
import FeedbackOnItem from 'components/Dashboard/FeedbackItem/FeedbackOnItem.js';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

const LegislationItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const location = useLocation();
  const { contentResults, activeResults, search, lastSeenMarkers, itemsViewed } = state;
  const { list, activeSearch } = search;
  const { Legislation } = contentResults[activeResults];
  const { currentId } = Legislation ? Legislation : {};
  const {
    index,
    item,
    scrollBarRef,
    itemOnclick,
    embedShowTags,
    explanations,
    feedItemFlags: propsFeedItemFlags,
    itemFromWidget,
    notShowShare,
  } = props;
  const feedItemFlags = propsFeedItemFlags
    ? propsFeedItemFlags
    : Legislation !== undefined
      ? Legislation.feedItemFlags
      : [];
  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);

  const itemClass = () => {
    if (item.primaryCurrentHouse) {
      switch (item.primaryCurrentHouse) {
        case 'Commons':
          return 'legislation-commons';
        case 'Lords':
          return 'legislation-lords';
        case 'Royal Assent':
          return 'legislation-royal';
        default:
          return 'legislation-grey';
      }
    }
    if (item.secondaryMadeOrLaid) {
      return 'legislation-grey';
    }
  };

  const locationDescriptor = (path) => {
    let locationSearch = contentItemSearchQuery(location, list);
    if (inboxSection && getUrlParam('topic-id') === 'all') {
      locationSearch = `?topic-id=${feedItemFlags?.[index]?.matchingKeywordLists[0]?.id}`;
    }
    return {
      pathname: path,
      search: `${locationSearch}`,
      hash: getSearchWithinHash() ? getSearchWithinHash(true) : '',
    };
  };

  const feedItem = feedItemFlags?.[index];
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    list !== null &&
    feedItemFlags?.length > 0 &&
    !!lastSeenMarkers['legislationFeedItemId'] &&
    lastSeenMarkers['legislationFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition;

  const alreadyMarkedFeedback = feedItem?.ratingScored === 'Negative';

  const CurrentStage = () => {
    const createCurrentStageDate = () => {
      let date;
      if (item.primaryCurrentHouse === 'Royal Assent') {
        date = item.primaryCurrentHouse;
      } else {
        date =
          item.primaryNextEventName !== null
            ? item.primaryNextEventName.replace('Date to be announced', 'Date TBA')
            : '';
      }
      return date;
    };
    return (
      <>
        {item.primaryCurrentHouse && createCurrentStageDate() && (
          <div className='current-house-item information-date'>
            <p>
              Current stage: <span className={` ${itemClass()}`}>{createCurrentStageDate()}</span>
            </p>
          </div>
        )}
      </>
    );
  };

  const { rememberPosition } = useRememberPosition();

  return (
    <>
      <li
        className={`my-2 content-item ${currentId === item.id ? 'active' : ''} adjustment-unseen-dot-parliamentary`}
        style={{ zIndex: props.array.length - props.index }}
      >
        <Link
          to={locationDescriptor(`${item.contentUrl}`)}
          onClick={(e) => {
            if (scrollBarRef) {
              rememberPosition('Legislation', scrollBarRef, index);
            }
            if (itemOnclick) {
              itemOnclick(e);
            }
          }}
        >
          {unseenConditional && (
            <>
              <span className='unseen-dot' />
            </>
          )}
          <div className='p-4'>
            <div className='d-flex align-items-start'>
              <div className='flex-grow-1 row mx-0 align-items-start legislation-title-container'>
                <div className={`legislation-title`}>
                  <h4 className={`mb-2 content-item-title`}>{item.title}</h4>
                  <p className='heading-date mb-1 px-0 information-date'>
                    <span className='date'>
                      {item.primaryTypeOfBill}
                      {item.contentType === 'Secondary' && <> legislation</>}
                    </span>
                    {!!item.primarySession && (
                      <>
                        <span className='dot'></span>Session {item.primarySession}
                      </>
                    )}
                  </p>
                  <CurrentStage />
                  {((list !== null && activeSearch && !itemFromWidget) || embedShowTags) && (
                    <TagsLists
                      explanations={explanations ?? Legislation?.explanations}
                      feedItemFlags={feedItemFlags}
                      index={index}
                      title={'Legislation'}
                      section={'Legislation'}
                    />
                  )}
                </div>
              </div>
              {!notShowShare && (
                <div className={`col-2 px-0 justify-content-end flex-centered`}>
                  <ThumbsDown index={index} item={item} />
                  <Share item={item} listItem />
                </div>
              )}
            </div>
          </div>
        </Link>
        {alreadyMarkedFeedback && <FeedbackOnItem item={item} index={index} />}
      </li>
    </>
  );
};

export default LegislationItem;
