import { useCallback, useContext } from 'react';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { store } from 'components/Store';
import useGroupFiltersData from './GroupsFiltersData';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const useGroupFunctions = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activeResults, contentResults, keywordsLists, team } = state;
  const { teamName } = team;
  const { generalApiCall } = useGeneralApiCall();
  const { GroupsFiltersData, groupsPreselectedFiltersData, categoriesWithRequiredTopicInput } = useGroupFiltersData();
  const { newAgencyPlan, agencyUser } = useTopicOrClientWord();
  const createFilterName = ({ filter }) => {
    if (filter) {
      const { category, topicDate, value } = filter;
      let filterName = category?.filterName;
      if (category?.type === 'picker') {
        filterName = `${filterName}${topicDate?.filterValue}`;
      } else if (category?.type === 'selectSentiment') {
        filterName = `${filterName}${value?.name}`;
      }
      return filterName;
    }
  };

  const createDynamicQueries = (filters) => {
    return filters
      .map((item) => {
        const { groupFilters } = item;
        let localFilters = [];
        groupFilters.forEach((filter) => {
          const { operator, value, category, sentimentTopic } = filter;
          const filterName = createFilterName({ filter });
          if (Array.isArray(value)) {
            if (operator.value === 'str_eq') {
              let values = [];
              value.forEach((valueItem) => {
                if (Array.isArray(valueItem?.filterValue)) {
                  values = [...values, ...valueItem?.filterValue];
                } else {
                  values = [...values, valueItem?.filterValue ?? valueItem?.name];
                }
              });
              localFilters.push({
                field: filterName,
                operator: operator?.value,
                values,
              });
            } else {
              value.forEach((valueItem) => {
                if (Array.isArray(valueItem?.filterValue)) {
                  valueItem?.filterValue?.forEach((item) => {
                    localFilters.push({
                      field: filterName,
                      operator: operator?.value,
                      value: item,
                    });
                  });
                } else {
                  localFilters.push({
                    field: filterName,
                    operator: operator?.value,
                    value: valueItem?.filterValue ?? valueItem?.name,
                  });
                }
              });
            }
          } else {
            localFilters.push({
              field: filterName,
              operator: operator?.value,
              value: category?.type === 'selectSentiment' ? `${sentimentTopic?.id}` : value?.filterValue ?? value?.name,
            });
          }
        });
        return {
          filters: localFilters,
        };
      })
      .filter((item) => item?.filters?.length > 0);
  };

  const getGroupInformation = ({ group }) => {
    const { dynamicQueries } = group;
    const dynamicFilters = dynamicQueries?.flatMap((item) => item?.filters);
    let filters = [];
    if (dynamicFilters.length > 0) {
      dynamicQueries.forEach((item) => {
        let groupFilters = [];
        item.filters.forEach((filter) => {
          const { operator, value, values } = filter;
          const field = filter?.field?.includes('topicMatchedKeywordListIds')
            ? 'topicMatchedKeywordListIds'
            : filter?.field;

          const sentiments = ['Positive', 'Negative', 'Mixed', 'Neutral'];
          const sentimentField =
            field === 'topicMatchedKeywordListIds'
              ? sentiments.includes(filter?.field?.split('topicMatchedKeywordListIds')?.[1])
              : false;

          const { categories, operators, topicDates } = GroupsFiltersData;
          const category = sentimentField
            ? categories?.find((cat) => cat?.type === 'selectSentiment')
            : categories?.find((cat) => cat?.filterName === field || cat?.name?.toLowerCase() === field);
          const condition = operators.find((ope) => ope.value === operator);

          let finalValue = [];
          let sentimentTopic;
          let topicDate;
          //AE: VALUE IS FOR STR_NOT_EQ AND VALUES IS FOR STR_EQ
          if (category?.type === 'select') {
            if (value) {
              finalValue.push(
                category?.options?.find((option) => {
                  const valueToCompare = option?.filterValue ?? option?.name;
                  return valueToCompare === value;
                })
              );
            }
            if (values) {
              values.forEach((splitValue) => {
                finalValue.push(
                  category?.options?.find((option) => {
                    const valueToCompare = option?.filterValue ?? option?.name;
                    return valueToCompare === splitValue;
                  })
                );
              });
            }
          } else if (category?.type === 'picker') {
            const filterSplit = filter?.field?.split('topicMatchedKeywordListIds');
            const dateValue = filterSplit[1];
            topicDate = topicDates?.find((item) => item?.filterValue === dateValue);
            if (value) {
              const topic = keywordsLists?.find((item) => item?.id === parseInt(value));
              if (!!topic) {
                finalValue = { ...topic, filterValue: `${topic?.id}` };
              }
            }
            if (values) {
              values.forEach((splitValue) => {
                const topic = keywordsLists?.find((item) => item?.id === parseInt(splitValue));
                if (!!topic) {
                  finalValue.push({ ...topic, filterValue: `${topic?.id}` });
                }
              });
            }
          } else if (category?.type === 'selectSentiment') {
            const filterSplit = filter?.field?.split('topicMatchedKeywordListIds');
            const sentimentValue = filterSplit[1];
            finalValue = { name: sentimentValue };
            if (value) {
              const topic = keywordsLists?.find((item) => item?.id === parseInt(value));
              if (!!topic) {
                sentimentTopic = topic;
              }
            }
          } else {
            if (value) {
              finalValue.push({ name: value });
            }
            if (values) {
              values.forEach((splitValue) => {
                finalValue.push({ name: splitValue });
              });
            }
          }
          groupFilters.push({
            category,
            operator: condition,
            value: finalValue,
            topicDate: topicDate ?? topicDates?.find((item) => item?.name === '365 days'),
            sentimentTopic,
          });
        });
        //AE: CASE FOR NOT_STR_EQ values, left in the case we need it
        groupFilters = groupFilters.reduce((acc, current) => {
          const existing = acc.find(
            (item) =>
              item.category?.name === current.category?.name &&
              item.operator?.name === current.operator?.name &&
              item?.operator?.value === 'str_not_eq'
          );
          if (existing) {
            const newValues = current.value.filter(
              (newItem) => !existing.value.some((existingItem) => existingItem.name === newItem.name)
            );
            existing.value = [...existing.value, ...newValues];
          } else {
            acc.push({ ...current });
          }
          return acc;
        }, []);

        filters.push({ groupFilters });
      });
    } else {
      filters = [{ groupFilters: [] }];
    }

    return {
      ...group,
      filters,
    };
  };

  const deleteGroup = async ({ id }) => {
    try {
      const pathname = `/api/crm-contact/delete-crm-group?id=${parseInt(id)}`;
      const method = 'delete';
      await generalApiCall({ method, pathname, needsAuthentication: true });
    } catch (error) {}
  };

  const saveGroup = async ({ groupInformation, update }) => {
    try {
      const { name, description, filters, id, type } = groupInformation;
      const pathname = `/api/crm-contact/${update ? 'update' : 'create'}-crm-group`;
      const method = 'post';
      let requestProperties = {
        name,
        description,
        type,
        dynamicQueries:
          type === 'Static' ? null : createDynamicQueries(filters)?.filter((item) => item?.filters?.length > 0),
      };
      if (id) {
        requestProperties = {
          ...requestProperties,
          id,
        };
      }
      return await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
    } catch (error) {}
  };

  const previewGroup = async ({ filters, source, id, pageSize = 20 }) => {
    try {
      const pathname = `/api/crm-contact/preview-${id ? '' : 'un'}saved-crm-group`;
      const method = 'post';
      let requestProperties = {
        pageNumber: 1,
        pageSize,
      };
      if (id) {
        requestProperties = {
          ...requestProperties,
          id,
        };
      } else {
        requestProperties = {
          ...requestProperties,
          queries: createDynamicQueries(filters),
        };
      }
      return await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
      });
    } catch (error) {}
  };

  const listingGroups = async ({ source, includeCounts }) => {
    try {
      const pathname = `/api/crm-contact/list-crm-groups${includeCounts ? '?includeCounts=true' : ''}`;
      const method = 'get';
      const results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
      });

      if (!!results) {
        const GroupsResults = {
          ...contentResults,
          [activeResults]: {
            ...contentResults[activeResults],
            Groups: results.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            }),
          },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: GroupsResults,
        });
        return results;
      }
    } catch (error) {}
  };

  const getGroup = async ({ id, source }) => {
    try {
      const pathname = `/api/crm-contact/get-crm-group?id=${id}`;
      const method = 'get';
      const result = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
        returnError: true,
      });
      if (result?.status === 404) {
        return result;
      } else {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'group',
          value: getGroupInformation({ group: result }),
        });
        return result;
      }
    } catch (error) {}
  };

  const listingGroupsForStakeholderOrContact = async ({ stakeholderContactId, contactId }) => {
    try {
      const pathname = `/api/crm-contact/${stakeholderContactId ? 'get-groups-by-stakeholder-contact-id?stakeholderContactId=' : 'get-groups-by-crm-contact-id?crmContactId='}${stakeholderContactId ?? contactId}`;
      const method = 'get';
      const results = await generalApiCall({ method, pathname, needsAuthentication: true, notShowErrorMessage: true });
      if (!!results) {
        const { crmContactGroups } = results;
        return crmContactGroups;
      }
    } catch (error) {}
  };

  const addRemoveContactToGroup = async ({ contactId, groupId, action = 'add' }) => {
    try {
      const pathname = `/api/crm-contact/${action}-crm-contact-${action === 'add' ? 'to' : 'from'}-group`;
      const method = 'post';
      const requestProperties = {
        crmContactId: contactId,
        crmContactGroupId: groupId,
      };
      return await generalApiCall({
        method,
        pathname,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestProperties,
      });
    } catch (error) {}
  };

  const adjustGroupInformation = useCallback((groupInformation) => {
    const filterNamesMapping = () => {
      return {
        'Vocal about...': 'Vocal about',
        'Positive about...': 'Positive about',
        'Negative about...': 'Negative about',
      };
    };
    let newGroupInformation = { ...groupInformation };
    const groupFilters = groupInformation?.filters?.[0]?.groupFilters;
    const initialDescription = groupsPreselectedFiltersData.find(
      (filter) => filter.description === groupInformation.description
    )?.description;
    const initialName = groupsPreselectedFiltersData.find((filter) => filter.label === groupInformation.name)?.label;
    const changeNameCondition = groupInformation?.filters?.length === 1 && !!initialDescription && !!initialName;
    if (changeNameCondition) {
      const category = GroupsFiltersData.categories.find(
        (category) => category.name === groupFilters?.[0]?.category?.name
      );

      const championsDescription = groupsPreselectedFiltersData.find(
        (filter) => filter.type === 'champions'
      )?.description;

      if (category && !!initialDescription && !!initialName) {
        if (groupInformation.description === championsDescription) {
          let newName = initialName;
          newName = newName.replace(agencyUser || newAgencyPlan ? 'Client' : 'Our', teamName);
          newGroupInformation = {
            ...newGroupInformation,
            description: initialDescription?.replace(' you ', ' we '),
            name: newName,
          };
        } else if (categoriesWithRequiredTopicInput.includes(category.name)) {
          const topicFilter = groupFilters?.find((item) => item?.category?.filterName === 'topicMatchedKeywordListIds');
          newGroupInformation = {
            ...newGroupInformation,
            name: `${filterNamesMapping()[initialName]} ${topicFilter?.sentimentTopic?.name ?? topicFilter?.value?.name}`,
            description: initialDescription
              // OP: Most vocal case
              ?.replace('any topic', topicFilter?.value?.name)
              //OP: sentiment case
              ?.replace('in relation to any topic', `about ${topicFilter?.sentimentTopic?.name}`),
          };
        }
      }
    }
    return newGroupInformation;
  }, []);

  const allowProceedSaveGroup = (filters) => {
    return !filters.every((filter) =>
      filter?.groupFilters?.every((groupFilter) => {
        return (
          (groupFilter?.isSaved && categoriesWithRequiredTopicInput.includes(groupFilter?.category?.name)) ||
          !categoriesWithRequiredTopicInput.includes(groupFilter?.category?.name)
        );
      })
    );
  };

  return {
    deleteGroup,
    saveGroup,
    createDynamicQueries,
    previewGroup,
    listingGroups,
    getGroupInformation,
    getGroup,
    listingGroupsForStakeholderOrContact,
    addRemoveContactToGroup,
    adjustGroupInformation,
    allowProceedSaveGroup,
  };
};

export { useGroupFunctions };
