import { useContext, useEffect, useRef, useState } from 'react';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import DropdownComponent from './DropdownComponent';
import { store } from 'components/Store';
import normalize from 'components/Dashboard/utilities/normalizeString';
import Resources from 'components/Settings/Topics/Resources';
import ReactGA from 'react-ga4';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { ReportDesignPopup } from 'components/Reports/ui/ReportDesignButton';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ComponentClientSelector } from 'components/Dashboard/Navigation/ClientsPicker';
import { ClientOrTopicsToDisplay, SubheaderComponent } from 'components/EmbedAnalytics/EmbedAnalyticsSettings';

const WebsiteFeedSettings = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, clients } = state;

  const {
    selectedTopics,
    setSelectedTopics,
    resources,
    setResources,
    uponClickOption,
    setUponClickOption,
    userId,
    setShowPopup,
    scrollPosition,
    designProps,
    headerState,
    titleElement,
    combineState,
    clientState,
    topicsByClient,
    masterTopic,
  } = props;

  const { headerValues, setHeaderValues } = headerState ?? {};
  const { combineUpdates, setCombineUpdates } = combineState ?? {};
  const { client, setClient } = clientState ?? {};

  const { designState, setDesignState } = designProps;
  const [codeToCopy, setCodeToCopy] = useState('');

  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const referenceValues = [
    'Key updates',
    'Consultations',
    'Legislation',
    'Parliamentary record',
    'Library material',
    'Upcoming events',
  ];

  const initialQuery = () => {
    let initialQuery = !!userId
      ? `userId=${userId}`
      : `${selectedTopics?.length === 1 ? 'keywordListId' : 'topicsIds'}=${selectedTopics.map((item) => item.id)?.join(',')}`;
    if (
      selectedTopics?.length > 1 &&
      ((team?.teamId && newAgencyPlan) || (!newAgencyPlan && (!combineUpdates || !masterTopic)))
    ) {
      initialQuery += `&teamId=${newAgencyPlan ? client?.teamId : team?.teamId}`;
    }
    return initialQuery;
  };

  const generateCodeFunction = () => {
    let finalResources = resources.slice();
    if (finalResources.includes('HandPickedKeyUpdates')) {
      let position = finalResources.indexOf('HandPickedKeyUpdates');
      finalResources[position] = 'KeyUpdates';
    }
    let iframeUrl = `${window.location.origin}/embed/?${initialQuery()}&resources=${finalResources.join(',')}&openItem=${uponClickOption.name}&backgroundColour=${encodeURIComponent(designState?.backgroundColour)}&headerColour=${encodeURIComponent(designState?.headerColour)}&barColour=${encodeURIComponent(designState?.barColour)}&includeBranding=${encodeURIComponent(designState?.includeBranding)}&headerText=${encodeURIComponent(headerValues?.header)}&subheaderText=${encodeURIComponent(headerValues?.subheader)}`;

    return `<div style="max-width: 550px; height: 700px;"><iframe width="100%" style="border-radius: 8px;" height="100%" src="${iframeUrl}" title="PolicyMogul embed content" frameborder="0"> </iframe></div>`;
  };

  const changeState = ({ property, newValue }) => {
    let newState = { ...designState };
    newState[property] = newValue;
    setDesignState(newState);
  };

  return (
    <>
      <div
        className='section-title mb-4 px-4 pb-3 flex-centered justify-content-between bg-white website-popup-title main-sticky-lg'
        style={{ minHeight: '72px', top: `${titleElement?.current?.getBoundingClientRect()?.height}px` }}
      >
        <h3 className='topic-title  mb-0'>
          <span className='d-none d-lg-inline-block'>Website feed</span>{' '}
          <span className='website-popup-settings-title'>settings</span>
        </h3>

        <div className='d-flex justify-content-end'>
          <button
            className={`${codeToCopy === '' ? 'd-inline-block' : 'd-none'} nowrap-item generate-code-button general-button action-button no-select-button px-4 }`}
            onClick={() => {
              let code = generateCodeFunction();
              setCodeToCopy(code);
              ReactGA.event({
                category: 'Widget',
                action: 'Code generated',
                label: selectedTopics[0]?.name,
              });
            }}
          >
            Generate code {agencyUser ? 'for client' : ''}
          </button>
          <button
            className={`${codeToCopy === '' ? 'd-none' : 'd-inline-block'} general-button mr-4 simple-link-button no-select-button`}
            onClick={() => {
              setCodeToCopy('');
            }}
          >
            {'Back'}
          </button>

          <button
            className={`${codeToCopy === '' ? 'd-none' : 'd-inline-block'}  generate-code-button general-button action-button no-select-button px-3 copy-action-button`}
            onClick={() => {
              let tempInput = document.createElement('input');
              tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
              tempInput.value = codeToCopy;
              document.body.appendChild(tempInput);
              tempInput.select();
              document.execCommand('copy');
              document.body.removeChild(tempInput);
              createNotification('success', `Code copied`);
              setShowPopup(false);
            }}
          >
            Copy code
          </button>
        </div>
      </div>
      <div className='px-4'>
        <div className='pb-3 topic-email-alerts-content pt-2'>
          {codeToCopy === '' && (
            <>
              {newAgencyPlan && (
                <div className='mb-5'>
                  <label className='mb-2'>Client</label>
                  <ComponentClientSelector
                    clientsLists={clients}
                    activeClientState={{ activeClient: client }}
                    selectClientFunction={({ client }) => {
                      setClient(client);
                    }}
                    notShowActions={true}
                    maximumZIndex={true}
                  />
                </div>
              )}
              <div className='mb-2'>
                <div className='keywords-website-settings'>
                  <ClientOrTopicsToDisplay
                    notAddMargin={true}
                    selectedTopics={selectedTopics}
                    setSelectedTopics={setSelectedTopics}
                    feed={true}
                    keywordsLists={newAgencyPlan ? topicsByClient : null}
                    client={client}
                    scrollBarPosition={scrollPosition}
                  />
                </div>
              </div>

              {masterTopic && (!newAgencyPlan || (newAgencyPlan && topicsByClient?.length > 0)) && (
                <div className='pl-2'>
                  <div className={`checkbox-item mt-4 mb-5 ${masterTopic ? '' : 'checkbox-item-disabled'}`}>
                    <input
                      type='checkbox'
                      id='combine-topics'
                      disabled={!masterTopic}
                      value={combineUpdates}
                      checked={combineUpdates}
                      onChange={(e) => {
                        setCombineUpdates(e.target.checked);
                      }}
                    />
                    <label htmlFor='combine-topics' className='pl-2 font-weight-normal'>
                      <span className='d-inline-block' style={{ marginTop: '2px' }}>
                        Combine topics
                      </span>
                      <span
                        style={{ marginTop: '-1px', verticalAlign: 'middle' }}
                        className='d-none d-lg-inline-block icon-info light-50-text d-inline-block pointer paragraph-p3 ml-2'
                        data-tooltip-class-name={'general-tooltip pricing-feature-tooltip report-popup-tooltip'}
                        data-tooltip-content={`When all topics are selected, website visitors will see all updates in one feed. If you would like people to be able to see updates for one topic at a time, de-select this option. You'll be able to compare the difference in the example to the right`}
                        data-tooltip-id={'embed-settings-tooltip'}
                      />
                    </label>
                  </div>
                </div>
              )}

              <div className='my-5'>
                <label>Updates to include </label>
                <div className='mt-2'>
                  <Resources
                    referenceValues={referenceValues}
                    resources={resources}
                    changeResources={setResources}
                    allow={true}
                    scrollPosition={scrollPosition}
                    closeOnScroll={true}
                  />
                </div>
              </div>
              <div className='my-5'>
                <label>Header text</label>
                <div className='mt-2'>
                  <input
                    className='main-input rounded px-3 py-2'
                    value={headerValues?.header}
                    onChange={(e) => setHeaderValues({ ...headerValues, header: e.target.value })}
                  />
                </div>
              </div>

              <SubheaderComponent
                selectedTopics={selectedTopics}
                subheader={headerValues?.subheader}
                setSubheader={(value) => setHeaderValues({ ...headerValues, subheader: value })}
                masterTopic={masterTopic && combineUpdates}
              />

              <div className='my-4'>
                <label>
                  Upon clicking an update...{' '}
                  <span
                    className='d-none d-lg-inline-block icon-info light-50-text d-inline-block pointer paragraph-p3 ml-2'
                    data-tooltip-html={`Choose what happens when<br/> one of your ${
                      agencyUser ? "client's" : ''
                    } website visitors<br/> clicks on an update`}
                    data-tooltip-id='embed-settings-tooltip'
                  />
                </label>
                <div className='mt-2'>
                  <DropdownComponent
                    mainItem={() => (
                      <span className='d-inline-block' style={{ marginTop: '1px', marginLeft: '1px' }}>
                        {uponClickOption?.label}
                      </span>
                    )}
                  >
                    <ClickingUpdateOptions uponClickOption={uponClickOption} setUponClickOption={setUponClickOption} />
                  </DropdownComponent>
                </div>
              </div>
              <div className='mt-5'>
                <ReportDesignPopup
                  property={'enableEmbedWidgetRemoveBranding'}
                  designState={designState}
                  changeState={changeState}
                  componentText={'feed'}
                  feed={true}
                />
              </div>
            </>
          )}
          {codeToCopy !== '' && (
            <>
              <p>
                Share this code with{' '}
                {newAgencyPlan
                  ? `${client?.teamName}`
                  : `your
                ${agencyUser ? 'client' : 'developers'}`}
              </p>
              <CodeElement codeToCopy={codeToCopy} />
            </>
          )}
        </div>
      </div>
      <ReactTooltip
        effect={'solid'}
        id={'embed-settings-tooltip'}
        className={'header-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
      />
    </>
  );
};

const ClickingUpdateOptions = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableEmbedWidgetOpenContentInWidget } = activePlan;
  const { setUponClickOption, uponClickOption } = props;

  const options = [
    {
      name: 'separateTab',
      label: 'Content opens in PolicyMogul (separate tab)',
      allowed: true,
    },
    {
      name: 'withinEmbed',
      label: 'Content is displayed within the embedded feed',
      allowed: enableEmbedWidgetOpenContentInWidget,
    },
  ];

  return (
    <div className='px-3 py-2'>
      {options.map((item, index) => {
        const { allowed, name, label } = item;
        return (
          <div
            className={`radio-button uponclick-radio-button ${allowed ? '' : 'handpicked-greyout'}`}
            key={`sort-filter${index}`}
            data-tooltip-content={allowed ? '' : 'Available on Mogul or Enterprise'}
            data-tooltip-id={'general-tooltip'}
            data-tooltip-float={true}
          >
            <input
              id={normalize(name)}
              onChange={() => {
                if (allowed) {
                  setUponClickOption(item);
                }
              }}
              type='radio'
              checked={uponClickOption.name === name}
            />
            <label htmlFor={normalize(name)} className='font-weight-normal'>
              {label}
              {!allowed && <span className='icon-padlock ml-2' />}
            </label>
          </div>
        );
      })}
    </div>
  );
};

const CodeElement = (props) => {
  const { codeToCopy } = props;

  useEffect(() => {
    const selectText = () => {
      setTimeout(() => {
        if (codeText.current !== null) {
          if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(codeText.current);
            range.select();
          } else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(codeText.current);
            selection.removeAllRanges();
            selection.addRange(range);
          } else {
            console.warn('Could not select text in node: Unsupported browser.');
          }
        }
      }, 50);
    };
    selectText();
    const selectKeydown = (e) => {
      if (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        selectText();
      }
    };
    window.addEventListener('keydown', selectKeydown);
    return () => {
      window.removeEventListener('keydown', selectKeydown);
    };
  }, []);
  const codeText = useRef(null);
  return (
    <p className='iframe-code' ref={codeText}>
      {codeToCopy}
    </p>
  );
};
export { CodeElement };
export default WebsiteFeedSettings;
