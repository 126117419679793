import React, { useState, useCallback, useRef } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import { formatHour } from './Helpers/FormatHour';
const HourSelect = (props) => {
  const [showHours, setShowHours] = useState(false);
  const { changeHour, hour, allow } = props;
  const hourElement = useRef(null);
  const hideHour = () => {
    setShowHours(false);
  };
  useOutsideElement(hourElement, hideHour);
  const setHour = (hour) => {
    changeHour(hour);
    setShowHours(false);
  };
  const createHours = () => {
    let items = [];
    for (let i = 0; i < 24; i++) {
      let hourToRender = `${i < 10 ? '0' : ''}${i}`;
      items.push(
        <div key={`hour${i}`}>
          <HourItem hour={`${hourToRender}:00`} setHour={setHour} selectedHour={hour} />
          <HourItem hour={`${hourToRender}:30`} setHour={setHour} selectedHour={hour} />
        </div>
      );
    }
    return items;
  };

  return (
    <>
      <div className='position-relative' ref={hourElement} style={{ width: 'fit-content' }}>
        <button
          className={`general-button pl-3 pr-4 mb-0 hour-select ${allow ? '' : 'disabled-hour-select'} ${showHours ? 'hour-select-opendropdown' : ''}`}
          onClick={() => {
            if (allow) {
              setShowHours(!showHours);
            }
          }}
        >
          {formatHour(hour)}
        </button>

        {showHours && (
          <>
            <div className='frequency-dropdown scroll-container search-bar-lists' id='hour-dropdown'>
              {createHours()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const HourItem = (props) => {
  let { hour, setHour, selectedHour } = props;
  let scrollHour = useCallback((node) => {
    if (node !== null) {
      if (hour === selectedHour) {
        let top = node.getBoundingClientRect().top;
        let element = document.getElementById('hour-dropdown');
        let elementTop = element.getBoundingClientRect().top;
        element.scrollTop = top - elementTop;
      }
    }
  }, []);
  return (
    <p
      className={`mb-0 hour-item ${hour === selectedHour ? 'hour-item-selected' : ''}`}
      onClick={() => setHour(hour)}
      ref={scrollHour}
    >
      {`${hour}`}{' '}
    </p>
  );
};

export default HourSelect;
